import React, { createContext, useReducer, useEffect } from 'react';
import { ErrorHandleSwitch } from '../Components/Router';
import { Route } from 'react-router-dom';
import FilterList from './FilterList';
import FilterDetail from './FilterDetail';
import ItemList from './ItemList';
import reducer, { initialState, setAuthority } from './reducer';
import useAsync from '../../../util/hooks/useAsync';
import { getUserInfoApi } from '../../../api/common';

export const FilterStateContext = createContext();
export const FilterDispatchContext = createContext();

const Filter = ({ match }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const { promise: getUserInfo } = useAsync({
        promise: getUserInfoApi,
        fixedParam: { isIntegrated: 'Y' },
        resolve: res => {
            const userInfo = { userList: [], groupList: [] };
            if (res) {
                if (res.userInfo && res.userInfo.userList) {
                    userInfo.userList = res.userInfo.userList;
                }
                if (res.groupInfo && res.groupInfo.groupList) {
                    userInfo.groupList = res.groupInfo.groupList;
                }
            }
            dispatch(setAuthority(userInfo));
        },
    });

    useEffect(() => {
        getUserInfo();
    }, []);

    return (
        <FilterDispatchContext.Provider value={dispatch}>
            <FilterStateContext.Provider value={state}>
                <ErrorHandleSwitch>
                    <Route exact path={`${match.path}/list`} component={FilterList} />
                    <Route
                        exact
                        path={[`${match.path}/create`, `${match.path}/:filterNum([0-9]+)`]}
                        component={FilterDetail}
                    />
                    <Route exact path={`${match.path}/item/list/:filterNum([0-9]+)`} component={ItemList} />
                    {/*<Route exact path={`${match.path}/create`} component={CreateFilter} />*/}
                    {/*<Route exact path={`${match.path}/:filterNum([0-9]+)/edit`} component={EditFilter} />*/}
                </ErrorHandleSwitch>
            </FilterStateContext.Provider>
        </FilterDispatchContext.Provider>
    );
};

export default Filter;
