import React, { useState } from 'react';
import Filter from '../../../Filter';
import { useParams } from 'react-router-dom';
import Category from '../../../Filter/FilterOption/Category';
import Floor from '../../../Filter/FilterOption/Floor';
import AssetDetail from '../AssetDetail';
import Card from '../../../Common/Card';
import Search from '../../../Search';
import SearchAutocomplete from '../../../Search/SearchOption/SearchAutocomplete';
import useTranslation from '../../../../util/hooks/useTranslation';
import Authorization from '../../../Filter/FilterOption/Authorization';
import ConfirmModal from '../../../Common/ConfirmModal';
import { SearchWrap, InputWrap, InputGroup } from '../../../Common/FilterSearchGroup/Components/Part';
import Button from '../../../Common/Button';

const QuickSearch = () => {
    const t = useTranslation('QuickSearch');
    const { targetId } = useParams();
    const [targetNum, setTargetNum] = useState(0);
    const [validation, setValidation] = useState(false);
    const [emptyInput, setEmptyInput] = useState(false);
    const [inputErrorModal, setInputErrorModal] = useState(false);

    const handleClick = () => {
        if (!targetNum) {
            setInputErrorModal(true);
        } else {
            setValidation(true);
        }
    };

    const handleTargetNum = selected => {
        setValidation(false);
        if (selected.length > 0) {
            setEmptyInput(false);
            setTargetNum(selected[0].targetNum);
        } else {
            setTargetNum(null);
        }
    };

    const refreshSearchSection = () => {
        setEmptyInput(!emptyInput);
    };

    return (
        <div>
            {!targetId && (
                <Filter
                    searchRefresh={refreshSearchSection}
                    Search={
                        <Search>
                            <div className="search-box flx-row flx-top gap-3">
                                <SearchWrap>
                                    <InputWrap>
                                        <InputGroup>
                                            <SearchAutocomplete
                                                handleSelectedKeyword={handleTargetNum}
                                                emptyInput={emptyInput}
                                            />
                                        </InputGroup>
                                        <Button
                                            className={'btn-brand btn-icon'}
                                            iconClassName={'icon-search'}
                                            onClick={handleClick}
                                        >
                                            {t('Search', 'Search')}
                                        </Button>
                                    </InputWrap>
                                </SearchWrap>
                            </div>
                        </Search>
                    }
                >
                    <Category />
                    <Floor />
                    <Authorization />
                </Filter>
            )}

            <Card
                header={{
                    title: t('Selected Asset'),
                }}
                className="z-index-0 quick-search-height"
            >
                <div style={{ height: '43rem' }}>
                    <AssetDetail targetNum={targetId || (validation && targetNum)} />
                </div>
            </Card>
            <ConfirmModal
                headerText={t('Error', 'Modal')}
                removeCancel
                confirmText={t('No assets are selected. Please select an asset.')}
                initModal={inputErrorModal}
                toggleModal={() => setInputErrorModal(!inputErrorModal)}
            />
        </div>
    );
};

export default QuickSearch;
