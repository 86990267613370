import React from 'react';
import { Route } from 'react-router-dom';
import AssetDetail from './AssetDetail';
import AssetList from './AssetList';
import QuickSearch from './QuickSearch';
import { ErrorHandleSwitch } from '../Components/Router';

const Assets = ({ match }) => {
    return (
        <ErrorHandleSwitch>
            <Route exact path={`${match.path}/detail`} component={AssetDetail} />
            <Route exact path={`${match.path}/list`} component={AssetList} />
            <Route
                exact
                path={[`/asset/quickSearch`, `${match.path}/quickSearch/:targetId([0-9]+)`]}
                component={QuickSearch}
            />
        </ErrorHandleSwitch>
    );
};

export default Assets;
