import React, { useState } from 'react';
import Button from '../Button';
import Label from '../Label';
import Card from '../Card';
import TextInput from '../Input/TextInput';
import DateInput from '../Input/DateInput';
import ReactSelect from '../Select';
import SearchableSelect from '../Select/SearchableSelect';
import TreeSelect from '../Select/TreeSelect';
import styled from 'styled-components';
import DatePicker from '../DatePicker';
import Tab from '../Tab';
import RadioButton from '../Input/RadioButton';
import Nameplate from '../Nameplate';
import Checkbox from '../Input/Checkbox';
import ToggleButton from '../Button/ToggleButton';
import TabWithTitle from '../Tab/TabWithTitle';
import SingleSelect from '../Select/SingleSelect';

const DisplayRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 5rem;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const Modules = () => {
    // For Button
    const handleButtonClick = e => {
        e.preventDefault();
        alert('button clicked');
    };

    // For Card Overlay
    const [cardOverlay, setCardOverlay] = useState(false);

    // For Input
    const [input, setInput] = useState({
        textInput: '',
        dateInput: '',
    });
    const handleChange = e => {
        setInput({ ...input, [e.target.name]: e.target.value });
    };

    //For Select
    const options = [
        { value: 'chocolate', label: 'Chocolate', test: '123' },
        { value: 'strawberry', label: 'Strawberry', test: '123' },
        { value: 'vanilla', label: 'Vanilla', test: 234 },
    ];

    const [selectedOption, setSelectedOption] = useState(null);
    const [radioButton, setRadioButton] = useState('MSG_NO_RES');

    const handleSelectValueChange = value => {
        setSelectedOption(value);
    };

    const options2 = [
        { value: 'chocolate', label: 'Chocolate', test: '123' },
        { value: 'strawberry', label: 'Strawberry', test: '123' },
        { value: 'vanilla', label: 'Vanilla', test: 234 },
        { value: 'chocolate2', label: 'Chocolate2', test: '123' },
        { value: 'strawberry2', label: 'Strawberry2', test: '123' },
        { value: 'vanilla2', label: 'Vanilla2', test: 234 },
        { value: 'chocolate3', label: 'Chocolate3', test: '123' },
        { value: 'strawberry3', label: 'Strawberry3', test: '123' },
        { value: 'vanilla3', label: 'Vanilla3', test: 234 },
        { value: 'chocolate4', label: 'Chocolate4', test: '123' },
        { value: 'strawberry4', label: 'Strawberry4', test: '123' },
        { value: 'vanilla4', label: 'Vanilla4', test: 234 },
    ];

    const [selectedOption2, setSelectedOption2] = useState(null);

    const handleSelectValueChange2 = selected => {
        setSelectedOption2(selected.map(v => v.value));
    };

    const options3 = [
        { value: '1', label: '1' },
        { value: '1-1', label: '1-1', parent: '1' },
        { value: '1-2', label: '1-2', parent: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '3-1', label: '3-1', parent: '3' },
        { value: '3-1-1', label: '3-1-1', parent: '3-1' },
        { value: '3-1-2', label: '3-1-2', parent: '3-1' },
        { value: '3-1-2-1', label: '3-1-2-1', parent: '3-1-2' },
        { value: '3-1-2-2', label: '3-1-2-2', parent: '3-1-2' },
        { value: '3-1-2-3', label: '3-1-2-3', parent: '3-1-2' },
        { value: '4', label: '4' },
    ];
    const treeOptions = [
        {
            value: '1',
            label: '1',
            children: [
                { value: '1-1', label: '1-1', parent: '1' },
                { value: '1-2', label: '1-2', parent: '1' },
            ],
        },
        { value: '2', label: '2' },
        {
            value: '3',
            label: '3',
            children: [
                {
                    value: '3-1',
                    label: '3-1',
                    parent: '3',
                    children: [
                        { value: '3-1-1', label: '3-1-1', parent: '3-1' },
                        {
                            value: '3-1-2',
                            label: '3-1-2',
                            parent: '3-1',
                            children: [
                                { value: '3-1-2-1', label: '3-1-2-1', parent: '3-1-2' },
                                { value: '3-1-2-2', label: '3-1-2-2', parent: '3-1-2' },
                                { value: '3-1-2-3', label: '3-1-2-3', parent: '3-1-2' },
                            ],
                        },
                    ],
                },
            ],
        },
        { value: '4', label: '4' },
    ];

    const [selectedOption3, setSelectedOption3] = useState([]);
    const [checkedCheckBox, setCheckedCheckBox] = useState(false);
    const [checkedToggleBtn, setCheckedToggleBtn] = useState(false);

    const handleSelectValueChange3 = selected => {
        setSelectedOption3(selected);
    };

    const [selectedDate, setSelectedDate] = useState(new Date() / 1000);
    const handleDateChange = selected => {
        setSelectedDate(selected);
    };
    const [selectedMsDate, setSelectedMsDate] = useState(new Date());
    const handleMsDateChange = selected => {
        setSelectedMsDate(selected);
    };

    const handleCheckedCheckBox = e => {
        setCheckedCheckBox(e.target.checked);
    };

    const handleCheckedToggleBtn = e => {
        setCheckedToggleBtn(e.target.checked);
    };

    const [singleSelectValue, setSingleSelectValue] = useState(null);
    const handleSelectChange = selected => {
        setSingleSelectValue(selected);
    };

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {/*For Button*/}
                <DisplayRow>
                    <div>
                        <span>버튼 without Icon</span>
                        <div>
                            <Button className={'btn-brand btn-shadow'} onClick={handleButtonClick}>
                                test
                            </Button>
                        </div>
                    </div>

                    <div>
                        <span>버튼 with Icon</span>
                        <div>
                            <Button
                                className={'btn-secondary btn-icon btn-shadow'}
                                onClick={handleButtonClick}
                                iconClassName={`icon-hamburger`}
                            >
                                hamburger
                            </Button>
                        </div>
                    </div>

                    <div>
                        <span>버튼 with Icon Only</span>
                        <div>
                            <Button
                                className={'btn-warning btn-icon-only btn-shadow'}
                                onClick={handleButtonClick}
                                iconClassName={`icon-close`}
                            />
                        </div>
                    </div>
                </DisplayRow>

                <br />
                <div className="pnt-border border-w" />
                <br />

                {/*For Label*/}
                <DisplayRow>
                    <div>
                        <span>Label Group without value</span>
                        <Label name={'test'} />
                    </div>

                    <div>
                        <span>Label Group with value</span>
                        <Label name={'test'} value={'optionsss'} />
                    </div>

                    <div>
                        <span>Label with class</span>
                        <Label name={'test'} labelValueClassName={'label-dot'} />
                    </div>
                </DisplayRow>

                <br />
                <div className="pnt-border border-w" />
                <br />

                {/* For Card */}
                <DisplayRow>
                    <div>
                        <span>Card without titleFunction</span>
                        <Card header={{ title: 'card test' }}>
                            <div>body jsx element test</div>
                        </Card>
                    </div>

                    <div>
                        <span>Card with header title sub & titleFunction</span>
                        <Card
                            header={{
                                title: 'card test',
                                subTitle: 'I am header title Sub',
                                action: (
                                    <Button
                                        className={'btn-danger btn-icon btn-shadow'}
                                        onClick={handleButtonClick}
                                        iconClassName={`icon-eye`}
                                    >
                                        check
                                    </Button>
                                ),
                            }}
                        >
                            <div>
                                Card title function has a common Button Component.
                                <br />
                                If you click the title button, you can see 'button clicked' on alert <br />
                                Please check the console after you click the button{' '}
                            </div>
                        </Card>
                    </div>

                    <div>
                        <span>Card with overlay</span>
                        <Card
                            header={{
                                title: 'card test',
                                subTitle: 'I am header title Sub',
                            }}
                            overlayOn={cardOverlay}
                            overlay={{
                                title: 'overlay Title',
                                content: <p>Hello, I am card overlay body :) </p>,
                            }}
                        >
                            <div>
                                Card title function has a common Button Component.
                                <br />
                                If you click the title button, you can see card Overlay.{' '}
                            </div>
                        </Card>
                    </div>
                </DisplayRow>

                <br />
                <div className="pnt-border border-w" />
                <br />

                {/* For Input */}
                <DisplayRow>
                    <div>
                        <span>Text Input without button</span>
                        <TextInput
                            type={'text'}
                            name={'textInput'}
                            value={input.textInput}
                            handleChange={handleChange}
                        />
                    </div>

                    <div>
                        <span>Text Input with button</span>
                        <TextInput
                            type={'text'}
                            name={'textInput'}
                            value={input.textInput}
                            handleChange={handleChange}
                            inputGroupClassName={'btn-on-right'}
                            buttonIcon={'icon-plus'}
                        />
                    </div>

                    <div>
                        <span>DateInput</span>
                        <DateInput
                            name={'dateInput'}
                            value={input.dateInput}
                            handleDateChange={handleChange}
                            className={'w-100'}
                        />
                    </div>

                    <div>
                        <span> React Select </span>
                        <ReactSelect
                            name={'selectInput'}
                            value={selectedOption}
                            onChange={handleSelectValueChange}
                            options={options}
                            // isMulti={true}
                        />
                    </div>

                    <div>
                        <span> Searchable Select </span>
                        <SearchableSelect
                            data={options2}
                            selected={selectedOption2}
                            onChange={handleSelectValueChange2}
                            title={'Category'}
                        />
                    </div>

                    <div>
                        <span> Tree Select </span>
                        <TreeSelect
                            data={options3}
                            title={'Category'}
                            valueKey={'value'}
                            labelKey={'label'}
                            parentKey={'parent'}
                            selected={selectedOption3}
                            onChange={handleSelectValueChange3}
                        />
                    </div>

                    <div>
                        <span> Single Select </span>
                        <SingleSelect
                            value={singleSelectValue}
                            onChange={handleSelectChange}
                            options={[
                                { value: 'MSG_NO_RES', label: 'No reply' },
                                { value: 'MSG_RES', label: 'Reply' },
                                { value: 'MSG_EMERGENCY', label: 'Urgent' },
                            ]}
                        />
                    </div>
                    <div>
                        <span> form-must and form-h-small </span>
                        <SingleSelect
                            placeholder={'placeholder test'}
                            value={singleSelectValue}
                            className={'form-must form-h-small'}
                            iconClassName={'figure-box bg-danger opacity-10'}
                            onChange={handleSelectChange}
                            options={[
                                { value: 'MSG_NO_RES', label: 'No reply' },
                                { value: 'MSG_RES', label: 'Reply' },
                                { value: 'MSG_EMERGENCY', label: 'Urgent' },
                            ]}
                        />
                    </div>
                    <div>
                        <span> disabled </span>
                        <SingleSelect
                            value={'MSG_RES'}
                            className={'form-disable'}
                            onChange={handleSelectChange}
                            options={[
                                { value: 'MSG_NO_RES', label: 'No reply' },
                                { value: 'MSG_RES', label: 'Reply' },
                                { value: 'MSG_EMERGENCY', label: 'Urgent' },
                            ]}
                        />
                    </div>
                    <div>
                        <span> icon </span>
                        <SingleSelect
                            value={singleSelectValue}
                            className={'form-h-large'}
                            onChange={handleSelectChange}
                            iconClassName={'icon-person'}
                            options={[
                                { value: 'MSG_NO_RES', label: 'No reply' },
                                { value: 'MSG_RES', label: 'Reply' },
                                { value: 'MSG_EMERGENCY', label: 'Urgent' },
                            ]}
                        />
                    </div>
                    <div>
                        <span>form-h-large </span>
                        <SingleSelect
                            valueKey={'type'}
                            labelKey={'msg'}
                            value={singleSelectValue}
                            className={'form-h-large'}
                            onChange={selected => setSingleSelectValue(selected.type)}
                            options={[
                                { type: 'MSG_NO_RES', msg: 'No reply' },
                                { type: 'MSG_RES', msg: 'Reply' },
                                { type: 'MSG_EMERGENCY', msg: 'Urgent' },
                            ]}
                            customSelectedValue
                        />
                    </div>
                </DisplayRow>

                <br />
                <div className="pnt-border border-w" />
                <br />

                <DisplayRow>
                    <DatePicker value={selectedDate} handleChange={handleDateChange} showTimeSelect />
                    <DatePicker
                        value={selectedMsDate}
                        handleChange={handleMsDateChange}
                        valueType={'ms'}
                        showTimeInput
                    />
                    <DatePicker value={selectedDate} handleChange={handleDateChange} withoutTime />
                </DisplayRow>

                <DisplayRow>
                    <div>
                        <div>form-h-small</div>
                        <RadioButton
                            radioGroupClassName={'form-h-small'}
                            name={'msgType'}
                            radioList={[
                                { value: 'MSG_NO_RES', label: 'No reply' },
                                { value: 'MSG_RES', label: 'Reply' },
                                { value: 'MSG_EMERGENCY', label: 'Urgent' },
                            ]}
                            value={radioButton}
                            onChange={selected => setRadioButton(selected)}
                        />
                    </div>
                    <div>
                        <div>default</div>
                        <RadioButton
                            name={'msgType'}
                            radioList={[
                                { value: 'MSG_NO_RES', label: 'No reply' },
                                { value: 'MSG_RES', label: 'Reply' },
                                { value: 'MSG_EMERGENCY', label: 'Urgent' },
                            ]}
                            value={radioButton}
                            onChange={selected => setRadioButton(selected)}
                        />
                    </div>
                    <div>
                        <div>form-h-big</div>
                        <RadioButton
                            radioGroupClassName={'form-h-big'}
                            name={'msgType'}
                            radioList={[
                                { value: 'MSG_NO_RES', label: 'No reply' },
                                { value: 'MSG_RES', label: 'Reply' },
                                { value: 'MSG_EMERGENCY', label: 'Urgent' },
                            ]}
                            value={radioButton}
                            onChange={selected => setRadioButton(selected)}
                        />
                    </div>
                </DisplayRow>
                <DisplayRow>
                    <div>
                        <div>Nameplate</div>
                        <Nameplate name={'utility-012'} icon={'icon-tool'} />
                    </div>
                    <div>
                        <div>Nameplate(Max-W-none)</div>
                        <Nameplate
                            name={'People and Technology'}
                            icon={'icon-building'}
                            className={'bg-warning max-w-none'}
                        />
                    </div>
                    <div>
                        <div>Nameplate(높이)</div>
                        <Nameplate name={'utility-012'} icon={'icon-tool'} className={'shadow-none form-h-big'} />
                    </div>
                </DisplayRow>
                <DisplayRow>
                    <Checkbox
                        name={'test1'}
                        id={'test1'}
                        checked={checkedCheckBox}
                        handleChecked={handleCheckedCheckBox}
                    />
                    <Checkbox name={'test2'} id={'test2'} disabled={true} />
                    <Checkbox
                        name={'test3'}
                        id={'test3'}
                        checked={checkedCheckBox}
                        handleChecked={handleCheckedCheckBox}
                        className={'form-h-big'}
                    />
                </DisplayRow>
                <DisplayRow>
                    <div>
                        <div>Toggle button</div>
                        <ToggleButton checked={checkedToggleBtn} handleChecked={handleCheckedToggleBtn} />
                    </div>
                    <div>
                        <div>Toggle button w icon</div>
                        <ToggleButton
                            checked={checkedToggleBtn}
                            handleChecked={handleCheckedToggleBtn}
                            className={'toggle-icon'}
                        />
                    </div>
                    <div>
                        <div>Toggle button w text</div>
                        <ToggleButton
                            checked={checkedToggleBtn}
                            handleChecked={handleCheckedToggleBtn}
                            className={'toggle-txt'}
                        />
                    </div>
                    <div>
                        <div>Toggle button w long txt</div>
                        <ToggleButton
                            checked={checkedToggleBtn}
                            handleChecked={handleCheckedToggleBtn}
                            className={'toggle-txt txt-long form-h-big'}
                            offText={'off long text'}
                            onText={'on long text'}
                        />
                    </div>
                </DisplayRow>
                <DisplayRow>
                    <div style={{ width: '40rem', height: '10rem' }}>
                        <div>Tab component style 1 (without title)</div>
                        <Tab
                            tabs={[
                                {
                                    title: 'Status Schema',
                                    tabId: 't1',
                                    content: <div>All</div>,
                                },
                                {
                                    title: 'Status',
                                    tabId: 't2',
                                    content: (
                                        <div className="card-header mb-4 pl-0 pr-0">
                                            <div className="card-header__title">
                                                <h3 className="title__main">List : Status</h3>
                                                <p className="title__sub">자산의 상태 목록 조회 기능입니다.</p>
                                            </div>
                                            <div className="card-header__function">
                                                <button className="pnt-btn btn-brand btn-icon btn-create">
                                                    <span className="icon-plus"></span>Add Status
                                                </button>
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Status Category',
                                    tabId: 't3',
                                    content: <div>Status Category</div>,
                                },
                            ]}
                        />
                    </div>

                    <div style={{ width: '40rem', height: '10rem' }}>
                        <Tab
                            styles={{ header: { justifyContent: 'center' }, headerItem: { width: '30%' } }}
                            tabs={[
                                {
                                    title: 'Status Schema',
                                    tabId: 't1',
                                    content: (
                                        <div className="card-header mb-4 pl-0 pr-0">
                                            <div className="card-header__title">
                                                <h3 className="title__main">List : Status</h3>
                                                <p className="title__sub">자산의 상태 목록 조회 기능입니다.</p>
                                            </div>
                                            <div className="card-header__function">
                                                <button className="pnt-btn btn-brand btn-icon btn-create">
                                                    <span className="icon-plus"></span>Add Status
                                                </button>
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Status',
                                    tabId: 't2',
                                    content: <div>Status Tab</div>,
                                },
                                {
                                    title: 'Status Category',
                                    tabId: 't3',
                                    content: <div>Status Category</div>,
                                },
                            ]}
                        />
                    </div>
                </DisplayRow>
                <DisplayRow>
                    <div style={{ width: '40rem', height: '10rem' }}>
                        <div>Tab component style 2 (with title)</div>
                        <TabWithTitle
                            title={'Hello world'}
                            tabs={[
                                {
                                    title: 'Status Schema',
                                    tabId: 't1',
                                    content: (
                                        <div className="card-header mb-4 pl-0 pr-0">
                                            <div className="card-header__title">
                                                <h3 className="title__main">List : Status</h3>
                                                <p className="title__sub">자산의 상태 목록 조회 기능입니다.</p>
                                            </div>
                                            <div className="card-header__function">
                                                <button className="pnt-btn btn-brand btn-icon btn-create">
                                                    <span className="icon-plus"></span>Add Status
                                                </button>
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Status',
                                    tabId: 't2',
                                    content: <div>Status Tab</div>,
                                },
                                {
                                    title: 'Status Category',
                                    tabId: 't3',
                                    content: <div>Status Category</div>,
                                },
                            ]}
                        />
                    </div>
                </DisplayRow>
            </div>
        </>
    );
};

export default Modules;
