import React, { useMemo } from 'react';
import useTranslation from '../../../util/hooks/useTranslation';
import { useSelector } from 'react-redux';
import useMonitor from '../util/useMonitor';
import { fetchLostSignalAlarm } from '../../../api/widget';
import WidgetCard from '../Components/WidgetCard';
import moment from 'moment';
import TimeLine from '../../Common/TimeLine';

const defaultData = [];
const LostSignalTimeline = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('LostSignalTimelineWidget');
    const { lang } = useSelector(state => state.UserInfo);
    const { config } = widgetInfo;
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const filterParam = useMemo(() => {
        let tempArr = [];
        categoryList
            .filter(category => category.parentCode === 'ASSET')
            .map(category => tempArr.push(category.categoryCode));
        let tempParam = {
            categoryCodes: tempArr.toString(),
        };
        return tempParam;
    }, [categoryList]);

    const { data } = useMonitor({
        config,
        defaultData,
        dynamicParam: filterParam,
        fetchData: fetchLostSignalAlarm,
        makeData: ({ newData }) => {
            if (newData.rows) {
                newData.rows.sort((a, b) => (a.lastDate > b.lastDate ? -1 : 1));
                return newData.rows.map(v => {
                    return {
                        ...v,
                        level: 'warning',
                        type: t('Lost signal'),
                        timeStr: moment(v.lastDate * 1000)
                            .locale('en')
                            .format('hh:mm A'),
                        dateStr: moment(v.lastDate * 1000)
                            .locale('en')
                            .format('MM-DD'),
                        location: v.floor,
                        typeClassName: 'text-danger',
                        targetNum: v.targetNum,
                    };
                });
            }
        },
    });
    return (
        <WidgetCard widgetInfo={widgetInfo} {...restProps}>
            <TimeLine events={data} />
            {children}
        </WidgetCard>
    );
};

export default LostSignalTimeline;
