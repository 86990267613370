import React, { useMemo } from 'react';
import SimpleCountWithoutCard from '../../MainPages/Components/SimpleCountWithoutCard';
import WidgetCard from '../Components/WidgetCard';
import useTranslation from '../../../util/hooks/useTranslation';
import { checkFalsy } from '../../../util/common/util';
import useMonitor from '../util/useMonitor';
import { useSelector } from 'react-redux';
import { fetchPlacementState } from '../../../api/widget';

const defaultData = [];
const CountByPlacementState = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('CountByPlacementStateWidget');
    const { config } = widgetInfo;
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const filterParam = useMemo(() => {
        let tempArr = [];
        categoryList
            .filter(category => category.parentCode === 'ASSET')
            .map(category => tempArr.push(category.categoryCode));
        let tempParam = {
            categoryCodes: tempArr.toString(),
        };
        return tempParam;
    }, [categoryList]);

    const { data } = useMonitor({
        config,
        defaultData,
        dynamicParam: filterParam,
        fetchData: fetchPlacementState,
    });
    return (
        <WidgetCard widgetInfo={widgetInfo} {...restProps}>
            <SimpleCountWithoutCard
                key={'1'}
                widgetInfo={{
                    title: t('Count By Placement State'),
                    mainCnt: checkFalsy(data.detectedTargetCnt),
                    mainAllCnt: checkFalsy(data.total),
                    mainStatus: t('Mapped', 'Common'),
                    colorClass: 'color-secondary',
                    detailCnt: [
                        {
                            colorClass: 'color-success',
                            label: t('In', 'Common'),
                            value: checkFalsy(data.locatedInTargetCnt),
                        },
                        {
                            colorClass: 'color-danger',
                            label: t('Out', 'Common'),
                            value: checkFalsy(data.locatedOutTargetCnt),
                        },
                        {
                            colorClass: 'color-gray',
                            label: t('Unknown', 'Common'),
                            value: checkFalsy(data.neverDetectedTargetCnt),
                        },
                    ],
                }}
            />
            {children}
        </WidgetCard>
    );
};

export default CountByPlacementState;
