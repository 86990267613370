import React, { useState } from 'react';
import useTranslation from '../../../util/hooks/useTranslation';
import WidgetSettingModal from '../Components/WidgetSettingModal';
import Label from '../../Common/Label';
import SingleSelect from '../../Common/Select/SingleSelect';
import { editWidgetSettings } from '../../../reducers/Dashboards/DashboardEdit';
import { useSettings } from '../util/useSettings';
import { useDispatch } from 'react-redux';

const MappedTagBatterySetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('MappedTagBatteryWidget');
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { settings: customSettings } = config;
    const dispatch = useDispatch();
    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});

    const handleMappedOptionChange = option => {
        setUpdatedWidgetInfo(prevState => {
            return {
                ...prevState,
                mappingOption: option,
            };
        });
    };
    const handleOkButtonClick = data => {
        if (customSettings) {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...customSettings, ...data, ...updatedWidgetInfo },
                }),
            );
        } else {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...data, ...updatedWidgetInfo },
                }),
            );
        }
    };

    return (
        <WidgetSettingModal
            headerTitle={t('Mapped tag battery widget setting')}
            widgetInfo={widgetInfo}
            hiddenHeader
            okCallback={handleOkButtonClick}
            {...restProps}
        >
            <Label
                name={t('Mapping option')}
                value={
                    <SingleSelect
                        className={'w-100'}
                        name={'mapped'}
                        onChange={handleMappedOptionChange}
                        options={[
                            { value: 'mapped', label: t('Mapped', 'Common') },
                            { value: 'unmapped', label: t('Unmapped', 'Common') },
                        ]}
                        value={
                            updatedWidgetInfo.mappingOption
                                ? updatedWidgetInfo.mappingOption
                                : settings.mappingOption
                                ? settings.mappingOption
                                : 'mapped'
                        }
                    />
                }
            />
        </WidgetSettingModal>
    );
};

export default MappedTagBatterySetting;
