import React, { useState } from 'react';
import Page from '../../../Common/Page';
import Card from '../../../Common/Card';
import Table from '../../../Common/Table';
import Search from './Components/Search';
import useTranslation from '../../../../util/hooks/useTranslation';
import { getLoginLogListApi } from '../../../../api/log';
import useAsync from '../../../../util/hooks/useAsync';
import useColumns from '../../../../util/hooks/useColumns';
import * as column from '../../util/grid/column';

const LoginHistory = () => {
    const t = useTranslation('Login History Log');

    const [loginLogData, setLoginLogData] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });

    const columns = useColumns([
        column.userId({}),
        column.userName({}),
        column.type({
            Cell: ({ value }) => {
                if (value === 'F') {
                    return <div>{t('Failure')}</div>;
                } else if (value === 'S') {
                    return <div>{t('Success')}</div>;
                }
            },
        }),
        column.description({
            Cell: ({ value }) => {
                if (value) {
                    return <div>{value}</div>;
                } else {
                    return <div>-</div>;
                }
            },
        }),
        column.ip({}),
        column.registeredDate({
            className: 'text-ellipsis',
        }),
    ]);

    const { promise: getLoginLogList, state: loginLogListInfo } = useAsync({
        promise: getLoginLogListApi,
        keepState: true,
        immediate: true,
        fixedParam: { pageSize: 15 },
        resolve: res => {
            setLoginLogData(res);
        },
    });

    const handlePageChange = pageIndex => {
        if (loginLogListInfo.request && (loginLogListInfo.request.keyword || loginLogListInfo.request.type)) {
            getLoginLogList({ ...loginLogListInfo.request, page: pageIndex });
        } else {
            getLoginLogList({ page: pageIndex });
        }
    };

    return (
        <Page menuPath={[t('Report', 'Menu'), t('Login History', 'Menu')]} className={'h-100'}>
            <Search getLoginLogList={getLoginLogList} />
            <Card
                className={'report-container'}
                header={{
                    title: t('Login History', 'Menu'),
                }}
            >
                <div style={{ height: '35rem' }}>
                    <Table
                        data={{ ...loginLogData, pageSize: 15 }}
                        columns={columns}
                        onPageChange={handlePageChange}
                        loading={loginLogListInfo.isLoading}
                    />
                </div>
            </Card>
        </Page>
    );
};

export default LoginHistory;
