import React, { useMemo } from 'react';
import WidgetCard from '../Components/WidgetCard';
import { checkFalsy } from '../../../util/common/util';
import SimpleCountWithoutCard from '../../MainPages/Components/SimpleCountWithoutCard';
import useMonitor from '../util/useMonitor';
import { fetchTagSummaryCount } from '../../../api/widget';
import { useSelector } from 'react-redux';
import useTranslation from '../../../util/hooks/useTranslation';

const defaultData = [];
const TagSummary = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('TagSummaryWidget');
    const { config } = widgetInfo;
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const filterParam = useMemo(() => {
        let tempArr = [];
        categoryList
            .filter(category => category.parentCode === 'ASSET')
            .map(category => tempArr.push(category.categoryCode));
        let tempParam = {
            categoryCodes: tempArr.toString(),
        };
        return tempParam;
    }, [categoryList]);

    const { data } = useMonitor({
        config,
        defaultData,
        dynamicParam: filterParam,
        fetchData: fetchTagSummaryCount,
    });

    return (
        <WidgetCard widgetInfo={widgetInfo} {...restProps}>
            <SimpleCountWithoutCard
                widgetInfo={{
                    title: t('Tag Summary'),
                    mainCnt: checkFalsy(data.totalCnt),
                    mainStatus: t('Registered', 'Common'),
                    detailCnt: [
                        {
                            colorClass: 'color-secondary',
                            label: t('Mapped', 'Common'),
                            value: checkFalsy(data.assignCnt),
                        },
                        {
                            colorClass: 'color-danger',
                            label: t('Unmapped', 'Common'),
                            value: checkFalsy(data.unAssignCnt),
                        },
                    ],
                }}
            />
            {children}
        </WidgetCard>
    );
};

export default TagSummary;
