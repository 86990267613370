import createSagaMiddleware from 'redux-saga';
import reducers from '../reducers';
import rootSaga from '../sagas';
import { configureStore } from '@reduxjs/toolkit';

export default function createStore() {
    const sagaMiddleware = createSagaMiddleware();
    const store = configureStore({
        reducer: {
            ...reducers,
        },
        middleware: [sagaMiddleware],
        devTools: true,
    });
    sagaMiddleware.run(rootSaga);

    return store;
}
