import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import Map from '../../../../Common/Map/Components/Map';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import RotatedImageOverlay from '../../../../Common/Map/Components/RotatedImageOverlay';
import { useSelector } from 'react-redux';
import L from 'leaflet';
import { Marker } from 'react-leaflet';
import useTranslation from '../../../../../util/hooks/useTranslation';

const LocationPopup = ({ modal, toggleModal, searchRef, selectedLog }) => {
    const t = useTranslation('Location Log');
    const { targetName, latLng, regDate, categoryCode, targetNum } = selectedLog;

    const { categoryToImg } = useSelector(state => state.CategoryInfo);
    const { floorList } = useSelector(state => state.FloorInfo);

    const [position, setPosition] = useState({
        x: 0,
        y: 0,
    });
    const [floorInfo, setFloorInfo] = useState({});
    const mapRef = useRef();
    const popupRef = useRef();

    useEffect(() => {
        if (selectedLog && selectedLog.floorId) {
            setFloorInfo(floorList.find(floor => floor.floorId === selectedLog.floorId));
        }
        if (mapRef && mapRef.current) {
            const map = mapRef.current.leafletElement;

            map.setView(latLng);
        }
    }, [selectedLog]);

    useEffect(() => {
        const widgetPosition = getWidgetPosition();
        const popupSize = getPopupSize();

        const x = (widgetPosition.width - popupSize.width) / 2 - 20;
        const y = -widgetPosition.height + 60;
        setPosition({
            x: x,
            y: y,
        });
    }, [popupRef.current]);

    const getWidgetPosition = () => {
        const widgetPosition = { x: 0, y: 0 };
        if (searchRef.current) {
            const searchContainer = searchRef.current;
            const { offsetWidth: width, offsetHeight: height } = searchContainer;

            widgetPosition.width = width;
            widgetPosition.height = height;
        }
        return widgetPosition;
    };
    const getPopupSize = () => {
        const popupSize = { width: 0, height: 0 };
        if (popupRef.current) {
            const popupContainer = popupRef.current;
            const { offsetWidth: width, offsetHeight: height } = popupContainer;

            popupSize.width = width;
            popupSize.height = height;
        }
        return popupSize;
    };

    return (
        <Draggable key={JSON.stringify(position)} bounds=".report-container" defaultPosition={position}>
            <Toast isOpen={modal} className={'popup-open'} innerRef={popupRef}>
                <ToastHeader toggle={toggleModal}>
                    {t('Location')} ({targetName} : {regDate})
                </ToastHeader>
                <ToastBody>
                    <div style={{ padding: '0.5rem 0', height: '100%' }}>
                        <Map ref={mapRef}>
                            {floorInfo && floorInfo.imgURL && floorInfo.bounds.length && (
                                <RotatedImageOverlay
                                    key={floorInfo.floorId}
                                    url={floorInfo.imgURL}
                                    deg={floorInfo.deg}
                                    bounds={floorInfo.bounds}
                                    onLoad={e => {
                                        if (mapRef.current) {
                                            const map = mapRef.current.leafletElement;
                                            if (map) {
                                                map.setView(latLng);
                                            }
                                        }
                                    }}
                                />
                            )}
                            {categoryCode ? (
                                <Marker
                                    key={targetNum}
                                    position={latLng}
                                    icon={L.divIcon({
                                        className: 'category-marker',
                                        html: `<img class="marker-img" src="${categoryToImg[categoryCode]}"/>`,
                                    })}
                                />
                            ) : (
                                <Marker
                                    key={targetNum}
                                    position={latLng}
                                    icon={L.divIcon({
                                        className: 'category-marker',
                                        html: `<div class="marker-img replace-img"><div></div></div>`,
                                    })}
                                />
                            )}
                        </Map>
                    </div>
                </ToastBody>
            </Toast>
        </Draggable>
    );
};

export default LocationPopup;
