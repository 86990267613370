import React, { useEffect, useMemo, useState } from 'react';
import ReactSelect from '../../../../Common/Select';
import TextInput from '../../../../Common/Input/TextInput';
import Button from '../../../../Common/Button';
import moment from 'moment';
import useTranslation from '../../../../../util/hooks/useTranslation';
import DatePicker from '../../../../Common/DatePicker';
import FilterSearchGroup from '../../../../Common/FilterSearchGroup';
import {
    SelectGroup,
    SearchWrap,
    InputWrap,
    FilterList,
    InputGroup,
} from '../../../../Common/FilterSearchGroup/Components/Part';
import FilterGroup from '../../../../Common/FilterSearchGroup/Components/FilterGroup';
import SearchGroup from '../../../../Common/FilterSearchGroup/Components/SearchGroup';
import FilterButtonArea from '../../../../Common/FilterSearchGroup/Components/FilterButtonArea';

const Search = ({ getLocationLogList }) => {
    const t = useTranslation('Location Log');
    const [selectedOption, setSelectedOption] = useState({ value: '', label: t('Search Option', 'Search') });
    const searchOptions = useMemo(() => {
        return [
            { value: '', label: t('Search Option', 'Search') },
            { value: 'targetName', label: t('Target Name') },
            { value: 'tagName', label: t('Device Name') },
        ];
    }, [t]);
    const [searchInput, setSearchInput] = useState('');
    const [searchInputDisabled, setSearchInputDisabled] = useState(true);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        if (selectedOption) {
            setSelectedOption(searchOptions.find(v => v.value === selectedOption.value));
        }
    }, [searchOptions]);

    const handleSelectValueChange = value => {
        setSelectedOption(value);
        if (value.value !== '') {
            setSearchInputDisabled(false);
        } else {
            setSearchInputDisabled(true);
            setSearchInput('');
        }
    };
    const handleRefreshClick = e => {
        setSelectedOption(searchOptions[0]);

        setSearchInput('');
        setStartDate(null);
        setEndDate(null);

        setSearchInputDisabled(true);
        getLocationLogList();
    };
    const handleSearchInputChange = e => {
        setSearchInput(e.target.value);
    };
    const handleSearchClick = () => {
        if (searchInput) {
            getLocationLogList({
                opt: selectedOption.value,
                keyword: searchInput,
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
        } else {
            getLocationLogList({
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
        }
    };
    const handleYesterdayButtonClick = () => {
        const start = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
        const end = new Date();

        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);

        setStartDate(start.valueOf());
        setEndDate(end.valueOf() - 1);
    };

    return (
        <FilterSearchGroup>
            <FilterGroup className={'bg-brand'} label={t('Search', 'Search')}>
                <FilterList>
                    <SelectGroup>
                        <div className={'datePicker-container'}>
                            <DatePicker
                                value={startDate}
                                handleChange={selected => setStartDate(selected)}
                                valueType={'ms'}
                                maxDate={endDate || new Date()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <div className={'text-center'}> ~ </div>
                    <SelectGroup>
                        <div className={'datePicker-container'}>
                            <DatePicker
                                value={endDate}
                                handleChange={selected => setEndDate(selected)}
                                valueType={'ms'}
                                minDate={startDate}
                                maxDate={new Date()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                </FilterList>
                <FilterButtonArea>
                    <Button className={'btn-secondary btn-yesterday'} onClick={handleYesterdayButtonClick}>
                        {t('Yesterday', 'Button')}
                    </Button>
                    <Button
                        className="btn-danger btn-icon-only"
                        iconClassName={'icon-replay'}
                        onClick={handleRefreshClick}
                    />
                </FilterButtonArea>
            </FilterGroup>
            <SearchGroup>
                <SearchWrap>
                    <SelectGroup>
                        <ReactSelect
                            name={'selectInput'}
                            placeholder={t('Search Option', 'Search')}
                            value={selectedOption}
                            options={searchOptions}
                            onChange={handleSelectValueChange}
                            customControlStyles={{
                                width: '100%',
                            }}
                            customMenuStyles={{ width: '100%' }}
                            customOptionStyles={{ width: '100%' }}
                        />
                    </SelectGroup>
                    <InputWrap>
                        <InputGroup>
                            <TextInput
                                type={'text'}
                                size={'60'}
                                placeholder={t(
                                    'Please Enter your search keyword after selecting search option.',
                                    'Search',
                                )}
                                name={'name'}
                                value={searchInput}
                                handleChange={handleSearchInputChange}
                                disabled={searchInputDisabled}
                            />
                        </InputGroup>
                        <Button
                            className={'btn-brand btn-icon'}
                            iconClassName={'icon-search'}
                            onClick={handleSearchClick}
                        >
                            {t('Search', 'Search')}
                        </Button>
                    </InputWrap>
                </SearchWrap>
            </SearchGroup>
        </FilterSearchGroup>
    );
};

export default Search;
