import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

const TimeLabelWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    color: #555;
`;

const displayTime = unixTimestamp => moment(unixTimestamp * 1000).format('MM-DD HH:mm:ss');

const PlayTimeDisplay = ({ playTime, startTime, endTime, on }) => {
    return (
        <TimeLabelWrapper>
            {on ? (
                <>
                    <span>{displayTime(startTime)}</span>
                    <span>{displayTime(playTime)}</span>
                    <span>{displayTime(endTime)}</span>
                </>
            ) : (
                <>
                    <span style={{ marginLeft: '0.5rem' }}>--:--:--</span>
                    <span>--:--:--</span>
                    <span style={{ marginRight: '0.5rem' }}>--:--:--</span>
                </>
            )}
        </TimeLabelWrapper>
    );
};

export default PlayTimeDisplay;
