import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FeatureGroup, LayersControl, Marker } from 'react-leaflet';
import L from 'leaflet';
import useAsync from '../../../../util/hooks/useAsync';
import { fetchSelectedAssetInfo } from '../../../../api/asset';
import moment from 'moment';
import RotatedImageOverlay from '../../../Common/Map/Components/RotatedImageOverlay';
import notFoundImg from '../../../../assets/images/image-not-found.jpg';
import GeofenceLayer from '../../../Common/Map/Layers/GeofenceLayer';
import { fetchFloorInfo, fetchGeofenceInfo } from '../../../../api/common';
import useTranslation from '../../../../util/hooks/useTranslation';
import { getLeafFloors } from '../../../../util/common/common';
import cx from 'classnames';
import Map from '../../../../Components/Common/Map/Components/Map';

export const LastDate = ({ selectedAssetInfo: { lastDate, isTagMapped } }) => {
    const t = useTranslation('AssetDetail');
    if (lastDate) {
        const diffSec = moment().diff(moment(lastDate * 1000), 'seconds');
        return (
            <>
                {isTagMapped === 'N' ? (
                    <span className={'icon-wifi icon-small off'} />
                ) : diffSec > 60 * 60 * 24 ? (
                    <span className={'icon-wifi icon-small icon-filter-gray'} />
                ) : diffSec > 1800 ? (
                    <span className={'icon-wifi icon-small icon-filter-red'} />
                ) : (
                    <span className={'icon-wifi icon-small icon-filter-blue'} />
                )}
                <span className="label-options">{moment(lastDate * 1000).format('YYYY-MM-DD HH:mm:ss')}</span>
            </>
        );
    }
    return (
        <>
            <span className="icon-wifi icon-small off" />
            <span className="label-options">{t('N/A')}</span>
        </>
    );
    // return lastDate ? (
    //     <>
    //         <span className="icon-wifi icon-small icon-filter-blue" />
    //         <span className="label-options">{moment(lastDate * 1000).format('YYYY-MM-DD HH:mm:ss')}</span>
    //     </>
    // ) : (
    //     <>
    //         <span className="icon-wifi icon-small wifi-none" />
    //         <span className="label-options">{t('N/A')}</span>
    //     </>
    // );
};

const PropertyItem = ({ configInfo, value, singleCol }) => {
    return (
        <div className={cx(singleCol ? 'col-xl-12' : 'col-xl-4 col-lg-6')}>
            <div className="pnt-label--group">
                <span className="label-main label-dot">{configInfo.displayName}</span>
                <span className="label-options">
                    {configInfo.inputValuesMap ? configInfo.inputValuesMap[value] : value}
                </span>
            </div>
        </div>
    );
};

const AssetDetail = ({ targetNum, excludeMap, singleCol }) => {
    const t = useTranslation('AssetDetail');
    const [selectedAssetInfo, setSelectedAssetInfo] = useState({});
    const [floorInfo, setFloorInfo] = useState([]);
    const [error, setError] = useState(false);
    const [selectedFloorInfo, setSelectedFloorInfo] = useState({});
    const [geofenceInfo, setGeofenceInfo] = useState([]);
    const { categoryConfig } = useSelector(state => state.CommonInfo);
    const [listableCategoryConfig, setListableCategoryConfig] = useState([]);

    const { promise: getSelectedAssetInfo } = useAsync({
        promise: fetchSelectedAssetInfo,
        resolve: res => {
            setSelectedAssetInfo(res || {});
        },
        reject: error => {
            console.log(error);
        },
    });

    const { promise: getFloorInfo } = useAsync({
        promise: fetchFloorInfo,
        resolve: res => {
            const tempFloorList = getLeafFloors(res.rows);
            setFloorInfo({ ...res, rows: tempFloorList } || {});
        },
        reject: error => {
            console.log(error);
            setFloorInfo({ rows: [] });
        },
    });
    const { promise: getGeofenceInfo } = useAsync({
        promise: fetchGeofenceInfo,
        resolve: res => {
            if (res.rows) {
                setGeofenceInfo(
                    res.rows.map(geofence => {
                        return { ...geofence, bounds: geofence.latLngList.map(latLng => [latLng.lat, latLng.lng]) };
                    }),
                );
            } else {
                setGeofenceInfo([]);
            }
        },
        reject: error => {
            console.log(error);
            setGeofenceInfo([]);
        },
    });

    useEffect(() => {
        if (targetNum) {
            getFloorInfo({ isAll: 'Y' });
            getSelectedAssetInfo({ targetNum: targetNum });
            getGeofenceInfo({ isAll: 'Y' });
        }
    }, [targetNum]);

    useEffect(() => {
        if (floorInfo.rows && floorInfo.rows.length > 0 && selectedAssetInfo.floorId) {
            setSelectedFloorInfo(floorInfo.rows.find(floor => floor.floorId === selectedAssetInfo.floorId));
        }
    }, [floorInfo, selectedAssetInfo]);

    useEffect(() => {
        const categoryConfigArray = [];
        for (let key in categoryConfig) {
            categoryConfigArray.push(categoryConfig);
        }
        categoryConfigArray.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));
        setListableCategoryConfig(categoryConfigArray.filter(v => v.isListProperty === 'Y').map(v => v.categoryCode));
    }, [selectedAssetInfo, categoryConfig]);

    return (
        <>
            <div className="row">
                <div className={cx(singleCol ? 'col-xl-12' : 'col-xl-4 col-lg-6')}>
                    <div className="pnt-label--group min-h-40">
                        <span className="label-main label-dot">{t('Name')}</span>
                        <span className="label-options">{selectedAssetInfo.targetName}</span>
                    </div>
                </div>
                <div className={cx(singleCol ? 'col-xl-12' : 'col-xl-4 col-lg-6')}>
                    <div className="pnt-label--group min-h-40">
                        <span className="label-main label-dot">{t('ID')}</span>
                        <span className="label-options">{selectedAssetInfo.targetId}</span>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className={cx(singleCol ? 'col-xl-12' : 'col-xl-4 col-lg-6')}>
                    <div className="pnt-label--group min-h-40">
                        <span className="label-main label-dot">{t('Category', 'Search')}</span>
                        <span className="label-options">{selectedAssetInfo.categoryName}</span>
                    </div>
                </div>
            </div>
            {!!listableCategoryConfig.length && (
                <>
                    <div className="pnt-border border-w" />
                    {listableCategoryConfig.map((config, index) => {
                        const properties = selectedAssetInfo.properties;
                        const nextConfig = listableCategoryConfig[index + 1];

                        const currCategoryConfig = categoryConfig[config];
                        const nextCategoryConfig = categoryConfig[nextConfig];

                        if (index % 2) {
                            return (
                                <div className="row">
                                    <PropertyItem configInfo={currCategoryConfig} value={properties[config]} />
                                    {!!nextConfig && (
                                        <PropertyItem configInfo={nextCategoryConfig} value={properties[nextConfig]} />
                                    )}
                                </div>
                            );
                        }
                        return null;
                    })}
                </>
            )}
            <div className="pnt-border border-w" />
            <div className="row mb-2">
                <div className={cx(singleCol ? 'col-xl-12' : 'col-xl-4 col-lg-6')}>
                    <div className="pnt-label--group min-h-40">
                        <span className="label-main label-dot">{t('Position')}</span>
                        <span className="label-options">{selectedAssetInfo.floorName}</span>
                    </div>
                </div>
                <div className={cx(singleCol ? 'col-xl-12' : 'col-xl-4 col-lg-6')}>
                    <div className="pnt-label--group min-h-40">
                        <span className="label-main label-dot">{t('Last Seen')}</span>
                        <LastDate selectedAssetInfo={selectedAssetInfo} />
                    </div>
                </div>
            </div>
            {!excludeMap && (
                <>
                    <Map tile={true} className="z-index-0">
                        {selectedFloorInfo && selectedFloorInfo.neLat && (
                            <RotatedImageOverlay
                                key={selectedFloorInfo.neLat}
                                url={selectedFloorInfo.imgURL}
                                deg={selectedFloorInfo.deg}
                                bounds={[
                                    [selectedFloorInfo.neLat, selectedFloorInfo.neLng],
                                    [selectedFloorInfo.swLat, selectedFloorInfo.swLng],
                                ]}
                                onLoad={e => {
                                    setError(false);
                                    console.log('load');
                                }}
                                onError={e => {
                                    setError(true);
                                    console.log('error');
                                }}
                            />
                        )}
                        {selectedFloorInfo && selectedFloorInfo.neLat && error && (
                            <RotatedImageOverlay
                                key={selectedFloorInfo.floorId}
                                url={notFoundImg}
                                bounds={[
                                    [selectedFloorInfo.neLat, selectedFloorInfo.neLng],
                                    [selectedFloorInfo.swLat, selectedFloorInfo.swLng],
                                ]}
                            />
                        )}
                        <LayersControl position="topright">
                            <LayersControl.Overlay key={t('Geofence')} name={t('Geofence')} checked={true}>
                                <FeatureGroup>
                                    {geofenceInfo && geofenceInfo.length > 0 && (
                                        <GeofenceLayer
                                            geofenceList={geofenceInfo.filter(
                                                geofence => geofence.floor === selectedAssetInfo.floorId,
                                            )}
                                        />
                                    )}
                                </FeatureGroup>
                            </LayersControl.Overlay>
                            <LayersControl.Overlay key={t('Marker')} name={t('Marker')} checked={true}>
                                <FeatureGroup>
                                    {selectedAssetInfo && selectedAssetInfo.lat ? (
                                        <Marker
                                            position={{ lat: selectedAssetInfo.lat, lng: selectedAssetInfo.lng }}
                                            icon={L.divIcon({
                                                className:
                                                    selectedAssetInfo.lostSignalStatus === 'LOSTSIGNAL_ON'
                                                        ? 'lostSignal-marker'
                                                        : 'simple-marker',
                                                html: `<div></div>`,
                                            })}
                                        />
                                    ) : null}
                                </FeatureGroup>
                            </LayersControl.Overlay>
                        </LayersControl>
                    </Map>
                    <div className="pt-4" />
                </>
            )}
        </>
    );
};

export default AssetDetail;
