import React, { useMemo, useState } from 'react';
import WidgetCard from '../Components/WidgetCard';
import ResponsivePieChart from '../../Common/Charts/PieChart';
import useMonitor from '../util/useMonitor';
import { fetchCountByCategory } from '../../../api/category';
import { useSelector } from 'react-redux';
import Table from '../../Common/Table';
import useColumns from '../../../util/hooks/useColumns';
import * as col from '../../MainPages/util/grid/column';

const defaultData = [];
const AssetCountByCategory = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const [assetCntForChart, setAssetCntForChart] = useState([]);
    const columns = useColumns([col.categoryName(), col.registered(), col.mapped()]);
    const filterParam = useMemo(() => {
        let tempArr = [];
        categoryList
            .filter(category => category.parentCode === 'ASSET')
            .map(category => tempArr.push(category.categoryCode));
        let tempParam = {
            categoryCodes: tempArr.toString(),
        };
        return tempParam;
    }, [categoryList]);

    const { data } = useMonitor({
        config,
        defaultData,
        dynamicParam: filterParam,
        fetchData: fetchCountByCategory,
        makeData: ({ newData }) => {
            if (newData.rows) {
                setAssetCntForChart(
                    newData.rows.map(v => ({ key: v.categoryCode, name: v.categoryName, value: v.registCnt })),
                );
            }
            return newData;
        },
    });

    return (
        <WidgetCard widgetInfo={widgetInfo} {...restProps}>
            <div className={'d-grid h-100'} style={{ gridTemplateRows: '1fr 1fr' }}>
                <div>
                    <ResponsivePieChart
                        legendProps={{
                            width: 220,
                            layout: 'vertical',
                            align: 'right',
                            verticalAlign: 'middle',
                        }}
                        data={assetCntForChart}
                        settings={{ chartType: 'doughnut' }}
                    />
                </div>
                <div>
                    <Table columns={columns} data={data.rows ? data : { rows: [] }} paging={false} />
                </div>
            </div>
            {children}
        </WidgetCard>
    );
};

export default AssetCountByCategory;
