import React, { useContext, useEffect, useState } from 'react';
import Card from '../../../../Common/Card';
import Label from '../../../../Common/Label';
import useTranslation from '../../../../../util/hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { FilterDetailDispatchContext, FilterDetailStateContext } from '../index';
import { IoIosPeople, IoIosPerson } from 'react-icons/io';
import CustomSwitch from '../../Components/StyledSwitch';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { setEditMode } from '../reducer';
import useConfirmModal from '../../../../Common/ConfirmModal/useConfirmModal';
import useAsync from '../../../../../util/hooks/useAsync';
import { deleteFilterApi, getFilterListApi } from '../../../../../api/filter';
import { FilterStateContext } from '../../index';
import { getAuthorityGroup } from '../../reducer';
import { filterInfo } from '../../Option';
import FilterGenerator from '../../FilterWidget/FilterGenerator';
import { useRecoilState } from 'recoil';
import { filterList as storeFilterList } from '../../../../../recoilStore/filter';

const AuthNamePlate = ({ auth }) => {
    const t = useTranslation('Filter');
    return (
        <li key={`${auth.section}_${auth.id}`} className={'mr-3'}>
            <div className="pnt-nameplate pl-2">
                {auth.section === 'user' ? (
                    <>
                        <IoIosPerson className={'transform-scale-bigger'} />
                        <span className={'ml-2'}>{auth.name}</span>
                    </>
                ) : (
                    <>
                        <IoIosPeople className={'transform-scale-bigger'} />
                        <span className={'ml-2'}>{auth.id === -1 ? t(auth.name) : auth.name}</span>
                    </>
                )}
            </div>
        </li>
    );
};

const ShowView = () => {
    const t = useTranslation('Filter');
    const buttonT = useTranslation('Button');
    const history = useHistory();
    const dispatch = useContext(FilterDetailDispatchContext);
    const { origin } = useContext(FilterDetailStateContext);
    const { authority } = useContext(FilterStateContext);
    const { userInfo } = useSelector(state => state.UserInfo);
    const [writePermission, setWritePermission] = useState(false);
    const [authInfo, setAuthInfo] = useState({ owner: [], accessRight: [] });

    const [filterList, setFilterList] = useRecoilState(storeFilterList);
    const { promise: getFilterList } = useAsync({
        promise: getFilterListApi,
        fixedParam: { isAll: 'Y' },
        resolve: ({ rows }) => {
            setFilterList(rows || []);
            history.replace('/filter/list');
        },
    });

    const { promise: deleteFilter } = useAsync({
        promise: deleteFilterApi,
        fixedParam: { filterNum: origin.filterNum },
        resolve: () => {
            getFilterList();
        },
        deps: [origin],
    });

    const { Modal: DeleteFilterModal, toggleModal: toggleDeleteFilterModal } = useConfirmModal({
        confirmText: t('Do you want to delete filter?'),
        okCallback: () => {
            deleteFilter();
        },
    });

    useEffect(() => {
        if (origin.filterInfoAccessRight && userInfo) {
            setWritePermission(
                origin.filterInfoAccessRight.some(filter => {
                    let permission = false;
                    if (
                        filter.accessScope === 'W' &&
                        (filter.userNum === -1 ||
                            filter.groupNum === -1 ||
                            filter.userNum === userInfo.userNum ||
                            userInfo.groupNums.includes(filter.groupNum))
                    ) {
                        permission = true;
                    }
                    return permission;
                }),
            );
        }
    }, [origin, userInfo]);

    useEffect(() => {
        if (origin.filterInfoAccessRight && authority) {
            const { accessRight, owner } = getAuthorityGroup(authority, origin);
            setAuthInfo({ accessRight, owner });
        }
    }, [origin, authority]);

    return (
        <>
            <Card
                header={{
                    title: `${t('Filter')} - ${t('View')}`,
                    titleIcon: 'icon-info',
                    action: (
                        <>
                            <button className="pnt-btn btn-gray btn-back mr-1" onClick={e => history.goBack()}>
                                {buttonT('Back')}
                            </button>
                            {writePermission && (
                                <>
                                    <div className="pnt-border border-h" />
                                    <button
                                        className={'pnt-btn btn-danger border'}
                                        onClick={() => {
                                            toggleDeleteFilterModal();
                                        }}
                                    >
                                        {buttonT('Delete')}
                                    </button>
                                    <button
                                        className={'pnt-btn btn-icon btn-primary border btn-long'}
                                        onClick={e => {
                                            dispatch(setEditMode(true));
                                        }}
                                    >
                                        <span className="icon-edit" style={{ filter: 'invert(1)' }} />
                                        {buttonT('Edit')}
                                    </button>
                                </>
                            )}
                        </>
                    ),
                }}
                bodyClassName={'overflow-visible'}
            >
                <>
                    <div className={'d-flex mb-3 pnt-label--group'}>
                        <Label
                            name={t('Name')}
                            labelValueClassName={'label-dot label-must mr-5'}
                            value={origin.filterName}
                        />
                    </div>
                    <div className={'d-flex mb-3 pnt-label--group'}>
                        <Label
                            name={t('Description')}
                            labelValueClassName={'label-dot mr-5'}
                            value={origin.description}
                        />
                    </div>
                    <div className={'d-flex pnt-label--group'}>
                        <Label name={t('Menu')} labelValueClassName={'label-dot mr-4'} />

                        <CustomSwitch checked={origin.isApplyMenu === 'Y'} />
                        {origin.isApplyMenu === 'Y' ? (
                            <span className={'pnt-txt txt-tiny ml-3'}>{t('Marking as a filter menu')}</span>
                        ) : (
                            <span className={'pnt-txt txt-tiny ml-3'}>{t('Not marking as a filter menu')}</span>
                        )}
                    </div>
                    <div className="pnt-border border-w" />
                    <div className={'d-flex'}>
                        <Label name={t('Filter setting')} labelValueClassName={'label-dot mr-5'} />
                        <div className={'d-flex justify-content-between w-100'}>
                            <div className={'d-flex flex-wrap'} style={{ gap: '0.5rem' }}>
                                <FilterGenerator filterInfo={origin} handleChange={() => {}} disableOption />
                            </div>
                        </div>
                    </div>
                    <div className="pnt-border border-w" />
                    <div className="d-flex pnt-label--group">
                        <Label name={t('Owner')} labelValueClassName={'label-dot mr-5'} />
                        <ul className={'d-flex flex-wrap mt-2 mb-2'}>
                            {authInfo.owner.map(auth => (
                                <AuthNamePlate key={`${auth.section}_${auth.id}`} auth={auth} />
                            ))}
                        </ul>
                    </div>
                    <div className="pnt-border border-w" />
                    <div className="d-flex pnt-label--group">
                        <Label name={t('Access Right')} labelValueClassName={'label-dot mr-5'} />
                        <ul className={'d-flex flex-wrap mt-2 mb-2'}>
                            {authInfo.accessRight.map(auth => (
                                <AuthNamePlate key={`${auth.section}_${auth.id}`} auth={auth} />
                            ))}
                        </ul>
                    </div>
                </>
            </Card>
            <DeleteFilterModal />
        </>
    );
};

export default ShowView;
