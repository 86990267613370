import React from 'react';
import { useTranslation } from 'react-i18next';

export const useAssetListFloorDetailColumns = () => {
    const { t } = useTranslation();
    return [
        {
            Header: t('RealTimeLocationStatus;IoT Item Name'),
            headerClassName: 'flex-center',
            accessor: 'targetName',
            className: 'flex-center text-ellipsis',
        },
        {
            Header: t('RealTimeLocationStatus;IoT Item ID'),
            headerClassName: 'flex-center',
            accessor: 'targetId',
            className: 'flex-center text-ellipsis',
        },
        {
            Header: t('RealTimeLocationStatus;Category Name'),
            headerClassName: 'flex-center',
            accessor: 'categoryName',
            className: 'flex-center',
        },
        {
            Header: t('RealTimeLocationStatus;Entry Time'),
            headerClassName: 'flex-center',
            accessor: 'entryTime',
            className: 'flex-center',
        },
    ];
};

export const useGeofenceTargetInfoColumns = () => {
    const { t } = useTranslation();
    return [
        {
            Header: t('GeofenceStatus;Target Name'),
            headerClassName: 'flex-center',
            accessor: 'targetName',
            className: 'flex-center text-ellipsis',
        },
        {
            Header: t('GeofenceStatus;Category'),
            headerClassName: 'flex-center',
            accessor: 'category',
            className: 'flex-center text-ellipsis',
        },
        {
            Header: t('GeofenceStatus;Authorized'),
            headerClassName: 'flex-center',
            accessor: 'authorized',
            className: 'flex-center',
        },
        {
            Header: t('GeofenceStatus;Entry Time'),
            headerClassName: 'flex-center',
            accessor: 'entryTime',
            className: 'flex-center',
        },
    ];
};

export const categoryName = ({ ...restProps }) => {
    return {
        Header: 'Category',
        accessor: 'categoryName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const targetName = ({ ...restProps }) => {
    return {
        Header: 'People Name',
        accessor: 'targetName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const tagName = ({ ...restProps }) => {
    return {
        Header: 'Tag Name',
        accessor: 'beaconName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const floorName = ({ ...restProps }) => {
    return {
        Header: 'Floor',
        accessor: 'floorName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const location = ({ ...restProps }) => {
    return {
        Header: 'Location',
        accessor: 'geofence',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const signalStatus = ({ ...restProps }) => {
    return {
        Header: 'Signal Status',
        accessor: 'status',
        className: 'p-0',
        ...restProps,
    };
};

export const lastDate = ({ ...restProps }) => {
    return {
        Header: 'Last Detected Time',
        accessor: 'lastDate',
        className: 'text-ellipsis',
        ...restProps,
    };
};
