import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Collapse } from 'reactstrap';
import ResizeDetector from 'react-resize-detector';
import styled from 'styled-components';
import { setFilter } from '../../reducers/Filter';
import useTranslation from '../../util/hooks/useTranslation';
import cx from 'classnames';

const MobileFilterRow = styled(Row)`
    justify-content: space-evenly;
`;

const FilterAction = styled.div`
    display: flex;
    flex: 0 0 auto;
    align-items: center;
`;

export const RESPONSIVE_FILTER_WIDTH = 690;

const Filter = ({ children, Search, searchRefresh, ...restProps }) => {
    const dispatch = useDispatch();

    const [filterOpen, setFilterOpen] = useState(false);
    const toggleFilter = () => setFilterOpen(!filterOpen);
    const t = useTranslation('Search');
    const { filterInfo } = useSelector(state => state.Filter);

    const refreshFilter = e => {
        dispatch(setFilter());
        if (typeof searchRefresh === 'function') {
            searchRefresh();
        }
    };

    useEffect(() => {
        dispatch(setFilter({ filterInfo, isRestrictionStatus: null }));
    }, []);

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => {
                const isSmall = width < RESPONSIVE_FILTER_WIDTH;
                return (
                    <>
                        <div className="filter_ver4">
                            <div className="flex-row filter-box flx-row flx-top gap-3">
                                <div className="filter-label bg-l-2 bg-secondary">{t('Filter')}</div>
                                {!isSmall && <div>{children}</div>}
                                <FilterAction>
                                    {isSmall && (
                                        <button className="pnt-btn btn-gray btn-filter-open" onClick={toggleFilter}>
                                            <span className="icon-down" />
                                            {t('More')}
                                        </button>
                                    )}

                                    <button
                                        className={cx('pnt-btn btn-danger btn-icon-only', isSmall ? 'ml-2' : null)}
                                        onClick={refreshFilter}
                                    >
                                        <span className="icon-replay">Refresh</span>
                                    </button>
                                </FilterAction>
                            </div>
                            {isSmall && (
                                <Collapse isOpen={filterOpen} className="ml-2 mb-3">
                                    <MobileFilterRow>{children}</MobileFilterRow>
                                </Collapse>
                            )}
                            {!!Search && Search}
                        </div>
                    </>
                );
            }}
        />
    );
};

export default Filter;
