export const generateKey = (accPath, keyObj) => {
    for (let key in keyObj) {
        if (keyObj.hasOwnProperty(key)) {
            const currentKey = accPath + '.' + key;
            if (typeof keyObj[key] === 'object') {
                generateKey(currentKey, keyObj[key]);
            } else {
                keyObj[key] = currentKey;
            }
        }
    }
};
