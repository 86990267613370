import React, { useRef, useState } from 'react';
import Page from '../../../Common/Page';
import Search from '../IotItemInfoLog/Components/Search';
import useTranslation from '../../../../util/hooks/useTranslation';
import { createIotItemExcelApi, fetchExcelLogApi, getIotItemInfoLogListApi } from '../../../../api/log';
import useAsync from '../../../../util/hooks/useAsync';
import Card from '../../../Common/Card';
import Button from '../../../Common/Button';
import Table from '../../../Common/Table';
import useColumns from '../../../../util/hooks/useColumns';
import * as column from '../../util/grid/column';
import useConfirmModal from '../../../Common/ConfirmModal/useConfirmModal';
import moment from 'moment';

const INTERVAL_TIME = 1000;

const IotItemInfoLog = () => {
    const t = useTranslation('Iot Item Information Log');
    const commonColumn = useTranslation('CommonColumn');
    const excelTimerRef = useRef();

    const [iotItemInfoLogData, setIotItemInfoLogData] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });

    const columns = useColumns([
        column.number({
            width: 50,
        }),
        column.dataType({
            width: 80,
            Cell: ({ value }) => {
                return <div>{t(value)}</div>;
            },
        }),
        column.targetNum({
            width: 80,
        }),
        column.iotItemId({
            width: 100,
        }),
        column.targetName({
            Header: 'IoT Item Name',
            width: 100,
        }),
        column.registeredDate({
            Header: 'Data Revision Date',
            width: 100,
        }),
        column.metaData({
            Cell: ({ value }) => {
                let list = [];
                if (value) {
                    list = Object.entries(value);
                }

                return list.map((metaData, index) => {
                    const [key, value] = metaData;
                    if (!Array.isArray(value)) {
                        return (
                            <div key={index} className={'pnt-txt txt-border txt-bold custom-action-btn'}>
                                {key}: {value}
                            </div>
                        );
                    } else {
                        return (
                            <div key={index} className={'pnt-txt txt-border txt-bold custom-action-btn'}>
                                {key}: {value.join(',')}
                            </div>
                        );
                    }
                });
            },
        }),
    ]);

    const { promise: getIotItemInfoLogList, state: iotItemLogListInfo } = useAsync({
        promise: getIotItemInfoLogListApi,
        keepState: true,
        immediate: true,
        fixedParam: { pageSize: 15 },
        resolve: res => {
            setIotItemInfoLogData(res);
        },
    });

    const { promise: createIotItemExcel } = useAsync({
        promise: createIotItemExcelApi,
        fixedParam: {
            zipFileName: 'IotItem_Log',
            fileName: 'IotItem_Log',
            columnMetas: [
                {
                    key: 'logNum',
                    name: commonColumn('No.'),
                },
                {
                    key: 'manipulationType',
                    name: commonColumn('Type of Data Revision'),
                },
                {
                    key: 'targetNum',
                    name: commonColumn('IoT Item No.'),
                },
                {
                    key: 'targetId',
                    name: commonColumn('IoT Item ID'),
                },
                {
                    key: 'targetName',
                    name: commonColumn('IoT Item Name'),
                },
                {
                    key: 'categoryCodePath',
                    name: commonColumn('Category Code Path'),
                },
                {
                    key: 'categoryNamePath',
                    name: commonColumn('Category Name Path'),
                },
                {
                    key: 'regDate',
                    name: commonColumn('Data Revision Date'),
                    isDateFormat: 'Y',
                },
                {
                    key: 'metadata',
                    name: commonColumn('Meta Data'),
                },
            ],
        },
        resolve: res => {
            const { filePath: url, reqNum } = res;

            if (url) {
                downloadWithURL(url, reqNum);
            }
        },
    });

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            if (res.dataSettingStatusType === 'COMPLETED') {
                clearExcelTimer();

                const elem = window.document.createElement('a');
                elem.href = res.filePath;
                elem.download = res.fileName;
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
            } else if (res.dataSettingStatusType === 'FAILED') {
                clearExcelTimer();
                toggleExcelDownloadResultModal();
            }
        },
    });

    const handlePageChange = pageIndex => {
        if (iotItemLogListInfo.request && (iotItemLogListInfo.request.keyword || iotItemLogListInfo.manipulationType)) {
            getIotItemInfoLogList({ ...iotItemLogListInfo.request, page: pageIndex });
        } else {
            getIotItemInfoLogList({ page: pageIndex });
        }
    };

    const handleExportFileClick = () => {
        const { request } = iotItemLogListInfo;

        delete request.page;
        delete request.pageSize;

        request.isAll = 'Y';

        const currentUnixTimestamp = moment().unix();
        createIotItemExcel({ ...request, startDate: 0, endDate: currentUnixTimestamp });
    };

    const downloadWithURL = (url, reqNum) => {
        excelTimerRef.current = setInterval(() => {
            fetchExcelLog({ reqNum: reqNum });
        }, INTERVAL_TIME);
    };

    const clearExcelTimer = () => {
        clearInterval(excelTimerRef.current);
        excelTimerRef.current = null;
    };

    const { Modal: ExcelDownloadResultModal, toggleModal: toggleExcelDownloadResultModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Result', 'ConfirmModal') },
        confirmText: t('Failed to download the excel.', 'ConfirmModal'),
    });

    return (
        <Page menuPath={[t('Report', 'Menu'), t('Iot Item Information Log', 'Menu')]} className={'h-100'}>
            <Search getIotItemInfoLogList={getIotItemInfoLogList} />
            <Card
                className={'report-container'}
                header={{
                    title: t('Iot Item Information Log', 'Menu'),
                    action: (
                        <Button className="btn-secondary" onClick={handleExportFileClick}>
                            {t('Export', 'Button')}
                        </Button>
                    ),
                }}
            >
                <div style={{ height: '35rem' }}>
                    <Table
                        data={{ ...iotItemInfoLogData, pageSize: 15 }}
                        columns={columns}
                        onPageChange={handlePageChange}
                        loading={iotItemLogListInfo.isLoading}
                        getRowProps={({ style }) => {
                            return {
                                style: {
                                    ...style,
                                },
                            };
                        }}
                    />
                </div>
            </Card>
            <ExcelDownloadResultModal />
        </Page>
    );
};

export default IotItemInfoLog;
