import React from 'react';
import Table from '../../Common/Table';
import AssetDetail from '../Assets/AssetDetail';

const AssetTable = ({ targetNum, tableKey, columns, data, onPageChange, onTrClick, excludeMap, singleCol }) => {
    return (
        <>
            {targetNum ? (
                <AssetDetail targetNum={targetNum} excludeMap={excludeMap} singleCol={singleCol} />
            ) : (
                <Table key={tableKey} columns={columns} data={data} onPageChange={onPageChange} onTrClick={onTrClick} />
            )}
        </>
    );
};

export default AssetTable;
