import React from 'react';
import WidgetSettingModal from '../Components/WidgetSettingModal';
import useTranslation from '../../../util/hooks/useTranslation';

const LostSignalTimelineSetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('LostSignalTimelineWidget');
    return (
        <WidgetSettingModal
            headerTitle={t('Lost signal timeline widget setting')}
            widgetInfo={widgetInfo}
            hiddenHeader
            {...restProps}
        />
    );
};

export default LostSignalTimelineSetting;
