import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    selectableSensorList: [],
    startDate: null,
    endDate: null,
};
const { actions, reducer } = createSlice({
    name: 'sensorLog',
    initialState,
    reducers: {
        setSensorList: (state, action) => {
            const sensorList = action.payload.rows.map(item => {
                const { sensorName, sensorNum } = item;
                return { label: sensorName, value: sensorNum };
            });
            state.selectableSensorList = sensorList;
        },
        setStartDate: (state, action) => {
            state.startDate = action.payload;
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload;
        },
    },
});

export const { setSensorList, setStartDate, setEndDate } = actions;
export default reducer;
