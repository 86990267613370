import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from '../../../reducers/Filter';
import { fetchCategoryInfo } from '../../../api/common';
import useAsync from '../../../util/hooks/useAsync';
import FilterOptionBox from './FilterOptionBox';
import { setCategoryList } from '../../../reducers/CommonInfo';
import useTranslation from '../../../util/hooks/useTranslation';
import TreeSelect from '../../Common/Select/TreeSelect';

const Category = ({}) => {
    const t = useTranslation('Filter');
    const dispatch = useDispatch();
    const { categoryList } = useSelector(state => state.CommonInfo);
    const { categoryCodes } = useSelector(state => state.Filter.filterInfo);
    const { serviceCategory } = useSelector(state => state.AppInfo);
    const { promise: getCategoryInfo } = useAsync({
        promise: fetchCategoryInfo,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            if (res.rows) {
                res.rows.sort((a, b) => (a.categoryCodePath > b.categoryCodePath ? 1 : -1));
            }
            dispatch(setCategoryList(res));
        },
        reject: error => {
            console.log(error);
            dispatch(setCategoryList({ rows: [] }));
        },
    });

    const handleChange = selectedOption => {
        dispatch(setFilter({ categoryCodes: selectedOption }));
    };

    useEffect(() => {
        getCategoryInfo({ isAll: 'Y' });
    }, [serviceCategory]);

    return (
        <FilterOptionBox>
            <TreeSelect
                data={categoryList}
                title={t('Category')}
                valueKey={'categoryCode'}
                labelKey={'categoryName'}
                parentKey={'parentCode'}
                selected={categoryCodes}
                onChange={handleChange}
            />
        </FilterOptionBox>
    );
};

export default Category;
