import React, { useMemo } from 'react';
import SimpleCountWithoutCard from '../../MainPages/Components/SimpleCountWithoutCard';
import { checkFalsy } from '../../../util/common/util';
import WidgetCard from '../Components/WidgetCard';
import useTranslation from '../../../util/hooks/useTranslation';
import { useSelector } from 'react-redux';
import useMonitor from '../util/useMonitor';
import { fetchTagStatusCount } from '../../../api/widget';

const defaultData = [];
const TagStatusSummary = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('TagStatusSummaryWidget');
    const { config } = widgetInfo;
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const filterParam = useMemo(() => {
        let tempArr = [];
        categoryList
            .filter(category => category.parentCode === 'ASSET')
            .map(category => tempArr.push(category.categoryCode));
        let tempParam = {
            categoryCodes: tempArr.toString(),
        };
        return tempParam;
    }, [categoryList]);

    const { data } = useMonitor({
        config,
        defaultData,
        dynamicParam: filterParam,
        fetchData: fetchTagStatusCount,
    });
    return (
        <WidgetCard widgetInfo={widgetInfo} {...restProps}>
            <SimpleCountWithoutCard
                widgetInfo={{
                    title: t('Tag status'),
                    mainCnt: checkFalsy(data.detectedTagCnt),
                    mainAllCnt: checkFalsy(data.registeredTagCnt),
                    mainStatus: t('Detected', 'Common'),
                    colorClass: 'color-secondary',
                    detailCnt: [
                        {
                            colorClass: 'color-danger',
                            label: t('Lost signal', 'Common'),
                            value: checkFalsy(data.lostSignalTagCnt),
                        },
                        {
                            colorClass: 'color-gray',
                            label: t('No response', 'Common'),
                            value: checkFalsy(data.neverDetectedTagCnt),
                        },
                    ],
                }}
            />
            {children}
        </WidgetCard>
    );
};

export default TagStatusSummary;
