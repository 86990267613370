import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { addDashboard, setDashboardList } from '../../../../reducers/Dashboards/Dashboards';
import moment from 'moment';
import useAsync from '../../../../util/hooks/useAsync';
import { createDashboardApi, updateDashboardApi, getDashboardListApi } from '../../../../api/dashboard';
import { setBookmarkMenu } from '../../../../reducers/CustomMenu';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../../../Common/Modal';
import Label from '../../../Common/Label';
import ReactSelect from '../../../Common/Select';
import TextInput from '../../../Common/Input/TextInput';
import Checkbox from '../../../Common/Input/Checkbox';
import cx from 'classnames';
import Button from '../../../Common/Button';
import { widgetInfoExport } from '../util';
import useConfirmModal from '../../../Common/ConfirmModal/useConfirmModal';

const DashboardDetailPopup = ({
    initOpen = false,
    setModal,
    toggleModal = function () {
        initOpen = !initOpen;
    },
    dashboardInfo,
    history,
    ...restProps
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { userInfo } = useSelector(state => state.UserInfo);
    const fileInputRef = useRef();
    const [updateDashboardInfo, setUpdateDashboardInfo] = useState({
        show: 'N',
        home: 'N',
        share: 'N',
        order: 0,
    });
    const [sharedDashboardList, setSharedDashboardList] = useState([]);
    const [selectedSharedDashboard, setSelectedSharedDashboard] = useState(null);
    const [widgetInfo, setWidgetInfo] = useState(null);
    const [validation, setValidation] = useState({
        menuName: false,
    });

    useEffect(() => {
        getSharedDashboardList();
    }, []);

    useEffect(() => {
        if (dashboardInfo) {
            setUpdateDashboardInfo(dashboardInfo);
        } else {
            setUpdateDashboardInfo({
                show: 'N',
                home: 'N',
                share: 'N',
                order: 0,
            });
        }
    }, [dashboardInfo]);

    const { promise: getCustomMenu } = useAsync({
        promise: getDashboardListApi,
        param: { isIndividual: 'Y', show: 'Y', pageSize: 0 },
        resolve: response => {
            let bookmarkedMenu = response.rows || [];
            dispatch(setBookmarkMenu(bookmarkedMenu));
            history.push(`/dashboards/edit/${createRes.response.menuNum}`);
        },
    });
    const { promise: getDashboardList } = useAsync({
        promise: getDashboardListApi,
        param: { isIndividual: 'Y' },
        resolve: response => {
            dispatch(setDashboardList(response));
        },
    });

    const { promise: getSharedDashboardList } = useAsync({
        promise: getDashboardListApi,
        param: { pageSize: 0 },
        resolve: response => {
            const dashboardList = response.rows.reduce((acc, curr) => {
                const { userID, menuNum, menuName, modDate, share } = curr;
                if ((userID === userInfo.userId && menuNum !== dashboardInfo.menuNum) || share === 'Y') {
                    acc.push({
                        ...curr,
                        label: (
                            <>
                                {userID === userInfo.userId ? (
                                    <>
                                        <span
                                            className={'btn-pill btn btn-outline-warning btn-sm'}
                                            style={{ lineHeight: 1, pointerEvents: 'none' }}
                                        >
                                            Mine
                                        </span>
                                        <span> / </span>
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={faShareAlt} className={'mr-2'} />
                                        <span>{userID} / </span>
                                    </>
                                )}
                                <span>{`${menuName} / ${moment(modDate * 1000).format('YYYY-MM-DD HH:mm:ss')}`}</span>
                            </>
                        ),
                        value: menuNum,
                    });
                }
                return acc;
            }, []);
            dashboardList.sort((a, b) => {
                return a.userID === userInfo.userId ? 0 : -1;
            });
            setSharedDashboardList(dashboardList);
        },
    });
    const { promise: createDashboard, state: createRes } = useAsync({
        promise: createDashboardApi,
        resolve: () => {
            getCustomMenu();
            getSharedDashboardList();
        },
    });
    const { promise: updateDashboard } = useAsync({
        promise: updateDashboardApi,
        resolve: () => {
            getDashboardList();
            getSharedDashboardList();
        },
    });

    const handleSubmitClick = () => {
        const newDashboardInfo = updateDashboardInfo;
        if (newDashboardInfo.menuName) {
            dispatch(addDashboard(newDashboardInfo));
            if (!dashboardInfo) {
                createDashboard({ ...newDashboardInfo, widgetInfo });
            } else {
                updateDashboard(newDashboardInfo);
            }
            setUpdateDashboardInfo({ show: 'N', home: 'N', share: 'N', order: 0 });
            setSelectedSharedDashboard(null);
            setValidation({ menuName: false });
            toggleModal();
        } else {
            setValidation({ menuName: true });
        }
    };

    const handleCancelButtonClick = () => {
        setUpdateDashboardInfo({ show: 'N', home: 'N', share: 'N', order: 0 });
        setSelectedSharedDashboard(null);
        setValidation({ menuName: false });
        toggleModal();
    };

    const { Modal: UploadFileResultModal, toggleModal: toggleUploadFileResultModal } = useConfirmModal({
        initModal: false,
        confirmText: t('ConfirmModal;Files other than JSON cannot be uploaded.'),
    });

    // 파일 업로드
    // 파일 선택 시 파일 명 상태 저장, json 외의 파일 선택 시 파일 명 초기화 및 확인 모달창
    const handleFile = async selected => {
        const { files } = selected.target;
        if (files[0] && files[0].type === 'application/json') {
            const filenameArr = files[0].name.split('.');
            const filename = filenameArr[0];
            setUpdateDashboardInfo(prevState => {
                return { ...prevState, menuName: filename, show: 'Y' };
            });
            let text = await files[0].text();
            setWidgetInfo(text);
        } else if (files[0] && files[0].type !== 'application/json') {
            setWidgetInfo(null);
            setUpdateDashboardInfo({ show: 'N', home: 'N', share: 'N', order: 0 });
            fileInputRef.current.value = '';
            toggleUploadFileResultModal();
        } else {
            setWidgetInfo(null);
            setUpdateDashboardInfo({ show: 'N', home: 'N', share: 'N', order: 0 });
            fileInputRef.current.value = '';
        }
    };

    return (
        <>
            <Modal
                initModal={initOpen}
                toggleModal={toggleModal}
                headerTitle={t('Dashboard;Dashboard detail')}
                modalFooter={
                    <div className={'modal-footer'}>
                        <Button className={'color-secondary'} onClick={() => handleCancelButtonClick()}>
                            {t('ConfirmModal;Cancel')}
                        </Button>
                        <Button className={'btn-secondary'} onClick={() => handleSubmitClick()}>
                            {t('ConfirmModal;OK')}
                        </Button>
                    </div>
                }
            >
                <div className={'modal-body'}>
                    <div className={'flx-col gap-4 pnt-label-5'}>
                        <Label
                            name={t('Dashboard;Import')}
                            value={
                                <div className={'w-100'}>
                                    <ReactSelect
                                        value={selectedSharedDashboard}
                                        isDisabled={!!updateDashboardInfo.menuNum}
                                        name={'baseMenu'}
                                        isClearable={true}
                                        options={sharedDashboardList}
                                        onChange={(option, select) => {
                                            setSelectedSharedDashboard(option);
                                            if (option) {
                                                setUpdateDashboardInfo(prevState => {
                                                    return {
                                                        ...prevState,
                                                        menuName: option.menuName,
                                                        description: option.description,
                                                        baseMenuNum: Number(option.menuNum),
                                                    };
                                                });
                                            } else {
                                                setUpdateDashboardInfo(prevState => {
                                                    return {
                                                        ...prevState,
                                                        menuName: '',
                                                        description: '',
                                                        baseMenuNum: '',
                                                    };
                                                });
                                            }
                                        }}
                                        filterOption={(option, searchText) => {
                                            const lowerCaseSearchText = searchText.toLowerCase();
                                            if (
                                                option.data.menuName.toLowerCase().includes(lowerCaseSearchText) ||
                                                option.data.userID.toLowerCase().includes(lowerCaseSearchText)
                                            ) {
                                                return true;
                                            }
                                            return false;
                                        }}
                                        customControlStyles={{ width: '100%' }}
                                        customMenuStyles={{ width: '100%' }}
                                    />
                                </div>
                            }
                        />

                        <Label
                            name={t('Dashboard;Dashboard Name')}
                            value={
                                <>
                                    <TextInput
                                        inputGroupClassName={cx(
                                            dashboardInfo ? 'w-75' : 'w-100',
                                            validation.menuName && 'form-must',
                                        )}
                                        type={'text'}
                                        name={'menuName'}
                                        id={'menuName'}
                                        placeholder={''}
                                        maxlength={25}
                                        value={updateDashboardInfo.menuName || ''}
                                        handleChange={e => {
                                            const { value } = e.target;
                                            setUpdateDashboardInfo(prevState => {
                                                return { ...prevState, menuName: value };
                                            });
                                        }}
                                    />
                                    {dashboardInfo && (
                                        <Button
                                            className={'btn-brand ml-3'}
                                            onClick={() => {
                                                const { menuName, widgetInfo } = dashboardInfo;
                                                widgetInfoExport({ filename: menuName, exportWidgetInfo: widgetInfo });
                                            }}
                                        >
                                            {t('Button;Export')}
                                        </Button>
                                    )}
                                </>
                            }
                        />

                        <Label
                            name={t('Dashboard;Dashboard Description')}
                            value={
                                <TextInput
                                    inputGroupClassName={'w-100'}
                                    type={'text'}
                                    name={'description'}
                                    id={'description'}
                                    placeholder={''}
                                    maxlength={100}
                                    value={updateDashboardInfo.description || ''}
                                    handleChange={e => {
                                        const { value } = e.target;
                                        setUpdateDashboardInfo(prevState => {
                                            return { ...prevState, description: value };
                                        });
                                    }}
                                />
                            }
                        />

                        <Label
                            name={t('Dashboard;Home')}
                            value={
                                <Checkbox
                                    id={'home'}
                                    handleChecked={e => {
                                        const { checked } = e.target;
                                        if (checked) {
                                            setUpdateDashboardInfo(prevState => {
                                                return { ...prevState, show: 'Y', home: 'Y' };
                                            });
                                        } else {
                                            setUpdateDashboardInfo(prevState => {
                                                return { ...prevState, home: 'N' };
                                            });
                                        }
                                    }}
                                    checked={updateDashboardInfo.home === 'Y'}
                                />
                            }
                        />

                        <Label
                            name={t('Dashboard;Bookmark')}
                            value={
                                <Checkbox
                                    id={'show'}
                                    handleChecked={e => {
                                        const { checked } = e.target;
                                        if (checked) {
                                            setUpdateDashboardInfo(prevState => {
                                                return { ...prevState, show: 'Y' };
                                            });
                                        } else {
                                            setUpdateDashboardInfo(prevState => {
                                                return { ...prevState, show: 'N', home: 'N' };
                                            });
                                        }
                                    }}
                                    checked={updateDashboardInfo.show === 'Y' || false}
                                />
                            }
                        />

                        <Label
                            name={t('Dashboard;Order')}
                            value={
                                <TextInput
                                    inputGroupClassName={'w-100'}
                                    type={'number'}
                                    id={'order'}
                                    handleChange={e => {
                                        const { value } = e.target;
                                        if (value > 100) {
                                            setUpdateDashboardInfo(prevState => {
                                                return { ...prevState, order: 100 };
                                            });
                                        } else {
                                            setUpdateDashboardInfo(prevState => {
                                                return { ...prevState, order: value };
                                            });
                                        }
                                    }}
                                    checked={updateDashboardInfo.share === 'Y'}
                                    name={'order'}
                                    value={updateDashboardInfo.order || 0}
                                    min={0}
                                    max={100}
                                />
                            }
                        />

                        <Label
                            name={t('Dashboard;Share')}
                            value={
                                <Checkbox
                                    id={'share'}
                                    handleChecked={e => {
                                        const { checked } = e.target;
                                        if (checked) {
                                            setUpdateDashboardInfo(prevState => {
                                                return { ...prevState, share: 'Y' };
                                            });
                                        } else {
                                            setUpdateDashboardInfo(prevState => {
                                                return { ...prevState, share: 'N' };
                                            });
                                        }
                                    }}
                                    checked={updateDashboardInfo['share'] === 'Y' || false}
                                />
                            }
                        />
                        {!dashboardInfo && (
                            <Label
                                name={t('Dashboard;Import')}
                                value={
                                    <div className="pnt-file--group file-widthout-js w-100">
                                        <input
                                            type="file"
                                            id="dashboardFile"
                                            onChange={handleFile}
                                            accept="application/json"
                                            ref={fileInputRef}
                                        />
                                        <label className="pnt-btn btn-brand btn-icon" htmlFor="dashboardFile">
                                            <span className="icon-upload" />
                                            {t('Button;Upload File')}
                                        </label>
                                    </div>
                                }
                            />
                        )}
                    </div>
                </div>
            </Modal>
            <UploadFileResultModal />
        </>
    );
};

export default withRouter(DashboardDetailPopup);
