import React, { useEffect, useState } from 'react';
import Category from '../../../Filter/FilterOption/Category';
import Floor from '../../../Filter/FilterOption/Floor';
import Filter from '../../../Filter';
import Card from '../../../Common/Card';
import { useFilterParam } from '../../../../util/hooks/useFilterParam';
import Search from '../../../Search';
import useAsync from '../../../../util/hooks/useAsync';
import { fetchAssetList } from '../../../../api/asset';
import * as col from '../../util/grid/column';
import ConfirmModal from '../../../Common/ConfirmModal';
import useTranslation from '../../../../util/hooks/useTranslation';
import { createBookmark, deleteBookmark } from '../../../../api/bookmark';
import Button from '../../../Common/Button';
import moment from 'moment';
import { SearchWrap, SelectGroup, InputWrap, InputGroup } from '../../../Common/FilterSearchGroup/Components/Part';
import ReactSelect from '../../../Common/Select';
import TextInput from '../../../Common/Input/TextInput';
import Page from '../../../Common/Page';
import AssetTable from '../../Components/AssetTable';

const AssetList = () => {
    const t = useTranslation('AssetList');
    const [listUpdateKey, setListUpdateKey] = useState(Date.now());
    const [keyword, setKeyword] = useState('');
    const [assetListInfo, setAssetListInfo] = useState({
        rows: [],
        page: 1,
    });
    const [bookmarkReqErrModal, setBookmarkReqErrModal] = useState(false);
    const [targetNum, setTargetNum] = useState(null);
    const bookmarkReqErrModalToggle = () => {
        setBookmarkReqErrModal(!bookmarkReqErrModal);
    };
    const [removeBookmarkReqErrModal, setRemoveBookmarkReqErrModal] = useState(false);
    const removeBookmarkReqErrModalToggle = () => {
        setRemoveBookmarkReqErrModal(!removeBookmarkReqErrModal);
    };

    const [changedFilterParam, setChangedFilterParam] = useState(false);
    const [inputOption, setInputOption] = useState('');
    const searchOptions = [
        { value: 'targetName', label: t('Name') },
        { value: 'targetId', label: t('ID') },
    ];

    const { promise: getAssetList } = useAsync({
        promise: fetchAssetList,
        resolve: res => {
            if (res) {
                res.rows = res.rows.map(v => {
                    if (v.lastDate) {
                        return {
                            ...v,
                            lastDateDiff: moment().diff(moment(v.lastDate * 1000), 'seconds'),
                        };
                    }
                    return v;
                });
            }
            setAssetListInfo(res || { rows: [], page: 1 });
        },
        reject: error => {
            console.log(error);
        },
    });

    const { promise: registerBookmark } = useAsync({
        promise: createBookmark,
        resolve: res => {
            if (res.code === '0') {
                const targetNum = res.pkValue;
                setAssetListInfo({
                    ...assetListInfo,
                    rows: assetListInfo.rows.map(info => {
                        if (info.targetNum === targetNum) {
                            return {
                                ...info,
                                isBookmark: 'Y',
                            };
                        }
                        return info;
                    }),
                });
            } else {
                setBookmarkReqErrModal(true);
            }
        },
        reject: error => {
            console.log(error);
            setBookmarkReqErrModal(true);
        },
    });
    const { promise: removeBookmark } = useAsync({
        promise: deleteBookmark,
        resolve: res => {
            if (res.code === '0') {
                const targetNum = res.pkValue;
                setAssetListInfo({
                    ...assetListInfo,
                    rows: assetListInfo.rows.map(info => {
                        if (info.targetNum === targetNum) {
                            return {
                                ...info,
                                isBookmark: 'N',
                            };
                        }
                        return info;
                    }),
                });
            } else {
                setRemoveBookmarkReqErrModal(true);
            }
        },
        reject: error => {
            console.log(error);
            setRemoveBookmarkReqErrModal(true);
        },
    });

    const filterParam = useFilterParam();
    const assetListColumns = [
        col.categoryNameAT(),
        col.targetNameAT(),
        col.targetIdAT(),
        col.floorAT(),
        col.lastDateDiffAT(),
        col.ownershipAT({
            Cell: ({ value, row: { original } }) => {
                const targetNum = original.targetNum;
                const bookmarked = value === 'Y';
                return (
                    <div style={{ width: '100%' }} className={'d-flex justify-content-center'}>
                        <input
                            style={{ marginRight: 0 }}
                            type="checkbox"
                            checked={bookmarked}
                            onChange={e => {}}
                            onClick={e => {
                                e.stopPropagation();
                                if (bookmarked) {
                                    removeBookmark({ targetNum });
                                } else {
                                    registerBookmark({ targetNum });
                                }
                            }}
                        />
                    </div>
                );
            },
        }),
    ];

    const handleOptionChange = e => {
        setInputOption(e);
    };

    const handleValueChange = e => {
        setKeyword(e.target.value);
    };

    const handleClick = () => {
        setListUpdateKey(Date.now());
        getAssetList({ opt: inputOption.value, keyword: keyword, ...filterParam, pageSize: 20 });
    };

    const refreshSearchSection = () => {
        setInputOption('');
        setKeyword('');
        setListUpdateKey(Date.now());
    };

    useEffect(() => {
        if (!changedFilterParam && filterParam.categoryCodes) {
            getAssetList({ ...filterParam, pageSize: 20 });
            setChangedFilterParam(true);
        }
    }, [filterParam]);

    return (
        <Page className={'h-100'}>
            <Filter
                searchRefresh={refreshSearchSection}
                Search={
                    <Search>
                        <div className="search-box flx-row flx-top gap-3">
                            <SearchWrap>
                                <SelectGroup>
                                    <ReactSelect
                                        name={'selectInput'}
                                        value={inputOption}
                                        options={searchOptions}
                                        onChange={handleOptionChange}
                                        placeholder={t('Search Option', 'Search')}
                                        customControlStyles={{
                                            width: '100%',
                                        }}
                                        customMenuStyles={{ width: '100%' }}
                                        customOptionStyles={{ width: '100%' }}
                                    />
                                </SelectGroup>
                                <InputWrap>
                                    <InputGroup>
                                        <TextInput
                                            handleChange={handleValueChange}
                                            value={keyword}
                                            placeholder={t('Please enter an asset name or ID', 'Search')}
                                            name={'textInput'}
                                            size={'60'}
                                        />
                                    </InputGroup>
                                    <Button
                                        className={'btn-brand btn-icon'}
                                        iconClassName={'icon-search'}
                                        onClick={handleClick}
                                    >
                                        {t('Search', 'Search')}
                                    </Button>
                                </InputWrap>
                            </SearchWrap>
                        </div>
                    </Search>
                }
            >
                <Category />
                <Floor />
            </Filter>
            <Card
                key={1}
                header={{
                    title: <>{targetNum ? t('Selected Asset', 'Menu') : t('Asset List', 'Menu')}</>,
                    action: targetNum && (
                        <Button
                            className={'btn-brand'}
                            onClick={() => {
                                setTargetNum(null);
                            }}
                        >
                            {t('Back', 'Button')}
                        </Button>
                    ),
                }}
                className={'report-container'}
            >
                <div style={{ height: '32.8rem' }}>
                    <AssetTable
                        key={listUpdateKey}
                        data={assetListInfo}
                        columns={assetListColumns}
                        onPageChange={pageIndex => {
                            if (filterParam.categoryCodes) {
                                const requestParam = {
                                    pageSize: 20,
                                    opt: inputOption.value,
                                    keyword: keyword,
                                    page: pageIndex,
                                    ...filterParam,
                                };
                                getAssetList(requestParam);
                            }
                        }}
                        targetNum={targetNum}
                        onTrClick={({ targetNum }) => {
                            setTargetNum(targetNum);
                        }}
                    />
                </div>
            </Card>
            <ConfirmModal
                initModal={bookmarkReqErrModal}
                modalToggle={bookmarkReqErrModalToggle}
                confirmText={t('Failed to register bookmark.')}
                removeCancel
            />
            <ConfirmModal
                initModal={removeBookmarkReqErrModal}
                modalToggle={removeBookmarkReqErrModalToggle}
                confirmText={t('The unbookmark request failed.')}
                removeCancel
            />
        </Page>
    );
};

export default AssetList;
