import React from 'react';
import useTranslation from '../../../util/hooks/useTranslation';
import WidgetSettingModal from '../Components/WidgetSettingModal';

const AssetCountByCategorySetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('AssetCountByCategoryWidget');
    return (
        <WidgetSettingModal
            headerTitle={t('Asset count by category widget setting')}
            widgetInfo={widgetInfo}
            hiddenHeader
            {...restProps}
        />
    );
};

export default AssetCountByCategorySetting;
