import React, { useContext, useEffect, useMemo, useState } from 'react';
import Button from '../../../../Common/Button';
import moment from 'moment';
import useTranslation from '../../../../../util/hooks/useTranslation';
import DatePicker from '../../../../Common/DatePicker';
import FilterSearchGroup from '../../../../Common/FilterSearchGroup';
import { FilterList, SelectGroup } from '../../../../Common/FilterSearchGroup/Components/Part';
import SearchableSelect from '../../../../Common/Select/SearchableSelect';
import FilterGroup from '../../../../Common/FilterSearchGroup/Components/FilterGroup';
import FilterButtonArea from '../../../../Common/FilterSearchGroup/Components/FilterButtonArea';
import { SensorLogDispatchContext, SensorLogStateContext } from '../index';

const Search = ({ getLocationLogList }) => {
    const t = useTranslation('Sensor Log');
    const [selectedOption, setSelectedOption] = useState({ value: '', label: t('Search Option', 'Search') });
    const { selectableSensorList } = useContext(SensorLogStateContext);
    const searchOptions = useMemo(() => {
        return [
            { value: '', label: t('Search Option', 'Search') },
            { value: 'targetName', label: t('Target Name') },
            { value: 'tagName', label: t('Device Name') },
        ];
    }, [t]);
    const [searchInput, setSearchInput] = useState([]);
    const [searchInputName, setSearchInputName] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        if (selectedOption) {
            setSelectedOption(searchOptions.find(v => v.value === selectedOption.value));
        }
    }, [searchOptions]);

    const handleRefreshClick = () => {
        setSelectedOption(searchOptions[0]);
        setSearchInput([]);
        setStartDate(null);
        setEndDate(null);
        setSearchInputName(null);
        getLocationLogList();
    };

    const handleSelectChange = selected => {
        setSearchInput(selected.map(({ value }) => value));
        setSearchInputName(selected.map(({ label }) => label));
    };

    const handleSearchClick = () => {
        if (searchInput) {
            getLocationLogList({
                opt: 'targetName',
                keyword: searchInputName.length ? searchInputName.join(',') : null,
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
        } else {
            getLocationLogList({
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
        }
    };

    const handleYesterdayButtonClick = () => {
        const start = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
        const end = new Date();

        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);

        setStartDate(start.valueOf());
        setEndDate(end.valueOf() - 1);
    };

    return (
        <FilterSearchGroup>
            <FilterGroup className={'bg-brand'} label={t('Search', 'Search')}>
                <FilterList>
                    <SelectGroup>
                        <div className={'datePicker-container'}>
                            <DatePicker
                                value={startDate}
                                handleChange={selected => setStartDate(selected)}
                                valueType={'ms'}
                                maxDate={endDate || new Date()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <div className={'text-center'}> ~ </div>
                    <SelectGroup>
                        <div className={'datePicker-container'}>
                            <DatePicker
                                value={endDate}
                                handleChange={selected => setEndDate(selected)}
                                valueType={'ms'}
                                minDate={startDate}
                                maxDate={new Date()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <SelectGroup>
                        <SearchableSelect
                            data={selectableSensorList}
                            selected={searchInput}
                            onChange={handleSelectChange}
                            title={t('Sensor')}
                        />
                    </SelectGroup>
                </FilterList>
                <FilterButtonArea>
                    <Button className={'btn-secondary btn-yesterday'} onClick={handleYesterdayButtonClick}>
                        {t('Yesterday', 'Button')}
                    </Button>
                    <Button className={'btn-brand btn-icon'} iconClassName={'icon-search'} onClick={handleSearchClick}>
                        {t('Search', 'Search')}
                    </Button>
                    <Button
                        className="btn-danger btn-icon-only"
                        iconClassName={'icon-replay'}
                        onClick={handleRefreshClick}
                    />
                </FilterButtonArea>
            </FilterGroup>
        </FilterSearchGroup>
    );
};
export default Search;
