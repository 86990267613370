import React from 'react';
import { Col } from 'reactstrap';
import userImg from '../../../../../assets/images/unknown_user.png';
import { useTranslation } from 'react-i18next';
import Label from '../../../../Common/Label';
import Modal from '../../../../Common/Modal';

const ProfileModal = ({ initModal, userInfo, toggleModal }) => {
    const { t } = useTranslation();
    return (
        <Modal initModal={initModal} toggleModal={toggleModal} headerTitle={t('UserBox;Profile')} removeCancel>
            <div className={'modal-body'}>
                <Col>
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginRight: '-15px', marginLeft: '-15px' }}>
                        <div>
                            <div className="profile-picture__wrap">
                                <img
                                    src={userImg}
                                    alt="profileImg"
                                    style={{ width: '150px', height: '150px', marginRight: '1rem' }}
                                />
                            </div>
                        </div>
                        <div className="flx-col" style={{ justifyContent: 'space-around' }}>
                            <Label
                                name={t('UserBox;Name')}
                                labelValueClassName={'color-brand'}
                                value={userInfo.userName}
                            />
                            <Label name={t('UserBox;ID')} labelValueClassName={'color-brand'} value={userInfo.userID} />
                            <Label
                                name={t('UserBox;Email')}
                                labelValueClassName={'color-brand'}
                                value={userInfo.userEmail}
                            />
                        </div>
                    </div>
                </Col>
            </div>
        </Modal>
    );
};

export default ProfileModal;
