import React from 'react';
import WidgetSettingModal from '../Components/WidgetSettingModal';
import useTranslation from '../../../util/hooks/useTranslation';

const AssetTrackingByCategorySetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('AssetTrackingByCategoryWidget');
    return (
        <WidgetSettingModal
            headerTitle={t('Asset tracking by category setting')}
            widgetInfo={widgetInfo}
            hiddenHeader
            {...restProps}
        />
    );
};

export default AssetTrackingByCategorySetting;
