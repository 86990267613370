import React from 'react';
import { Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Flag from 'react-flagkit';
import styled from 'styled-components';
import language from '../../../../../util/staticData/language.json';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../../../../reducers/UserInfo';
import ConfirmModal from '../../../../Common/ConfirmModal';
import Modal from '../../../../Common/Modal';

const LanguageItem = styled.li`
    font-size: 1.25rem;
    cursor: pointer;
    border-radius: 5px;
    margin: 5px;
    &.active {
        background-color: #e2e2e2;
    }
    &:hover {
        background-color: var(--blur-blue);
    }
    & > div {
        margin-left: auto;
        margin-right: auto;
        width: 170px;
    }
`;

const getCountry = lang => {
    const findCountry = language.find(v => v.value === lang);
    return findCountry ? findCountry.id : 'KR';
};

const LanguageSelectModal = ({ initModal, toggleModal }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { lang } = useSelector(state => state.UserInfo);

    return (
        <Modal
            initModal={initModal}
            toggleModal={toggleModal}
            headerTitle={t('UserBox;Language selection')}
            removeCancel
        >
            <div className={'modal-body'}>
                <Col>
                    <h6 className="pb-2">{t('UserBox;Please select a language')}</h6>
                    <ul>
                        {language.map(v => (
                            <LanguageItem
                                key={v.id}
                                className={v.id === getCountry(lang) ? 'active' : ''}
                                onClick={() => {
                                    dispatch(setLanguage(v.value));
                                }}
                            >
                                <div>
                                    <Flag className="mr-3 opacity-8" country={v.id} />
                                    {v.label}
                                </div>
                            </LanguageItem>
                        ))}
                    </ul>
                </Col>
            </div>
        </Modal>
    );
};

export default LanguageSelectModal;
