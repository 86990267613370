import React, { useMemo, useState, useEffect } from 'react';
import WidgetCard from '../Components/WidgetCard';
import useTranslation from '../../../util/hooks/useTranslation';
import useMonitor from '../util/useMonitor';
import { fetchAssetList } from '../../../api/asset';
import { useSelector } from 'react-redux';
import AssetTable from '../../MainPages/Components/AssetTable';
import * as col from '../../MainPages/util/grid/column';
import Button from '../../Common/Button';

const defaultData = {};
const AssetList = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('AssetListWidget');
    const { config } = widgetInfo;
    const { categoryList } = useSelector(state => state.CategoryInfo);

    const [targetNum, setTargetNum] = useState(null);
    const [tableData, setTableData] = useState({
        rows: [],
        page: 1,
    });
    const [pageIndex, setPageIndex] = useState(1);

    const filterParam = useMemo(() => {
        let tempArr = [];
        categoryList
            .filter(category => category.parentCode === 'ASSET')
            .map(category => tempArr.push(category.categoryCode));
        let tempParam = {
            categoryCodes: tempArr.toString(),
            page: pageIndex,
            pageSize: 20,
        };
        return tempParam;
    }, [categoryList, pageIndex]);

    const { data } = useMonitor({
        config,
        defaultData,
        dynamicParam: filterParam,
        fetchData: fetchAssetList,
    });

    useEffect(() => {
        if (data.rows) {
            setTableData(data);
        }
    }, [data]);

    const assetListColumns = [col.categoryNameAT(), col.targetNameAT(), col.floorAT(), col.lastDateDiffAT()];

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            headerAction={
                targetNum && (
                    <Button
                        className={'btn-brand'}
                        onClick={() => {
                            setTargetNum(null);
                        }}
                    >
                        {t('Back', 'Button')}
                    </Button>
                )
            }
            {...restProps}
        >
            <AssetTable
                tableKey={1}
                targetNum={targetNum}
                data={tableData}
                columns={assetListColumns}
                onTrClick={({ targetNum }) => {
                    setTargetNum(targetNum);
                }}
                onPageChange={pageIndex => {
                    setPageIndex(pageIndex);
                }}
            />
            {children}
        </WidgetCard>
    );
};

export default AssetList;
