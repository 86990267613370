import { createSlice } from '@reduxjs/toolkit';
import { SOCKET_CONNECTING } from '../util/symbol/window';

const initialState = {
    socket: null,
};

const { actions, reducer } = createSlice({
    name: 'socketInfo',
    initialState,
    reducers: {
        setSocket: (state, action) => {
            state.socket = action.payload;
            window[SOCKET_CONNECTING] = false;
        },
        setEventHandler: (state, action) => {
            const { messageType, callback } = action.payload;
            if (state.socket) {
                if (typeof callback === 'function') {
                    state.socket.on(messageType, function (data) {
                        callback(data);
                    });
                } else {
                    state.socket.off(messageType);
                }
            }
        },
    },
});

export const { setSocket, setEventHandler } = actions;
export default reducer;
