import React, { useRef, useState } from 'react';
import Page from '../../../Common/Page';
import Card from '../../../Common/Card';
import Button from '../../../Common/Button';
import Table from '../../../Common/Table';
import useTranslation from '../../../../util/hooks/useTranslation';
import useAsync from '../../../../util/hooks/useAsync';
import Search from './Components/Search';
import useColumns from '../../../../util/hooks/useColumns';
import * as column from '../../util/grid/column';
import useConfirmModal from '../../../Common/ConfirmModal/useConfirmModal';
import { createIotItemMappingExcelApi, fetchExcelLogApi, getIotItemMappingLogApi } from '../../../../api/log';
import moment from 'moment';

const INTERVAL_TIME = 1000;

const IotItemMappingLog = () => {
    const t = useTranslation('Iot Item Mapping Log');
    const commonColumn = useTranslation('CommonColumn');

    const excelTimerRef = useRef();
    const [iotItemMappingLogData, setIotItemMappingLogData] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });

    const { promise: getIotItemMappingLog, state: iotItemMappingLogInfo } = useAsync({
        promise: getIotItemMappingLogApi,
        keepState: true,
        immediate: true,
        fixedParam: { pageSize: 15 },
        resolve: res => {
            setIotItemMappingLogData(res);
        },
    });

    const { promise: createIotItemMappingExcel } = useAsync({
        promise: createIotItemMappingExcelApi,
        fixedParam: {
            zipFileName: 'targetDevice_history',
            fileName: 'targetDevice_history',
            columnMetas: [
                {
                    key: 'logNum',
                    name: commonColumn('No.'),
                },
                {
                    key: 'manipulationType',
                    name: commonColumn('Map/Unmap'),
                },
                {
                    key: 'targetNum',
                    name: commonColumn('IoT Item No.'),
                },
                {
                    key: 'targetId',
                    name: commonColumn('IoT Item ID'),
                },
                {
                    key: 'targetName',
                    name: commonColumn('IoT Item Name'),
                },
                {
                    key: 'deviceNum',
                    name: commonColumn('Device No.'),
                },
                {
                    key: 'deviceId',
                    name: commonColumn('Device ID'),
                },
                {
                    key: 'deviceName',
                    name: commonColumn('Device Name'),
                },
                {
                    key: 'deviceType',
                    name: commonColumn('Device Type'),
                },
                {
                    key: 'regDate',
                    name: commonColumn('Map/Unmap Date'),
                    isDateFormat: 'Y',
                },
            ],
        },
        resolve: res => {
            const { filePath: url, reqNum } = res;

            if (url) {
                downloadWithURL(url, reqNum);
            }
        },
    });

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            if (res.dataSettingStatusType === 'COMPLETED') {
                clearExcelTimer();

                const elem = window.document.createElement('a');
                elem.href = res.filePath;
                elem.download = res.fileName;
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
            } else if (res.dataSettingStatusType === 'FAILED') {
                clearExcelTimer();
                toggleExcelDownloadResultModal();
            }
        },
        reject: res => {
            console.log(res);
        },
    });

    const columns = useColumns([
        column.number({
            width: 50,
        }),
        column.dataType({
            Header: 'Map/Unmap',
            width: 80,
            Cell: ({ value }) => {
                return <div>{t(value)}</div>;
            },
        }),
        column.targetNum({
            width: 80,
        }),
        column.targetId({
            width: 100,
        }),
        column.targetName({
            Header: 'IoT Item Name',
            width: 100,
        }),
        column.deviceType({
            width: 100,
        }),
        column.deviceNum({
            width: 100,
        }),
        column.deviceId({
            width: 100,
        }),
        column.deviceName({
            width: 100,
            accessor: 'deviceName',
        }),
        column.registeredDate({
            Header: 'Map/Unmap Date',
            width: 100,
        }),
    ]);

    const handlePageChange = pageIndex => {
        if (
            iotItemMappingLogInfo.request &&
            (iotItemMappingLogInfo.request.keyword || iotItemMappingLogInfo.manipulationType)
        ) {
            getIotItemMappingLog({ ...iotItemMappingLogInfo.request, page: pageIndex });
        } else {
            getIotItemMappingLog({ page: pageIndex });
        }
    };

    const handleExportFileClick = () => {
        const { request } = iotItemMappingLogInfo;

        delete request.page;
        delete request.pageSize;

        request.isAll = 'Y';

        const currentUnixTimestamp = moment().unix();
        createIotItemMappingExcel({ ...request, startDate: 0, endDate: currentUnixTimestamp });
    };

    const downloadWithURL = (url, reqNum) => {
        excelTimerRef.current = setInterval(() => {
            fetchExcelLog({ reqNum });
        }, INTERVAL_TIME);
    };

    const clearExcelTimer = () => {
        clearInterval(excelTimerRef.current);
        excelTimerRef.current = null;
    };

    const { Modal: ExcelDownloadResultModal, toggleModal: toggleExcelDownloadResultModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Result', 'ConfirmModal') },
        confirmText: t('Failed to download the excel.', 'ConfirmModal'),
    });

    return (
        <Page menuPath={[t('Report', 'Menu'), t('Iot Item Mapping Log', 'Menu')]} className={'h-100'}>
            <Search getIotItemMappingLog={getIotItemMappingLog} />
            <Card
                className={'report-container'}
                header={{
                    title: t('Iot Item Mapping Log', 'Menu'),
                    action: (
                        <Button className="btn-secondary" onClick={handleExportFileClick}>
                            {t('Export', 'Button')}
                        </Button>
                    ),
                }}
            >
                <div style={{ height: '35rem' }}>
                    <Table
                        data={{ ...iotItemMappingLogData, pageSize: 15 }}
                        columns={columns}
                        onPageChange={handlePageChange}
                        loading={iotItemMappingLogInfo.isLoading}
                    />
                </div>
            </Card>
            <ExcelDownloadResultModal />
        </Page>
    );
};

export default IotItemMappingLog;
