import React, { useContext, useEffect, useState } from 'react';
import Card from '../../../Common/Card';
import Table from '../../../Common/Table';
import useTranslation from '../../../../util/hooks/useTranslation';
import { FilterDetailStateContext } from './index';
import { fetchIotItemList } from '../../../../api/asset';
import useAsync from '../../../../util/hooks/useAsync';
import useColumns from '../../../../util/hooks/useColumns';
import * as col from '../../util/grid/column/filter';
import { makeFilterParam } from '../reducer';

const PREVIEW_LIST_PAGE_SIZE = 10;
const tableInitData = { rows: [], pageSize: PREVIEW_LIST_PAGE_SIZE };
const FilterPreview = () => {
    const { editing, editMode } = useContext(FilterDetailStateContext);
    const t = useTranslation('Filter');
    const buttonT = useTranslation('Button');
    const [assetListInfo, setAssetListInfo] = useState(tableInitData);
    const [beforeFilterNum, setBeforeFilterNum] = useState(0);

    const assetListColumns = useColumns([col.category(), col.name(), col.num(), col.itemOwner(), col.location()], t);

    const { promise: getAssetList } = useAsync({
        promise: fetchIotItemList,
        fixedParam: { pageSize: PREVIEW_LIST_PAGE_SIZE },
        resolve: res => {
            let listData = tableInitData;
            if (res && res.rows) {
                listData = res;
            }
            setAssetListInfo({ ...listData, pageSize: PREVIEW_LIST_PAGE_SIZE });
        },
    });

    const handlePageChange = pageIndex => {
        getAssetList({
            ...makeFilterParam(editing.filterInfoCondition),
            pageSize: PREVIEW_LIST_PAGE_SIZE,
            page: pageIndex,
        });
    };

    useEffect(() => {
        if (editing.filterNum && editing.filterNum !== beforeFilterNum) {
            getAssetList({ ...makeFilterParam(editing.filterInfoCondition), pageSize: PREVIEW_LIST_PAGE_SIZE });
        }
        return () => {
            if (editing.filterNum) {
                setBeforeFilterNum(editing.filterNum);
            }
        };
    }, [editing]);

    return (
        <Card
            header={{
                title: t('Preview of search result'),
                subTitle: t('This function shows the list of assets that fits the filter conditions.'),
                action: editMode ? (
                    <>
                        <button
                            className={`pnt-btn btn-icon btn-blue`}
                            onClick={() => {
                                getAssetList({
                                    ...makeFilterParam(editing.filterInfoCondition),
                                    pageSize: PREVIEW_LIST_PAGE_SIZE,
                                });
                            }}
                        >
                            <span className="icon-refresh" />
                            {buttonT('Refresh')}
                        </button>
                    </>
                ) : null,
            }}
        >
            <div style={{ height: '300px' }}>
                <Table data={assetListInfo} columns={assetListColumns} onPageChange={handlePageChange} />
            </div>
        </Card>
    );
};

export default FilterPreview;
