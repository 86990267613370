import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    selectableLGeofenceList: [],
    startDate: null,
    endDate: null,
};
const { actions, reducer } = createSlice({
    name: 'geofenceLog',
    initialState,
    reducers: {
        setGeofenceList: (state, action) => {
            const geofenceList = action.payload.rows.map(item => {
                const { fcName, fcNum } = item;
                return { label: fcName, value: fcNum };
            });
            state.selectableLGeofenceList = geofenceList;
        },
        setStartDate: (state, action) => {
            state.startDate = action.payload;
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload;
        },
    },
});
export const { setGeofenceList, setStartDate, setEndDate } = actions;
export default reducer;
