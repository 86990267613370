import { useDispatch, useSelector } from 'react-redux';
import React, { forwardRef, useState } from 'react';
import { deleteWidget } from '../../../reducers/Dashboards/DashboardEdit';
import cx from 'classnames';
import ConfirmModal from '../../Common/ConfirmModal';
import { Button as RSButton, Card, CardBody, CardHeader } from 'reactstrap';
import Button from '../../Common/Button';
import { useTranslation } from 'react-i18next';
import { getUsableWidgetInfo } from '../../MainPages/Dashboards/util';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSettings } from '../util/useSettings';

const HeaderButton = ({ children, ...restProps }) => {
    return (
        <RSButton
            outline
            className="border-0 btn-transition"
            color="light"
            style={{ padding: '1px 6px', marginBottom: '6px', transform: 'scaleY(1.5) scaleX(1.5)' }}
            {...restProps}
        >
            {children}
        </RSButton>
    );
};

const GridLayoutCard = (
    {
        subTitle,
        children,
        selectedElement = false,
        handleGoBackClick,
        searchFilter,
        widgetInfo,
        headerAction,
        select,
        bodyClassName,
        className,
        ...restProps
    },
    ref,
) => {
    children = [...children];
    const { id, type, config } = widgetInfo;
    const { t } = useTranslation();
    const resizeHandler = children.pop();
    const { settings: customSettings } = config;
    const settings = useSettings(config);
    const { SettingComponent } = getUsableWidgetInfo(type);
    const dispatch = useDispatch();
    const { mode } = useSelector(state => state.DashboardFrame);

    const [deleteModal, setDeleteModal] = useState(false);
    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };
    const handleDelete = e => {
        dispatch(deleteWidget(id));
    };

    const [settingModal, setSettingModal] = useState(false);
    const toggleSettingModal = () => {
        setSettingModal(!settingModal);
    };

    const displayName = () => {
        const { name } = settings;
        if (customSettings && customSettings.name && customSettings.name !== t(`Widget;${name}`)) {
            return customSettings.name;
        } else {
            return type !== 'temp' ? t(`Widget;${name}`) : t('Widget;New widget');
        }
    };

    return (
        <>
            <Card {...restProps} className={cx(className, 'widget-card')} innerRef={ref}>
                <CardHeader
                    className={cx(
                        'widget-header',
                        mode !== 'edit' && settings.hiddenHeader && 'hidden-header',
                        !!headerAction && 'action-header',
                    )}
                >
                    <div className={'card-header__title'}>
                        <div className={'title-wrap'}>
                            <h3 className={'title__main'}>{displayName()}</h3>
                            <p className={'title__sub'}>{subTitle}</p>
                        </div>
                    </div>
                    {(headerAction || handleGoBackClick) && (
                        <div className={'card-header__function'}>
                            {headerAction}
                            {handleGoBackClick && (
                                <Button className={'btn-brand'} onClick={handleGoBackClick}>
                                    {t('Button;Back')}
                                </Button>
                            )}
                        </div>
                    )}
                    <div className={'widget-edit-btn'}>
                        {mode === 'edit' && SettingComponent && (
                            <HeaderButton onClick={toggleSettingModal}>
                                <FontAwesomeIcon icon={faBars} />
                            </HeaderButton>
                        )}
                        {mode === 'edit' && (
                            <button type="button" className="close" aria-label="Close" onClick={toggleDeleteModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        )}
                    </div>
                </CardHeader>
                <div className={'widget-search'}>{searchFilter}</div>
                <CardBody className={bodyClassName}>{children}</CardBody>
                {resizeHandler}
            </Card>
            {mode === 'edit' && (
                <ConfirmModal
                    initModal={deleteModal}
                    toggleModal={toggleDeleteModal}
                    header={{ title: t('ConfirmModal;Confirm') }}
                    confirmText={t('Widget;Do you want to delete the widget?')}
                    okCallback={handleDelete}
                />
            )}
            {SettingComponent && (
                <SettingComponent widgetInfo={widgetInfo} modal={settingModal} toggleModal={toggleSettingModal} />
            )}
        </>
    );
};

export default forwardRef(GridLayoutCard);
