import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useFilterParam = () => {
    const [filterParam, setFilterParam] = useState({});
    const { filterInfo } = useSelector(state => state.Filter);
    const { categoryList, floorList } = useSelector(state => state.CommonInfo);
    const { serviceCategory } = useSelector(state => state.AppInfo);

    useEffect(() => {
        const param = {};
        // 카테고리 설정
        if (filterInfo.categoryCodes.length) {
            param.categoryCodes = filterInfo.categoryCodes.join(',');
        } else {
            param.categoryCodes = categoryList
                .filter(v => v.parentCode)
                .map(v => v.categoryCode)
                .join(',');
        }

        // 층 설정
        if (floorList.length) {
            param.floorIds = filterInfo.floorIds.length ? filterInfo.floorIds.join(',') : null;
            param.floorId = filterInfo.floorId;
        }

        // 허가 여부 설정
        if (filterInfo.isRestrictionStatus) {
            param.isRestrictionStatus = filterInfo.isRestrictionStatus[0];
        }
        setFilterParam(param);
    }, [filterInfo, categoryList, floorList, serviceCategory]);

    return filterParam;
};
