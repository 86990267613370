import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import ResizeDetector from 'react-resize-detector';
import AppMain from '../Layout/AppMain';
import { TopSwitch } from './Components/Router';
import { useTranslation } from 'react-i18next';
import ConfirmModal from '../Common/ConfirmModal';
import { deleteError } from '../../reducers/ErrorHandler';

const Main = ({ location }) => {
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const { colorScheme, enableMobileMenu, enableFixedSidebar, enableClosedSidebar, enablePageTabsAlt } = useSelector(
        state => state.ThemeOptions,
    );
    const { error } = useSelector(state => state.ErrorHandler);
    const { lang } = useSelector(state => state.UserInfo);

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [lang]);

    const handleErrorClick = ({ err, index }) => {
        dispatch(deleteError({ err, index }));
    };

    return (
        <>
            <ResizeDetector
                handleWidth
                render={({ width }) => (
                    <div
                        className={cx(
                            'fixed-header fixed-footer',
                            'app-container',
                            !location.pathname.match(/^\/login$/) && 'app-theme-' + colorScheme,
                            { 'background-color-unset': location.pathname.match(/^\/login$/) },
                            { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
                            { 'closed-sidebar': enableClosedSidebar || width < 1250 },
                            { 'closed-sidebar-mobile': width < 1250 },
                            { 'sidebar-mobile-open': enableMobileMenu },
                            { 'body-tabs-shadow-btn': enablePageTabsAlt },
                        )}
                    >
                        <TopSwitch MainComponent={AppMain} />
                    </div>
                )}
            />

            {/*{error.map((err, index) => {*/}
            {/*    return (*/}
            {/*        <ConfirmModal*/}
            {/*            key={err.message + index}*/}
            {/*            header={{ title: t('ConfirmModal;Result') }}*/}
            {/*            confirmText={*/}
            {/*                <div>*/}
            {/*                    {t(`ErrorHandler;${err.message}`)} {err.errorResponse && `(${err.errorResponse})`}*/}
            {/*                </div>*/}
            {/*            }*/}
            {/*            initModal={true}*/}
            {/*            toggleModal={() => dispatch(deleteError({ err, index }))}*/}
            {/*            okCallback={handleErrorClick}*/}
            {/*            callbackParam={{ err, index }}*/}
            {/*            removeCancel*/}
            {/*        />*/}
            {/*    );*/}
            {/*})}*/}
        </>
    );
};

export default withRouter(Main);
