import React, { useState, useReducer, createContext, useEffect, useCallback } from 'react';
import Category from '../../../Filter/FilterOption/Category';
import Filter from '../../../Filter';
import Search from '../../../Search';
import Table from '../../../Common/Table';
import Card from '../../../Common/Card';
import useAsync from '../../../../util/hooks/useAsync';
import { fetchTargetLocationLog } from '../../../../api/log';
import Map from '../../../Common/Map/Components/Map';
import { LayersControl, Marker, Polyline } from 'react-leaflet';
import L from 'leaflet';
import Floor from '../../../Filter/FilterOption/Floor';
import SearchOptionFloor from '../../../Search/SearchOption/Floor';
import PlayController from '../Components/PlayController';
import { useFilterParam } from '../../../../util/hooks/useFilterParam';
import * as col from '../../util/grid/column';
import moment from 'moment';
import ConfirmModal from '../../../Common/ConfirmModal';
import { initialState, playControllerReducer, setInitState } from '../Components/PlayController/playControllerReducer';
import RotatedImageOverlay from '../../../Common/Map/Components/RotatedImageOverlay';
import notFoundImg from '../../../../assets/images/image-not-found.jpg';
import useTranslation from '../../../../util/hooks/useTranslation';
import GeofenceLayer from '../../../Common/Map/Layers/GeofenceLayer';
import { fetchFloorInfo, fetchGeofenceInfo } from '../../../../api/common';
import { getLeafFloors } from '../../../../util/common/common';
import HeatmapLayer from 'react-leaflet-heatmap-layer';
import Control from 'react-leaflet-control';
import MapOptionSelect from '../Components/MapOptionSelect';
import { useSelector } from 'react-redux';
import Button from '../../../Common/Button';
import { FilterList, SelectGroup, SearchWrap } from '../../../Common/FilterSearchGroup/Components/Part';
import DatePicker from '../../../Common/DatePicker';
import Page from '../../../Common/Page';

const now = moment().unix();
const defaultEndTime = now + 1;

export const PlayControllerStateContext = createContext();
export const PlayControllerDispatchContext = createContext();

const AnalysisByLocation = () => {
    const { lang } = useSelector(state => state.UserInfo);
    const [state, dispatch] = useReducer(playControllerReducer, initialState);
    const t = useTranslation('AnalysisByLocation');
    const filterParam = useFilterParam();
    const [beforePlayTime, setBeforePlayTime] = useState();
    const [selectFloorModal, setSelectFloorModal] = useState(false);
    const [selectDateRangeModal, setSelectDateRangeModal] = useState(false);
    const [searchParam, setSearchParam] = useState({});
    const [floorInfo, setFloorInfo] = useState([]);
    const [currentFloor, setCurrentFloor] = useState({});
    const [imgLoadErr, setImgLoadErr] = useState(false);
    const [markerLogListInfo, setMarkerLogListInfo] = useState({});
    const [heatmapLogInfo, setHeatmapLogInfo] = useState([]);
    const [withinOneMinuteHeatmapLogInfo, setWithinOneMinuteHeatmapLogInfo] = useState([]);
    const [spaghettiLogInfo, setSpaghettiLogInfo] = useState([]);
    const [withinOneMinuteSpaghettiLogInfo, setWithinOneMinuteSpaghettiLogInfo] = useState([]);
    const [geofenceInfo, setGeofenceInfo] = useState([]);
    const [selectedMapOption, setSelectedMapOption] = useState([{ value: '', label: t('None') }]);
    const [startDate, setStartDate] = useState(null);
    const [refreshDatepicker, setRefreshDatepicker] = useState(false);
    const [endDate, setEndDate] = useState(null);
    const [locationLogListInfo, setLocationLogListInfo] = useState({
        rows: [],
        totalCount: 0,
        totalPage: 0,
        pageSize: 0,
    });

    const assetColumn = [
        col.categoryNameAT({ className: 'd-flex justify-content-center' }),
        col.assetNameAT({ className: 'd-flex justify-content-center' }),
    ];

    const { promise: getTargetLocationLog } = useAsync({
        promise: fetchTargetLocationLog,
        resolve: res => {
            const markerLog = {};
            const logList = [];
            const timeList = [];
            let tempStartTime = now;
            let tempEndTime = defaultEndTime;
            let tempSpaghettiLog = {};
            let tempLog;

            if (res.rows) {
                tempStartTime = null;
                tempEndTime = null;
                tempLog = [];
                const rows = res.rows;
                rows.sort((a, b) => (a.regDate > b.regDate ? 1 : -1));

                for (let i = 0, len = rows.length; i < len; i++) {
                    const log = rows[i];
                    if (!tempSpaghettiLog[log.targetId]) {
                        tempSpaghettiLog[log.targetId] = { log: [[log.lat, log.lng, log.regDate]] };
                    } else {
                        tempSpaghettiLog[log.targetId].log.unshift([log.lat, log.lng, log.regDate]);
                    }

                    tempLog.unshift([log.lat, log.lng, 1, log.regDate]);

                    if (!timeList.includes(log.regDate)) {
                        timeList.push(log.regDate);
                    }

                    if (markerLog[log.regDate]) {
                        markerLog[log.regDate] = [
                            ...markerLog[log.regDate].filter(logInfo => logInfo.targetId !== log.targetId),
                            log,
                        ];
                    } else {
                        const beforeTime = timeList[timeList.length - 2];
                        if (beforeTime && markerLog[beforeTime]) {
                            markerLog[log.regDate] = [
                                ...markerLog[beforeTime].filter(logInfo => logInfo.targetId !== log.targetId),
                                log,
                            ];
                        } else {
                            markerLog[log.regDate] = [log];
                        }
                    }

                    if (!logList.find(v => v.targetId === log.targetId)) {
                        logList.push(log);
                    }
                    if (!tempStartTime || tempStartTime > log.regDate) {
                        tempStartTime = log.regDate;
                    }
                    if (!tempEndTime || tempEndTime < log.regDate) {
                        tempEndTime = log.regDate;
                    }
                }
            }
            if (tempStartTime && tempEndTime) {
                dispatch(setInitState({ startTime: tempStartTime, endTime: tempEndTime }));
            }

            setHeatmapLogInfo(tempLog);
            setSpaghettiLogInfo(tempSpaghettiLog);
            setLocationLogListInfo({ ...res, rows: logList });
            setMarkerLogListInfo(markerLog);
        },
        reject: err => {
            console.log(err);
        },
    });

    const { promise: getFloorInfo } = useAsync({
        promise: fetchFloorInfo,
        resolve: res => {
            const tempFloorList = getLeafFloors(res.rows);
            setFloorInfo({ ...res, rows: tempFloorList } || {});
        },
        reject: error => {
            console.log(error);
            setFloorInfo({ rows: [] });
        },
    });

    const { promise: getGeofenceInfo } = useAsync({
        promise: fetchGeofenceInfo,
        resolve: res => {
            if (res.rows) {
                setGeofenceInfo(
                    res.rows.map(geofence => {
                        return { ...geofence, bounds: geofence.latLngList.map(latLng => [latLng.lat, latLng.lng]) };
                    }),
                );
            } else {
                setGeofenceInfo([]);
            }
        },
        reject: error => {
            console.log(error);
            setGeofenceInfo([]);
        },
    });

    const handleFloorChange = selected => {
        if (selected) {
            setSearchParam({ ...searchParam, floorId: selected.floorId });
        }
    };

    const handleSearch = () => {
        if (!(startDate && endDate)) {
            setSelectDateRangeModal(true);
            return false;
        }
        if (!searchParam.floorId) {
            setSelectFloorModal(true);
            return false;
        }
        if (searchParam.floorId && startDate && endDate) {
            const foundedFloor = floorInfo.rows.find(floor => floor.floorId === searchParam.floorId);
            setCurrentFloor(foundedFloor);
            getGeofenceInfo({ isAll: 'Y' });
            getTargetLocationLog({
                ...filterParam,
                floorIds: searchParam.floorId,
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
                pageSize: 0,
            });
        }
    };

    const handleSelectedMapOption = useCallback(selected => {
        setSelectedMapOption(selected);
    }, []);

    const gradient = {
        0.2: '#5a81e6',
        0.4: '#dbe371',
        0.6: '#d49657',
        0.8: '#d45e57',
        0.9: '#d45e57',
    };

    useEffect(() => {
        getFloorInfo({ pageSize: 10 });
    }, []);

    useEffect(() => {
        const tempLog = heatmapLogInfo.filter(log => log[3] <= state.playTime);
        setWithinOneMinuteHeatmapLogInfo(tempLog);
        const objectNames = Object.keys(spaghettiLogInfo);
        const tempSpaghetti = [];

        for (let i = 0; i < Object.keys(spaghettiLogInfo).length; i++) {
            let objectLog = spaghettiLogInfo[objectNames[i]].log.filter(log => log[2] <= state.playTime);
            const logInOneMinute = objectLog.filter(log => log[2] >= state.playTime - 60);
            tempSpaghetti[i] = logInOneMinute;
        }

        setWithinOneMinuteSpaghettiLogInfo(tempSpaghetti);

        return () => {
            if (markerLogListInfo[state.playTime]) {
                setBeforePlayTime(state.playTime);
            }
        };
    }, [state.playTime]);
    const handleSearchRefresh = () => {
        setRefreshDatepicker(!refreshDatepicker);
        setStartDate(null);
        setEndDate(null);
        setSearchParam({});
    };

    return (
        <Page className={'h-100'}>
            <Filter
                searchRefresh={handleSearchRefresh}
                Search={
                    <Search>
                        <div className="search-box flx-row flx-top">
                            <FilterList>
                                <SelectGroup>
                                    <div className={'datePicker-container'}>
                                        <DatePicker
                                            value={startDate}
                                            handleChange={selected => setStartDate(selected)}
                                            valueType={'ms'}
                                            maxDate={endDate || new Date()}
                                            showTimeInput
                                        />
                                    </div>
                                </SelectGroup>
                                <div
                                    className={'text-center d-flex align-items-center justify-content-center'}
                                    style={{ height: '10px' }}
                                >
                                    {` ~ `}
                                </div>
                                <SelectGroup>
                                    <div className={'datePicker-container'}>
                                        <DatePicker
                                            value={endDate}
                                            handleChange={selected => setEndDate(selected)}
                                            valueType={'ms'}
                                            minDate={startDate}
                                            maxDate={new Date()}
                                            showTimeInput
                                        />
                                    </div>
                                </SelectGroup>
                            </FilterList>
                        </div>
                        <div className="search-box flx-row flx-top gap-1">
                            <SearchWrap>
                                <SearchOptionFloor selected={searchParam.floorId} handleChange={handleFloorChange} />
                                <Button
                                    className={'btn-brand btn-icon'}
                                    iconClassName={'icon-search'}
                                    onClick={handleSearch}
                                >
                                    {t('Search', 'Search')}
                                </Button>
                            </SearchWrap>
                        </div>
                    </Search>
                }
            >
                <Category />
                <Floor />
            </Filter>
            <PlayControllerDispatchContext.Provider value={dispatch}>
                <PlayControllerStateContext.Provider value={state}>
                    <div className="analysis-layout">
                        <Card key={'1'} header={{ title: t('Asset List', 'Menu') }} className={'analysis-list-height'}>
                            <Table data={locationLogListInfo} columns={assetColumn} paging={false} />
                        </Card>
                        <Card
                            key={'2'}
                            header={{ title: t('Position History Viewer') }}
                            className={'analysis-viewer-height'}
                        >
                            <div style={{ height: '110px', width: '100%' }}>
                                <PlayController
                                    dispatch={dispatch}
                                    state={state}
                                    on={!!locationLogListInfo.rows.length}
                                />
                            </div>
                            <div style={{ height: 'calc(100% - 130px)' }}>
                                <Map tile={true} className="z-index-0">
                                    <Control
                                        position={'topleft'}
                                        key={`control-${t(selectedMapOption[0].label)}-${lang}`}
                                    >
                                        <MapOptionSelect
                                            handleChange={handleSelectedMapOption}
                                            value={selectedMapOption.map(v => ({ ...v, label: v.label }))}
                                        />
                                    </Control>

                                    {currentFloor && currentFloor.neLat && (
                                        <RotatedImageOverlay
                                            key={currentFloor.floorId}
                                            url={currentFloor.imgURL}
                                            deg={currentFloor.deg}
                                            bounds={[
                                                [currentFloor.neLat, currentFloor.neLng],
                                                [currentFloor.swLat, currentFloor.swLng],
                                            ]}
                                            onLoad={e => {
                                                setImgLoadErr(false);
                                            }}
                                            onError={e => {
                                                setImgLoadErr(true);
                                            }}
                                        />
                                    )}
                                    {currentFloor && currentFloor.neLat && imgLoadErr && (
                                        <RotatedImageOverlay
                                            key={currentFloor.imgURL}
                                            url={notFoundImg}
                                            bounds={[
                                                [currentFloor.neLat, currentFloor.neLng],
                                                [currentFloor.swLat, currentFloor.swLng],
                                            ]}
                                        />
                                    )}
                                    {markerLogListInfo[state.playTime]
                                        ? markerLogListInfo[state.playTime].map(v => (
                                              <Marker
                                                  key={v.targetId}
                                                  position={[v.lat, v.lng]}
                                                  icon={L.divIcon({
                                                      className: 'simple-marker',
                                                      html: `<div></div>`,
                                                  })}
                                              />
                                          ))
                                        : markerLogListInfo[beforePlayTime] &&
                                          markerLogListInfo[beforePlayTime].map(v => (
                                              <Marker
                                                  key={v.targetId}
                                                  position={[v.lat, v.lng]}
                                                  icon={L.divIcon({
                                                      className: 'simple-marker',
                                                      html: `<div></div>`,
                                                  })}
                                              />
                                          ))}
                                    {selectedMapOption.length > 0 && selectedMapOption[0].value === 'heatmap' && (
                                        <HeatmapLayer
                                            fitBoundsOnLoad={false}
                                            fitBoundsOnUpdate={false}
                                            points={withinOneMinuteHeatmapLogInfo}
                                            gradient={gradient}
                                            longitudeExtractor={m => m[1]}
                                            latitudeExtractor={m => m[0]}
                                            intensityExtractor={m => parseFloat(m[2])}
                                        />
                                    )}
                                    {selectedMapOption.length > 0 &&
                                        selectedMapOption[0].value === 'spaghettiDiagram' &&
                                        withinOneMinuteSpaghettiLogInfo.map(log => <Polyline positions={log} />)}
                                    <LayersControl position="topright" key={`layer-${t('Zone')}-${lang}`}>
                                        <LayersControl.Overlay name={t('Zone')} checked={true}>
                                            {geofenceInfo && (
                                                <GeofenceLayer
                                                    geofenceList={geofenceInfo.filter(
                                                        geofence => geofence.floor === currentFloor.floorId,
                                                    )}
                                                />
                                            )}
                                        </LayersControl.Overlay>
                                    </LayersControl>
                                </Map>
                            </div>
                        </Card>
                    </div>
                </PlayControllerStateContext.Provider>
            </PlayControllerDispatchContext.Provider>
            <ConfirmModal
                removeCancel
                confirmText={t('Select a floor')}
                initModal={selectFloorModal}
                toggleModal={() => setSelectFloorModal(!selectFloorModal)}
            />
            <ConfirmModal
                removeCancel
                confirmText={t('Please select the date first')}
                initModal={selectDateRangeModal}
                toggleModal={() => setSelectDateRangeModal(!selectDateRangeModal)}
            />
        </Page>
    );
};

export default AnalysisByLocation;
