import React from 'react';
import WidgetSettingModal from '../Components/WidgetSettingModal';
import useTranslation from '../../../util/hooks/useTranslation';

const AssetListSetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('AssetListWidget');
    return (
        <WidgetSettingModal headerTitle={t('Asset list setting')} widgetInfo={widgetInfo} hiddenHeader {...restProps} />
    );
};

export default AssetListSetting;
