import React, { useReducer, useRef, useState, createContext } from 'react';
import useTranslation from '../../../../util/hooks/useTranslation';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import useAsync from '../../../../util/hooks/useAsync';
import { fetchFloorList } from '../../../../api/common';
import { setFloorList } from '../../../../reducers/Common/FloorInfo';
import useColumns from '../../../../util/hooks/useColumns';
import * as column from '../../util/grid/column';
import { fetchExcelLogApi, fetchExcelSensorLogExport, fetchSensorList, fetchSensorLogList } from '../../../../api/log';
import useConfirmModal from '../../../Common/ConfirmModal/useConfirmModal';
import Page from '../../../Common/Page';
import Search from './Components/Search';
import Card from '../../../Common/Card';
import Button from '../../../Common/Button';
import Table from '../../../Common/Table';
import moment from 'moment';
import { excelExport } from '../util/util';
import sensorLogReducer, { initialState, setSensorList } from './sensorLogReducer';

const INTERVAL_TIME = 1000;
export const SensorLogDispatchContext = createContext();
export const SensorLogStateContext = createContext();

const SensorLog = () => {
    const t = useTranslation('Sensor Log');
    const storeDispatch = useDispatch();

    const [state, dispatch] = useReducer(sensorLogReducer, initialState);
    const searchRef = useRef();
    const excelTimerRef = useRef();
    const { startDate, endDate } = state;

    const match = useRouteMatch();

    useAsync({
        promise: fetchFloorList,
        resolve: response => {
            storeDispatch(setFloorList(response));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });
    useAsync({
        promise: fetchSensorList,
        resolve: response => {
            dispatch(setSensorList(response));
        },
        immediate: true,
    });

    const columns = useColumns([
        column.number(),
        column.sensorId(),
        column.sensorName(),
        column.sensorType(),
        column.injectionVolume(),
        column.totalDose(),
        column.stepCount(),
        column.bloodOxygenSaturation(),
        column.noise(),
        column.so2(),
        column.sleepIndex(),
        column.earthquakeIntensity(),
        column.relativeHumidity(),
        column.respiratoryRate(),
        column.infusionRate(),
        column.pulseRate(),
        column.oneTimePcaInjection(),
        column.numberOfPacketTransmissions(),
        column.o2(),
        column.diaperUrine(),
        column.diaperFart(),
        column.diaperFeces(),
        column.movement(),
        column.diastolic(),
        column.lel(),
        column.injectedAmount(),
        column.systolicBloodPressure(),
        column.humidity(),
        column.height(),
        column.heatstroke(),
        // column.heartRate(),
        column.weight(),
        column.h2s(),
        column.volatileOrganicCompounds(),
        column.equivalentCarbonDioxide(),
        column.dust(),
        column.dropRaid(),
        column.dropWater(),
        column.dosage(),
        column.distance(),
        column.discomfortIndex(),
        column.crack(),
        column.density(),
        column.co(),
        column.capacity(),
        column.calories(),
        column.buttonTwoClicks(),
        column.buttonOneClicks(),
        column.battery(),
        column.atmosphericPressure(),
        column.zAxisAngularVelocity(),
        column.yAxisAngularVelocity(),
        column.xAxisAngularVelocity(),
        column.illuminance(),
        column.zSideAcceleration(),
        column.YSideAcceleration(),
        column.xSideAcceleration(),
        column.temperature(),
        column.registeredDate(),
    ]);

    const [sensorLogData, setSensorLogData] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });

    const { promise: getSensorLogList, state: sensorLogListInfo } = useAsync({
        promise: fetchSensorLogList,
        keepState: true,
        immediate: true,
        fixedParam: { pageSize: 15 },
        resolve: res => {
            const data = res.rows.map(log => {
                return {
                    comNum: log.comNum,
                    logNum: log.logNum,
                    targetId: log.targetId,
                    targetName: log.targetName,
                    deviceId: log.deviceId,
                    deviceName: log.deviceName,
                    deviceType: log.deviceType,
                    floorId: log.floorId,
                    ...JSON.parse(log.data),
                    regDate: log.regDate,
                    ...log,
                };
            });
            setSensorLogData({ ...res, rows: data });
        },
    });

    const { promise: createNewLogFile } = useAsync({
        promise: fetchExcelSensorLogExport,
        fixedParam: {
            startDate: startDate ? moment(startDate).unix() : 0,
            endDate: endDate ? moment(endDate).unix() : moment().unix(),
            zipFileName: 'Sensor_Log',
            fileName: 'Sensor_Log',
            columnMetas: [
                {
                    key: 'logNum',
                    name: '로그 번호',
                },
                {
                    key: 'sensorName',
                    name: '센서 이름',
                },
                {
                    key: 'sensorId',
                    name: '센서 아이디',
                },
                {
                    key: 'sensorType',
                    name: '센서타입',
                },
                {
                    key: 'VTBI',
                    name: '주입량',
                },
                {
                    key: 'TOTAL_DOSAGE',
                    name: '총 투여량',
                },

                {
                    key: 'STEP',
                    name: '걸음 수',
                },
                {
                    key: 'SPO2',
                    name: '혈중산소포화도',
                },
                {
                    key: 'SOUND_NOISE',
                    name: '소음',
                },
                {
                    key: 'SO2',
                    name: 'SO2',
                },
                {
                    key: 'SLEEP',
                    name: '수면지수',
                },
                {
                    key: 'SEISMIC_INTENS',
                    name: '지진강도',
                },
                {
                    key: 'R_HUMIDITY',
                    name: '상태습도',
                },
                {
                    key: 'RESPIRATION_RATE',
                    name: '호흡수',
                },
                {
                    key: 'RATE',
                    name: '주입속도',
                },
                {
                    key: 'PULSE_RATE',
                    name: '맥박수',
                },
                {
                    key: 'PCA_BOLUS',
                    name: '1회 PCA 주입량',
                },
                {
                    key: 'PACKET_COUNTER',
                    name: '패킷 전송 횟수',
                },

                {
                    key: 'O2',
                    name: 'O2',
                },

                {
                    key: 'NAPPY_GAS',
                    name: '기저귀-방귀',
                },

                {
                    key: 'NAPPY_SMALL',
                    name: '기저귀-소변',
                },

                {
                    key: 'Diaper-Feces',
                    name: '기저귀-대변',
                },

                {
                    key: 'MOVEMENT',
                    name: '움직임',
                },

                {
                    key: 'H_BLOODPRESSURE',
                    name: '최저혈압',
                },

                {
                    key: 'LEL',
                    name: 'LEL',
                },

                {
                    key: 'Injected Amount',
                    name: '주입된 량',
                },

                {
                    key: 'L_BLOODPRESSURE',
                    name: '최고혈압',
                },

                {
                    key: 'HUMIDITY',
                    name: '습도',
                },

                {
                    key: 'HEIGHT',
                    name: '신장',
                },

                {
                    key: 'HEAT_STROKE',
                    name: '열사병',
                },

                {
                    key: 'WEIGHT',
                    name: '무게',
                },
                {
                    key: 'H2S',
                    name: 'H2S',
                },
                {
                    key: 'ETVOC',
                    name: 'ETVOC',
                },
                {
                    key: 'ECO2',
                    name: 'ECO2',
                },
                {
                    key: 'DUST',
                    name: '먼지',
                },
                {
                    key: 'DROP_RATE',
                    name: '드랍레이드',
                },
                {
                    key: 'DROP_COUNT',
                    name: '방울수',
                },
                {
                    key: 'DOSAGE',
                    name: '투여량',
                },
                {
                    key: 'DISTANCE',
                    name: '거리',
                },
                {
                    key: 'DISCOMFORT_IDX',
                    name: '불쾌지수',
                },
                {
                    key: 'CRACK',
                    name: '균열',
                },
                {
                    key: 'CONC',
                    name: '농도',
                },
                {
                    key: 'CO',
                    name: 'CO',
                },
                {
                    key: 'CAPACITY',
                    name: '용량',
                },
                {
                    key: 'CALORIES',
                    name: '칼로리',
                },
                {
                    key: 'BUTTON2_COUNT',
                    name: '버튼 2 클릭수',
                },
                {
                    key: 'BUTTON1_COUNT',
                    name: '버튼 1 클릭수',
                },
                {
                    key: 'BATTERY',
                    name: '배터리',
                },
                {
                    key: 'ACCELER_Z_AXIS',
                    name: '가속도 Z',
                },
                {
                    key: 'ACCELER_Y_AXIS',
                    name: '가속도 Y',
                },
                {
                    key: 'ACCELER_X_AXIS',
                    name: '가속도 X',
                },
                {
                    key: 'ANBIENT_LIGHT',
                    name: '조도',
                },
                {
                    key: 'ANGULAR_Z_AXIS',
                    name: '각속도 Z',
                },
                {
                    key: 'ANGULAR_Y_AXIS',
                    name: '각속도 Y',
                },
                {
                    key: 'ANGULAR_X_AXIS',
                    name: '각속도 X',
                },
                {
                    key: 'TEMPERATURE',
                    name: '온도',
                },
            ],
        },
        resolve: res => {
            const { filePath: url, reqNum } = res;
            if (url) {
                downloadWithURL(url, reqNum);
            }
        },
        reject: res => {
            toggleExcelModal();
            console.log(res);
        },
    });

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            const { dataSettingStatusType, filePath: url, fileName } = res;
            if (dataSettingStatusType === 'COMPLETED') {
                clearExcelTimer();
                excelExport(url, fileName);
            } else if (dataSettingStatusType === 'FAILED') {
                clearExcelTimer();
                toggleExcelDownloadResultModal();
            }
        },
    });

    const downloadWithURL = (url, reqNum) => {
        excelTimerRef.current = setInterval(() => {
            fetchExcelLog({ reqNum: reqNum });
        }, INTERVAL_TIME);
    };

    const clearExcelTimer = () => {
        clearInterval(excelTimerRef.current);
        excelTimerRef.current = null;
    };
    const handlePageChange = pageIndex => {
        if (
            sensorLogListInfo.request &&
            (sensorLogListInfo.request.keyword ||
                sensorLogListInfo.request.startDate ||
                sensorLogListInfo.request.endDate)
        ) {
            getSensorLogList({ ...sensorLogListInfo.request, page: pageIndex });
        } else {
            getSensorLogList({ page: pageIndex });
        }
    };

    const handleExportFileClick = () => {
        const { request } = sensorLogListInfo;
        delete request.page;
        delete request.pageSize;
        request.isAll = 'Y';
        createNewLogFile({ ...request });
    };

    const { Modal: ExcelDownloadResultModal, toggleModal: toggleExcelDownloadResultModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Result', 'ConfirmModal') },
        confirmText: t('Failed to download the excel.', 'ConfirmModal'),
    });

    const { toggleModal: toggleExcelModal, Modal: ExcelModal } = useConfirmModal({
        initModal: false,
        header: {
            title: t('Excel Download', 'Sensor Log List'),
        },
        confirmText: t(
            'You can see and download the Excel File that you have requested under `Report > Sensor Log List` menu.',
            'Sensor Log List',
        ),
        removeCancel: true,
    });

    return (
        <SensorLogDispatchContext.Provider value={dispatch}>
            <SensorLogStateContext.Provider value={state}>
                <Page menuPath={[t('Report'), t('Sensor Log')]} className={'h-100'}>
                    <Search getLocationLogList={getSensorLogList} />
                    <Card
                        innerRef={searchRef}
                        className={'report-container'}
                        header={{
                            title: t('Sensor Log List'),
                            action: (
                                <Button className="btn-secondary" onClick={handleExportFileClick}>
                                    {t('Export', 'Button')}
                                </Button>
                            ),
                        }}
                    >
                        <div style={{ height: '35rem' }}>
                            <Table
                                data={{ ...sensorLogData, pageSize: 15 }}
                                columns={columns}
                                onPageChange={handlePageChange}
                                loading={sensorLogListInfo.isLoading}
                            />
                        </div>
                    </Card>
                    <ExcelDownloadResultModal />
                    <ExcelModal />
                </Page>
            </SensorLogStateContext.Provider>
        </SensorLogDispatchContext.Provider>
    );
};

export default SensorLog;
