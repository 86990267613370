import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Badge, Button, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
import widgetList from '../index';
import { editWidget } from '../../../reducers/Dashboards/DashboardEdit';
import ConfirmModal from '../../Common/ConfirmModal';
import TextInput from '../../Common/Input/TextInput';

const WidgetList = ({ widgetList, onClick }) => {
    const { t } = useTranslation();

    const tempWidgetListArr = [];
    const widgetListArr = [];
    let total = 0;
    for (let key in widgetList) {
        const widget = widgetList[key];
        const { category } = widget;

        if (!tempWidgetListArr[category]) {
            tempWidgetListArr[category] = 1;
        } else {
            tempWidgetListArr[category] += 1;
        }

        if (category !== undefined) {
            total += 1;
        }
    }
    widgetListArr.push(
        <ListGroupItem tag="button" onClick={onClick} value={'All'} className={'layout'}>
            <div>{t('WidgetList;Total')}</div>
            <div>
                <Badge pill>{total}</Badge>
            </div>
        </ListGroupItem>,
    );

    for (let key in tempWidgetListArr) {
        let i = 1;
        if (key !== 'undefined') {
            widgetListArr.push(
                <ListGroupItem
                    tag="button"
                    onClick={onClick}
                    value={key}
                    key={tempWidgetListArr[key] + i}
                    className={'layout'}
                >
                    <div>{t(`WidgetList;${key}`)}</div>
                    <div>
                        <Badge pill>{tempWidgetListArr[key]}</Badge>
                    </div>
                </ListGroupItem>,
            );
        }
        i++;
    }

    return widgetListArr;
};

const WidgetPrevList = ({ widgetList, layout, toggleModal }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const WidgetPrevArr = [];
    const handleAddClick = (e, widgetInfo) => {
        dispatch(
            editWidget({
                name: widgetInfo.name,
                category: widgetInfo.category,
                id: layout.i,
                type: widgetInfo.type,
                config: widgetInfo.config,
                layout: { ...layout, ...widgetInfo.layout },
            }),
        );
        toggleModal();
    };

    for (let key in widgetList) {
        const widget = widgetList[key];
        if (widget.type !== 'temp') {
            WidgetPrevArr.push(
                <ListGroupItem key={widget.type}>
                    <Row className={'flex-center'}>
                        <Col xs={9}>
                            <strong>{t(`Widget;${widget.name}`)}</strong>
                        </Col>
                        <Col xs={3}>
                            <Button
                                onClick={e => {
                                    handleAddClick(e, widget);
                                }}
                            >
                                {t('WidgetList;ADD')}
                            </Button>
                        </Col>

                        {/*<Col xs={2} className={'flex-center'} style={{ padding: '0 10px' }}></Col>*/}
                    </Row>
                    <Row>
                        <Col xs={12} style={{ marginTop: '1rem' }}>
                            <span>- {t(`Widget;${widget.desc}`)}</span>
                            {/*{widget.thumbnailURL ? (*/}
                            {/*    <img*/}
                            {/*        src={widget.thumbnailURL}*/}
                            {/*        onError={e => {*/}
                            {/*            e.target.src = imageNotFound;*/}
                            {/*        }}*/}
                            {/*        style={{ width: '90%', height: 'auto' }}*/}
                            {/*        alt={'Image not found'}*/}
                            {/*    />*/}
                            {/*) : (*/}
                            {/*    <img*/}
                            {/*        src={imageNotFound}*/}
                            {/*        style={{ width: '90%', height: 'auto' }}*/}
                            {/*        alt={'Image not found'}*/}
                            {/*    />*/}
                            {/*)}*/}
                        </Col>
                    </Row>
                </ListGroupItem>,
            );
        }
    }
    if (!WidgetPrevArr.length) {
        WidgetPrevArr.push(
            <ListGroupItem key={'noItem'}>
                <Row>
                    <Col className={'flex-center'}>{t('WidgetList;There are no search widget')}</Col>
                </Row>
            </ListGroupItem>,
        );
    }
    return WidgetPrevArr;
};

const PalettePopup = ({
    initOpen = false,
    setModal,
    toggleModal = function () {
        initOpen = !initOpen;
    },
    layout,
    ...restProps
}) => {
    const { t } = useTranslation();
    const [filteredList, setFilteredList] = useState(widgetList);
    const ps = useRef();

    const scrollTop = () => {
        const curr = ps.current;
        if (curr) {
            curr.scrollTop = 0;
        }
    };

    const handleFilterChange = e => {
        const filteredList = {};
        const value = e.currentTarget.value;
        for (let key in widgetList) {
            const widget = widgetList[key];
            if (t(`Widget;${widget.name}`).toUpperCase().indexOf(value.toUpperCase()) >= 0) {
                filteredList[key] = widget;
            }
        }
        setFilteredList(filteredList);
    };

    const onListClick = e => {
        const value = e.currentTarget.value;
        const filteredList = {};

        if (value === 'All') {
            setFilteredList(widgetList);
        } else {
            for (let key in widgetList) {
                const widget = widgetList[key];
                if (widget.category && widget.category.indexOf(value) >= 0) {
                    filteredList[key] = widget;
                }
            }
            setFilteredList(filteredList);
        }
        scrollTop();
    };
    // const onSubmit = useCallback(
    //     e => {
    //         dispatch(
    //             editWidget({
    //                 name: selectedWidget.name,
    //                 id: layout.i,
    //                 config: selectedWidget.config,
    //                 layout: { ...layout, ...selectedWidget.layout },
    //             }),
    //         );
    //         toggleModal();
    //     },
    //     [layout, selectedWidget],
    // );

    return (
        <ConfirmModal
            className={'widget-add-popup'}
            initModal={initOpen}
            toggleModal={toggleModal}
            style={{ maxWidth: '45rem' }}
            header={{ title: t('WidgetList;Add Widget') }}
            removeCancel
            okButtonTitle={t('UserBox;Close')}
            confirmText={
                <Row>
                    <Col xs={5}>
                        <Row>
                            <TextInput
                                type={'text'}
                                name={'dashboard-palette-popup'}
                                placeholder={t('Search;Search')}
                                handleChange={handleFilterChange}
                                inputGroupClassName={'mb-2'}
                            />
                        </Row>
                        <Row>
                            <ListGroup flush style={{ width: '100%' }}>
                                <WidgetList widgetList={widgetList} onClick={onListClick} />
                            </ListGroup>
                        </Row>
                    </Col>

                    <Col xs={7} style={{ width: '100%', height: '400px', paddingRight: 0 }}>
                        <PerfectScrollbar containerRef={e => (ps.current = e)}>
                            <ListGroup flush>
                                <WidgetPrevList layout={layout} toggleModal={toggleModal} widgetList={filteredList} />
                            </ListGroup>
                        </PerfectScrollbar>
                    </Col>
                </Row>
            }
            {...restProps}
        />
        // <Modal isOpen={initOpen} toggle={toggleModal} {...restProps} style={{ maxWidth: '45rem' }}>
        //     <ModalHeader toggle={toggleModal}>{t('WidgetList;Add Widget')}</ModalHeader>
        //     <ModalBody style={{ padding: '2rem' }}>
        //         <Row>
        //             <Col xs={5}>
        //                 <Row>
        //                     <Input
        //                         className={'mb-2'}
        //                         type="text"
        //                         placeholder={t('Search;Search')}
        //                         onChange={handleFilterChange}
        //                     />
        //                 </Row>
        //                 <Row>
        //                     <ListGroup flush style={{ width: '100%' }}>
        //                         <WidgetList widgetList={widgetList} onClick={onListClick} />
        //                     </ListGroup>
        //                 </Row>
        //             </Col>
        //
        //             <Col xs={7} style={{ width: '100%', height: '400px', paddingRight: 0 }}>
        //                 <PerfectScrollbar>
        //                     <ListGroup flush>
        //                         <WidgetPrevList layout={layout} toggleModal={toggleModal} widgetList={filteredList} />
        //                     </ListGroup>
        //                 </PerfectScrollbar>
        //             </Col>
        //         </Row>
        //     </ModalBody>
        //     <ModalFooter>
        //         {/*<Button type="submit" color="primary" onClick={handleOkClick}>*/}
        //         {/*    {t('Button;Ok')}*/}
        //         {/*</Button>*/}
        //         <Button
        //             onClick={e => {
        //                 e.preventDefault();
        //                 toggleModal();
        //             }}
        //         >
        //             {t('Button;Cancel')}
        //         </Button>
        //     </ModalFooter>
        // </Modal>
    );
};

export default PalettePopup;
