import React, { useEffect, useReducer, useRef, useState } from 'react';
import GridLayoutCard from '../Components/GridLayoutCard';
import { useTranslation } from 'react-i18next';
import { Col, Row, Container } from 'reactstrap';
import Card from '../../Common/Card';
import useAsync from '../../../util/hooks/useAsync';
import { fetchRealTimeSensorMonitoring } from '../../../api/monitoring';
import Button from '../../Common/Button';
import realTimeSensorMonitoringReducer, {
    clearSensingTypes,
    initialState,
    setSelectedSensor,
    setSelectedSensorType,
    setSensorList,
    setSelectedFloor,
    setSensorType,
} from './realTimeSensorMonitoringReducer';
import SensorType from './Components/SensorType';
import SelectedSensor from './Components/SelectedSensor';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import IMAGES from './Components/img';
import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { LoadingBlock } from '../../Common/LoadingBlock';

export const RealTimeSensorMonitoringContext = React.createContext();
export const RealTimeSensorMonitoringDispatchContext = React.createContext();

const NoDataWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NoDataComponent = styled.span`
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.1);
`;

const RealTimeSensorMonitoring = ({ children, widgetInfo, ...restProps }) => {
    const { t } = useTranslation();
    const listWidgetRef = useRef();
    const specificSensorWidgetRef = useRef();

    const [state, dispatch] = useReducer(realTimeSensorMonitoringReducer, initialState);
    const { sensorType, sensorList, selectedSensor } = state;

    const [showSearchArea, setShowSearchArea] = useState(false);

    const { promise: getSensorList, state: sensorInfo } = useAsync({
        promise: fetchRealTimeSensorMonitoring,
        resolve: res => {
            const { rows } = res;
            dispatch(setSensorList(rows));
        },
    });

    useEffect(() => {
        getSensorList();
        dispatch(setSensorType('ALL TYPE'));
    }, []);

    const handleSearchAreaClick = () => {
        setShowSearchArea(!showSearchArea);
    };

    const handleLocationClick = sensor => {
        dispatch(setSelectedSensor(sensor));
    };

    const handleGoBackClick = () => {
        dispatch(setSelectedSensor({}));
    };

    const handleRefreshClick = () => {
        dispatch(setSelectedSensorType([]));
        dispatch(setSelectedFloor([]));
        dispatch(clearSensingTypes());
        getSensorList();
    };

    // const loadMoreItems = getSensorList({ page: 1 });

    // const itemCount =
    //     sensorInfo.response && sensorInfo.response.page < sensorInfo.response.totalPage
    //         ? sensorList.length + 1
    //         : sensorList.length;
    //
    // const isItemLoaded = index =>
    //     !(sensorInfo.response.page < sensorInfo.response.totalPage) || index < sensorList.length;
    //
    // const Item = ({ index, style }) => (
    //     <Col className="item" style={style}>
    //         <Card
    //             header={{
    //                 title: `${sensorList[index].targetName} > ${sensorList[index].sensorName}`,
    //             }}
    //             className={'card-custom'}
    //         >
    //             <div className={'flx-col'}>
    //                 <div className={'flx-row gap-2 row-space-around'}>
    //                     <div className={'monitoring-img'}>
    //                         <img
    //                             src={IMAGES[`${sensorList[index].sensingType}_${sensorList[index].status}`]}
    //                             alt={`${sensorList[index].sensingType}_${sensorList[index].status}`}
    //                             className={'img-fluid'}
    //                         />
    //                         <p className={'pnt-txt txt-bold tc'}>
    //                             {t(`RealTimeSensorMonitoring;${sensorList[index].sensingType}`)}
    //                         </p>
    //                     </div>
    //
    //                     <div className={'flx-col align-items-center flx-center'}>
    //                         {sensorList[index].currentValue ? (
    //                             <>
    //                                 <p className={'pnt-txt txt-bold'}>
    //                                     {moment.unix(sensorList[index].modDate).format('YYYY-MM-DD HH:mm:ss') || ''}
    //                                 </p>
    //                                 <div className={'pnt-txt txt-bold txt-large'}>
    //                                     {sensorList[index].currentValue} {sensorList[index].measurementUnit}
    //                                 </div>
    //                                 <span className={'pnt-txt txt-bold txt-large'}>
    //                                     {t(`RealTimeSensorMonitoring;${sensorList[index].status}`)}
    //                                 </span>
    //                             </>
    //                         ) : (
    //                             <>
    //                                 <div className={'pnt-txt txt-bold txt-large'}>N/A</div>
    //                                 <span className={'pnt-txt txt-bold txt-large'}>
    //                                     {t(`RealTimeSensorMonitoring;null`)}
    //                                 </span>
    //                             </>
    //                         )}
    //                     </div>
    //                 </div>
    //             </div>
    //         </Card>
    //     </Col>
    // );

    return (
        <RealTimeSensorMonitoringDispatchContext.Provider value={dispatch}>
            <RealTimeSensorMonitoringContext.Provider value={state}>
                {Object.keys(selectedSensor).length === 0 ? (
                    <GridLayoutCard
                        widgetInfo={widgetInfo}
                        {...restProps}
                        ref={listWidgetRef}
                        subTitle={
                            sensorType === 'ALL TYPE'
                                ? t('RealTimeSensorMonitoring;Fixed Type & Mobile Type')
                                : sensorType === 'F'
                                ? t('RealTimeSensorMonitoring;Fixed Type')
                                : t('RealTimeSensorMonitoring;Mobile Type')
                        }
                        headerAction={
                            <>
                                <Button
                                    className="btn-danger btn-icon-only"
                                    iconClassName={'icon-refresh'}
                                    onClick={handleRefreshClick}
                                />
                                <div className="pnt-border border-h" />
                                <Button className={'btn-secondary'} onClick={handleSearchAreaClick}>
                                    {t('Button;Search')}
                                </Button>
                            </>
                        }
                        searchFilter={showSearchArea && <SensorType />}
                    >
                        <LoadingBlock blocking={sensorInfo.isLoading}>
                            <div className={'scrollbar-container'}>
                                <PerfectScrollbar>
                                    <Container fluid style={{ height: '100%' }}>
                                        {/*<Row>*/}
                                        {/*    <Col md="5" className={'item-container'}>*/}
                                        {/*        <span>{`${t('RealTimeSensorMonitoring;Number of Sensor')} : `}</span>*/}
                                        {/*        <span>0</span>*/}
                                        {/*    </Col>*/}
                                        {/*    <Col md="5" className={'item-container'}>*/}
                                        {/*        <span>{`${t('RealTimeSensorMonitoring;Number of Sensor')} : `}</span>*/}
                                        {/*        <span>0</span>*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                        {/*<InfiniteLoader*/}
                                        {/*    isItemLoaded={isItemLoaded}*/}
                                        {/*    itemCount={itemCount}*/}
                                        {/*    loadMoreItems={loadMoreItems}*/}
                                        {/*>*/}
                                        {/*    {({ onItemsRendered, ref }) => (*/}
                                        {/*        <FixedSizeList*/}
                                        {/*            height={150}*/}
                                        {/*            width={200}*/}
                                        {/*            itemSize={90}*/}
                                        {/*            itemCount={itemCount}*/}
                                        {/*            onItemsRendered={onItemsRendered}*/}
                                        {/*            ref={ref}*/}
                                        {/*        >*/}
                                        {/*            {Item}*/}
                                        {/*        </FixedSizeList>*/}
                                        {/*    )}*/}
                                        {/*</InfiniteLoader>*/}

                                        <Row className={`${isMobile && 'mt-2'}`} style={{ height: '100%' }}>
                                            {sensorList && sensorList.length > 0 ? (
                                                sensorList.map(sensor => {
                                                    return sensor.sensorItems.map((sensorItem, i) => {
                                                        return (
                                                            <Col
                                                                className="item"
                                                                key={`${sensor.targetId}+${sensorItem.sensingType}+${
                                                                    sensorItem.status + i
                                                                }`}
                                                            >
                                                                <Card
                                                                    header={{
                                                                        title: `${sensor.targetName} > ${sensor.sensorName}`,
                                                                    }}
                                                                    className={'card-custom'}
                                                                >
                                                                    <div className={'flx-col'}>
                                                                        <div
                                                                            className={'flx-row gap-2 row-space-around'}
                                                                        >
                                                                            <div className={'monitoring-img'}>
                                                                                <img
                                                                                    style={{ borderRadius: '8px' }}
                                                                                    src={
                                                                                        IMAGES[
                                                                                            `${sensorItem.sensingType}_${sensorItem.status}`
                                                                                        ]
                                                                                    }
                                                                                    alt={`${sensorItem.sensingType}_${sensorItem.status}`}
                                                                                    className={'img-fluid'}
                                                                                />
                                                                                <p className={'pnt-txt txt-bold tc'}>
                                                                                    {t(
                                                                                        `RealTimeSensorMonitoring;${sensorItem.sensingType}`,
                                                                                    )}
                                                                                </p>
                                                                            </div>

                                                                            <div
                                                                                className={
                                                                                    'flx-col align-items-center flx-center'
                                                                                }
                                                                            >
                                                                                {sensorItem.currentValue ? (
                                                                                    <>
                                                                                        <p
                                                                                            className={
                                                                                                'pnt-txt txt-bold'
                                                                                            }
                                                                                        >
                                                                                            {moment
                                                                                                .unix(
                                                                                                    sensorItem.modDate,
                                                                                                )
                                                                                                .format(
                                                                                                    'YYYY-MM-DD HH:mm:ss',
                                                                                                ) || ''}
                                                                                        </p>
                                                                                        <div
                                                                                            className={
                                                                                                'pnt-txt txt-bold txt-large'
                                                                                            }
                                                                                        >
                                                                                            {sensorItem.currentValue}{' '}
                                                                                            {sensorItem.measurementUnit}
                                                                                        </div>
                                                                                        <span
                                                                                            className={
                                                                                                'pnt-txt txt-bold txt-large'
                                                                                            }
                                                                                        >
                                                                                            {t(
                                                                                                `RealTimeSensorMonitoring;${sensorItem.status}`,
                                                                                            )}
                                                                                        </span>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <div
                                                                                            className={
                                                                                                'pnt-txt txt-bold txt-large'
                                                                                            }
                                                                                        >
                                                                                            N/A
                                                                                        </div>
                                                                                        <span
                                                                                            className={
                                                                                                'pnt-txt txt-bold txt-large'
                                                                                            }
                                                                                        >
                                                                                            {t(
                                                                                                `RealTimeSensorMonitoring;null`,
                                                                                            )}
                                                                                        </span>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <Button
                                                                                className={'btn-gray mt-md-5 w-100'}
                                                                                onClick={() =>
                                                                                    handleLocationClick(sensor)
                                                                                }
                                                                            >
                                                                                {t(
                                                                                    'RealTimeSensorMonitoring;See Location',
                                                                                )}
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </Col>
                                                        );
                                                    });
                                                })
                                            ) : (
                                                <NoDataWrapper>
                                                    <NoDataComponent>
                                                        {t('Search;There are no search results')}
                                                    </NoDataComponent>
                                                </NoDataWrapper>
                                            )}
                                        </Row>
                                    </Container>
                                </PerfectScrollbar>
                            </div>
                        </LoadingBlock>

                        {children}
                    </GridLayoutCard>
                ) : (
                    <GridLayoutCard
                        widgetInfo={widgetInfo}
                        {...restProps}
                        ref={specificSensorWidgetRef}
                        handleGoBackClick={handleGoBackClick}
                    >
                        <SelectedSensor />
                        {children}
                    </GridLayoutCard>
                )}
            </RealTimeSensorMonitoringContext.Provider>
        </RealTimeSensorMonitoringDispatchContext.Provider>
    );
};

export default RealTimeSensorMonitoring;
