import React from 'react';
import { Route } from 'react-router-dom';
import LocationLog from './LocationLog';
import LocationLogFileList from './LocationLogFileList';
import { ErrorHandleSwitch } from '../Components/Router';
import useAsync from '../../../util/hooks/useAsync';
import { useDispatch } from 'react-redux';
import { setAllCategoryList } from '../../../reducers/Common/CategoryInfo';
import { fetchCategory } from '../../../api/asset';
import SensorLog from './SensorLog';
import ScannerStateLog from './ScannerStateLog';
import GeofenceLog from './GeofenceLog';
import IotItemInfoLog from './IotItemInfoLog';
import IotItemMappingLog from './IotItemMappingLog';
import LoginHistory from './LoginHistory';

const Report = ({ match }) => {
    const storeDispatch = useDispatch();

    useAsync({
        promise: fetchCategory,
        param: { isAll: 'Y' },
        resolve: ({ rows }) => {
            storeDispatch(setAllCategoryList(rows));
        },
        immediate: true,
    });

    return (
        <ErrorHandleSwitch>
            <Route exact path={`${match.path}/location-log`} component={LocationLog} />
            <Route exact path={`${match.path}/location-log/list`} component={LocationLogFileList} />
            <Route exact path={`${match.path}/scanner-log/`} component={ScannerStateLog} />
            <Route exact path={`${match.path}/sensor-log`} component={SensorLog} />
            <Route exact path={`${match.path}/geofence-log`} component={GeofenceLog} />
            <Route exact path={`${match.path}/iotItem-log/list`} component={IotItemInfoLog} />
            <Route exact path={`${match.path}/iotItem-mapping-log/list`} component={IotItemMappingLog} />
            <Route exact path={`${match.path}/login-log/list`} component={LoginHistory} />
        </ErrorHandleSwitch>
    );
};

export default Report;
