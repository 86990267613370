import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from '../../../Common/Table';
import DashboardDetailPopup from './DashboardDetailPopup';
import moment from 'moment';
import { setDashboardList, selectDashboard } from '../../../../reducers/Dashboards/Dashboards';
import { BookmarkIcon, ShareIcon, DashboardSubMenu } from './Components/DashboardListColumns';
import useAsync from '../../../../util/hooks/useAsync';
import { getDashboardListApi } from '../../../../api/dashboard';
import { LoadingBlock } from '../../../Common/LoadingBlock';
import Button from '../../../Common/Button';
import Card from '../../../Common/Card';
import { useTranslation } from 'react-i18next';

const gridStyle = { height: 'calc(100vh - 150px - 73px - 20px)' };

const DashboardsList = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { dashboardListInfo, selectedDashboard } = useSelector(state => state.Dashboards);
    const [modal, setModal] = useState(false);
    const columns = useMemo(() => {
        return [
            {
                Header: t(`Dashboard;Bookmark`),
                accessor: 'show',
                width: 90,
                headerClassName: 'flex-center',
                className: 'flex-center',
                style: { fontSize: '1rem' },
                Cell: BookmarkIcon,
            },
            {
                Header: t('Dashboard;Share'),
                accessor: 'share',
                className: 'flex-center',
                Cell: ShareIcon,
            },
            {
                Header: t('Dashboard;Dashboard Name'),
                className: 'pr-3 pl-3 text-ellipsis',
                accessor: 'menuName',
                width: 330,
            },
            {
                Header: t('Dashboard;Description'),
                className: 'pr-3 pl-3 text-ellipsis',
                accessor: 'description',
            },
            {
                Header: t('Dashboard;Modified Date'),
                accessor: 'modDate',
                width: 300,
                className: 'flex-center',
                Cell: ({ value, row: { original } }) => {
                    return moment((value || original.regDate) * 1000).format('YYYY-MM-DD HH:mm:ss');
                },
            },
            {
                Header: '',
                id: 'subMenu',
                width: 70,
                className: 'flex-center overflow-visible',
                Cell: DashboardSubMenu,
            },
        ];
    }, [t]);

    const { state, promise } = useAsync({
        promise: getDashboardListApi,
        resolve: response => {
            dispatch(setDashboardList(response));
        },
    });

    const handleModalOpen = () => {
        setModal(true);
    };
    const handleModalClose = () => {
        setModal(false);
        dispatch(selectDashboard(null));
    };
    useEffect(() => {
        promise({ isIndividual: 'Y' });
    }, []);

    useEffect(() => {
        if (selectedDashboard) {
            setModal(true);
        } else {
            setModal(false);
        }
        return () => {
            setModal(false);
        };
    }, [selectedDashboard]);

    return (
        <>
            <Card
                header={{
                    title: t('Dashboard;Dashboard List'),
                    action: (
                        <Button className={'btn-secondary'} onClick={handleModalOpen}>
                            {t('Dashboard;Create a Dashboard')}
                        </Button>
                    ),
                }}
            >
                <LoadingBlock blocking={state.isLoading}>
                    <div style={gridStyle}>
                        <Table columns={columns} data={dashboardListInfo} paging={false} />
                    </div>
                </LoadingBlock>
            </Card>

            <DashboardDetailPopup initOpen={modal} toggleModal={handleModalClose} dashboardInfo={selectedDashboard} />
        </>
    );
};

export default DashboardsList;
