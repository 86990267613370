import React, { createContext, useReducer, useRef, useState } from 'react';
import useTranslation from '../../../../util/hooks/useTranslation';
import Page from '../../../Common/Page';
import useConfirmModal from '../../../Common/ConfirmModal/useConfirmModal';
import useAsync from '../../../../util/hooks/useAsync';
import { fetchExcelLogApi, fetchGeofenceExcelLogExport, fetchGeofenceLogList } from '../../../../api/log';
import Search from './Components/Search';
import Card from '../../../Common/Card';
import Button from '../../../Common/Button';
import Table from '../../../Common/Table';
import useColumns from '../../../../util/hooks/useColumns';
import * as column from '../../util/grid/column';
import { fetchFloorList, fetchGeofenceList } from '../../../../api/common';
import { setFloorList } from '../../../../reducers/Common/FloorInfo';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { excelExport } from '../util/util';
import geofenceLogReducer, { initialState, setGeofenceList } from './geofenceLogReducer';

export const GeofenceLogDispatchContext = createContext();
export const GeofenceLogStateContext = createContext();

const INTERVAL_TIME = 1000;
const GeofenceLog = () => {
    const t = useTranslation('Geofence Log');

    const searchRef = useRef();
    const excelTimerRef = useRef();
    const storeDispatch = useDispatch();
    const [state, dispatch] = useReducer(geofenceLogReducer, initialState);
    const match = useRouteMatch();
    const { startDate, endDate } = state;

    useAsync({
        promise: fetchFloorList,
        resolve: response => {
            storeDispatch(setFloorList(response));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });

    useAsync({
        promise: fetchGeofenceList,
        resolve: response => {
            dispatch(setGeofenceList(response));
        },
        immediate: true,
    });

    const columns = useColumns([
        column.number(),
        column.categoryName(),
        column.targetName(),
        column.targetId(),
        column.floor(),
        column.geofence(),
        column.authorizedNotAuthorized(),
        column.inDate(),
        column.inScannerName(),
        column.outDate(),
        column.outScannerName(),
    ]);

    const [geofenceLogData, setGeofenceLogData] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });

    const { promise: getGeofenceLogList, state: geofenceLogListInfo } = useAsync({
        promise: fetchGeofenceLogList,
        keepState: true,
        immediate: true,
        fixedParam: { pageSize: 15 },
        resolve: res => {
            const data = res.rows.map(log => {
                return {
                    comNum: log.comNum,
                    categoryCode: log.categoryCode,
                    targetName: log.targetName,
                    targetId: log.targetId,
                    floorIdPath: log.floorIdPath,
                    fcName: log.fcName,
                    permitted: log.permitted,
                    inDate: log.inDate,
                    inScannerName: log.inScannerName,
                    outDate: log.outDate,
                    outScannerName: log.outScannerName,
                    logNum: log.logNum,
                    ...log,
                };
            });
            setGeofenceLogData({ ...res, rows: data });
        },
    });

    const { promise: createNewLogFile } = useAsync({
        promise: fetchGeofenceExcelLogExport,
        fixedParam: {
            zipFileName: 'Geofence_Log',
            fileName: 'Geofence_Log',
            startDate: startDate ? moment(startDate).unix() : 0,
            endDate: endDate ? moment(endDate).unix() : moment().unix(),
            columnMetas: [
                {
                    key: 'logNum',
                    name: '로그 번호',
                },
                {
                    key: 'categoryCode',
                    name: '카테고리 이름',
                },
                {
                    key: 'targetName',
                    name: '대상 이름',
                },
                {
                    key: 'targetId',
                    name: '대상 아이디',
                },
                {
                    key: 'floorIdPath',
                    name: '층',
                },
                {
                    key: 'fcName',
                    name: '지오펜스',
                },
                {
                    key: 'permitted',
                    name: '인가/비인가',
                },
                {
                    key: 'inTime',
                    isDateFormat: 'Y',
                    name: '입장',
                },
                {
                    key: 'inScannerName',
                    name: '입장스캐너',
                },
                {
                    key: 'outTime',
                    isDateFormat: 'Y',
                    name: '입장',
                },
                {
                    key: 'outScannerName',
                    name: '퇴장스캐너',
                },
            ],
        },
        resolve: res => {
            const { filePath: url, reqNum } = res;
            if (url) {
                downloadWithURL(url, reqNum);
            }
        },
        reject: res => {
            toggleExcelModal();
            console.log(res);
        },
    });

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            const { dataSettingStatusType, filePath: url, fileName } = res;
            if (dataSettingStatusType === 'COMPLETED') {
                clearExcelTimer();
                excelExport(url, fileName);
            } else if (dataSettingStatusType === 'FAILED') {
                clearExcelTimer();
                toggleExcelDownloadResultModal();
            }
        },
    });

    const downloadWithURL = (url, reqNum) => {
        excelTimerRef.current = setInterval(() => {
            fetchExcelLog({ reqNum: reqNum });
        }, INTERVAL_TIME);
    };

    const clearExcelTimer = () => {
        clearInterval(excelTimerRef.current);
        excelTimerRef.current = null;
    };

    const handlePageChange = pageIndex => {
        if (
            geofenceLogListInfo.request &&
            (geofenceLogListInfo.request.keyword ||
                geofenceLogListInfo.request.startDate ||
                geofenceLogListInfo.request.endDate)
        ) {
            getGeofenceLogList({ ...geofenceLogListInfo.request, page: pageIndex });
        } else {
            getGeofenceLogList({ page: pageIndex });
        }
    };

    const handleExportFileClick = () => {
        const { request } = geofenceLogListInfo;
        delete request.page;
        delete request.pageSize;
        request.isAll = 'Y';
        createNewLogFile({ ...request });
    };

    const { Modal: ExcelDownloadResultModal, toggleModal: toggleExcelDownloadResultModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Result', 'ConfirmModal') },
        confirmText: t('Failed to download the excel.', 'ConfirmModal'),
    });

    const { toggleModal: toggleExcelModal, Modal: ExcelModal } = useConfirmModal({
        initModal: false,
        header: {
            title: t('Excel Download', 'Geofence Log List'),
        },
        confirmText: t(
            'You can see and download the Excel File that you have requested under `Report > Geofence Log List` menu.',
            'Geofence Log List',
        ),
        removeCancel: true,
    });

    return (
        <GeofenceLogDispatchContext.Provider value={dispatch}>
            <GeofenceLogStateContext.Provider value={state}>
                <Page menuPath={[t('Report'), t('Geofence Log')]} className={'h-100'}>
                    <Search getGeofenceLogList={getGeofenceLogList} />
                    <Card
                        innerRef={searchRef}
                        className={'report-container'}
                        header={{
                            title: t('Geofence Log List'),
                            action: (
                                <Button className="btn-secondary" onClick={handleExportFileClick}>
                                    {t('Export', 'Button')}
                                </Button>
                            ),
                        }}
                    >
                        <div style={{ height: '35rem' }}>
                            <Table
                                data={{ ...geofenceLogData, pageSize: 15 }}
                                columns={columns}
                                onPageChange={handlePageChange}
                                loading={geofenceLogListInfo.isLoading}
                            />
                        </div>
                    </Card>
                    <ExcelModal />
                    <ExcelDownloadResultModal />
                </Page>
            </GeofenceLogStateContext.Provider>
        </GeofenceLogDispatchContext.Provider>
    );
};

export default GeofenceLog;
