import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { secToHms } from '../../../../util/common/util';
import useTranslation from '../../../../util/hooks/useTranslation';
import EVT_TYPE from '../../../../util/staticData/eventType';

// Location-Log Table
export const number = ({ ...restProps } = {}) => {
    return {
        Header: 'No.',
        headerClassName: 'flex-center',
        accessor: 'logNum',
        className: 'flex-center',
        width: 100,
        ...restProps,
    };
};

export const targetName = ({ ...restProps }) => {
    return {
        Header: 'Target Name',
        headerClassName: 'text-ellipsis',
        accessor: 'targetName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const deviceName = ({ ...restProps }) => {
    return {
        Header: 'Device Name',
        headerClassName: 'flex-center',
        accessor: 'tagName',
        className: 'flex-center text-ellipsis',
        ...restProps,
    };
};

export const floor = ({ ...restProps }) => {
    return {
        Header: 'Floor',
        headerClassName: 'text-ellipsis',
        accessor: 'floorId',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const location = ({ handleLatLngClick, t, ...restProps } = {}) => {
    return {
        Header: 'Location',
        headerClassName: 'flex-center',
        accessor: 'latLng',
        className: 'flex-center',
        width: 250,
        Cell: ({ value, row }) => {
            let [lat, lng] = value;
            const { original } = row;

            lat = parseFloat(lat).toFixed(6);
            lng = parseFloat(lng).toFixed(6);

            return (
                <>
                    <div
                        className={'pnt-txt txt-border txt-bold custom-action-btn'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            if (typeof handleLatLngClick === 'function') {
                                handleLatLngClick(original);
                            }
                        }}
                    >
                        {t('Latitude')} {lat}
                    </div>
                    <div
                        className={'pnt-txt txt-border txt-bold custom-action-btn'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            if (typeof handleLatLngClick === 'function') {
                                handleLatLngClick(original);
                            }
                        }}
                    >
                        {t('Longitude')} {lng}
                    </div>
                </>
            );
        },
        t,
        ...restProps,
    };
};

export const registeredDate = ({ ...restProps }) => {
    return {
        Header: 'Registered Date',
        headerClassName: 'text-ellipsis',
        accessor: 'regDate',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            const date = moment.unix(value).format('YYYY-MM-DD HH:mm:ss');
            return <div>{date}</div>;
        },
        ...restProps,
    };
};

//Location-Log-File Table
export const downloadLog = ({ t, ...restProps }) => {
    return {
        Header: 'Download',
        headerClassName: 'flex-center',
        className: 'flex-center',
        accessor: 'filePath',
        Cell: ({ value, row }) => {
            const { original } = row;
            if (original.dataSettingStatusType !== 'COMPLETED') {
                return <div className={'pnt-txt txt-bold'}>-</div>;
            } else {
                return (
                    <a href={value} download={original.fileName}>
                        <div className={'pnt-txt txt-border txt-bold custom-action-btn'} style={{ cursor: 'pointer' }}>
                            {t('Download')}
                        </div>
                    </a>
                );
            }
        },
        t,
        ...restProps,
    };
};

export const reqNum = ({ ...restProps }) => {
    return {
        Header: 'No.',
        headerClassName: 'flex-center',
        className: 'flex-center',
        accessor: 'reqNum',
        ...restProps,
    };
};

export const fileName = ({ ...restProps }) => {
    return {
        Header: 'File Name',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'fileName',
        ...restProps,
    };
};

export const dataStatus = ({ t, ...restProps } = {}) => {
    return {
        Header: 'Data Status',
        headerClassName: 'flex-center',
        className: 'flex-center',
        accessor: 'dataSettingStatusType',
        Cell: ({ value, row }) => {
            if (value === 'COMPLETED')
                return (
                    <div className={'pnt-txt txt-border txt-bold'} style={{ color: '#000' }}>
                        {t('COMPLETED')}
                    </div>
                );
            else if (value === 'FAILED')
                return (
                    <div className={'pnt-txt txt-border txt-bold'} style={{ color: '#ff3333' }}>
                        {t('FAILED')}
                    </div>
                );
            else if (value === 'APPENDING')
                return (
                    <div className={'pnt-txt txt-border txt-bold'} style={{ color: '#FFC107' }}>
                        {t('APPENDING')}
                    </div>
                );
            else if (value === 'PROGRESSING') {
                return (
                    <div className={'pnt-txt txt-border txt-bold'} style={{ color: '#3E66FB' }}>
                        {t('PROGRESSING')}
                    </div>
                );
            }
        },
        t,
        ...restProps,
    };
};
// geofence log table
// export const categoryName = ({ ...restProps } = {}) => {
//     return {
//         Header: 'Category Name',
//         headerClassName: 'flex-center',
//         className: 'flex-center text-ellipsis',
//         accessor: 'categoryCode',
//     };
// };
export const targetId = ({ ...restProps } = {}) => {
    return {
        Header: 'Target ID',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        width: 80,
        accessor: 'targetId',
    };
};
export const geofence = ({ ...restProps } = {}) => {
    return {
        Header: 'Geofence',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'fcName',
    };
};
export const authorizedNotAuthorized = ({ ...restProps } = {}) => {
    return {
        Header: 'Authorized Not Authorized',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'permitted',
    };
};
export const inDate = ({ ...restProps } = {}) => {
    return {
        Header: 'In Date',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'inDate',
        Cell: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
    };
};
export const inScannerName = ({ ...restProps } = {}) => {
    return {
        Header: 'In Scanner Name',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'inScannerName',
    };
};
// 스캐너 상태 로그
export const scannerName = ({ ...restProps } = {}) => {
    return {
        Header: 'Scanner Name',
        headerClassName: 'flex-center',
        accessor: 'scannerName',
        className: 'flex-center',
        ...restProps,
    };
};
export const macAddress = ({ ...restProps } = {}) => {
    return {
        Header: 'Mac Address',
        headerClassName: 'flex-center',
        accessor: 'macAddr',
        className: 'flex-center',
        ...restProps,
    };
};

export const startDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Start Date',
        headerClassName: 'flex-center',
        accessor: 'startDate',
        className: 'flex-center',
        Cell: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        ...restProps,
    };
};
export const endDate = ({ ...restProps } = {}) => {
    return {
        Header: 'End Date',
        headerClassName: 'flex-center',
        accessor: 'endDate',
        className: 'flex-center',
        Cell: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        ...restProps,
    };
};

//sensor log
export const sensorName = ({ ...restProps } = {}) => {
    return {
        Header: 'Sensor Name',
        headerClassName: 'flex-center',
        accessor: 'sensorName',
        className: 'flex-center text-ellipsis',
        ...restProps,
    };
};

export const sensorId = ({ ...restProps } = {}) => {
    return {
        Header: 'Sensor Id',
        headerClassName: 'flex-center',
        accessor: 'sensorId',
        className: 'flex-center text-ellipsis',
        ...restProps,
    };
};
export const sensorType = ({ ...restProps } = {}) => {
    return {
        Header: 'Sensor Type',
        headerClassName: 'flex-center',
        accessor: 'sensorType',
        className: 'flex-center text-ellipsis',
        ...restProps,
    };
};

export const injectionVolume = ({ ...restProps } = {}) => {
    return {
        Header: 'Injection Volume',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'VTBI',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const totalDose = ({ ...restProps } = {}) => {
    return {
        Header: 'Total Dose',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'TOTAL_DOSAGE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const stepCount = ({ ...restProps } = {}) => {
    return {
        Header: 'Step Count',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'STEP',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const bloodOxygenSaturation = ({ ...restProps } = {}) => {
    return {
        Header: 'Blood Oxygen Saturation',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'SPO2',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const noise = ({ ...restProps } = {}) => {
    return {
        Header: 'Noise',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'SOUND_NOISE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const so2 = ({ ...restProps } = {}) => {
    return {
        Header: 'SO2',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'SO2',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const sleepIndex = ({ ...restProps } = {}) => {
    return {
        Header: 'Sleep Index',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'SLEEP',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const earthquakeIntensity = ({ ...restProps } = {}) => {
    return {
        Header: 'Earthquake Intensity',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'SEISMIC_INTENS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const relativeHumidity = ({ ...restProps } = {}) => {
    return {
        Header: 'Relative Humidity',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'R_HUMIDITY',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const respiratoryRate = ({ ...restProps } = {}) => {
    return {
        Header: 'Respiratory Rate',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'RESPIRATION_RATE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};

export const infusionRate = ({ ...restProps } = {}) => {
    return {
        Header: 'Infusion Rates',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'RATE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const pulseRate = ({ ...restProps } = {}) => {
    return {
        Header: 'Pulse Rate',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'PULSE_RATE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const oneTimePcaInjection = ({ ...restProps } = {}) => {
    return {
        Header: 'One Time PCA Injection',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'PCA_BOLUS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const numberOfPacketTransmissions = ({ ...restProps } = {}) => {
    return {
        Header: 'Number Of Packet Transmissions',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'PACKET_COUNTER',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const o2 = ({ ...restProps } = {}) => {
    return {
        Header: 'O2',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'O2',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const diaperUrine = ({ ...restProps } = {}) => {
    return {
        Header: 'Diaper-Urine',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'NAPPY_GAS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const diaperFart = ({ ...restProps } = {}) => {
    return {
        Header: 'Diaper-Fart',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'NAPPY_SMALL',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const diaperFeces = ({ ...restProps } = {}) => {
    return {
        Header: 'Diaper-Feces',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'NAPPY_BIG',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const movement = ({ ...restProps } = {}) => {
    return {
        Header: 'Movement',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'MOVEMENT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const diastolic = ({ ...restProps } = {}) => {
    return {
        Header: 'Diastolic',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'H_BLOODPRESSURE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};

export const geofenceName = ({ ...restProps }) => {
    return {
        Header: 'Geofence Name',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'fcName',
        ...restProps,
    };
};

export const countOfEntries = ({ ...restProps }) => {
    return {
        Header: 'Count of entries',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'entryCount',
        ...restProps,
    };
};

export const countOfUnique = ({ ...restProps }) => {
    return {
        Header: 'Count of unique',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'uniqueCount',
        ...restProps,
    };
};

export const averageStayOfEntry = ({ ...restProps }) => {
    return {
        Header: 'Average length of stay per entry',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'avgStayEntryTime',
        Cell: ({ value }) => {
            return <span>{`${moment(value * 1000).format('m')}m ${moment(value * 1000).format('ss')}s`}</span>;
        },
        ...restProps,
    };
};

export const averageStayOfUnique = ({ ...restProps }) => {
    return {
        Header: 'Average length of stay per unique',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'avgStayUniqueTime',
        Cell: ({ value }) => {
            return <span>{`${moment(value * 1000).format('m')}m ${moment(value * 1000).format('ss')}s`}</span>;
        },
        ...restProps,
    };
};

// iot Item Information Log Table
export const dataType = ({ ...restProps }) => {
    return {
        Header: 'Type of Data Revision',
        headerClassName: 'text-ellipsis',
        accessor: 'manipulationType',
        className: 'flex-center text-ellipsis',
        ...restProps,
    };
};

export const targetNum = ({ ...restProps }) => {
    return {
        Header: 'IoT Item No.',
        headerClassName: 'text-ellipsis',
        accessor: 'targetNum',
        className: 'flex-center text-ellipsis',
        ...restProps,
    };
};

export const iotItemId = ({ ...restProps }) => {
    return {
        Header: 'IoT Item ID',
        headerClassName: 'text-ellipsis',
        accessor: 'targetId',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const metaData = ({ ...restProps }) => {
    return {
        Header: 'Meta Data',
        headerClassName: 'text-ellipsis',
        accessor: 'metadata',
        className: 'text-overflow',
        ...restProps,
    };
};

export const deviceType = ({ ...restProps }) => {
    return {
        Header: 'Device Type',
        headerClassName: 'text-ellipsis',
        accessor: 'deviceType',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const deviceNum = ({ ...restProps }) => {
    return {
        Header: 'Device No.',
        headerClassName: 'text-ellipsis',
        accessor: 'deviceNum',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const deviceId = ({ ...restProps }) => {
    return {
        Header: 'Device ID',
        headerClassName: 'text-ellipsis',
        accessor: 'deviceId',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const userId = ({ ...restProps }) => {
    return {
        Header: 'User ID',
        headerClassName: 'text-ellipsis',
        accessor: 'userID',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const userName = ({ ...restProps }) => {
    return {
        Header: 'User Name',
        headerClassName: 'text-ellipsis',
        accessor: 'userName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const type = ({ ...restProps }) => {
    return {
        Header: 'Log Type',
        headerClassName: 'text-ellipsis',
        accessor: 'type',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const description = ({ ...restProps }) => {
    return {
        Header: 'Reason for Login Failure',
        headerClassName: 'text-ellipsis',
        accessor: 'description',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const ip = ({ ...restProps }) => {
    return {
        Header: 'IP',
        headerClassName: 'text-ellipsis',
        accessor: 'ip',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const lel = ({ ...restProps } = {}) => {
    return {
        Header: 'LEL',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'LEL',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const injectedAmount = ({ ...restProps } = {}) => {
    return {
        Header: 'Injected Amount',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'INFUSED_VOL',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const systolicBloodPressure = ({ ...restProps } = {}) => {
    return {
        Header: 'Systolic Blood Pressure',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'L_BLOODPRESSURE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const humidity = ({ ...restProps } = {}) => {
    return {
        Header: 'Humidity',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'HUMIDITY',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const height = ({ ...restProps } = {}) => {
    return {
        Header: 'Height',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'HEIGHT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const heatstroke = ({ ...restProps } = {}) => {
    return {
        Header: 'Heatstroke',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'HEAT_STROKE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const heartRate = ({ ...restProps } = {}) => {
    return {
        Header: 'Heart Rate',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'HEART_RATE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const weight = ({ ...restProps } = {}) => {
    return {
        Header: 'Weight',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'WEIGHT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const h2s = ({ ...restProps } = {}) => {
    return {
        Header: 'H2S',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'H2S',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};

export const volatileOrganicCompounds = ({ ...restProps } = {}) => {
    return {
        Header: 'Volatile Organic Compounds',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ETVOC',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const equivalentCarbonDioxide = ({ ...restProps } = {}) => {
    return {
        Header: 'Equivalent Carbon Dioxide',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ECO2',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const dust = ({ ...restProps } = {}) => {
    return {
        Header: 'Dust',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'DUST',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const dropRaid = ({ ...restProps } = {}) => {
    return {
        Header: 'Drop Raid',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'DROP_RATE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const dropWater = ({ ...restProps } = {}) => {
    return {
        Header: 'Drop Water',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'DROP_COUNT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const dosage = ({ ...restProps } = {}) => {
    return {
        Header: 'Dosage',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'DOSAGE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const distance = ({ ...restProps } = {}) => {
    return {
        Header: 'Distance',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'DISTANCE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const discomfortIndex = ({ ...restProps } = {}) => {
    return {
        Header: 'Discomfort Index',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'DISCOMFORT_IDX',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const crack = ({ ...restProps } = {}) => {
    return {
        Header: 'Crack',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'CRACK',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const density = ({ ...restProps } = {}) => {
    return {
        Header: 'Density',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'CONC',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const co = ({ ...restProps } = {}) => {
    return {
        Header: 'CO',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'CO',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const capacity = ({ ...restProps } = {}) => {
    return {
        Header: 'Capacity',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'CAPACITY',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const calories = ({ ...restProps } = {}) => {
    return {
        Header: 'Calories',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'CALORIES',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const buttonTwoClicks = ({ ...restProps } = {}) => {
    return {
        Header: 'Button Two Clicks',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'BUTTON2_COUNT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const buttonOneClicks = ({ ...restProps } = {}) => {
    return {
        Header: 'Button One Clicks',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'BUTTON1_COUNT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const battery = ({ ...restProps } = {}) => {
    return {
        Header: 'Battery',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'BATTERY',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const atmosphericPressure = ({ ...restProps } = {}) => {
    return {
        Header: 'Atmospheric Pressure',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'BAROM_PRESURE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const zAxisAngularVelocity = ({ ...restProps } = {}) => {
    return {
        Header: 'Z-Axis Angular Velocity',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ACCELER_Z_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const yAxisAngularVelocity = ({ ...restProps } = {}) => {
    return {
        Header: 'Y-Axis Angular Velocity',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ACCELER_Y_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const xAxisAngularVelocity = ({ ...restProps } = {}) => {
    return {
        Header: 'X-Axis Angular Velocity',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ACCELER_X_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const illuminance = ({ ...restProps } = {}) => {
    return {
        Header: 'Ambient Light',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ANBIENT_LIGHT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const zSideAcceleration = ({ ...restProps } = {}) => {
    return {
        Header: 'Z Side Acceleration',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ANGULAR_Z_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const YSideAcceleration = ({ ...restProps } = {}) => {
    return {
        Header: 'Y Side Acceleration',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ANGULAR_Y_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const xSideAcceleration = ({ ...restProps } = {}) => {
    return {
        Header: 'X Side Acceleration',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ANGULAR_X_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const lastReceivedDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Last Received Date',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: '',
        ...restProps,
    };
};
export const temperature = ({ ...restProps } = {}) => {
    return {
        Header: 'Temperature',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'TEMPERATURE',
        ...restProps,
    };
};

export const outDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Out Date',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'outDate',
        Cell: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        ...restProps,
    };
};
export const outScannerName = ({ ...restProps } = {}) => {
    return {
        Header: 'Out Scanner Name',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'outScannerName',
    };
};

// AT Columns
const EllipsisSpan = styled.span`
    width: calc(100% - 20px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const categoryNameAT = (props = {}) => {
    const { className, ...restProps } = props;
    const t = useTranslation('Search');
    return {
        Header: () => <div className={className}>{t('Category')}</div>,
        accessor: 'categoryName',
        width: 100,
        Cell: ({ value, row: { original } }) => {
            return (
                <div className={`text-ellipsis ${className}`} title={value}>
                    {value}
                </div>
            );
        },
        ...restProps,
    };
};
export const targetNameAT = props => {
    const t = useTranslation('ColumnAt');
    return {
        Header: t('Name'),
        accessor: 'targetName',
        width: 100,
        Cell: ({ value, row: { original } }) => {
            return (
                <div className={'text-ellipsis'} title={value}>
                    {value}
                </div>
            );
        },
        ...props,
    };
};
export const assetNameAT = (props = {}) => {
    const { className, ...restProps } = props;
    const t = useTranslation('ColumnAt');
    return {
        Header: () => <div className={className}>{t('Asset Name')}</div>,
        accessor: 'targetName',
        Cell: ({ value, row: { original } }) => {
            return (
                <div className={`text-ellipsis ${className}`} title={value}>
                    {value}
                </div>
            );
        },
        ...restProps,
    };
};
export const targetIdAT = props => {
    const t = useTranslation('ColumnAt');
    return {
        Header: t('ID'),
        accessor: 'targetId',
        width: 100,
        ...props,
    };
};

export const floorAT = (props = {}) => {
    const t = useTranslation('ColumnAt');
    const { className, ...restProps } = props;
    return {
        Header: () => <div className={className}>{t('Position')}</div>,
        accessor: 'floorName',
        width: 100,
        Cell: ({ value }) => {
            return (
                <div className={`text-ellipsis ${className}`} title={value}>
                    {value}
                </div>
            );
        },
        ...restProps,
    };
};

export const locationAT = (props = {}) => {
    const t = useTranslation('ColumnAt');
    const { className, ...restProps } = props;
    return {
        Header: () => <div className={className}>{t('Location')}</div>,
        accessor: 'floorName',
        Cell: ({ value }) => {
            return (
                <div className={`text-ellipsis ${className}`} title={value}>
                    {value}
                </div>
            );
        },
        ...restProps,
    };
};
export const zoneAT = props => {
    const t = useTranslation('ColumnAt');
    return {
        Header: t('Zone'),
        accessor: 'fcName',
        ...props,
    };
};
export const positionAT = props => {
    const t = useTranslation('ColumnAt');
    return {
        Header: t('Position'),
        accessor: 'floorName',
        width: 100,
        Cell: ({ value, row: { original } }) => {
            const fcName = original.fcName;
            const position = `${value ? value : ''}${fcName ? ' > ' + fcName : ''}`;
            return (
                <div className={'text-ellipsis'} title={position}>
                    {position}
                </div>
            );
        },
        ...props,
    };
};

export const detectedAT = (props = { currDate: moment(), lang: {} }) => {
    const { currDate, lang, ...restProps } = props;
    const t = useTranslation('ColumnAt');
    return {
        Header: t('Detected'),
        accessor: 'lastDate',
        width: 100,
        Cell: ({ value }) => {
            let diffSec = null;
            let hmsLabel = null;
            if (value) {
                diffSec = currDate.diff(moment(value * 1000), 'seconds');
                const { h, m, s } = secToHms(diffSec);
                hmsLabel = `${h ? h + (lang.hr ? lang.hr : t('hr')) + ' ' : ''}${
                    m ? m + (lang.min ? lang.min : t('min')) + ' ' : ''
                }${s ? s + (lang.sec ? lang.sec : t('sec')) : ''} ${t('ago')}`;
            }
            return hmsLabel ? (
                <>
                    {diffSec > 60 * 60 * 24 ? (
                        <span className={'icon-wifi icon-small icon-filter-gray'} />
                    ) : diffSec > 1800 ? (
                        <span className={'icon-wifi icon-small icon-filter-red'} />
                    ) : (
                        <span className={'icon-wifi icon-small icon-filter-blue'} />
                    )}
                    <EllipsisSpan title={hmsLabel}>{hmsLabel}</EllipsisSpan>
                </>
            ) : (
                <>
                    <span className={'icon-wifi icon-small wifi-none'} />
                    {t('N/A')}
                </>
            );
        },
        ...restProps,
    };
};
export const lastDateAT = (props = { currDate: moment(), lang: {} }) => {
    const { currDate, lang, ...restProps } = props;
    const t = useTranslation('ColumnAt');
    return {
        Header: t('Last Seen'),
        accessor: 'lastDate',
        width: 100,
        Cell: ({ value, row: { original } }) => {
            const isTagMapped = original.isTagMapped;
            let diffSec = null;
            let hmsLabel = null;
            if (value) {
                diffSec = currDate.diff(moment(value * 1000), 'seconds');
                const { h, m, s } = secToHms(diffSec);
                hmsLabel = `${h ? h + ' ' + (lang.hr ? lang.hr : t('hr')) + ' ' : ''}${
                    m ? m + ' ' + (lang.min ? lang.min : t('min')) + ' ' : ''
                }${s ? s + ' ' + (lang.sec ? lang.sec : t('sec')) : ''} ${t('ago')}`;
            }
            return hmsLabel ? (
                <>
                    {isTagMapped === 'N' ? (
                        <span className={'icon-wifi icon-small wifi-none'} />
                    ) : diffSec > 60 * 60 * 24 ? (
                        <span className={'icon-wifi icon-small icon-filter-gray'} />
                    ) : diffSec > 1800 ? (
                        <span className={'icon-wifi icon-small icon-filter-red'} />
                    ) : (
                        <span className={'icon-wifi icon-small icon-filter-blue'} />
                    )}
                    <EllipsisSpan title={hmsLabel}>{hmsLabel}</EllipsisSpan>
                </>
            ) : (
                <>
                    <span className={'icon-wifi icon-small wifi-none'} />
                    {t('N/A')}
                </>
            );
        },
        ...restProps,
    };
};
export const lastDateDiffAT = (props = { currDate: moment(), lang: {} }) => {
    const { currDate, lang, ...restProps } = props;
    const t = useTranslation('ColumnAt');
    return {
        Header: t('Last Seen'),
        accessor: 'lastDateDiff',
        width: 150,
        Cell: ({ value, row: { original } }) => {
            const isTagMapped = original.isTagMapped;
            let hmsLabel = null;
            if (value) {
                const { h, m, s } = secToHms(value);
                hmsLabel = `${h ? h + ' ' + (lang.hr ? lang.hr : t('hr')) + ' ' : ''}${
                    m ? m + ' ' + (lang.min ? lang.min : t('min')) + ' ' : ''
                }${s ? s + ' ' + (lang.sec ? lang.sec : t('sec')) : ''} ${t('ago')}`;
            }
            return hmsLabel ? (
                <>
                    {isTagMapped === 'N' ? (
                        <span className={'icon-wifi icon-small off'} />
                    ) : value > 60 * 60 * 24 ? (
                        <span className={'icon-wifi icon-small icon-filter-gray'} />
                    ) : value > 1800 ? (
                        <span className={'icon-wifi icon-small icon-filter-red'} />
                    ) : (
                        <span className={'icon-wifi icon-small icon-filter-blue'} />
                    )}
                    <EllipsisSpan title={hmsLabel}>{hmsLabel}</EllipsisSpan>
                </>
            ) : (
                <>
                    <span className={'icon-wifi icon-small off'} />
                    {t('N/A')}
                </>
            );
        },
        ...restProps,
    };
};
export const ownershipAT = props => {
    const t = useTranslation('ColumnAt');
    return {
        Header: () => <div style={{ textAlign: 'center' }}>{t('Ownership')}</div>,
        accessor: 'isBookmark',
        width: 70,
        Cell: row => {
            return (
                <div style={{ width: '100%' }} className={'d-flex justify-content-center'}>
                    <input
                        style={{ marginRight: 0 }}
                        disabled={true}
                        type="checkbox"
                        checked={row.value === 'Y'}
                        onClick={e => {
                            e.stopPropagation();
                        }}
                    />
                </div>
            );
        },
        ...props,
    };
};
export const entryDateAT = props => {
    const t = useTranslation('ColumnAt');
    return {
        Header: t('Entry'),
        accessor: 'inDate',
        width: 150,
        Cell: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : null;
        },
        ...props,
    };
};
export const exitDateAT = props => {
    const t = useTranslation('ColumnAt');
    return {
        Header: t('Exit'),
        accessor: 'outDate',
        width: 150,
        Cell: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : null;
        },
        ...props,
    };
};
export const countAT = props => {
    const t = useTranslation('ColumnAt');
    return {
        Header: t('Count'),
        accessor: 'registCnt',
    };
};
export const registeredCntAT = (props = {}) => {
    const { className, ...restProps } = props;
    const t = useTranslation('ColumnAt');
    return {
        Header: () => <div style={{ textAlign: 'center' }}>{t('Registered')}</div>,
        accessor: 'registCnt',
        Cell: ({ value }) => {
            return (
                <div className={`text-ellipsis ${className}`} title={value}>
                    {value}
                </div>
            );
        },
        ...restProps,
    };
};
export const mappedCntAT = (props = {}) => {
    const t = useTranslation('ColumnAt');
    const { className, ...restProps } = props;
    return {
        Header: () => <div style={{ textAlign: 'center' }}>{t('Mapped')}</div>,
        accessor: 'mappedCnt',
        Cell: ({ value }) => {
            return (
                <div className={`text-ellipsis ${className}`} title={value}>
                    {value}
                </div>
            );
        },
        ...restProps,
    };
};
export const occurrenceDatetimeAT = props => {
    const t = useTranslation('ColumnAt');
    return {
        Header: t('Occurrence Datetime'),
        accessor: 'regDate',
        Cell: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : null;
        },
    };
};

export const eventTypeAT = props => {
    const t = useTranslation('ColumnAt');
    return {
        Header: t('Event Type'),
        accessor: 'interfaceCommandType',
        Cell: ({ value }) => {
            return EVT_TYPE[value];
        },
    };
};

export const deviceNameAT = props => {
    const t = useTranslation('ColumnAt');
    return {
        Header: t('Name'),
        accessor: 'deviceName',
    };
};

export const entranceEventTypeAT = props => {
    const t = useTranslation('ColumnAt');
    return {
        Header: t('Event Type'),
        accessor: 'inOutState',
        width: 100,
        Cell: ({ value, row: { original } }) => {
            const inDate = original.inDate;
            const outDate = original.outDate || 0;
            let result = '';
            if (inDate > outDate) {
                result = t('Location in');
            } else {
                result = t('Location out');
            }
            return result;
        },
    };
};

export const targetNameWithIdAT = props => {
    const t = useTranslation('ColumnAt');
    return {
        Header: t('Name/ID'),
        accessor: 'targetName',
        Cell: ({ value, row: { original } }) => {
            const displayValue = `${value} / ${original.targetId}`;
            return (
                <div className={'text-ellipsis'} title={displayValue}>
                    {displayValue}
                </div>
            );
        },
    };
};

export const idAT = props => {
    const t = useTranslation('ColumnAt');
    return {
        Header: t('ID'),
        accessor: 'id', // 정확한 키값 확인이 필요함
    };
};

export const regDateAT = props => {
    const t = useTranslation('ColumnAt');
    return {
        Header: () => <div style={{ textAlign: 'center' }}>{t('Time')}</div>,
        accessor: 'regDate',
        width: 200,
        Cell: ({ value }) => {
            return (
                <div className={`text-ellipsis ${props}`} title={value}>
                    {value}
                </div>
            );
        },
        ...props,
    };
};

export const categoryName = ({ ...restProps }) => {
    return {
        Header: 'Category',
        headerClassName: 'flex-center',
        className: 'flex-center',
        accessor: 'categoryName',
        ...restProps,
    };
};

export const categoryCount = ({ ...restProps }) => {
    const { t } = useTranslation();
    return {
        Header: 'Count',
        headerClassName: 'flex-center',
        className: 'flex-center',
        accessor: 'registCnt',
        ...restProps,
    };
};

export const floorName = ({ ...restProps }) => {
    return {
        Header: 'Floor',
        headerClassName: 'flex-center',
        className: 'flex-center',
        accessor: 'floorName',
        ...restProps,
    };
};

export const registered = ({ ...restProps }) => {
    return {
        Header: 'Registered',
        headerClassName: 'flex-center',
        className: 'flex-center',
        accessor: 'registCnt',
        ...restProps,
    };
};

export const mapped = ({ ...restProps }) => {
    return {
        Header: 'Mapped',
        headerClassName: 'flex-center',
        className: 'flex-center',
        accessor: 'mappedCnt',
        ...restProps,
    };
};

export const lastDate = ({ ...restProps }) => {
    const t = useTranslation('ColumnAt');
    return {
        Header: 'Last Seen',
        accessor: 'lastDate',
        width: 150,
        Cell: ({ value, row: { original } }) => {
            const isTagMapped = original.isTagMapped;
            let diffSec = null;
            let hmsLabel = null;
            if (value) {
                diffSec = moment().diff(moment(value * 1000), 'seconds');
                const { h, m, s } = secToHms(diffSec);

                hmsLabel = `${h ? h + ' ' + t('hr') + ' ' : ''}${m ? m + ' ' + t('min') + ' ' : ''}${
                    s ? s + ' ' + t('sec') : ''
                } ${t('ago')}`;
            }
            return hmsLabel ? (
                <>
                    {isTagMapped === 'N' ? (
                        <span className={'icon-wifi icon-small icon-wifi off'} />
                    ) : diffSec > 60 * 60 * 24 ? (
                        <span className={'icon-wifi icon-small icon-filter-gray'} />
                    ) : diffSec > 1800 ? (
                        <span className={'icon-wifi icon-small icon-filter-red'} />
                    ) : (
                        <span className={'icon-wifi icon-small icon-filter-blue'} />
                    )}
                    <EllipsisSpan title={hmsLabel}>{hmsLabel}</EllipsisSpan>
                </>
            ) : (
                <>
                    <span className={'icon-wifi icon-small icon-wifi off'} />
                    {t('N/A')}
                </>
            );
        },
        ...restProps,
    };
};
export const sensorMacAddress = ({ ...restProps }) => {
    return {
        Header: 'Sensor Mac Address',
        headerClassName: 'text-ellipsis',
        accessor: 'sensorId',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const sensorItems = ({ ...restProps }) => {
    return {
        Header: 'Measurement Item',
        headerClassName: 'text-ellipsis',
        accessor: 'sensorItems',
        className: 'text-overflow',
        ...restProps,
    };
};

export const data = ({ ...restProps }) => {
    return {
        Header: 'Value',
        headerClassName: 'text-ellipsis',
        accessor: 'data',
        ...restProps,
    };
};

export const remarks = ({ ...restProps }) => {
    return {
        Header: 'Remarks',
        headerClassName: 'text-ellipsis',
        accessor: 'remarks',
        className: 'text-ellipsis p-1',
        ...restProps,
    };
};
