import React, { useEffect, useMemo, useState } from 'react';
import FilterSearchGroup from '../../../Common/FilterSearchGroup';
import useTranslation from '../../../../util/hooks/useTranslation';
import ReactSelect from '../../../Common/Select';
import {
    InputGroup,
    SelectGroup,
    InputWrap,
    SearchWrap,
    FilterList,
} from '../../../Common/FilterSearchGroup/Components/Part';
import TextInput from '../../../Common/Input/TextInput';
import Button from '../../../Common/Button';
import SearchGroup from '../../../Common/FilterSearchGroup/Components/SearchGroup';
import SearchButtonArea from '../../../Common/FilterSearchGroup/Components/SearchButtonArea';
import FilterButtonArea from '../../../Common/FilterSearchGroup/Components/FilterButtonArea';
import { FILTER_LIST_PAGE_SIZE } from './index';

const SEARCH_OPTION = 'filterName';

const Search = ({ fetchListData }) => {
    const t = useTranslation('Filter');

    const [searchInput, setSearchInput] = useState('');

    const handleRefreshClick = e => {
        setSearchInput('');
        fetchListData();
    };

    const handleSearchClick = () => {
        if (searchInput && searchInput.trim()) {
            fetchListData({
                opt: SEARCH_OPTION,
                keyword: searchInput,
            });
        } else {
            fetchListData();
        }
    };

    const handleInputChange = e => {
        const { value } = e.currentTarget;
        setSearchInput(value);
    };

    return (
        <FilterSearchGroup>
            <SearchGroup className={'bg-brand'} label={t('Search', 'Search')}>
                <SearchWrap>
                    <InputWrap>
                        <InputGroup>
                            <TextInput
                                style={{ borderRadius: '5px' }}
                                type={'text'}
                                size={'60'}
                                placeholder={t('Input Filter Name')}
                                name={'name'}
                                value={searchInput}
                                handleChange={handleInputChange}
                            />
                        </InputGroup>
                    </InputWrap>
                </SearchWrap>
                <SearchButtonArea>
                    <Button className={'btn-brand btn-icon'} iconClassName={'icon-search'} onClick={handleSearchClick}>
                        {t('Search', 'Search')}
                    </Button>
                    <Button
                        className="btn-danger btn-icon-only"
                        iconClassName={'icon-replay'}
                        onClick={handleRefreshClick}
                    />
                </SearchButtonArea>
            </SearchGroup>
        </FilterSearchGroup>
    );
};

export default Search;
