import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';

const Count = styled.div`
    .at__widgets--overviews--main {
        .slash {
            font-size: 1.5rem;
        }
        .cnt {
            font-size: 2.5rem;
            font-weight: bold;
        }
    }
    .main-desc {
        border-left: 1.5px solid #d3d3d3;
        padding-left: 10px;
    }
    .at__widgets--overviews--sub {
        border-left: 1.5px solid #d3d3d3;
        padding-left: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }
`;

const SimpleCount = ({
    children,
    className,
    widgetInfo = {
        title: '',
        mainCnt: 0,
        mainAllCnt: 0,
        mainStatus: 'Detected',
        detailCnt: [{ colorClass: 'color-success', label: 'In', value: 0 }],
    },
    ref,
    ...restProps
}) => {
    return (
        <Count className="d-flex w-100 justify-content-between h-100" {...restProps}>
            <div className="at__widgets--overviews--main">
                <div className="main-num">
                    {!isNaN(widgetInfo.mainAllCnt) ? (
                        <>
                            <span className={cx(widgetInfo.colorClass, 'cnt')}>{widgetInfo.mainCnt}</span>
                            <span className="slash">/</span>
                            <span className={'cnt'}>{widgetInfo.mainAllCnt}</span>
                        </>
                    ) : (
                        <span className={cx(widgetInfo.colorClass, 'cnt')}>{widgetInfo.mainCnt}</span>
                    )}
                </div>
                <div className="main-desc font-weight-bold">
                    <span>{widgetInfo.mainStatus}</span>
                </div>
            </div>
            <div className="at__widgets--overviews--sub">
                <div className="">
                    {widgetInfo.detailCnt.map(({ colorClass, label, value }, index) => (
                        <div className={'d-flex w-100 justify-content-between'} key={label}>
                            <div key={index} className={colorClass} style={{ fontSize: '1rem' }}>
                                {label}
                            </div>
                            <div style={{ marginLeft: '5rem' }}>{value}</div>
                        </div>
                    ))}
                </div>
            </div>
        </Count>
    );
};

export default SimpleCount;
