import { reqGet } from './index';

// 자산 위치 현황 조회
export const fetchPlacementState = param => reqGet('/v3/api/common/statistics/count-by-placement-state/count', param);

// 태그 부착 현황 조회
export const fetchMappedTagCount = param => reqGet('/v3/api/common/statistics/asset-tag-mapping/count', param);

// 자산 태그 배터리 현황 조회
export const fetchMappedTagBatteryCount = param =>
    reqGet('/v3/api/common/statistics/count-by-mapped-tags-battery/count', param);

// 태그 현황 조회
export const fetchTagSummaryCount = param => reqGet('/v3/api/common/statistics/count-by-tags-summary/count', param);

// 태그 위치 현황 조회
export const fetchTagStatusCount = param => reqGet('/v3/api/common/statistics/tag-status/count', param);

// 미맵핑 태그 베터리 현황 조회
export const fetchUnMappedTagBatteryCount = param =>
    reqGet('/v3/api/common/statistics/unmapped-tag-battery/count', param);

// 자산 실시간 위치 조회
export const fetchRealTimeLocation = param => reqGet('/v3/api/common/realtime/location/list', param);

// 최근 신호 상실 자산 알림 현황 조회
export const fetchLostSignalAlarm = param => reqGet('/v3/api/common/statistics/recent-alert-lostsignal/list', param);
