import React, { useState } from 'react';
import Search from './Components/Search';
import Card from '../../../Common/Card';
import Table from '../../../Common/Table';
import * as column from '../../util/grid/column';
import useAsync from '../../../../util/hooks/useAsync';
import { fetchExcelLogList } from '../../../../api/log';
import useColumns from '../../../../util/hooks/useColumns';
import useCustomTranslation from '../../../../util/hooks/useTranslation';
import Page from '../../../Common/Page';
import useTranslation from '../../../../util/hooks/useTranslation';

const LocationLogFileList = () => {
    const t = useTranslation('Location Log List');
    const locationLogListT = useCustomTranslation('Location Log List');
    const columnT = useCustomTranslation('CommonColumn');

    const [excelLogData, setExcelLogData] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });

    const columns = useColumns(
        [
            column.reqNum(),
            column.fileName(),
            column.dataStatus({ t: locationLogListT }),
            column.downloadLog({ t: locationLogListT }),
            column.registeredDate({ t: columnT }),
        ],
        locationLogListT,
    );

    const { promise: getExcelLogList, state: excelLogInfo } = useAsync({
        promise: fetchExcelLogList,
        keepState: true,
        immediate: true,
        fixedParam: { pageSize: 15, exportLogType: 'LOCATION' },
        resolve: res => {
            setExcelLogData(res);
        },
    });

    const handlePageChange = pageIndex => {
        if (excelLogInfo.request && (excelLogInfo.request.startDate || excelLogInfo.request.endDate)) {
            getExcelLogList({ ...excelLogInfo.request, page: pageIndex });
        } else {
            getExcelLogList({ page: pageIndex });
        }
    };

    return (
        <Page menuPath={[t('Report', 'Menu'), t('Saved Location Log List')]} className={'h-100'}>
            <Search getExcelLogList={getExcelLogList} />
            <Card
                header={{
                    title: t('Saved Location Log List'),
                }}
            >
                <div style={{ height: '35rem' }}>
                    <Table
                        data={{
                            ...excelLogData,
                            pageSize: 15,
                        }}
                        columns={columns}
                        onPageChange={handlePageChange}
                        loading={excelLogInfo.isLoading}
                    />
                </div>
            </Card>
        </Page>
    );
};

export default LocationLogFileList;
