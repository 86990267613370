import React from 'react';
import WidgetSettingModal from '../Components/WidgetSettingModal';
import useTranslation from '../../../util/hooks/useTranslation';

const AssetLocationSetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation();
    return (
        <WidgetSettingModal
            headerTitle={t('Asset location setting')}
            widgetInfo={widgetInfo}
            hiddenHeader
            {...restProps}
        />
    );
};

export default AssetLocationSetting;
