import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useTranslation from '../../../../../util/hooks/useTranslation';
import Button from '../../../../Common/Button';
import cx from 'classnames';
import SearchableSelect from '../../../../Common/Select/SearchableSelect';
import Card from '../../../../Common/Card';
import { FilterDetailDispatchContext, FilterDetailStateContext } from '../index';
import { setEditMode, updateFilter } from '../reducer';
import { staticFilterInfo, defaultFilterInfo, defaultDynamicSelectFilterInfo } from '../../Option';
import Label from '../../../../Common/Label';
import TextInput from '../../../../Common/Input/TextInput';
import CustomSwitch from '../../Components/StyledSwitch';
import { Row, Col } from 'reactstrap';
import RightSelect from './RightSelect';
import { createFilterApi, editFilterApi, getFilterListApi } from '../../../../../api/filter';
import useAsync from '../../../../../util/hooks/useAsync';
import useConfirmModal from '../../../../Common/ConfirmModal/useConfirmModal';
import { useRecoilState } from 'recoil';
import { filterList as storeFilterList } from '../../../../../recoilStore/filter';
import FilterGenerator from '../../FilterWidget/FilterGenerator';
import { useSelector } from 'react-redux';

const DropdownButton = ({ onClick }) => {
    const t = useTranslation('Filter');
    return (
        <Button className={cx('btn-secondary', 'w-px-150', 'btn-icon')} onClick={onClick}>
            <span className="icon-plus">{t('Add filter')}</span>
            {t('Add filter')}
        </Button>
    );
};

const SERVICE_CODE = 'AM01';

const EditView = () => {
    const history = useHistory();
    const { filterNum } = useParams();
    const dispatch = useContext(FilterDetailDispatchContext);
    const { editing } = useContext(FilterDetailStateContext);
    const t = useTranslation('Filter');
    const buttonT = useTranslation('Button');
    const [validationMsg, setValidationMsg] = useState([]);
    const { searchableCategoryPropertiesList } = useSelector(state => state.CategoryInfo);

    const handleSelectValueChange = selected => {
        const selectedCondition = selected.reduce((acc, curr) => {
            const conditionId = curr.value;
            const existFilter = editing.filterInfoCondition.find(filter => filter.conditionId === conditionId);
            if (existFilter) {
                acc.push(existFilter);
            } else {
                let addedFilterInitValue = defaultFilterInfo.find(filter => filter.conditionId === conditionId);
                if (!addedFilterInitValue) {
                    const categoryProp = searchableCategoryPropertiesList.find(prop => prop.propertyId === conditionId);
                    if (categoryProp) {
                        addedFilterInitValue = {
                            ...defaultDynamicSelectFilterInfo,
                            conditionId: categoryProp.propertyId,
                        };
                    }
                }

                if (addedFilterInitValue) {
                    acc.push(addedFilterInitValue);
                }
            }
            return acc;
        }, []);
        dispatch(updateFilter({ filterInfoCondition: selectedCondition }));
    };

    const [filterList, setFilterList] = useRecoilState(storeFilterList);
    const { promise: getFilterList } = useAsync({
        promise: getFilterListApi,
        fixedParam: { isAll: 'Y' },
        resolve: ({ rows }) => {
            setFilterList(rows || []);
        },
    });

    const saveSuccessCallback = () => {
        toggleSaveSuccessModal();
        getFilterList();
    };

    const { promise: createFilter } = useAsync({
        promise: createFilterApi,
        // resolve: res => {
        //     if (res && res.pkValue && res.pkName === 'filterNum') {
        //         toggleSaveSuccessModal();
        //     }
        // },
        resolve: saveSuccessCallback,
        reject: err => {
            toggleSaveFailModal();
        },
    });

    const { promise: editFilter } = useAsync({
        promise: editFilterApi,
        resolve: saveSuccessCallback,
        reject: err => {
            toggleSaveFailModal();
        },
    });

    const { Modal: ConfirmSaveModal, toggleModal: toggleConfirmSaveModal } = useConfirmModal({
        confirmText: filterNum ? t('Do you want to save information?') : t('Do you want to create filter?'),
        okCallback: () => {
            const validation = checkValidation(editing);

            if (validation) {
                if (filterNum) {
                    editFilter({ ...editing, serviceCode: SERVICE_CODE });
                } else {
                    createFilter({ ...editing, serviceCode: SERVICE_CODE });
                }
            } else {
                toggleValidationModal();
            }
        },
    });

    const { Modal: ValidationModal, toggleModal: toggleValidationModal } = useConfirmModal({
        header: { title: t('Validation Fail') },
        confirmText: (
            <>
                {validationMsg.map((msg, i) => {
                    return (
                        <li key={`validation_${i}`}>
                            <span className="pnt-label--group">
                                <div
                                    className="label-main label-dot w-100"
                                    style={{ fontWeight: 'bold', fontSize: '0.9rem' }}
                                >
                                    {msg}
                                </div>
                            </span>
                        </li>
                    );
                })}
            </>
        ),
    });

    const { Modal: SaveSuccessModal, toggleModal: toggleSaveSuccessModal } = useConfirmModal({
        confirmText: filterNum ? t('Filter is changed successfully') : t('Filter is created successfully'),
        okCallback: () => {
            history.push('/filter/list');
        },
    });

    const { Modal: SaveFailModal, toggleModal: toggleSaveFailModal } = useConfirmModal({
        confirmText: t('The request failed.', 'ErrorHandler'),
    });

    const checkValidation = filterInfo => {
        const errMsg = [];

        const { filterName, description, filterInfoCondition, filterInfoAccessRight = [] } = filterInfo;
        const ownership = filterInfoAccessRight.filter(v => v.accessScope === 'W');
        const accessRight = filterInfoAccessRight.filter(v => v.accessScope === 'R');

        if (filterName && filterName.length > 15) {
            errMsg.push(t('Filter name should be under 15 characters'));
        }
        if (!filterName || !filterName.trim().length) {
            errMsg.push(t('Filter name is required'));
        }
        if (!description || !description.length) {
            errMsg.push(t('Filter description is required'));
        }
        if (description && description.length > 100) {
            errMsg.push(t('Filter description should be under 100 characters'));
        }
        if (filterInfoCondition.length === 0) {
            errMsg.push(t('At least one filter should be selected'));
        }
        if (!ownership.length) {
            errMsg.push(t('Owner is required'));
        }
        if (!accessRight.length) {
            errMsg.push(t('Access right is required'));
        }

        setValidationMsg(errMsg);

        return !errMsg.length;
    };

    useEffect(() => {}, [staticFilterInfo, searchableCategoryPropertiesList]);

    const filterConditionList = useMemo(() => {
        return [
            ...staticFilterInfo.map(v => ({
                value: v.conditionId,
                label: t(v.name),
            })),
            ...searchableCategoryPropertiesList.map(v => ({ value: v.propertyId, label: v.displayName })),
        ];
    }, [staticFilterInfo, searchableCategoryPropertiesList]);

    return (
        <>
            <Card
                header={{
                    title: `${t('Filter')} - ${filterNum ? t('Edit') : t('Create')}`,
                    titleIcon: filterNum ? 'icon-edit' : 'icon-create',
                    action: (
                        <>
                            <Button
                                className="btn-gray"
                                onClick={e => {
                                    if (filterNum) {
                                        dispatch(setEditMode(false));
                                    } else {
                                        history.goBack();
                                    }
                                }}
                            >
                                {buttonT('Cancel')}
                            </Button>
                            <Button
                                className={'btn-icon btn-brand'}
                                onClick={() => {
                                    toggleConfirmSaveModal();
                                }}
                            >
                                <span className="icon-check" />
                                {buttonT('Save')}
                            </Button>
                        </>
                    ),
                }}
                bodyClassName={'overflow-visible'}
            >
                <div className={'d-flex mb-3 pnt-label--group'}>
                    <Label
                        name={t('Name')}
                        labelValueClassName={'label-dot mr-5'}
                        value={
                            <TextInput
                                inputGroupClassName={'w-100 form-must'}
                                name={'filterName'}
                                value={editing.filterName}
                                placeholder={t('Please enter a name for the filter')}
                                handleChange={e => dispatch(updateFilter({ filterName: e.target.value }))}
                            />
                        }
                    />
                </div>
                <div className={'d-flex mb-3 pnt-label--group'}>
                    <Label
                        name={t('Description')}
                        labelValueClassName={'label-dot mr-5'}
                        value={
                            <TextInput
                                inputGroupClassName={'w-100'}
                                name={'description'}
                                value={editing.description}
                                placeholder={t('Please enter a description')}
                                handleChange={e => dispatch(updateFilter({ description: e.target.value }))}
                            />
                        }
                    />
                </div>
                <div className={'d-flex pnt-label--group'}>
                    <Label name={t('Menu')} labelValueClassName={'label-dot mr-4'} />
                    <CustomSwitch
                        checked={editing.isApplyMenu === 'Y'}
                        onChange={checked => dispatch(updateFilter({ isApplyMenu: checked ? 'Y' : 'N' }))}
                    />
                    {editing.isApplyMenu === 'Y' ? (
                        <span className={'pnt-txt txt-tiny ml-3'}>{t('Marking as a filter menu')}</span>
                    ) : (
                        <span className={'pnt-txt txt-tiny ml-3'}>{t('Not marking as a filter menu')}</span>
                    )}
                </div>
                <div className="pnt-border border-w" />
                <div className={'d-flex'}>
                    <Label name={t('Filter setting')} labelValueClassName={'label-dot mr-5'} />
                    <div
                        className={'d-flex justify-content-between w-100 align-items-center'}
                        style={{ columnGap: '0.5rem' }}
                    >
                        <div className={'d-flex flex-wrap'} style={{ gap: '0.5rem' }}>
                            <FilterGenerator
                                filterInfo={editing}
                                handleChange={(selected, isEditable, conditionInfo) => {
                                    dispatch(
                                        updateFilter({
                                            filterInfoCondition: editing.filterInfoCondition.map(filter => {
                                                if (filter.conditionId === conditionInfo.conditionId) {
                                                    return {
                                                        ...filter,
                                                        isEditable,
                                                        conditionValues: selected,
                                                    };
                                                }
                                                return filter;
                                            }),
                                        }),
                                    );
                                }}
                            />
                        </div>
                        <div>
                            <SearchableSelect
                                keepSortOrder
                                className={'align-right'}
                                data={filterConditionList}
                                selected={editing.filterInfoCondition.map(v => v.conditionId)}
                                onChange={handleSelectValueChange}
                                DropdownControlComponent={DropdownButton}
                            />
                        </div>
                    </div>
                </div>
                <div className="pnt-border border-w" />
                <div className={'d-flex pnt-label--group'}>
                    <Label name={t('Access Right')} labelValueClassName={'label-dot mr-5'} />
                    <Row className="w-100" style={{ rowGap: '0.5rem' }}>
                        <Col xl={'6'}>
                            <RightSelect
                                title={t('Owner')}
                                accessScope={'W'}
                                list={editing.filterInfoAccessRight.filter(v => v.accessScope === 'W')}
                                handleChange={changedList => {
                                    dispatch(
                                        updateFilter({
                                            filterInfoAccessRight: [
                                                ...editing.filterInfoAccessRight.filter(v => v.accessScope === 'R'),
                                                ...changedList,
                                            ],
                                        }),
                                    );
                                }}
                            />
                        </Col>
                        <Col xl={'6'}>
                            <RightSelect
                                title={t('Access rights')}
                                accessScope={'R'}
                                list={editing.filterInfoAccessRight.filter(v => v.accessScope === 'R')}
                                handleChange={changedList => {
                                    dispatch(
                                        updateFilter({
                                            filterInfoAccessRight: [
                                                ...editing.filterInfoAccessRight.filter(v => v.accessScope === 'W'),
                                                ...changedList,
                                            ],
                                        }),
                                    );
                                }}
                            />
                        </Col>
                    </Row>
                </div>
            </Card>
            <ConfirmSaveModal />
            <ValidationModal />
            <SaveSuccessModal />
            <SaveFailModal />
        </>
    );
};

export default EditView;
