import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
    alertList: [],
    activeAlertLength: 0,
    toastConfigValue: {
        useAlert: true,
        limitAlert: 5,
        autoClose: 5000,
    },
    checkedInterfaceCommandType: [],
    selectedAlertInfo: {},
};

const { actions, reducer } = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setAlert: {
            reducer: (state, action) => {
                if (state.checkedInterfaceCommandType.includes(action.payload.interfaceCommandType)) {
                    state.alertList.push(action.payload);
                }
            },
            prepare: ({ notificationLog: { targetName, interfaceCommandType, regDate, targetNum } }) => {
                const alertDate = moment(regDate * 1000).format('YYYY-MM-DD HH:mm:ss');
                return {
                    payload: {
                        targetName,
                        interfaceCommandType,
                        alertDate,
                        targetNum,
                    },
                };
            },
        },
        setChangeActiveAlertLength: (state, action) => {
            state.activeAlertLength += action.payload;
            if (action.payload === 1) {
                state.alertList.shift();
            }
        },
        setDeleteAlert: (state, action) => {
            state.alertList.shift();
        },
        setCheckedInterfaceCommandType: (state, action) => {
            state.checkedInterfaceCommandType = action.payload.reduce((acc, cur) => {
                if (cur.isChecked === 'Y') {
                    acc.push(cur.interfaceCommandType);
                }
                return acc;
            }, []);
        },
        setSelectedAlertInfo: (state, action) => {
            state.selectedAlertInfo = action.payload;
        },
    },
});

export const {
    setAlert,
    setChangeActiveAlertLength,
    setDeleteAlert,
    setCheckedInterfaceCommandType,
    setSelectedAlertInfo,
} = actions;
export default reducer;
