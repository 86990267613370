import React, { useEffect } from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';

const StyledSwitch = styled(Switch)`
    margin-left: 0.5rem;
    .react-switch-bg {
        border-radius: 7px !important;
    }

    .react-switch-handle {
        border-radius: 7px !important;
    }
`;

const CustomSwitch = ({ checked, onChange = () => {} }) => {
    return (
        <StyledSwitch
            onChange={onChange}
            checked={checked}
            borderRadius={1}
            uncheckedIcon={false}
            checkedIcon={false}
            offHandleColor={'#cccccc'}
            onHandleColor={'#fca558'}
            onColor={'#FF7800'}
            className={'ml-3'}
            uncheckedHandleIcon={<span className="icon-close icon-filter-white ml-1" />}
            checkedHandleIcon={<span className="icon-check icon-filter-white ml-1" />}
        />
    );
};

export default CustomSwitch;
