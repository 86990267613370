import React from 'react';
import { Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { setColorScheme } from '../../../../../reducers/ThemeOptions';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../../../../Common/ConfirmModal';
import Modal from '../../../../Common/Modal';

const ThemeSwatch = ({ className }) => {
    const dispatch = useDispatch();
    const { colorScheme } = useSelector(state => state.ThemeOptions);
    return (
        <div
            className={cx('swatch-holder cursor-pointer', className, { active: colorScheme === className })}
            onClick={() => {
                dispatch(setColorScheme(className));
            }}
        />
    );
};

const AppSettingModal = ({ initModal, toggleModal }) => {
    const { t } = useTranslation();
    return (
        <Modal
            initModal={initModal}
            toggleModal={toggleModal}
            headerTitle={t('UserBox;Application setting')}
            removeCancel
        >
            <div className={'modal-body'}>
                <Col>
                    <h6 className="pb-2">{t('UserBox;Please choose color scheme')}</h6>
                    <div className="theme-settings-swatches">
                        <ThemeSwatch className={'pnt-navy'} />
                        <ThemeSwatch className={'pnt-light'} />
                        <ThemeSwatch className={'pnt-dark'} />
                        <ThemeSwatch className={'pnt-flower'} />
                        <ThemeSwatch className={'pnt-plant'} />
                    </div>
                </Col>
            </div>
        </Modal>
    );
};

export default AppSettingModal;
