import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterSearchGroup from '../../../Common/FilterSearchGroup';
import Button from '../../../Common/Button';
import SingleSelect from '../../../Common/Select/SingleSelect';
import DatePicker from '../../../Common/DatePicker';
import {
    setSelectedCategory,
    setSelectedFloor,
    setStartDate,
    setEndDate,
    setSelectedFloorInfo,
} from '../congestionStatusReducer';
import { CongestionStatusContext, CongestionStatusDispatchContext } from '../index';
import { useSelector } from 'react-redux';
import FloorTreeSelect from '../../Components/FloorTreeSelect';
import moment from 'moment';
import FilterGroup from '../../../Common/FilterSearchGroup/Components/FilterGroup';
import SearchGroup from '../../../Common/FilterSearchGroup/Components/SearchGroup';
import SearchButtonArea from '../../../Common/FilterSearchGroup/Components/SearchButtonArea';
import { FilterList, SelectGroup } from '../../../Common/FilterSearchGroup/Components/Part';

const CongestionFilterGroup = ({ handleSearchClick }) => {
    const { t } = useTranslation();
    const [categoryListWithAllOption, setCategoryListWithAllOption] = useState([]);
    const { floorList } = useSelector(state => state.FloorInfo);
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const { selectedCategory, selectedFloor, startDate, endDate } = useContext(CongestionStatusContext);
    const dispatch = useContext(CongestionStatusDispatchContext);

    const handleSelectCategoryChange = e => {
        dispatch(setSelectedCategory(e));
    };

    const handleYesterdayBtnClick = () => {
        dispatch(setStartDate(moment().subtract(1, 'days').startOf('day').valueOf()));
        dispatch(setEndDate(moment().subtract(1, 'days').endOf('day').valueOf()));
    };

    const handleTodayBtnClick = () => {
        dispatch(setStartDate(moment().startOf('day').valueOf()));
        dispatch(setEndDate(moment().endOf('day').valueOf()));
    };

    const handleFloorChange = selects => {
        if (selects[0]) {
            dispatch(setSelectedFloor(selects[0].floorId));
            dispatch(setSelectedFloorInfo(selects[0]));
        }
    };

    useEffect(() => {
        setCategoryListWithAllOption([{ categoryCode: '', categoryName: 'All' }, ...categoryList]);
    }, [categoryList]);

    return (
        <FilterSearchGroup className={'card absolute-filter'}>
            <SearchGroup label={t('CongestionStatus;Filter')}>
                <SingleSelect
                    placeholder={'All'}
                    value={categoryListWithAllOption.length === 0 ? '' : selectedCategory}
                    onChange={handleSelectCategoryChange}
                    options={categoryListWithAllOption}
                    valueKey={'categoryCode'}
                    labelKey={'categoryName'}
                    listStyle={{ height: '200px', overflow: 'auto' }}
                />
                <FloorTreeSelect
                    key={selectedFloor}
                    floorList={floorList}
                    selectedFloorStr={selectedFloor}
                    mode={'radioSelect'}
                    handleChange={handleFloorChange}
                />
            </SearchGroup>
            <SearchGroup className={'bg-brand'} label={t('CongestionStatus;Search')}>
                <FilterList>
                    <SelectGroup>
                        <div className={'datePicker-container mr-2'}>
                            <DatePicker
                                value={startDate}
                                handleChange={selected => dispatch(setStartDate(selected))}
                                valueType={'ms'}
                                maxDate={endDate || moment().valueOf()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <div> ~ </div>
                    <SelectGroup>
                        <div className={'datePicker-container ml-2'}>
                            <DatePicker
                                value={endDate}
                                handleChange={selected => dispatch(setEndDate(selected))}
                                valueType={'ms'}
                                minDate={startDate}
                                maxDate={moment().valueOf()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                </FilterList>
                <SearchButtonArea>
                    <Button className={'btn-secondary btn-yesterday'} onClick={handleYesterdayBtnClick}>
                        {t('Button;Yesterday')}
                    </Button>
                    <Button className={'btn-secondary btn-yesterday'} onClick={handleTodayBtnClick}>
                        {t('Button;Today')}
                    </Button>
                    <Button className="pnt-btn btn-brand" onClick={handleSearchClick}>
                        {t('CongestionStatus;Search')}
                    </Button>
                </SearchButtonArea>
            </SearchGroup>
        </FilterSearchGroup>
    );
};
export default CongestionFilterGroup;
