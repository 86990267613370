import { generateKey } from '../index';

// recoil 상태값이 필요한 경우, 아래 객체에 필드 추가 후 import 해서 사용
// 필드명으로 키가 생성됨. 값은 객체 제외하고 아무값이나 넣어도 상관없음.
// 객체는 하위에 상태를 넣을 때 사용.
// 서비스 별로 Key 파일 생성 필요
//
// recoil atom, selector 정의 시, 키 속성으로 사용
// import rmsKey from '...';
// ...
//  key : rmsKey.xx.xx      // 'rmsKey.xx.xx'
// ...

// 한계점 : 객체 특성상 키가 중복이되면 하나의 값으로 덮어 씌우므로
// 이와 같은 방법으로 unique한 namespace를 만들어 사용하지만
// 두개의 값을 가질 수 없으므로 객체를 값으로 넣어서 새로운 namespace를 만들면
// 그 필드명으로 된 키는 사용할 수 없다.
export const rmsKey = {
    filter: {
        list: '',
        menuList: '',
        selectedOrigin: '',
        selected: '',
        filterParam: '',
        detailView: {
            editMode: '',
            origin: '',
            editing: '',
            writePermission: '',
        },
    },
};

generateKey('rms', rmsKey);

export default rmsKey;
