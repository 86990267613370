import React, { useEffect, useMemo, useState, useCallback, useContext } from 'react';
import Table from '../../../Common/Table';
import * as col from '../../util/grid/column/filter';
import { useHistory } from 'react-router-dom';
import Card from '../../../Common/Card';
import useTranslation from '../../../../util/hooks/useTranslation';
import useAsync from '../../../../util/hooks/useAsync';
import { copyFilterApi, getFilterListApi } from '../../../../api/filter';
import Search from './Search';
import Button from '../../../Common/Button';
import useColumns from '../../../../util/hooks/useColumns';
import Page from '../../../Common/Page';
import useConfirmModal from '../../../Common/ConfirmModal/useConfirmModal';
import { useRecoilState } from 'recoil';
import { filterList as storeFilterList } from '../../../../recoilStore/filter';
import { FilterStateContext } from '../index';
import { getAuthorityGroup } from '../reducer';

const tableHeight = { height: 'calc(100vh - 60px - 88px - 3rem - 60px - 4rem - 4px)' };
export const FILTER_LIST_PAGE_SIZE = 20;

const FilterList = () => {
    const [allFilterList, setAllFilterList] = useRecoilState(storeFilterList);
    const t = useTranslation('Filter');
    const history = useHistory();
    const { authority } = useContext(FilterStateContext);
    const [copyMode, setCopyMode] = useState(false);
    const [checkedFilter, setCheckedFilter] = useState('');
    const [filterList, setFilterList] = useState({ rows: [], pageSize: FILTER_LIST_PAGE_SIZE });

    const { Modal: CopyFilterSuccessModal, toggleModal: toggleCopyFilterSuccessModal } = useConfirmModal({
        header: { title: t('Copy Completed') },
        confirmText: t('Filter was copied successfully'),
        removeCancel: true,
    });
    const { Modal: CopyFilterFailModal, toggleModal: toggleCopyFilterFailModal } = useConfirmModal({
        header: { title: t('Request Fail') },
        confirmText: t('Please choose what to copy'),
        removeCancel: true,
    });

    const columns = useMemo(() => {
        const defaultColumns = [col.filterName(), col.owner({ t }), col.accessRights({ t })];
        if (copyMode) {
            defaultColumns.unshift({
                width: 50,
                accessor: 'checkBox',
                Cell: ({ row: { original } }) => {
                    const isChecked = original.filterNum === checkedFilter;
                    return (
                        <input
                            checked={isChecked}
                            disabled={checkedFilter && !isChecked}
                            type="checkbox"
                            name={'categoryCode'}
                            onChange={() => {}}
                            onClick={e => {
                                e.stopPropagation();
                                let checkedId = '';
                                if (e.currentTarget.checked) {
                                    checkedId = original.filterNum;
                                }
                                setCheckedFilter(checkedId);
                            }}
                        />
                    );
                },
            });
        }
        return defaultColumns;
    }, [checkedFilter, copyMode]);

    const filterColumns = useColumns(columns, t, [columns]);

    const handleCancelBtnClick = () => {
        setCopyMode(false);
    };
    const handleCopyBtnClick = () => {
        copyFilter({ filterNum: checkedFilter });
    };

    const handleTrClick = useCallback(
        selected => {
            if (!copyMode) {
                history.push(`/filter/${selected.filterNum}`);
            }
        },
        [copyMode, history],
    );

    const { promise: getFilterList, state: filterListInfo } = useAsync({
        promise: getFilterListApi,
        immediate: true,
        fixedParam: { pageSize: FILTER_LIST_PAGE_SIZE },
        resolve: res => {
            let tempFilterList = [];
            if (res.rows) {
                tempFilterList = res.rows.map(filter => {
                    const { accessRight, owner } = getAuthorityGroup(authority, filter);
                    return {
                        ...filter,
                        groupNamesForAccessRight: accessRight.map(v => (v.id === -1 ? v.id : v.name)).join(','),
                        groupNamesForOwner: owner.map(v => (v.id === -1 ? v.id : v.name)).join(','),
                    };
                });
            }
            setCheckedFilter('');
            setFilterList({ ...res, rows: tempFilterList, pageSize: FILTER_LIST_PAGE_SIZE });
        },
    });

    const { promise: getAllFilterList } = useAsync({
        promise: getFilterListApi,
        fixedParam: { isAll: 'Y' },
        resolve: ({ rows }) => {
            if (rows) {
                setAllFilterList(rows);
            }
        },
    });

    const { promise: copyFilter } = useAsync({
        promise: copyFilterApi,
        resolve: res => {
            toggleCopyFilterSuccessModal();
            setCheckedFilter('');
            setCopyMode(false);

            handlePageChange(1);
            getAllFilterList();
        },
        reject: res => {
            toggleCopyFilterFailModal();
        },
        deps: [filterListInfo],
    });

    const handlePageChange = pageIndex => {
        let requestParam = {};
        if (filterListInfo.request && filterListInfo.request.keyword) {
            requestParam = filterListInfo.request;
        }
        getFilterList({ ...requestParam, page: pageIndex });
    };

    useEffect(() => {
        if (authority.userList) {
            getFilterList();
        }
    }, [authority]);

    return (
        <Page menuPath={[t('Filters', 'Menu'), t('Filter List', 'Menu')]} className={'h-100'}>
            <Search fetchListData={getFilterList} />
            <Card
                header={{
                    title: (copyMode && `${t('Filter List')} - ${t('copy')}`) || t('Filter List'),
                    titleIcon: copyMode && 'icon-copy',
                    action: copyMode ? (
                        <>
                            <Button className="btn-gray btn-back" onClick={handleCancelBtnClick}>
                                {t('Cancel', 'Button')}
                            </Button>
                            <Button className="btn-secondary btn-icon" onClick={handleCopyBtnClick}>
                                <span className="icon-check" />
                                {t('Copy')}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button className="btn-icon btn-secondary" onClick={() => setCopyMode(true)}>
                                <span className="icon-copy" />
                                {t('Copy')}
                            </Button>
                            <Button
                                className="btn-icon btn-brand btn-long"
                                onClick={e => history.push('/filter/create')}
                            >
                                <span className="icon-plus" />
                                {t('Create Filter')}
                            </Button>
                        </>
                    ),
                }}
            >
                <div style={tableHeight}>
                    <Table
                        onPageChange={handlePageChange}
                        textAlign={'center'}
                        data={filterList}
                        columns={filterColumns}
                        onTrClick={handleTrClick}
                    />
                </div>
            </Card>
            <CopyFilterSuccessModal />
            <CopyFilterFailModal />
            {/*<ConfirmModal*/}
            {/*    header={{ title: t('ConfirmModal;Copy Completed') }}*/}
            {/*    confirmText={t('Filter;Filter was copied successfully')}*/}
            {/*    okCallback={() => {*/}
            {/*        setCopyFilterSuccessModal(!copyFilterSuccessModal);*/}
            {/*        setCheckedFilter('');*/}
            {/*        setCopyMode(false);*/}
            {/*    }}*/}
            {/*    toggleModal={() => {*/}
            {/*        setCopyFilterSuccessModal(!copyFilterSuccessModal);*/}
            {/*        setCheckedFilter('');*/}
            {/*        setCopyMode(false);*/}
            {/*    }}*/}
            {/*    initModal={copyFilterSuccessModal}*/}
            {/*    removeCancel*/}
            {/*/>*/}
            {/*<ConfirmModal*/}
            {/*    header={{ title: t('ConfirmModal;Request Fail') }}*/}
            {/*    confirmText={t('Filter;Please choose what to copy.')}*/}
            {/*    okCallback={() => setCopyFilterFailModal(!copyFilterFailModal)}*/}
            {/*    toggleModal={() => setCopyFilterFailModal(!copyFilterFailModal)}*/}
            {/*    initModal={copyFilterFailModal}*/}
            {/*    removeCancel*/}
            {/*/>*/}
        </Page>
    );
};

export default FilterList;
