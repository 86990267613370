import React, { useEffect, useState } from 'react';
import Select from 'react-dropdown-select';
import useTranslation from '../../../../../util/hooks/useTranslation';
import styled from 'styled-components';

const StyledSelect = styled(Select)`
    background-color: #fff;
    .react-dropdown-select-item {
        color: black !important;
    }
    .react-dropdown-select-content {
        span {
            color: black !important;
        }
    }
    & .react-dropdown-select-input {
        display: none;
    }
    & .react-dropdown-select-dropdown-handle {
        margin: 0 0 0 5px;
        svg {
            color: black !important;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;

const MapOptionSelect = ({ handleChange, value }) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const t = useTranslation('MapOptionSelect');
    const option = [
        { value: '', label: t('None') },
        { value: 'spaghettiDiagram', label: t('Spaghetti diagram') },
        { value: 'heatmap', label: t('Heatmap') },
    ];

    useEffect(() => {
        if (selectedValue.length > 0) {
            let foundOne = option.find(v => v.value === selectedValue[0].value);
            setSelectedValue([foundOne]);
        }
    }, [t]);

    return (
        <StyledSelect
            style={{ backgroundColor: 'white', width: '11rem' }}
            options={option}
            searchable={false}
            onChange={handleChange}
            values={selectedValue}
            placeholder={t('Select path type')}
        />
    );
};

export default React.memo(MapOptionSelect);
