import React, { useContext, useEffect, useMemo, Fragment, useState } from 'react';
import Card from '../../../Common/Card';
import useTranslation from '../../../../util/hooks/useTranslation';
import Table from '../../../Common/Table';
import useMonitor from '../../util/useMonitor';
import { fetchRealTimeSensorMonitoring, fetchSensingTypes } from '../../../../api/monitoring';
import { SensorStatusDispatchContext, SensorStatusStateContext } from '../index';
import {
    clearSensorStatusWidget,
    setSensorListParam,
    setSelectedSensor,
    setMonthlyPrintPopup,
    setSensingTypeOptions,
    setReportType,
} from '../sensorStatusReducer';
import useColumns from '../../../../util/hooks/useColumns';
import * as column from '../../../MainPages/util/grid/column';
import { useSelector } from 'react-redux';
import useAsync from '../../../../util/hooks/useAsync';
import { UncontrolledTooltip } from 'reactstrap';
import SearchGroup from '../../../Common/FilterSearchGroup/Components/SearchGroup';
import ReactSelect from '../../../Common/Select';
import { InputGroup, InputWrap, SearchWrap, SelectGroup } from '../../../Common/FilterSearchGroup/Components/Part';
import TextInput from '../../../Common/Input/TextInput';
import Button from '../../../Common/Button';
import SearchButtonArea from '../../../Common/FilterSearchGroup/Components/SearchButtonArea';
import FilterSearchGroup from '../../../Common/FilterSearchGroup';

const defaultData = {
    totalPage: 1,
    pageSize: 1,
    page: 1,
    totalCount: 1,
    rows: [
        {
            sensorNum: 451,
            comNum: 100003,
            sensorName: 'GAS2',
            sensorId: '00:13:43:73:AD:E3',
            modelName: 'Industrial Scientific > VentisPro',
            floorId: 'sse_test',
            lat: 37.51111530856609,
            lng: 127.05665794907381,
            lastDate: 0,
            modDate: 1542621523,
            regDate: 1542611573,
            itemCnt: 3,
            posMode: 'F',
            sensorItems: [
                {
                    sensorNum: 451,
                    sensingType: 'CO',
                    measurementUnit: '2',
                    currentValue: 0.0,
                    minRangeLimit: 1.0,
                    maxRangeLimit: 6.0,
                    minControlLimit: 3.0,
                    maxControlLimit: 4.0,
                    minRiskLimit: 2.0,
                    maxRiskLimit: 5.0,
                    status: 'OUTOFRANGE',
                    modDate: 1542674231,
                },
                {
                    sensorNum: 451,
                    sensingType: 'CO2',
                    measurementUnit: '1',
                    currentValue: 0.0,
                    minRangeLimit: 2.0,
                    maxRangeLimit: 7.0,
                    minControlLimit: 4.0,
                    maxControlLimit: 5.0,
                    minRiskLimit: 3.0,
                    maxRiskLimit: 6.0,
                    status: 'OUTOFRANGE',
                    modDate: 1542674231,
                },
                {
                    sensorNum: 451,
                    sensingType: 'H2S',
                    measurementUnit: '1',
                    currentValue: 0.0,
                    minRangeLimit: 1.0,
                    maxRangeLimit: 6.0,
                    minControlLimit: 3.0,
                    maxControlLimit: 4.0,
                    minRiskLimit: 2.0,
                    maxRiskLimit: 5.0,
                    status: 'OUTOFRANGE',
                    modDate: 1542674231,
                },
                {
                    sensorNum: 451,
                    sensingType: 'LEL',
                    measurementUnit: '5',
                    currentValue: 56.0,
                    minRangeLimit: 1.0,
                    maxRangeLimit: 6.0,
                    minControlLimit: 3.0,
                    maxControlLimit: 4.0,
                    minRiskLimit: 2.0,
                    maxRiskLimit: 5.0,
                    status: 'OUTOFRANGE',
                    modDate: 1542674231,
                },
            ],
            targetNum: 71,
            targetId: 'OMRON_2JCIE-BU01',
            targetName: 'OMRON_2JCIE-BU01',
            sensingTypes: 'CO,CO2,H2S,LEL,O2',
        },
    ],
};

// 센서 목록
const SensorList = ({ config }) => {
    const t = useTranslation('SensorStatus');
    const commonColumnT = useTranslation('CommonColumn');

    const searchOptions = useMemo(() => {
        return [
            { value: '', label: t('Search Option', 'Search') },
            { value: 'targetName', label: commonColumnT('Target Name') },
            { value: 'targetId', label: commonColumnT('Target ID') },
            { value: 'sensorId', label: commonColumnT('Sensor Mac Address') },
            { value: 'floorId', label: commonColumnT('Floor') },
        ];
    }, [t]);

    const { sensorListParam, sensingTypeOptions, monthlyPrintPopup } = useContext(SensorStatusStateContext);
    const dispatch = useContext(SensorStatusDispatchContext);

    const { mode } = useSelector(state => state.DashboardFrame);

    const [selectedSensingType, setSelectedSensingType] = useState(null);
    const [data, setData] = useState({ totalCount: 0, totalPage: 1, rows: [] });
    const [selectedOption, setSelectedOption] = useState(searchOptions[0]);
    const [searchInput, setSearchInput] = useState('');
    const [searchInputDisabled, setSearchInputDisabled] = useState(true);

    useAsync({
        promise: fetchSensingTypes,
        immediate: true,
        resolve: res => {
            const { rows } = res;
            dispatch(setSensingTypeOptions(rows));
        },
    });

    const columns = useColumns(
        [
            column.targetName({
                width: 100,
            }),
            column.targetId({
                Header: 'Target Id',
                width: 100,
            }),
            column.sensorMacAddress({
                width: 130,
            }),
            column.floor({
                width: 60,
            }),
            column.registeredDate({
                width: 100,
            }),
            column.sensorItems({
                Cell: ({ value, row: original }) => {
                    if (value && value.length && sensingTypeOptions.length) {
                        return value.map((sensorItem, index) => {
                            const {
                                currentValue,
                                measurementUnit,
                                status,
                                minRangeLimit,
                                minRiskLimit,
                                minControlLimit,
                                maxControlLimit,
                                maxRiskLimit,
                                maxRangeLimit,
                            } = sensorItem;
                            const sensingType = sensingTypeOptions.find(
                                sensingType => sensingType.sCD === sensorItem.sensingType,
                            );
                            if (sensingType) {
                                return (
                                    <Fragment key={sensingType.sCD + index + original.targetNum}>
                                        <div
                                            id={sensingType.sCD + index + original.targetNum}
                                            className={'pnt-txt txt-border txt-bold custom-action-btn'}
                                        >
                                            {sensingType.sName}
                                        </div>
                                        <UncontrolledTooltip
                                            hideArrow
                                            placement={'auto'}
                                            target={sensingType.sCD + index + original.targetNum}
                                        >
                                            <div>
                                                {t('currentValue')}: {currentValue}
                                            </div>
                                            <div>
                                                {t('measurementUnit')}: {measurementUnit}
                                            </div>
                                            <div>
                                                {t('status')}: {status ? t(status) : '-'}
                                            </div>
                                            <div>
                                                {t('minRangeLimit')}: {minRangeLimit}
                                            </div>
                                            <div>
                                                {t('minRiskLimit')}: {minRiskLimit}
                                            </div>
                                            <div>
                                                {t('minControlLimit')}: {minControlLimit}
                                            </div>
                                            <div>
                                                {t('maxControlLimit')}: {maxControlLimit}
                                            </div>
                                            <div>
                                                {t('maxRiskLimit')}: {maxRiskLimit}
                                            </div>
                                            <div>
                                                {t('maxRangeLimit')}: {maxRangeLimit}
                                            </div>
                                        </UncontrolledTooltip>
                                    </Fragment>
                                );
                            }
                        });
                    } else {
                        return <div>-</div>;
                    }
                },
            }),
        ],
        commonColumnT,
        [sensingTypeOptions],
    );

    const { monitor: sensorListMonitor, stop: sensorListMonitorStop } = useMonitor({
        config,
        defaultData,
        dynamicParam: sensorListParam,
        manualMonitor: true,
        fetchData: fetchRealTimeSensorMonitoring,
        makeData: ({ newData }) => {
            if (newData.rows) {
                setData(newData);
            }
        },
    });

    useEffect(() => {
        sensorListMonitorStop();
        if (sensorListParam.pageSize && mode === 'monitoring') {
            sensorListMonitor();
        }
        return () => {
            sensorListMonitorStop();
        };
    }, [sensorListParam]);

    const handleRefreshClick = () => {
        dispatch(clearSensorStatusWidget());

        setSelectedSensingType(null);
        setSelectedOption(searchOptions[0]);
        setSearchInputDisabled(true);
        setSearchInput('');
    };

    const handlePageChange = pageIndex => {
        dispatch(setSensorListParam({ page: pageIndex }));
    };

    const handleSearchClick = () => {
        let param = {};
        if (searchInput) {
            param.opt = selectedOption.value;
            param.keyword = searchInput;
        }
        if (selectedSensingType) {
            param.sensingTypes = selectedSensingType.sCD;
        }

        dispatch(setSensorListParam({ ...param }));
    };

    const handleSelectValueChange = value => {
        setSelectedOption(value);
        if (value.value !== '') {
            setSearchInputDisabled(false);
        } else {
            setSearchInputDisabled(true);
        }
    };

    const handleInputChange = e => {
        const { value } = e.target;
        setSearchInput(value);
    };

    const handleSensorClick = sensor => {
        dispatch(setSelectedSensor(sensor));
        dispatch(setReportType('daily'));
    };

    return (
        <>
            <Card
                header={{
                    title: t('Sensor List'),
                }}
            >
                <div className={'h-80'}>
                    <FilterSearchGroup label={t('Search', 'Search')}>
                        <SearchGroup className={'bg-brand'} label={t('Search', 'Search')}>
                            <div style={{ width: '10rem' }}>
                                <ReactSelect
                                    name={'sensingType'}
                                    placeholder={t('Select all')}
                                    value={selectedSensingType}
                                    options={sensingTypeOptions}
                                    onChange={selected => {
                                        setSelectedSensingType(selected);
                                    }}
                                    valueKey={'sCD'}
                                    labelKey={'sName'}
                                    customControlStyles={{ width: '100%' }}
                                    customMenuStyles={{ width: '100%' }}
                                    customOptionStyles={{ width: '100%' }}
                                />
                            </div>

                            <SearchWrap>
                                <SelectGroup>
                                    <ReactSelect
                                        name={'selectInput'}
                                        placeholder={t('Search Option', 'Search')}
                                        value={selectedOption}
                                        options={searchOptions}
                                        onChange={handleSelectValueChange}
                                        customControlStyles={{
                                            width: '100%',
                                        }}
                                        customMenuStyles={{ width: '100%' }}
                                        customOptionStyles={{ width: '100%' }}
                                    />
                                </SelectGroup>
                                <InputWrap>
                                    <InputGroup>
                                        <TextInput
                                            style={{ borderRadius: '5px' }}
                                            type={'text'}
                                            size={'60'}
                                            placeholder={t(
                                                'Please Enter your search keyword after selecting search option.',
                                                'Search',
                                            )}
                                            name={'name'}
                                            value={searchInput}
                                            handleChange={handleInputChange}
                                            disabled={searchInputDisabled}
                                        />
                                    </InputGroup>
                                </InputWrap>
                            </SearchWrap>
                            <SearchButtonArea>
                                <Button
                                    className={'btn-brand btn-icon'}
                                    iconClassName={'icon-search'}
                                    onClick={handleSearchClick}
                                >
                                    {t('Search', 'Search')}
                                </Button>
                                <Button
                                    className="btn-danger btn-icon-only"
                                    iconClassName={'icon-replay'}
                                    onClick={handleRefreshClick}
                                />
                            </SearchButtonArea>
                            {/*<div>*/}
                            {/*    <Button*/}
                            {/*        className={'btn-secondary'}*/}
                            {/*        onClick={() => dispatch(setMonthlyPrintPopup(!monthlyPrintPopup))}*/}
                            {/*    >*/}
                            {/*        {t('Monthly Report Print')}*/}
                            {/*    </Button>*/}
                            {/*</div>*/}
                        </SearchGroup>
                    </FilterSearchGroup>
                    <Table
                        onPageChange={handlePageChange}
                        onTrClick={handleSensorClick}
                        columns={columns}
                        data={{ ...data, pageSize: 15 }}
                    />
                </div>
            </Card>
        </>
    );
};

export default SensorList;
