import React from 'react';
import { Route } from 'react-router-dom';
import { ErrorHandleSwitch } from '../Components/Router';
import History from '../AlertMenu/History';

const HistoryMenu = ({ match }) => {
    return (
        <ErrorHandleSwitch>
            <Route exact path={`${match.path}/history`} component={History} />
        </ErrorHandleSwitch>
    );
};

export default HistoryMenu;
