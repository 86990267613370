import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    monitoringIntervalTime: 10000,
    serviceCategory: 'ASSET',
    serviceCode: 'at01',
};

const { reducer, actions } = createSlice({
    name: 'appInfo',
    initialState,
    reducers: {
        initAppInfo: (state, action) => {
            const appInfo = action.payload;
            for (const key in appInfo) {
                state[key] = appInfo[key];
            }
            if (!state.serviceCategory) {
                state.serviceCategory = 'ASSET';
            }
        },
    },
});

export const { initAppInfo } = actions;
export default reducer;
