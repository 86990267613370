import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    filterInfo: {
        categoryCodes: [],
        floorIds: [],
    },
    filterLayout: {
        static: true,
        x: 0,
        y: 0,
        w: 12,
        h: 3,
    },
};

const { actions, reducer } = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setFilter: (state, action) => {
            const filterInfo = action.payload;
            if (filterInfo) {
                for (let key in filterInfo) {
                    if (filterInfo.hasOwnProperty(key)) {
                        state.filterInfo[key] = filterInfo[key];
                    }
                }
            } else {
                const filterInfo = state.filterInfo;
                for (let key in filterInfo) {
                    if (filterInfo.hasOwnProperty(key)) {
                        state.filterInfo[key] = [];
                    }
                }
            }
        },
        setFilterLayout: (state, action) => {
            const filterLayout = action.payload;
            if (filterLayout) {
                state.filterLayout = filterLayout;
            }
        },
    },
});

export const { setFilter, setFilterLayout } = actions;
export default reducer;
