import React, { useMemo, useState, useContext, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import useTranslation from '../../../../../../util/hooks/useTranslation';
import ReactSelect from '../../../../../Common/Select';
import { FilterStateContext } from '../../../index';
import TextInput from '../../../../../Common/Input/TextInput';
import Button from '../../../../../Common/Button';
import Table from '../../../../../Common/Table';
import BlockUi from 'react-block-ui';
import useColumns from '../../../../../../util/hooks/useColumns';
import {
    userName,
    userID,
    userTeam,
    departmentName,
    departmentID,
    departmentPath,
} from '../../../../util/grid/column/filter';
import useDidMount from '../../../../../../util/hooks/useDidMount';

export const LIST_PAGE_SIZE = 20;

const TYPE_OPTION_DEPARTMENT = 'groupName';
const TYPE_OPTION_USER = 'userName';

const RightSelectModal = ({ title, accessScope, selectedList, isOpen, closeModal, okCallback }) => {
    const t = useTranslation('Filter');
    const buttonT = useTranslation('Button');
    const { authority } = useContext(FilterStateContext);
    const isMounted = useDidMount();

    const typeOptions = useMemo(() => {
        return [
            { value: TYPE_OPTION_DEPARTMENT, label: t('Department') },
            { value: TYPE_OPTION_USER, label: t('User') },
        ];
    }, [t]);
    const originType = selectedList[0] && typeof selectedList[0].userNum === 'number' ? typeOptions[1] : typeOptions[0];
    const [typeOption, setTypeOption] = useState(originType);
    const [gridList, setGridList] = useState({ rows: [], pageSize: LIST_PAGE_SIZE });
    const [checkedList, setCheckedList] = useState(selectedList);

    // 모든 사용자 선택 옵션
    const [selectAllUsers, setSelectAllUsers] = useState(
        selectedList[0] && (selectedList[0].userNum === -1 || selectedList[0].groupNum === -1),
    );

    const handleTypeChange = selected => {
        setTypeOption(selected);
    };

    const [keyword, setKeyword] = useState('');
    const handleInputChange = e => {
        setKeyword(e.currentTarget.value);
    };

    const handleSearchClick = () => {
        let newList = authority.userList;
        if (typeOption.value === TYPE_OPTION_DEPARTMENT) {
            newList = authority.groupList;
        }
        if (keyword) {
            newList = newList.filter(v => v[typeOption.value].toLowerCase().includes(keyword.toLowerCase()));
        }
        setGridList({
            pageSize: LIST_PAGE_SIZE,
            rows: newList,
            totalCount: newList.length,
            totalPage: Math.ceil(newList.length / LIST_PAGE_SIZE),
            page: 1,
        });
    };

    const checkColumn = useMemo(
        () => ({
            Header: ({}) => {
                // return (
                //     <input
                //         type="checkbox"
                //         checked={gridAllCheck}
                //         onClick={() => {
                //             setGridAllCheck(!gridAllCheck);
                //         }}
                //     />
                // );
                return null;
            },
            width: 50,
            accessor: 'checkBox',
            Cell: ({ row: { original } }) => {
                const isChecked = original.userNum
                    ? !!checkedList.find(v => v.userNum === original.userNum)
                    : !!checkedList.find(v => v.groupNum === original.groupNum);
                let thisValue = { key: 'userNum', value: original.userNum };
                if (typeOption.value === TYPE_OPTION_DEPARTMENT) {
                    thisValue = { key: 'groupNum', value: original.groupNum };
                }
                return (
                    <input
                        checked={isChecked}
                        type="checkbox"
                        // onChange={() => {}}
                        onChange={e => {
                            e.stopPropagation();
                            const checked = e.currentTarget.checked;
                            if (checked) {
                                setCheckedList([...checkedList, { [thisValue.key]: thisValue.value, accessScope }]);
                            } else {
                                setCheckedList(checkedList.filter(v => v[thisValue.key] !== thisValue.value));
                            }
                        }}
                    />
                );
            },
        }),
        [checkedList],
    );

    const columns = useMemo(() => {
        let col = [checkColumn, userName(), userID(), userTeam()];
        if (typeOption.value === TYPE_OPTION_DEPARTMENT) {
            col = [checkColumn, departmentName(), departmentID(), departmentPath()];
        }
        return col;
    }, [typeOption, checkedList]);

    const userListColumns = useColumns(columns, t, [typeOption, checkedList]);

    useEffect(() => {
        let list = authority.userList;
        if (typeOption.value === TYPE_OPTION_DEPARTMENT) {
            list = authority.groupList;
        }
        setKeyword('');
        setGridList({
            pageSize: LIST_PAGE_SIZE,
            rows: list,
            totalCount: list.length,
            totalPage: Math.ceil(list.length / LIST_PAGE_SIZE),
            page: 1,
        });
        if (isMounted && !selectAllUsers) {
            setCheckedList([]);
        }
    }, [typeOption]);

    return (
        <>
            <Modal
                className={'pnt-modal'}
                isOpen={isOpen}
                container={document.getElementsByClassName('app-container')[0]}
            >
                <ModalHeader toggle={closeModal}>
                    <span className={'styled-modal-title'}>{`${title} - ${t('Search for department or user')}`}</span>
                </ModalHeader>
                <ModalBody>
                    <div className="d-flex mb-4">
                        <ReactSelect
                            // placeholder={t('Department')}
                            name={'type'}
                            value={typeOption}
                            options={typeOptions}
                            onChange={handleTypeChange}
                            customControlStyles={{
                                marginRight: '0.5rem',
                                fontWeight: 'bold',
                                fontSize: '0.8rem',
                                width: '150px',
                            }}
                            customOptionStyles={{ fontWeight: 'bold', fontSize: '0.8rem', width: '150px' }}
                        />
                        <TextInput
                            name={'keyword'}
                            placeholder={t('Input keyword')}
                            handleChange={handleInputChange}
                            value={keyword}
                        />
                        <Button
                            className={'btn-secondary btn-icon-only ml-2'}
                            onClick={handleSearchClick}
                            iconClassName={'icon-search'}
                        />
                    </div>
                    <div style={{ height: '400px' }}>
                        <label className="pnt-checkbox-label">
                            <input
                                checked={selectAllUsers}
                                type="checkbox"
                                id="zone"
                                onChange={e => {
                                    setSelectAllUsers(e.target.checked);
                                    if (e.target.checked) {
                                        setCheckedList([{ userNum: -1, groupNum: -1, accessScope }]);
                                    } else {
                                        setCheckedList([]);
                                    }
                                }}
                            />
                            {t('Select all users')}
                        </label>
                        <BlockUi
                            tag={'div'}
                            blocking={selectAllUsers}
                            style={{ height: '350px' }}
                            className={'cursor-not-allowed'}
                        >
                            {/*    <BlockUi*/}
                            {/*        tag={'div'}*/}
                            {/*        blocking={oneItemSelected}*/}
                            {/*        style={{ height: '350px' }}*/}
                            {/*        className={'change-cursor'}*/}
                            {/*        loader={*/}
                            {/*            <div*/}
                            {/*                className={*/}
                            {/*                    'd-flex justify-content-center align-items-center block-ui-message-style'*/}
                            {/*                }*/}
                            {/*            >*/}
                            {/*                <span>{t('User only can choose either user or department')}</span>*/}
                            {/*            </div>*/}
                            {/*        }*/}
                            {/*    >*/}
                            {/*        <Table*/}
                            {/*            textAlign={'center'}*/}
                            {/*            columns={typeOption.value === TYPE_OPTION_DEPARTMENT ? departmentListColumns : userListColumns}*/}
                            {/*            data={typeOption.value === TYPE_OPTION_DEPARTMENT ? departmentData : userData}*/}
                            {/*        />*/}
                            {/*    </BlockUi>*/}
                            <Table
                                key={JSON.stringify(gridList)}
                                columns={userListColumns}
                                data={gridList}
                                manualPagination={false}
                                autoResetPage={false}
                                onTrClick={row => {
                                    const { userNum, groupNum } = row;
                                    const isChecked = userNum
                                        ? !!checkedList.find(v => v.userNum === userNum)
                                        : !!checkedList.find(v => v.groupNum === groupNum);
                                    let thisValue = { key: 'userNum', value: userNum };
                                    if (typeOption.value === TYPE_OPTION_DEPARTMENT) {
                                        thisValue = { key: 'groupNum', value: groupNum };
                                    }
                                    if (!isChecked) {
                                        setCheckedList([
                                            ...checkedList,
                                            { [thisValue.key]: thisValue.value, accessScope },
                                        ]);
                                    } else {
                                        setCheckedList(checkedList.filter(v => v[thisValue.key] !== thisValue.value));
                                    }
                                }}
                            />
                        </BlockUi>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn-gray mr-1 " onClick={closeModal}>
                        {buttonT('Close')}
                    </Button>
                    <Button
                        className={`btn-icon btn-brand`}
                        onClick={() => {
                            // handleClick();
                            okCallback(checkedList);
                            closeModal();
                        }}
                    >
                        <span className="icon-check" />
                        {buttonT('Ok')}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default RightSelectModal;
