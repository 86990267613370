import React, { useRef, useState } from 'react';
import Table from '../../../Common/Table';
import Card from '../../../Common/Card';
import * as column from '../../util/grid/column';
import Search from './Components/Search';
import useAsync from '../../../../util/hooks/useAsync';
import { fetchExcelLogExport, fetchLocationLogList } from '../../../../api/log';
import LocationPopup from './Components/LocationPopup';
import { useRouteMatch } from 'react-router-dom';
import { fetchFloorList } from '../../../../api/common';
import { setFloorList } from '../../../../reducers/Common/FloorInfo';
import { useDispatch } from 'react-redux';
import useColumns from '../../../../util/hooks/useColumns';
import useCustomTranslation from '../../../../util/hooks/useTranslation';
import useTranslation from '../../../../util/hooks/useTranslation';
import Page from '../../../Common/Page';
import Button from '../../../Common/Button';
import useConfirmModal from '../../../Common/ConfirmModal/useConfirmModal';

const LocationLog = () => {
    const t = useTranslation('Location Log');
    const columnT = useCustomTranslation('CommonColumn');
    const dispatch = useDispatch();
    const [selectedLog, setSelectedLog] = useState({});
    const searchRef = useRef();

    const match = useRouteMatch();

    useAsync({
        promise: fetchFloorList,
        resolve: response => {
            dispatch(setFloorList(response));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });

    const handleLatLngClick = log => {
        setSelectedLog(log);
        setPopup(!popup);
    };

    const columns = useColumns([
        column.number(),
        column.targetName(),
        column.deviceName(),
        column.floor(),
        column.location({
            handleLatLngClick,
            t: columnT,
            width: 350,
        }),
        column.registeredDate(),
    ]);

    const [locationLogData, setLocationLogData] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });
    const [popup, setPopup] = useState(false);

    const { promise: getLocationLogList, state: locationLogListInfo } = useAsync({
        promise: fetchLocationLogList,
        keepState: true,
        immediate: true,
        fixedParam: { pageSize: 15 },
        resolve: res => {
            const data = res.rows.map(log => {
                return {
                    logNum: log.logNum,
                    targetName: log.targetName,
                    tagName: log.tagName,
                    floorId: log.floorId,
                    latLng: [log.lat, log.lng],
                    regDate: log.regDate,
                    ...log,
                };
            });

            setLocationLogData({ ...res, rows: data });
        },
    });

    const { promise: createNewLogFile } = useAsync({
        promise: fetchExcelLogExport,
        fixedParam: {
            zipFileName: 'Location_Log',
            fileName: 'Location_Log',
            columnMetas: [
                {
                    key: 'logNum',
                    name: '로그 번호',
                },
                {
                    key: 'targetName',
                    name: '대상 이름',
                },
                {
                    key: 'beaconName',
                    name: '디바이스 이름',
                },
                {
                    key: 'floor',
                    name: '층',
                },
                {
                    key: 'lat',
                    name: '위도',
                },
                {
                    key: 'lng',
                    name: '경도',
                },
            ],
        },
        resolve: res => {
            toggleExcelModal();
        },
        reject: res => {
            console.log(res);
        },
    });

    const handlePageChange = pageIndex => {
        if (
            locationLogListInfo.request &&
            (locationLogListInfo.request.keyword ||
                locationLogListInfo.request.startDate ||
                locationLogListInfo.request.endDate)
        ) {
            getLocationLogList({ ...locationLogListInfo.request, page: pageIndex });
        } else {
            getLocationLogList({ page: pageIndex });
        }
    };

    const handleToggleModal = () => {
        setPopup(!popup);
    };

    const handleExportFileClick = () => {
        const { request } = locationLogListInfo;

        delete request.page;
        delete request.pageSize;

        request.isAll = 'Y';

        createNewLogFile({ ...request });
    };

    const { toggleModal: toggleExcelModal, Modal: ExcelModal } = useConfirmModal({
        initModal: false,
        header: {
            title: t('Excel Download', 'Location Log List'),
        },
        confirmText: t(
            'You can see and download the Excel File that you have requested under `Report > Location Log List` menu.',
            'Location Log List',
        ),
        removeCancel: true,
    });

    return (
        <Page menuPath={[t('Report'), t('Location Log')]} className={'h-100'}>
            <Search getLocationLogList={getLocationLogList} />
            <Card
                innerRef={searchRef}
                className={'report-container'}
                header={{
                    title: t('Location Log List'),
                    action: (
                        <Button className="btn-secondary" onClick={handleExportFileClick}>
                            {t('Export', 'Button')}
                        </Button>
                    ),
                }}
            >
                <div style={{ height: '35rem' }}>
                    <Table
                        data={{ ...locationLogData, pageSize: 15 }}
                        columns={columns}
                        onPageChange={handlePageChange}
                        loading={locationLogListInfo.isLoading}
                    />
                </div>

                <LocationPopup
                    modal={popup}
                    toggleModal={handleToggleModal}
                    selectedLog={selectedLog}
                    searchRef={searchRef}
                />
            </Card>

            <ExcelModal />
        </Page>
    );
};

export default LocationLog;
