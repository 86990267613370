import React from 'react';
import { FeatureGroup, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const PopupDiv = styled.div`
    width: 150px;
    height: 135px;
    font-size: 0.8rem;
`;

const PopupBtn = styled.button`
    width: 70px;
    height: 30px;
    margin-top: 10px;
    float: right;
    border: none;
    background-color: #3e66fb;
    color: white;
    border-radius: 3px;
    font-size: 0.8rem;
`;

const WrappedLabel = styled.div`
    min-height: 30px;
`;

const MarkerLayer = ({ markerList, handleViewBtnClick }) => {
    const { t } = useTranslation();
    return (
        <FeatureGroup>
            {markerList &&
                markerList.length > 0 &&
                markerList.map(marker => {
                    let markerName;
                    if (marker.state.lostSignal === 'LOSTSIGNAL_ON') {
                        markerName = 'lostSignal';
                    } else {
                        markerName = 'simple';
                    }
                    return (
                        <Marker
                            key={marker.beacon.beaconNum}
                            position={{ lat: marker.location.lat, lng: marker.location.lng }}
                            icon={L.divIcon({
                                className: `${markerName}-marker`,
                                html: `<div></div>`,
                            })}
                        >
                            <Popup>
                                <PopupDiv>
                                    <WrappedLabel className="pnt-label--group">
                                        <span
                                            className="label-main label-dot mr-1 text-ellipsis"
                                            title={marker.target.categoryName}
                                        >
                                            {marker.target.categoryName}
                                        </span>
                                    </WrappedLabel>
                                    <WrappedLabel className="pnt-label--group">
                                        <span
                                            className="label-main label-dot text-ellipsis"
                                            title={marker.target.targetName}
                                        >
                                            {marker.target.targetName}
                                        </span>
                                    </WrappedLabel>
                                    <WrappedLabel className="pnt-label--group">
                                        <span
                                            className="label-main label-dot text-ellipsis"
                                            title={marker.location.floor}
                                        >
                                            {marker.location.floor}
                                        </span>
                                    </WrappedLabel>
                                    <PopupBtn
                                        onClick={() => {
                                            handleViewBtnClick(marker);
                                        }}
                                    >
                                        {t('View')}
                                    </PopupBtn>
                                </PopupDiv>
                            </Popup>
                        </Marker>
                    );
                })}
        </FeatureGroup>
    );
};

export default MarkerLayer;
