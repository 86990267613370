import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import useTranslation from '../../../../util/hooks/useTranslation';
import FilterSearchGroup from '../../../Common/FilterSearchGroup';
import SearchGroup from '../../../Common/FilterSearchGroup/Components/SearchGroup';
import SearchButtonArea from '../../../Common/FilterSearchGroup/Components/SearchButtonArea';
import Button from '../../../Common/Button';
import { SelectGroup } from '../../../Common/FilterSearchGroup/Components/Part';
import DatePicker from '../../../Common/DatePicker';
import ReactSelect from '../../../Common/Select';
import { SensorStatusDispatchContext, SensorStatusStateContext } from '../index';
import useAsync from '../../../../util/hooks/useAsync';
import { fetchRealTimeSensorMonitoring } from '../../../../api/monitoring';
import Table from '../../../Common/Table';
import useColumns from '../../../../util/hooks/useColumns';
import * as column from '../../../MainPages/util/grid/column';
import Label from '../../../Common/Label';
import styled from 'styled-components';
import TextInput from '../../../Common/Input/TextInput';
import { setMonthlyPrintPopup } from '../sensorStatusReducer';

const PrintSettingTableContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, auto));
    grid-auto-rows: 440px;
    grid-gap: 10px;

    & .pnt-label--group {
        align-self: center;
    }
`;

// 센서 목록창에서 월별 리포트 인쇄를 눌렀을 때 나오는 셋팅 창
const MonthlyReportPrintSettingPopup = ({ modal, cardRef }) => {
    const t = useTranslation('SensorStatus');

    const { sensingTypeOptions, monthlyPrintPopup } = useContext(SensorStatusStateContext);
    const dispatch = useContext(SensorStatusDispatchContext);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedSensingType, setSelectedSensingType] = useState(null);
    const [position, setPosition] = useState({
        x: 0,
        y: 0,
    });
    const [popupSize, setPopupSize] = useState({
        width: 0,
        height: 0,
    });
    const [sensorList, setSensorList] = useState({ rows: [], totalPage: 1, totalCount: 0 });
    const [selectedSensorList, setSelectedSensorList] = useState({ rows: [], totalPage: 1, totalCount: 0 });
    const [nonSelectedSensorList, setNonSelectedSensorList] = useState({ rows: [], totalPage: 1, totalCount: 0 });
    const [input, setInput] = useState({
        nonPrint: '',
        print: '',
    });
    const [inputFilteredList, setInputFilteredList] = useState({
        nonPrint: [],
        print: [],
    });

    const searchOptions = useMemo(() => {
        return [
            { value: 'targetName', label: t('Target Name', 'CommonColumn') },
            { value: 'targetId', label: t('Target Id', 'CommonColumn') },
        ];
    }, [t]);

    const [selectedNonPrintSearchOption, setSelectedNonPrintSearchOption] = useState(searchOptions[0]);
    const [selectedPrintSearchOption, setSelectedPrintSearchOption] = useState(searchOptions[0]);

    const popupRef = useCallback(node => {
        if (node !== null) {
            const { width, height } = node.getBoundingClientRect();

            setPopupSize({
                width,
                height,
            });
        }
    }, []);

    const columns = useColumns([
        column.targetName({
            width: 100,
        }),
        column.targetId({}),
        column.remarks({
            width: 100,
            accessor: 'type',
            Cell: ({ value, row: { original } }) => {
                if (value === 'nonSelected') {
                    return (
                        <Button
                            onClick={() => handleAddAndRemoveButtonClick(original)}
                            className={'btn-brand table-btn'}
                        >
                            {t('Select')}
                        </Button>
                    );
                } else {
                    return (
                        <Button
                            onClick={() => handleAddAndRemoveButtonClick(original)}
                            className={'btn-brand table-btn'}
                        >
                            {t('Release')}
                        </Button>
                    );
                }
            },
        }),
    ]);

    useEffect(() => {
        const widgetPosition = getWidgetPosition();

        const x = (widgetPosition.width - popupSize.width) / 2 - 20;
        const y = -widgetPosition.height + 60;
        setPosition({
            x: x,
            y: y,
        });
    }, [popupSize]);

    useEffect(() => {
        if (sensingTypeOptions.length) {
            setSelectedSensingType(sensingTypeOptions[0]);
            getSensorList({ sensingTypes: sensingTypeOptions[0].sCD });
        }
    }, [sensingTypeOptions]);

    const { promise: getSensorList, state: sensorListInfo } = useAsync({
        promise: fetchRealTimeSensorMonitoring,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            setSensorList(res);
            setNonSelectedSensorList({
                ...res,
                rows: res.rows.map(list => {
                    return {
                        ...list,
                        type: 'nonSelected',
                    };
                }),
            });
            setSelectedSensorList({ rows: [], totalPage: 1, totalCount: 0 });
        },
    });

    const getWidgetPosition = () => {
        const widgetPosition = { x: 0, y: 0 };
        if (cardRef.current) {
            const searchContainer = cardRef.current;
            const { offsetWidth: width, offsetHeight: height } = searchContainer;

            widgetPosition.width = width;
            widgetPosition.height = height;
        }
        return widgetPosition;
    };

    const handleSearchClick = () => {
        if (selectedSensingType) {
            getSensorList({ sensingTypes: selectedSensingType.sCD });
        }
    };

    const handleAllRowsClick = e => {
        const { title } = e.target;

        if (title === 'select') {
            setNonSelectedSensorList({ rows: [], totalPage: 1, totalCount: 0 });
            setSelectedSensorList({
                ...sensorList,
                rows: sensorList.rows.map(list => {
                    return {
                        ...list,
                        type: 'selected',
                    };
                }),
            });
        } else {
            setNonSelectedSensorList({
                ...sensorList,
                rows: sensorList.rows.map(list => {
                    return {
                        ...list,
                        type: 'nonSelected',
                    };
                }),
            });
            setSelectedSensorList({ rows: [], totalPage: 1, totalCount: 0 });
        }
    };

    const handlePrintButtonClick = () => {};

    const handleAddAndRemoveButtonClick = selected => {
        const { type } = selected;

        if (type === 'nonSelected') {
            setNonSelectedSensorList(prev => {
                return {
                    ...prev,
                    rows: prev.rows.filter(list => list.sensorNum !== selected.sensorNum),
                };
            });
            setSelectedSensorList(prev => {
                return {
                    ...prev,
                    rows: [...prev.rows, { ...selected, type: 'selected' }],
                };
            });
        } else {
            setNonSelectedSensorList(prev => {
                return {
                    ...prev,
                    rows: [...prev.rows, { ...selected, type: 'nonSelected' }],
                };
            });
            setSelectedSensorList(prev => {
                return {
                    ...prev,
                    rows: prev.rows.filter(list => list.sensorNum !== selected.sensorNum),
                };
            });
        }
    };

    const handleListFilter = e => {
        const { value, name } = e.currentTarget;

        if (name === 'nonPrint') {
            setInput(prev => {
                return {
                    ...prev,
                    nonPrint: value,
                };
            });
            setInputFilteredList(prev => {
                return {
                    ...prev,
                    nonPrint: nonSelectedSensorList.rows.filter(sensorList => {
                        if (selectedNonPrintSearchOption.value === 'targetName') {
                            return sensorList.targetName.includes(value);
                        } else {
                            return sensorList.targetId.includes(value);
                        }
                    }),
                };
            });
        } else {
            setInput(prev => {
                return {
                    ...prev,
                    print: value,
                };
            });
            setInputFilteredList(prev => {
                return {
                    ...prev,
                    print: selectedSensorList.rows.filter(sensorList => {
                        if (selectedNonPrintSearchOption.value === 'targetName') {
                            return sensorList.targetName.includes(value);
                        } else {
                            return sensorList.targetId.includes(value);
                        }
                    }),
                };
            });
        }
    };

    return (
        <Draggable key={JSON.stringify(position)} bounds=".sensor-status-container" defaultPosition={position}>
            <Toast isOpen={modal} className={'popup-open'} innerRef={popupRef}>
                <ToastHeader toggle={() => dispatch(setMonthlyPrintPopup(!monthlyPrintPopup))}>
                    {t('Monthly Report Print Setting')}
                </ToastHeader>
                <ToastBody>
                    <div>
                        <FilterSearchGroup>
                            <SearchGroup className={'bg-brand'} label={t('Search', 'Search')}>
                                <SelectGroup>
                                    <DatePicker
                                        value={startDate}
                                        handleChange={selected => setStartDate(selected)}
                                        valueType={'ms'}
                                        maxDate={endDate || new Date()}
                                        withoutTime
                                    />
                                </SelectGroup>
                                <SelectGroup>
                                    <DatePicker
                                        value={endDate}
                                        handleChange={selected => setEndDate(selected)}
                                        valueType={'ms'}
                                        minDate={startDate}
                                        maxDate={new Date()}
                                        withoutTime
                                    />
                                </SelectGroup>
                                <SelectGroup>
                                    <ReactSelect
                                        name={'sensingType'}
                                        placeholder={t('Select all')}
                                        value={selectedSensingType}
                                        options={sensingTypeOptions}
                                        onChange={selected => {
                                            setSelectedSensingType(selected);
                                        }}
                                        valueKey={'sCD'}
                                        labelKey={'sName'}
                                        customControlStyles={{ width: '100%' }}
                                        customMenuStyles={{ width: '100%' }}
                                        customOptionStyles={{ width: '100%' }}
                                    />
                                </SelectGroup>
                                <SearchButtonArea>
                                    <Button
                                        className={'btn-brand btn-icon'}
                                        iconClassName={'icon-search'}
                                        onClick={handleSearchClick}
                                    >
                                        {t('Search', 'Search')}
                                    </Button>
                                </SearchButtonArea>
                            </SearchGroup>
                        </FilterSearchGroup>

                        <PrintSettingTableContainer className={'flx-full'}>
                            <div className={'flx-col'}>
                                <Label name={t('Non-Print Sensor')} />
                                <div className={'flx-sb'}>
                                    <ReactSelect
                                        name={'searchOption'}
                                        value={selectedNonPrintSearchOption}
                                        options={searchOptions}
                                        onChange={selected => setSelectedNonPrintSearchOption(selected)}
                                    />
                                    <TextInput
                                        inputGroupClassName={'w-100 ml-2'}
                                        name={'nonPrint'}
                                        placeholder={t('Search Sensor')}
                                        handleChange={handleListFilter}
                                        value={input.nonPrint}
                                    />
                                </div>
                                <Table
                                    columns={columns}
                                    data={{
                                        ...nonSelectedSensorList,
                                        rows:
                                            input.nonPrint || inputFilteredList.nonPrint.length
                                                ? inputFilteredList.nonPrint
                                                : nonSelectedSensorList.rows,
                                    }}
                                    paging={false}
                                    loading={sensorListInfo.isLoading}
                                />
                                <div>
                                    <Button
                                        title={'select'}
                                        className={'btn-brand btn-full w-100'}
                                        onClick={e => handleAllRowsClick(e)}
                                    >
                                        {t('Select all')}
                                    </Button>
                                </div>
                            </div>

                            <div className={'flx-col'}>
                                <Label name={t('Print Sensor')} />
                                <div className={'flx-sb'}>
                                    <ReactSelect
                                        name={'searchOption'}
                                        value={selectedPrintSearchOption}
                                        options={searchOptions}
                                        onChange={selected => setSelectedPrintSearchOption(selected)}
                                    />
                                    <TextInput
                                        inputGroupClassName={'w-100 ml-2'}
                                        name={'print'}
                                        placeholder={t('Search Sensor')}
                                        handleChange={handleListFilter}
                                        value={input.print}
                                    />
                                </div>
                                <div className={'h-100'}>
                                    <Table
                                        columns={columns}
                                        data={{
                                            ...selectedSensorList,
                                            rows:
                                                inputFilteredList.print.length || input.print
                                                    ? inputFilteredList.print
                                                    : selectedSensorList.rows,
                                        }}
                                        paging={false}
                                    />
                                </div>
                                <div>
                                    <Button
                                        title={'release'}
                                        className={'btn-brand btn-full w-100'}
                                        onClick={e => handleAllRowsClick(e)}
                                    >
                                        {t('Release all')}
                                    </Button>
                                </div>
                            </div>
                        </PrintSettingTableContainer>

                        <div className={'flx-center-center mt-2'}>
                            <Button className={'btn-secondary'} onClick={handlePrintButtonClick}>
                                {t('Print')}
                            </Button>
                        </div>
                    </div>
                </ToastBody>
            </Toast>
        </Draggable>
    );
};

export default React.memo(MonthlyReportPrintSettingPopup);
