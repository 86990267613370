import React from 'react';
import { useTranslation } from 'react-i18next';
import SensingFilter from './SensingFilter';
import FilterSearchGroup from '../../../Common/FilterSearchGroup';
import FilterGroup from '../../../Common/FilterSearchGroup/Components/FilterGroup';
import FilterButtonArea from '../../../Common/FilterSearchGroup/Components/FilterButtonArea';

const SensorType = () => {
    const { t } = useTranslation();

    return (
        <FilterSearchGroup className={'card absolute-filter'}>
            <FilterGroup className={'bg-brand'} label={t('AssetCountStatus;Search')}>
                <SensingFilter />
                <FilterButtonArea />
            </FilterGroup>
        </FilterSearchGroup>
    );
};
export default SensorType;
