import React, { forwardRef, useRef, useMemo } from 'react';
import 'leaflet/dist/leaflet.css';
import '../../../../assets/main/map.scss';
import { LayersControl, Map as LeafletMap, ZoomControl, FeatureGroup } from 'react-leaflet';
import Control from 'react-leaflet-control';
import GTileLayer from '../Layers/GTileLayer';
import ResizeDetector from 'react-resize-detector';
import GeofenceLayer from '../Layers/GeofenceLayer';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';
import { defaultOptions } from '../config';
import { useSelector } from 'react-redux';

const Map = (
    {
        tile = true,
        children,
        customLayers,
        onMoveend,
        onZoomstart,
        onZoomend,
        geofenceList,
        handleGeofenceClick,
        ...restProps
    },
    ref,
) => {
    const { t } = useTranslation();
    const mapRef = useRef();
    const resizeTO = useRef();
    const handleResize = () => {
        if (resizeTO.current) {
            clearTimeout(resizeTO.current);
            resizeTO.current = null;
        }
        resizeTO.current = setTimeout(() => {
            if (mapRef.current) {
                mapRef.current.leafletElement.invalidateSize();
            }
            resizeTO.current = null;
        }, 200);
    };
    const { lang, userInfo } = useSelector(state => state.UserInfo);
    const mapDefaultCenter = useMemo(() => {
        let center = defaultOptions.center;
        const { companyInfo } = userInfo;
        if (companyInfo && companyInfo.lat) {
            center = [companyInfo.lat, companyInfo.lng];
        }
        return center;
    }, [userInfo]);

    return (
        <ResizeDetector handleWidth handleHeight onResize={handleResize}>
            <div
                style={{ width: '100%', height: '100%' }}
                onMouseDown={e => {
                    e.stopPropagation();
                }}
            >
                <LeafletMap
                    {...defaultOptions}
                    center={mapDefaultCenter}
                    onClick={e => {
                        // 위경도 확인용. 지우지 마세요.
                        console.log(e);
                    }}
                    onMoveEnd={onMoveend}
                    onZoomstart={onZoomstart}
                    onZoomend={onZoomend}
                    ref={reference => {
                        if (ref) {
                            ref.current = reference;
                        }
                        mapRef.current = reference;
                    }}
                    whenReady={({ target }) => {
                        L.DomEvent.disableScrollPropagation(target._controlContainer);
                    }}
                    {...restProps}
                >
                    <FeatureGroup>
                        <ZoomControl
                            position="bottomright"
                            key={'zoom-control-' + lang}
                            zoomInTitle={t('Zoom in')}
                            zoomOutTitle={t('Zoom out')}
                        />
                        <Control
                            key={'aim-control-' + lang}
                            position="bottomright"
                            className="leaflet-bar custom-leaflet-control"
                        >
                            <a
                                href={'#;'}
                                role="button"
                                onClick={e => {
                                    e.preventDefault();
                                    const map = mapRef.current.leafletElement;
                                    if (map) {
                                        if (map.hasLayer(L.ImageOverlay)) {
                                            map.eachLayer(layer => {
                                                if (layer instanceof L.ImageOverlay) {
                                                    map.fitBounds(layer.getBounds());
                                                    return false;
                                                }
                                            });
                                        } else {
                                            map.eachLayer(layer => {
                                                if (layer instanceof L.Marker) {
                                                    map.setView([layer._latlng.lat, layer._latlng.lng]);
                                                    return false;
                                                }
                                            });
                                        }
                                    }
                                }}
                            >
                                <i className="fas fa-crosshairs" />
                            </a>
                        </Control>
                    </FeatureGroup>
                    {tile && <GTileLayer />}
                    {false && (
                        <LayersControl position="topright">
                            {/*{!tile && (*/}
                            {/*    <LayersControl.Overlay name="Map" checked={false}>*/}
                            {/*        <GTileLayer />*/}
                            {/*    </LayersControl.Overlay>*/}
                            {/*)}*/}
                            <LayersControl.Overlay name="Geofence">
                                <GeofenceLayer geofenceList={geofenceList} handleClick={handleGeofenceClick} />
                            </LayersControl.Overlay>
                            {customLayers}
                        </LayersControl>
                    )}
                    {children}
                </LeafletMap>
            </div>
        </ResizeDetector>
    );
};

export default forwardRef(Map);
