import React from 'react';
import useTranslation from '../../../util/hooks/useTranslation';
import WidgetSettingModal from '../Components/WidgetSettingModal';

const CountByPlacementStateSetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('CountByPlacementStateWidget');
    return (
        <WidgetSettingModal
            headerTitle={t('Count by placement state widget setting')}
            widgetInfo={widgetInfo}
            hiddenHeader
            {...restProps}
        />
    );
};

export default CountByPlacementStateSetting;
