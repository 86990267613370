import React, { useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CHART_COLORS } from '../../../Common/Charts/config';

const CategoryBarChart = ({ congestedGeofenceInfo }) => {
    const [geofenceInfo, setGeofenceInfo] = useState([]);
    const [categoryList, setCategoryList] = useState([]);

    useEffect(() => {
        let tempArr = [];
        congestedGeofenceInfo.map(geofence => {
            let obj = {};
            geofence.categoryInfo.map(category => {
                obj[category.categoryName] = category.categoryCount;
                if (!categoryList.includes(category.categoryName)) {
                    categoryList.push(category.categoryName);
                    setCategoryList(categoryList);
                }
            });
            tempArr.push({ fcName: geofence.fcName, ...obj });
        });

        setGeofenceInfo(tempArr);
    }, [congestedGeofenceInfo]);

    return (
        <ResponsiveContainer width={'100%'} height={'100%'}>
            <BarChart
                key={geofenceInfo}
                data={geofenceInfo}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="fcName" />
                <YAxis />
                <Tooltip />
                <Legend payload={geofenceInfo.length === 0 && []} />
                {categoryList.map((category, index) => {
                    return <Bar dataKey={category} stackId="a" fill={CHART_COLORS[index % CHART_COLORS.length]} />;
                })}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default CategoryBarChart;
