import React, { useEffect, useMemo, useState } from 'react';
import Card from '../../Common/Card';
import SimpleCount from '../../MainPages/Components/SimpleCount';
import useMonitor from '../util/useMonitor';
import { fetchPlacementState, fetchRealTimeLocation } from '../../../api/widget';
import useTranslation from '../../../util/hooks/useTranslation';
import useColumns from '../../../util/hooks/useColumns';
import * as column from '../../MainPages/util/grid/column';
import { fetchAssetList } from '../../../api/asset';
import GridLayoutCard from '../Components/GridLayoutCard';
import Button from '../../Common/Button';
import FilterSearchGroup from '../../Common/FilterSearchGroup';
import { SearchWrap, SelectGroup } from '../../Common/FilterSearchGroup/Components/Part';
import ReactSelect from '../../Common/Select';
import { useSelector } from 'react-redux';
import RotatedImageOverlay from '../../Common/Map/Components/RotatedImageOverlay';
import { FeatureGroup, LayersControl } from 'react-leaflet';
import GeofenceLayer from '../../Common/Map/Layers/GeofenceLayer';
import Map from '../../Common/Map/Components/Map';
import { fetchGeofenceInfo } from '../../../api/bookmark';
import useAsync from '../../../util/hooks/useAsync';
import MarkerLayer from './Component/MarkerLayer';
import AssetTable from '../../MainPages/Components/AssetTable';

const defaultData = [];

const checkFalsy = value => {
    return value ? value : 0;
};

const AssetLocation = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('AssetLocationWidget');
    const { config } = widgetInfo;
    const { floorList } = useSelector(state => state.FloorInfo);
    const [showSearchArea, setShowSearchArea] = useState(false);
    const [selectedFloor, setSelectedFloor] = useState(floorList[0]);
    const [geofenceInfo, setGeofenceInfo] = useState([]);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [page, setPage] = useState(1);
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const category = useMemo(() => {
        let tempArr = [];
        categoryList
            .filter(category => category.parentCode === 'ASSET')
            .map(category => tempArr.push(category.categoryCode));

        return tempArr.toString();
    }, [categoryList]);

    const filterParam = useMemo(() => {
        if (selectedFloor && selectedFloor.floorId) {
            let tempParam = {
                floorIds: selectedFloor.floorId,
                categoryCodes: category,
            };
            return tempParam;
        }
    }, [category, selectedFloor]);

    const listFilterParam = useMemo(() => {
        if (selectedFloor && selectedFloor.floorId) {
            let tempParam = {
                pageSize: 20,
                isTagMapped: 'Y',
                floorIds: selectedFloor.floorId,
                categoryCodes: category,
                page: page,
            };
            return tempParam;
        }
    }, [category, selectedFloor, page]);

    const { data } = useMonitor({
        config,
        defaultData,
        dynamicParam: filterParam,
        fetchData: fetchPlacementState,
    });

    const { data: assetInfo } = useMonitor({
        config,
        defaultData,
        dynamicParam: listFilterParam,
        fetchData: fetchAssetList,
    });

    const { data: realtimeLocation } = useMonitor({
        config,
        defaultData,
        dynamicParam: filterParam,
        fetchData: fetchRealTimeLocation,
    });

    const { promise: getGeofenceInfo } = useAsync({
        promise: fetchGeofenceInfo,
        resolve: res => {
            if (res.rows) {
                setGeofenceInfo(
                    res.rows.map(geofence => {
                        return { ...geofence, bounds: geofence.latLngList.map(latLng => [latLng.lat, latLng.lng]) };
                    }),
                );
            } else {
                setGeofenceInfo([]);
            }
        },
        reject: error => {
            console.log(error);
            setGeofenceInfo([]);
        },
    });

    const columns = useColumns([column.categoryName(), column.targetName(), column.floorName()]);

    const handlePageChange = index => {
        setPage(index);
    };

    const handleSearchAreaClick = e => {
        setShowSearchArea(!showSearchArea);
    };

    const handleSelectValueChange = value => {
        setSelectedFloor(value);
    };

    const handleViewBtnClick = ({ target }) => {
        setSelectedMarker(target);
    };

    const handleTrClick = v => {
        setSelectedMarker(v);
    };

    useEffect(() => {
        if (floorList.length > 0) {
            setSelectedFloor(floorList[0]);
        }
    }, [floorList]);

    useEffect(() => {
        if (selectedFloor && selectedFloor.floorId) {
            getGeofenceInfo({ floor: selectedFloor.floorId });
        }
    }, [selectedFloor]);

    return (
        <GridLayoutCard
            widgetInfo={widgetInfo}
            headerAction={
                <Button className={'btn-secondary'} onClick={handleSearchAreaClick}>
                    {t('Search')}
                </Button>
            }
            searchFilter={
                showSearchArea && (
                    <FilterSearchGroup className={'card absolute-filter'}>
                        <SearchWrap className={'w-100'}>
                            <SelectGroup className={'w-100'}>
                                <ReactSelect
                                    name={'selectInput'}
                                    value={selectedFloor}
                                    valueKey={'floorId'}
                                    labelKey={'floorName'}
                                    customControlStyles={{ width: '100%' }}
                                    customMenuStyles={{ width: '100%' }}
                                    customOptionStyles={{ width: '100%' }}
                                    onChange={handleSelectValueChange}
                                    options={floorList}
                                />
                            </SelectGroup>
                        </SearchWrap>
                    </FilterSearchGroup>
                )
            }
            {...restProps}
        >
            <div className={'d-grid h-100'} style={{ gridTemplateColumns: '1fr 2fr', columnGap: '10px' }}>
                <div className={'d-grid'} style={{ gridTemplateRows: '200px auto' }}>
                    <SimpleCount
                        key={filterParam}
                        widgetInfo={{
                            title: selectedFloor ? selectedFloor.floorName : '',
                            mainCnt: checkFalsy(data.detectedTargetCnt),
                            mainAllCnt: checkFalsy(data.total),
                            mainStatus: t('Mapped'),
                            colorClass: 'color-secondary',
                            detailCnt: [
                                {
                                    colorClass: 'color-success',
                                    label: t('In'),
                                    value: checkFalsy(data.locatedInTargetCnt),
                                },
                                {
                                    colorClass: 'color-danger',
                                    label: t('Out'),
                                    value: checkFalsy(data.locatedOutTargetCnt),
                                },
                                {
                                    colorClass: 'color-gray',
                                    label: t('Unknown'),
                                    value: checkFalsy(data.neverDetectedTargetCnt),
                                },
                            ],
                        }}
                    />

                    <Card
                        header={{
                            title: (
                                <>
                                    {selectedMarker && selectedMarker.targetNum
                                        ? t('Selected Asset', 'Menu')
                                        : t('Asset list')}
                                </>
                            ),
                            action: selectedMarker && selectedMarker.targetNum && (
                                <Button className={'btn-brand'} onClick={() => setSelectedMarker(null)}>
                                    {t('Back', 'Button')}
                                </Button>
                            ),
                        }}
                    >
                        {
                            <AssetTable
                                excludeMap={true}
                                singleCol={true}
                                targetNum={selectedMarker && selectedMarker.targetNum}
                                columns={columns}
                                data={assetInfo.rows && assetInfo.rows.length > 0 ? assetInfo : { rows: [] }}
                                onPageChange={handlePageChange}
                                onTrClick={handleTrClick}
                            />
                        }
                    </Card>
                </div>
                <div>
                    <Map tile={true} className="z-index-0">
                        {selectedFloor && selectedFloor.neLat && (
                            <RotatedImageOverlay
                                key={selectedFloor.neLat}
                                url={selectedFloor.imgURL}
                                deg={selectedFloor.deg}
                                bounds={[
                                    [selectedFloor.neLat, selectedFloor.neLng],
                                    [selectedFloor.swLat, selectedFloor.swLng],
                                ]}
                            />
                        )}
                        <LayersControl position="topright">
                            <LayersControl.Overlay name={t('Geofence', 'Common')} checked={true}>
                                {geofenceInfo && geofenceInfo.length > 0 && (
                                    <GeofenceLayer
                                        geofenceList={geofenceInfo.filter(
                                            geofence => geofence.floor === selectedFloor.floorId,
                                        )}
                                    />
                                )}
                            </LayersControl.Overlay>
                            <LayersControl.Overlay name={t('Marker', 'Common')} checked={true}>
                                {realtimeLocation && (
                                    <MarkerLayer
                                        markerList={realtimeLocation.rows}
                                        handleViewBtnClick={handleViewBtnClick}
                                    />
                                )}
                            </LayersControl.Overlay>
                        </LayersControl>
                    </Map>
                </div>
            </div>
            {children}
        </GridLayoutCard>
    );
};

export default AssetLocation;
