import React, { useMemo } from 'react';
import SimpleCountWithoutCard from '../../MainPages/Components/SimpleCountWithoutCard';
import WidgetCard from '../Components/WidgetCard';
import useMonitor from '../util/useMonitor';
import { fetchMappedTagBatteryCount, fetchUnMappedTagBatteryCount } from '../../../api/widget';
import useTranslation from '../../../util/hooks/useTranslation';
import { useSelector } from 'react-redux';
import { checkFalsy } from '../../../util/common/util';
import { useSettings } from '../util/useSettings';

const defaultData = [];
const MappedTagBattery = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('MappedTagBatteryWidget');
    const { config } = widgetInfo;
    const { settings: customSettings } = config;
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const filterParam = useMemo(() => {
        let tempArr = [];
        categoryList
            .filter(category => category.parentCode === 'ASSET')
            .map(category => tempArr.push(category.categoryCode));
        let tempParam = {
            categoryCodes: tempArr.toString(),
        };
        return tempParam;
    }, [categoryList]);

    const { data } = useMonitor({
        config,
        defaultData,
        dynamicParam: filterParam,
        fetchData: customSettings
            ? customSettings.mappingOption === 'mapped'
                ? fetchMappedTagBatteryCount
                : fetchUnMappedTagBatteryCount
            : fetchMappedTagBatteryCount,
    });

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            subTitle={
                customSettings
                    ? customSettings.mappingOption === 'mapped'
                        ? t('Mapped', 'Common')
                        : t('Unmapped', 'Common')
                    : t('Mapped', 'Common')
            }
            {...restProps}
        >
            <SimpleCountWithoutCard
                key={data}
                widgetInfo={{
                    mainCnt: checkFalsy(
                        customSettings && customSettings.mappingOption === 'unmapped'
                            ? data.critical
                            : data.criticalCnt,
                    ),
                    mainAllCnt: checkFalsy(data.total),
                    mainStatus: t('Critical', 'Common'),
                    colorClass: 'color-danger',
                    detailCnt: [
                        {
                            colorClass: 'color-success',
                            label: t('Normal', 'Common'),
                            value: checkFalsy(
                                customSettings && customSettings.mappingOption === 'unmapped'
                                    ? data.normal
                                    : data.normalCnt,
                            ),
                        },
                        {
                            colorClass: 'color-warning',
                            label: t('Warning', 'Common'),
                            value: checkFalsy(
                                customSettings && customSettings.mappingOption === 'unmapped'
                                    ? data.warning
                                    : data.warningCnt,
                            ),
                        },
                        {
                            colorClass: 'color-gray',
                            label: t('Unknown', 'Common'),
                            value: checkFalsy(
                                customSettings && customSettings.mappingOption === 'unmapped'
                                    ? data.unknown
                                    : data.unknownCnt,
                            ),
                        },
                    ],
                }}
            />
            {children}
        </WidgetCard>
    );
};

export default MappedTagBattery;
