import React, { useContext, useEffect, useMemo, useState } from 'react';
import Button from '../../../../Common/Button';
import moment from 'moment';
import useTranslation from '../../../../../util/hooks/useTranslation';
import DatePicker from '../../../../Common/DatePicker';
import FilterSearchGroup from '../../../../Common/FilterSearchGroup';
import SearchableSelect from '../../../../Common/Select/SearchableSelect';
import { useSelector } from 'react-redux';
import FloorTreeSelect from '../../Components/FloorTreeSelect';
import FilterGroup from '../../../../Common/FilterSearchGroup/Components/FilterGroup';
import SearchGroup from '../../../../Common/FilterSearchGroup/Components/SearchGroup';
import {
    FilterList,
    InputGroup,
    InputWrap,
    SearchWrap,
    SelectGroup,
} from '../../../../Common/FilterSearchGroup/Components/Part';
import ReactSelect from '../../../../Common/Select';
import TextInput from '../../../../Common/Input/TextInput';
import SearchButtonArea from '../../../../Common/FilterSearchGroup/Components/SearchButtonArea';
import { GeofenceLogDispatchContext, GeofenceLogStateContext } from '../index';
import { setStartDate, setEndDate } from '../geofenceLogReducer';

const Search = ({ getGeofenceLogList }) => {
    const t = useTranslation('Geofence Log');
    const { floorList } = useSelector(state => state.FloorInfo);
    const dispatch = useContext(GeofenceLogDispatchContext);
    const { selectableLGeofenceList, startDate, endDate } = useContext(GeofenceLogStateContext);
    const [selectedOption, setSelectedOption] = useState({ value: null, label: t('Search Option', 'Search') });
    const searchOptions = useMemo(() => {
        return [
            { value: '', label: t('Search Option', 'Search') },
            { value: 'targetName', label: t('Target Name') },
            { value: 'targetId', label: t('Target ID') },
        ];
    }, [t]);

    const [keyword, setKeyword] = useState(null);
    const [searchInputDisabled, setSearchInputDisabled] = useState(true);
    useEffect(() => {
        if (selectedOption) {
            setSelectedOption(searchOptions.find(v => v.value === selectedOption.value));
        }
    }, [searchOptions]);

    const handleSelectValueChange = selected => {
        const { value } = selected;
        setSelectedOption(value);
        if (value !== '') {
            setSearchInputDisabled(false);
        } else {
            setSearchInputDisabled(true);
            setKeyword('');
        }
    };

    const handleRefreshClick = () => {
        setSelectedOption(searchOptions[0]);
        setKeyword('');
        setSelectedFloorId({ floorId: '', floorName: '' });
        setSelectedFloorIdPath(null);
        setSelectedGeofence([]);
        dispatch(setStartDate(null));
        dispatch(setEndDate(null));
        setSearchInputDisabled(true);
        getGeofenceLogList();
    };

    const handleSearchInputChange = e => {
        const { value } = e.target;
        setKeyword(value);
    };

    const handleSearchClick = () => {
        getGeofenceLogList({
            startDate: startDate ? moment(startDate).unix() : null,
            endDate: endDate ? moment(endDate).unix() : null,
            fcNums: selectedGeofence.length ? selectedGeofence.join(',') : null,
            floorIds: selectedFloorId.floorId ? selectedFloorId.floorId : null,
            floorIdPath: selectedFloorIdPath,
            keyword,
            opt: selectedOption.value ? selectedOption.value : null,
        });
    };

    const handleYesterdayButtonClick = () => {
        const start = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
        const end = new Date();
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);
        dispatch(setStartDate(start.valueOf()));
        dispatch(setEndDate(end.valueOf() - 1));
    };

    const [selectedFloorId, setSelectedFloorId] = useState({
        floorId: '',
        floorName: '',
    });

    const [selectedFloorIdPath, setSelectedFloorIdPath] = useState(null);
    const handleSelectedFloor = selected => {
        const { floorId, floorIdPath, floorName } = selected;
        setSelectedFloorId({ floorId, floorName });
        setSelectedFloorIdPath(floorIdPath);
    };

    const [selectedGeofence, setSelectedGeofence] = useState([]);
    const handleSelectedGeofence = selected => {
        setSelectedGeofence(selected.map(({ value }) => value));
    };

    return (
        <FilterSearchGroup>
            <FilterGroup className={'bg-brand'} label={t('Search', 'Search')}>
                <FilterList>
                    <SelectGroup>
                        <div className={'datePicker-container'}>
                            <DatePicker
                                value={startDate}
                                handleChange={selected => dispatch(setStartDate(selected))}
                                valueType={'ms'}
                                maxDate={endDate || new Date()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <div className={'text-center'}> ~ </div>
                    <SelectGroup>
                        <div className={'datePicker-container'}>
                            <DatePicker
                                value={endDate}
                                handleChange={selected => dispatch(setEndDate(selected))}
                                valueType={'ms'}
                                minDate={startDate}
                                maxDate={new Date()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <Button className={'btn-secondary btn-yesterday'} onClick={handleYesterdayButtonClick}>
                        {t('Yesterday', 'Button')}
                    </Button>
                    <SelectGroup>
                        <FloorTreeSelect
                            title={'title'}
                            selectedOption={selectedFloorId.floorName}
                            onChange={handleSelectedFloor}
                        />
                    </SelectGroup>
                    <SelectGroup>
                        <SearchableSelect
                            selected={selectedGeofence}
                            onChange={handleSelectedGeofence}
                            data={selectableLGeofenceList}
                            title={t('Geofence')}
                        />
                    </SelectGroup>
                </FilterList>
            </FilterGroup>
            <SearchGroup>
                <SearchWrap>
                    <SelectGroup>
                        <ReactSelect
                            name={'selectInput'}
                            placeholder={t('Search Option', 'Search')}
                            value={selectedOption}
                            options={searchOptions}
                            onChange={handleSelectValueChange}
                            customControlStyles={{
                                width: '100%',
                            }}
                            customMenuStyles={{ width: '100%' }}
                            customOptionStyles={{ width: '100%' }}
                        />
                    </SelectGroup>
                    <InputWrap>
                        <InputGroup>
                            <TextInput
                                style={{ borderRadius: '5px' }}
                                type={'text'}
                                size={'60'}
                                placeholder={t(
                                    'Please Enter your search keyword after selecting search option.',
                                    'Search',
                                )}
                                name={'name'}
                                value={keyword}
                                handleChange={handleSearchInputChange}
                                disabled={searchInputDisabled}
                            />
                        </InputGroup>
                    </InputWrap>
                </SearchWrap>
                <SearchButtonArea>
                    <Button className={'btn-brand btn-icon'} iconClassName={'icon-search'} onClick={handleSearchClick}>
                        {t('Search', 'Search')}
                    </Button>
                    <Button
                        className="btn-danger btn-icon-only"
                        iconClassName={'icon-replay'}
                        onClick={handleRefreshClick}
                    />
                </SearchButtonArea>
            </SearchGroup>
        </FilterSearchGroup>
    );
};
export default Search;
