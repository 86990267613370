import React, { useState, useMemo, useEffect, useContext } from 'react';
import RightSelectModal from './RightSelectModal';
import useTranslation from '../../../../../../util/hooks/useTranslation';
import Button from '../../../../../Common/Button';
import { IoIosPeople, IoIosPerson } from 'react-icons/io';
import { FilterStateContext } from '../../../index';
import { FilterDetailDispatchContext, FilterDetailStateContext } from '../../index';
import { updateFilter } from '../../reducer';

const RightItem = ({ right, onDelete }) => {
    const { authority } = useContext(FilterStateContext);

    const rightTargetName = useMemo(() => {
        if (typeof right.groupNum === 'number') {
            const found = (authority.groupList || []).find(v => v.groupNum === right.groupNum);
            return found ? found.groupName : '';
        } else if (typeof right.userNum === 'number') {
            const found = (authority.userList || []).find(v => v.userNum === right.userNum);
            return found ? found.userName : '';
        } else {
            return '';
        }
    }, [authority, right]);

    return (
        <div className="flx-sb border-bottom align-items-center p-2">
            <div className="mr-4">
                {typeof right.groupNum === 'number' ? (
                    <IoIosPeople className={'transform-scale-medium'} />
                ) : (
                    <IoIosPerson className={'transform-scale-medium'} />
                )}
                <span className="pnt-txt txt-tiny ml-2">{rightTargetName}</span>
            </div>
            <Button className={'btn-danger btn-small btn-icon-only'} iconClassName={'icon-close'} onClick={onDelete} />
        </div>
    );
};

const RightSelect = ({ list, title, handleChange, accessScope }) => {
    const t = useTranslation('Filter');
    const buttonT = useTranslation('Button');
    const [openModal, setOpenModal] = useState(false);
    const [selectedList, setSelectedList] = useState(list);
    const checkAll = useMemo(() => {
        return !!list.find(v => v.userNum === -1 || v.groupNum === -1);
    }, [list]);

    useEffect(() => {
        handleChange(selectedList);
    }, [selectedList]);

    return (
        <>
            <div className={'right-wrapper'}>
                <div className="right-header sticky-header">
                    <span style={{ color: '#FF7800' }} className="font-weight-bold">
                        {title}
                    </span>
                    <Button className={`btn-icon btn-secondary`} onClick={() => setOpenModal(true)}>
                        <span className="icon-plus" />
                        {buttonT('Add')}
                    </Button>
                </div>
                <div className="p-3">
                    {checkAll ? (
                        <div className="flx-sb border-bottom align-items-center p-2">
                            <div className="mr-4">
                                <IoIosPeople className={'transform-scale-medium'} />
                                <span className="pnt-txt txt-tiny ml-2">{t('All users')}</span>
                            </div>
                            <Button
                                className={'btn-danger btn-small btn-icon-only'}
                                iconClassName={'icon-close'}
                                onClick={() => {
                                    setSelectedList([]);
                                }}
                            />
                        </div>
                    ) : (
                        list.map(right => {
                            return (
                                <RightItem
                                    key={`${right.accessScope}_${right.userNum}_${right.groupNum}`}
                                    right={right}
                                    onDelete={() => {
                                        setSelectedList(
                                            selectedList.filter(
                                                v =>
                                                    !(
                                                        (typeof v.userNum === 'number' &&
                                                            v.userNum === right.userNum) ||
                                                        (typeof v.groupNum === 'number' &&
                                                            v.groupNum === right.groupNum)
                                                    ),
                                            ),
                                        );
                                    }}
                                />
                            );
                        })
                    )}
                </div>
            </div>
            {openModal && (
                <RightSelectModal
                    key={JSON.stringify(selectedList)}
                    title={title}
                    accessScope={accessScope}
                    selectedList={selectedList}
                    isOpen={openModal}
                    closeModal={() => setOpenModal(false)}
                    okCallback={checkedList => {
                        setSelectedList(checkedList);
                    }}
                />
            )}
        </>
    );
};

export default RightSelect;
