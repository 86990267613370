import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    categoryList: [],
    categoryConfig: {},
    floorList: [],
};

const { reducer, actions } = createSlice({
    name: 'commonInfo',
    initialState,
    reducers: {
        setCategoryList: (state, action) => {
            state.categoryList = action.payload.rows.filter(category => {
                if (category.categoryCodePath.includes('ASSET')) {
                    return category;
                }
            });
        },
        setCategoryConfig: (state, action) => {
            const categoryConfig = action.payload;
            state.categoryConfig = categoryConfig.reduce((acc, config) => {
                const inputValues = config.inputValues;
                if (inputValues.length) {
                    config.inputValuesMap = inputValues.reduce((acc, curr) => {
                        acc[curr.value] = curr.name;
                        return acc;
                    }, {});
                }
                acc[config.categoryCode] = config;
                return acc;
            }, {});
        },
        setFloorList: (state, action) => {
            state.floorList = action.payload.rows.map(v => ({
                ...v,
                bounds: [
                    [v.swLat, v.swLng],
                    [v.neLat, v.neLng],
                ],
            }));
        },
    },
});

export const { setCategoryList, setCategoryConfig, setFloorList } = actions;
export default reducer;
