import React, { useContext, useEffect, useMemo, useState } from 'react';
import Button from '../../../../Common/Button';
import moment from 'moment';
import useTranslation from '../../../../../util/hooks/useTranslation';
import DatePicker from '../../../../Common/DatePicker';
import FloorTreeSelect from '../../Components/FloorTreeSelect';

import SearchableSelect from '../../../../Common/Select/SearchableSelect';
import FilterSearchGroup from '../../../../Common/FilterSearchGroup';
import { SelectGroup, FilterList } from '../../../../Common/FilterSearchGroup/Components/Part';
import FilterGroup from '../../../../Common/FilterSearchGroup/Components/FilterGroup';
import FilterButtonArea from '../../../../Common/FilterSearchGroup/Components/FilterButtonArea';
import { ScannerStateLogDispatchContext, ScannerStateLogStateContext } from '../index';
import { setEndDate, setStartDate } from '../scannerLogReducer';

const Search = ({ getScannerStateLogList }) => {
    const t = useTranslation('Scanner State Log');

    const dispatch = useContext(ScannerStateLogDispatchContext);
    const { scannerList, startDate, endDate } = useContext(ScannerStateLogStateContext);

    const [selectedOption, setSelectedOption] = useState({ value: '', label: t('Search Option', 'Search') });
    const searchOptions = useMemo(() => {
        return [
            { value: '', label: t('Search Option', 'Search') },
            { value: 'targetName', label: t('Target Name') },
            { value: 'targetId', label: t('Target ID') },
        ];
    }, [t]);

    useEffect(() => {
        if (selectedOption) {
            setSelectedOption(searchOptions.find(v => v.value === selectedOption.value));
        }
    }, [searchOptions]);

    const handleRefreshClick = () => {
        setSelectedOption(searchOptions[0]);

        setSelectedFloorId({ floorId: '', floorName: '' });
        setSelectedFloorIdPath(null);
        setSelectedScanner([]);
        dispatch(setStartDate(null));
        dispatch(setEndDate(null));
        getScannerStateLogList();
    };

    const handleSearchClick = () => {
        getScannerStateLogList({
            startDate: startDate ? moment(startDate).unix() : null,
            endDate: endDate ? moment(endDate).unix() : null,
            floor: selectedFloorId.floorId ? selectedFloorId.floorId : null,
            floorPath: selectedFloorIdPath,
            scannerNums: selectedScanner.length ? selectedScanner.join(',') : null,
        });
    };

    const handleYesterdayButtonClick = () => {
        const start = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
        const end = new Date();
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);
        dispatch(setStartDate(start.valueOf()));
        dispatch(setEndDate(end.valueOf() - 1));
    };

    const [selectedFloorId, setSelectedFloorId] = useState({ floorId: '', floorName: '' });
    const [selectedFloorIdPath, setSelectedFloorIdPath] = useState(null);
    const handleSelectedFloor = selected => {
        const { floorId, floorIdPath, floorName } = selected;
        setSelectedFloorId({ floorId, floorName });
        setSelectedFloorIdPath(floorIdPath);
    };

    const [selectedScanner, setSelectedScanner] = useState([]);
    const handleSelectedScanner = selected => {
        setSelectedScanner(selected.map(({ value }) => value));
    };
    return (
        <FilterSearchGroup>
            <FilterGroup className={'bg-brand'} label={t('Search', 'Search')}>
                <FilterList>
                    <SelectGroup>
                        <div className={'datePicker-container'}>
                            <DatePicker
                                value={startDate}
                                handleChange={selected => dispatch(setStartDate(selected))}
                                valueType={'ms'}
                                maxDate={endDate || new Date()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <SelectGroup>
                        <div className={'datePicker-container'}>
                            <DatePicker
                                value={endDate}
                                handleChange={selected => dispatch(setEndDate(selected))}
                                valueType={'ms'}
                                minDate={startDate}
                                maxDate={new Date()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <SelectGroup>
                        <FloorTreeSelect
                            title={'title'}
                            selectedOption={selectedFloorId.floorName}
                            onChange={handleSelectedFloor}
                        />
                    </SelectGroup>
                    <SelectGroup>
                        <SearchableSelect
                            selected={selectedScanner}
                            onChange={handleSelectedScanner}
                            data={scannerList}
                            title={t('Scanner')}
                        />
                    </SelectGroup>
                </FilterList>
                <FilterButtonArea>
                    <Button className={'btn-secondary btn-yesterday'} onClick={handleYesterdayButtonClick}>
                        {t('Yesterday', 'Button')}
                    </Button>
                    <Button className={'btn-brand btn-icon'} iconClassName={'icon-search'} onClick={handleSearchClick}>
                        {t('Search', 'Search')}
                    </Button>
                    <Button
                        className="btn-danger btn-icon-only"
                        iconClassName={'icon-replay'}
                        onClick={handleRefreshClick}
                    />
                </FilterButtonArea>
            </FilterGroup>
        </FilterSearchGroup>
    );
};
export default Search;
