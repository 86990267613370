import { useEffect, useRef } from 'react';
import { checkExpire, checkRefreshExpire, getLsUserInfo } from '../common/util';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchOAuthToken } from '../../api/login';
import useAsync from './useAsync';
import { logOut, setOauthInfo, setOauthStatusModal } from '../../reducers/UserInfo';
import moment from 'moment';

export const useAuthCheck = ({ history }) => {
    const { auth } = useSelector(state => state.UserInfo);
    useEffect(() => {
        if (!auth) {
            history.replace('/login');
        }
    }, [auth]);
};

export const useUserInfoCheck = () => {
    const { auth } = useSelector(state => state.UserInfo);
    const history = useHistory();

    useEffect(() => {
        if (!checkExpire()) {
            history.replace('/login');
        }
    }, [auth]);
};

// AppMain페이지에서 checkExpire를 활용하여 토근이 만료됐는지 체크 및 페이지 전환(?)
export const useUserTokenCheck = () => {
    const { oAuthInfo } = useSelector(state => state.UserInfo);
    const history = useHistory();
    const dispatch = useDispatch();
    const refreshTokenRef = useRef();

    const { promise: refreshOAuthToken } = useAsync({
        promise: fetchOAuthToken,
        resolve: response => {
            dispatch(setOauthInfo(response));
        },
        reject: response => {
            dispatch(setOauthStatusModal(true));
            dispatch(logOut());
        },
    });

    // 토큰 만료시간이 현재시각과 비교했을때
    // 30분 보다 적게 남았을경우 바로 리프레쉬 토큰 발급 (1)
    // 30분 이상 남았을때는 30분이 남게 되었을때 토큰 발급 (remainingTime - 60 * 30)
    // 60 * 30 = 1800 => 30분
    const getRefreshTokenTime = expireAt => {
        const remainingTime = expireAt - moment().unix();
        return remainingTime > 60 * 30 ? remainingTime - 60 * 30 : 1;
    };

    const clearTime = () => {
        if (refreshTokenRef.current) {
            clearTimeout(refreshTokenRef.current);
        }
    };

    useEffect(() => {
        // 로그아웃시 유저 인포가 {}로 변함으로서 useEffect 실행 및 로그인 페이지로 전환
        // 혹은 refresh_token_time이 현재 시각을 지나버린 경우 로그인 페이지로 전환
        if (!checkExpire() || !checkRefreshExpire()) {
            history.replace('/login');
        }

        // 화면 리프레쉬할시 저장된 데이터가 사라지기때문에 로컬스토리지에서 유저 인포 가져오기
        let refresh_token, expire_at;
        const lsUserInfo = getLsUserInfo();
        if (!oAuthInfo.refresh_token && lsUserInfo.oAuthInfo) {
            refresh_token = lsUserInfo.oAuthInfo.refresh_token;
            expire_at = lsUserInfo.oAuthInfo.expire_at;
        } else if (oAuthInfo.refresh_token) {
            refresh_token = oAuthInfo.refresh_token;
            expire_at = oAuthInfo.expire_at;
        }

        if (refresh_token && expire_at) {
            clearTime();
            refreshTokenRef.current = setTimeout(() => {
                refreshOAuthToken({
                    grant_type: 'refresh_token',
                    refresh_token: refresh_token,
                });
            }, getRefreshTokenTime(expire_at) * 1000);
            // setTimeout은 milliseconds 기준이기 때문에 seconds에서 milliseconds로 전환위해 * 1000
        }
    }, [oAuthInfo]);
};
