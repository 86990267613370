import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    mode: null,
    fullScreen: false,
};

const { actions, reducer } = createSlice({
    name: 'dashboardFrame',
    initialState,
    reducers: {
        setMode: (state, action) => {
            state.mode = action.payload;
        },
        setFullScreen: (state, action) => {
            state.fullScreen = action.payload;
        },
    },
});

export const { setMode, setFullScreen } = actions;
export default reducer;
