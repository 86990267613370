export const CustomNav = [
    {
        id: 'dashboard',
        icon: 'icon-menu-first',
        label: 'Dashboards',
        content: [
            {
                label: 'Dashboard List',
                to: '#/dashboards/list',
            },
        ],
    },
    {
        id: 'filter',
        icon: 'icon-menu-first',
        label: 'Filters',
        content: [
            {
                label: 'Filter List',
                to: '#/filter/list',
            },
        ],
    },
    {
        id: 'asset',
        icon: 'icon-menu-second',
        label: 'Assets',
        content: [
            {
                label: 'Quick Search',
                to: '#/asset/quickSearch',
            },
            {
                label: 'Asset List',
                to: '#/asset/list',
            },
        ],
    },
    {
        icon: 'icon-menu-third',
        label: 'Position History',
        id: 'positionHistory',
        content: [
            {
                label: 'Entrance',
                to: '#/positionHistory/entrance',
            },
            {
                label: 'Analysis by Zone',
                to: '#/positionHistory/analysisByLocation',
            },
            {
                label: 'Analysis by an Asset',
                to: '#/positionHistory/analysisByAsset',
            },
        ],
    },
    {
        icon: 'icon-menu-seven',
        label: 'Alert',
        id: 'alert',
        content: [
            {
                label: 'History',
                to: '#/alert/history',
            },
        ],
    },
    {
        id: 'report',
        icon: 'icon-menu-second',
        label: 'Report',
        content: [
            {
                label: 'Location Log',
                to: '#/report/location-log',
            },
            {
                label: 'Saved Location Log List',
                to: '#/report/location-log/list',
            },
            {
                label: 'Iot Item Information Log',
                to: '#/report/iotItem-log/list',
            },
            {
                label: 'Iot Item Mapping Log',
                to: '#/report/iotItem-mapping-log/list',
            },
            {
                label: 'Login History',
                to: '#/report/login-log/list',
            },
            {
                label: 'Scanner State Log',
                to: '#/report/scanner-log',
            },
            {
                label: 'Sensor Log',
                to: '#/report/Sensor-log',
            },
            {
                label: 'Geofence Log',
                to: '#/report/geofence-log',
            },
        ],
    },
    // {
    //     icon: 'icon-menu-fourth',
    //     label: 'Settings',
    //     id: 'wmsConnection',
    //     to: '',
    // },
];

export const SettingsNav = [
    // {
    //     id: 'menuRights',
    //     icon: 'icon-menu-third',
    //     label: 'Menu Access Rights',
    //     to: '#/setting/menu',
    // },
    {
        id: 'wmsConnection',
        icon: 'icon-menu-fourth',
        label: 'Settings',
        to: '',
    },
];
