import React from 'react';
import Nouislider from 'nouislider-react';
import 'nouislider/dist/nouislider.css';

const PlayBar = ({ playTime, startTime, endTime }) => {
    return (
        <Nouislider
            className={'play-bar'}
            animate={false}
            connect={[true, false]}
            start={[playTime]}
            step={1}
            style={{ height: '0.3rem', zIndex: 0 }}
            range={{
                min: startTime,
                max: endTime,
            }}
            onStart={(render, handle, value, un, percent) => {
                console.log('onStart', { render, handle, value, un, percent });
            }}
            onEnd={(render, handle, value, un, percent) => {
                console.log('onEnd', { render, handle, value, un, percent });
            }}
        />
    );
};

export default PlayBar;
