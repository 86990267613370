import React, { useEffect, useRef, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import useAsync from '../../../util/hooks/useAsync';
import { fetchAssetList } from '../../../api/asset';
import styled from 'styled-components';
import useTranslation from '../../../util/hooks/useTranslation';
import { useFilterParam } from '../../../util/hooks/useFilterParam';

const ASSET_NAME_PAGE_SIZE = 20;

const WrappedTypeahead = styled.div`
    @media (max-width: 690px) {
        & #targetName {
            left: 24%;
            top: 44%;
            width: 50%;
        }
    }

    & .form-control {
        font-size: 0.8rem;
        height: 38px;
        border: 2px solid orange;
    }

    & .rbt-input-main .form-control .rbt-input {
        border: 1px solid orange;
    }
`;

/**
 * Input box에 작성되는 키워드에 따라 전체 asset list에서 알맞는 정보를 가져오고
 * 유저에게 그 키워드에 맞는 리스트를 보여줌으로서 정확하게 보고싶은 정보를 선택할 수 있게 함
 *
 * @param handleSelectedKeyword function / 유저가 선택한 키워드를 부모 컴포넌트에게 전달하는 역할
 * @param emptyInput boolean type / clear method를 실행하기 위한 조건 변수
 *
 */

const SearchAutocomplete = ({ handleSelectedKeyword, emptyInput }) => {
    const t = useTranslation('AutoComplete');
    const [itemList, setItemList] = useState([]);
    const [keyword, setKeyword] = useState('');
    const autocompleteRef = useRef();

    const { categoryCodes, floorIds, isRestrictionStatus } = useFilterParam();

    const { promise: getAssetList } = useAsync({
        promise: fetchAssetList,
        resolve: res => {
            if (keyword) {
                let tempList = [];
                res.rows.map(row =>
                    tempList.push({
                        label: `${row.targetName} (${row.targetId})`,
                        value: row.targetId,
                        targetNum: row.targetNum,
                    }),
                );

                if (res.page === 1) {
                    setItemList(tempList || { rows: [] });
                } else {
                    setItemList(itemList.concat(tempList));
                }
            }

            const autocompleteRefElement = autocompleteRef.current;
            if (!autocompleteRefElement.state.showMenu && typeof autocompleteRefElement.toggleMenu === 'function') {
                autocompleteRefElement.toggleMenu();
            }
        },
        reject: error => {
            console.log(error);
        },
    });

    //만약 emptyInput이 true라면 clear method를 실행함으로서 초기화를 시켜줌
    useEffect(() => {
        setItemList([]);
        autocompleteRef.current.clear();
    }, [emptyInput]);

    return (
        <WrappedTypeahead>
            <Typeahead
                id="targetId"
                ref={autocompleteRef}
                style={{ fontSize: '0.8rem' }}
                className={'typeahead-custom'}
                multiple={false}
                allowNew={false}
                onInputChange={text => {
                    if (keyword) {
                        setItemList([]);
                    }
                    setKeyword(text);
                    setTimeout(() => {
                        getAssetList({
                            pageSize: ASSET_NAME_PAGE_SIZE,
                            opt: 'targetSearch',
                            keyword: text,
                            categoryCodes: categoryCodes,
                            floorIds,
                            isRestrictionStatus,
                        });
                    }, 500);
                }}
                onChange={handleSelectedKeyword}
                options={itemList}
                placeholder={t('Input asset ID or name')}
                emptyLabel={t('No matches found')}
                paginationText={t('Display additional results...')}
                maxResults={ASSET_NAME_PAGE_SIZE - 1}
                onPaginate={(e, shownResults) => {
                    const page = Math.ceil(shownResults / ASSET_NAME_PAGE_SIZE);
                    getAssetList({
                        page,
                        pageSize: ASSET_NAME_PAGE_SIZE,
                        opt: 'targetSearch',
                        keyword: keyword,
                        categoryCodes: categoryCodes,
                        floorIds,
                        isRestrictionStatus,
                    });
                }}
            />
        </WrappedTypeahead>
    );
};

export default SearchAutocomplete;
