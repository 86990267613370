import produce from 'immer';
import { defaultActionCreator } from '../../../util/core';
import moment from 'moment';

const SET_INIT_STATE = 'SET_INIT_STATE';
const SET_PLAY_TIME = 'SET_PLAY_TIME';
const SET_PLAY = 'SET_PLAY';
const SET_PAUSE = 'SET_PAUSE';
const SET_START_TIME = 'SET_START_TIME';
const SET_END_TIME = 'SET_END_TIME';

export const setInitState = param => defaultActionCreator(SET_INIT_STATE, param);
export const setPlayTime = param => defaultActionCreator(SET_PLAY_TIME, param);
export const setPlay = param => defaultActionCreator(SET_PLAY, param);
export const setPause = param => defaultActionCreator(SET_PAUSE, param);
export const setStartTime = param => defaultActionCreator(SET_START_TIME, param);
export const setEndTime = param => defaultActionCreator(SET_END_TIME, param);

const now = moment().unix();

export const initialState = {
    play: false,
    playTime: now,
    startTime: now,
    endTime: now + 1,
    speed: 1,
};

export const playControllerReducer = (state, action) => {
    switch (action.type) {
        case SET_INIT_STATE:
            return produce(state, draft => {
                const initState = action.payload;
                draft.playTime = initState.startTime;
                draft.startTime = initState.startTime;
                draft.endTime = initState.endTime;
                draft.play = false;
                draft.speed = 1;
            });
        case SET_PLAY_TIME:
            return produce(state, draft => {
                if (action.payload) {
                    draft.playTime = action.payload;
                } else {
                    if (draft.playTime < draft.endTime) {
                        draft.playTime++;
                    }
                }
            });
        case SET_PLAY:
            return produce(state, draft => {
                if (action.payload.playTime === action.payload.endTime) {
                    draft.playTime = action.payload.startTime;
                }

                draft.play = true;
                draft.speed = action.payload.speed;
            });
        case SET_PAUSE:
            return produce(state, draft => {
                draft.play = false;
                if (action.payload && action.payload.speed) {
                    draft.speed = action.payload.speed;
                }
            });
        case SET_START_TIME:
            return produce(state, draft => {
                draft.play = false;
                draft.playTime = action.payload.startTime;
            });
        case SET_END_TIME:
            return produce(state, draft => {
                draft.play = false;
                draft.playTime = action.payload.endTime;
            });
        default:
            return state;
    }
};
