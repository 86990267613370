import React, { useState } from 'react';
import styled from 'styled-components';
import { Collapse, Row } from 'reactstrap';
import ResizeDetector from 'react-resize-detector';
import { RESPONSIVE_FILTER_WIDTH } from '../Filter';
import useTranslation from '../../util/hooks/useTranslation';

const MobileFilterRow = styled(Row)`
    display: flex;
    justify-content: center;
    > * {
        margin: 0.3rem;
    }
`;

const FilterBox = styled.div`
    //display: flex;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 10px;

    @media (max-width: 1420px) {
        grid-template-columns: 1fr;
        grid-row-gap: 0.5rem;
    }
`;

const Search = ({ children, onClick, withoutSearchBtn, ...rest }) => {
    const [filterOpen, setFilterOpen] = useState(false);
    const toggleFilter = () => setFilterOpen(!filterOpen);
    const t = useTranslation('Search');
    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => {
                const isSmall = width < RESPONSIVE_FILTER_WIDTH;
                return (
                    <>
                        <div className="flex-row search-box flx-row flx-top gap-3">
                            <div className="filter-label bg-l-2 bg-brand">{t('Search')} </div>

                            {!isSmall && <FilterBox>{children}</FilterBox>}

                            <div>
                                {isSmall && (
                                    <>
                                        <button className="pnt-btn btn-gray btn-filter-open" onClick={toggleFilter}>
                                            <span className="icon-down" />
                                            {t('More')}
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                        {isSmall && (
                            <Collapse isOpen={filterOpen} className="mt-3">
                                <MobileFilterRow>{children}</MobileFilterRow>
                            </Collapse>
                        )}
                    </>
                );
            }}
        />
    );
};

export default Search;
