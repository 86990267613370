import React, { useEffect, useState } from 'react';
import Category from '../../../Filter/FilterOption/Category';
import Floor from '../../../Filter/FilterOption/Floor';
import Filter from '../../../Filter';
import Table from '../../../Common/Table';
import Card from '../../../Common/Card';
import useAsync from '../../../../util/hooks/useAsync';
import { fetchAlertInfo } from '../../../../api/alert';
import * as col from '../../util/grid/column';
import Search from '../../../Search';
import ReactSelect from '../../../Common/Select';
import { useFilterParam } from '../../../../util/hooks/useFilterParam';
import SearchAutocomplete from '../../../Search/SearchOption/SearchAutocomplete';
import useTranslation from '../../../../util/hooks/useTranslation';
import EVT_TYPE from '../../../../util/staticData/eventType';
import { SearchWrap, SelectGroup, FilterList } from '../../../Common/FilterSearchGroup/Components/Part';
import InputWrap from '../../../Common/FilterSearchGroup/Components/InputWrap';
import Button from '../../../Common/Button';
import InputGroup from '../../../Common/FilterSearchGroup/Components/InputGroup';
import DatePicker from '../../../Common/DatePicker';
import moment from 'moment';
import Page from '../../../Common/Page';

const getEvtTypeOptions = evtTypeMap => {
    const evtTypeOptions = [];
    for (let type in evtTypeMap) {
        evtTypeOptions.push({ value: type, label: evtTypeMap[type] });
    }
    return evtTypeOptions;
};

const AlertHistory = () => {
    const t = useTranslation('AlertHistory');
    const [listUpdateKey, setListUpdateKey] = useState(Date.now());
    const [alertInfo, setAlertInfo] = useState({ rows: [] });
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [eventType, setEventType] = useState('');
    const [searchKeyword, setSearchKeyword] = useState(null);
    const [emptyInput, setEmptyInput] = useState(false);
    const [selectedEventType, setSelectedEventType] = useState('');
    const [resetDate, setResetDate] = useState(false);
    const [changedFilterParam, setChangedFilterParam] = useState(false);
    const filterParam = useFilterParam();

    const { promise: getAlertInfo } = useAsync({
        promise: fetchAlertInfo,
        resolve: res => {
            setAlertInfo(res || { rows: [], page: 1 });
        },
        reject: error => {
            console.log(error);
        },
    });

    const evenTypeOptions = getEvtTypeOptions(EVT_TYPE);

    const alertHistoryColumns = [
        col.occurrenceDatetimeAT(),
        col.eventTypeAT(),
        col.categoryNameAT(),
        col.targetNameAT(),
        col.targetIdAT(),
        col.floorAT(),
    ];

    const handleClick = () => {
        setListUpdateKey(Date.now());
        getAlertInfo({
            startDate: startDate ? moment(startDate).unix() : null,
            endDate: endDate ? moment(endDate).unix() : null,
            opt: 'targetId',
            keyword: searchKeyword,
            interfaceCommandTypes: selectedEventType,
            pageSize: 20,
            ...filterParam,
        });
    };

    useEffect(() => {
        if (!changedFilterParam && filterParam.categoryCodes) {
            getAlertInfo({ ...filterParam, pageSize: 20 });
            setChangedFilterParam(true);
        }
    }, [filterParam]);

    const handleEventTypeChange = selectedEvent => {
        if (selectedEvent.value !== '') {
            setEventType(selectedEvent);
            setSelectedEventType(selectedEvent.value);
        }
    };

    const handleSelectedChange = selected => {
        if (selected[0]) {
            setSearchKeyword(selected[0].value);
        } else {
            setSearchKeyword('');
        }
    };

    const refreshSearchSection = () => {
        setEmptyInput(!emptyInput);
        setEventType({ value: '', label: t('Event type') });
        setResetDate(!resetDate);
        setStartDate(null);
        setEndDate(null);
        setSearchKeyword(null);
        setSelectedEventType('');
        setListUpdateKey(Date.now());
    };

    return (
        <Page className={'h-100'}>
            <Filter
                searchRefresh={refreshSearchSection}
                Search={
                    <Search>
                        <div className="search-box flx-row flx-top">
                            <FilterList>
                                <SelectGroup>
                                    <div className={'datePicker-container'}>
                                        <DatePicker
                                            value={startDate}
                                            handleChange={selected => setStartDate(selected)}
                                            valueType={'ms'}
                                            maxDate={endDate || new Date()}
                                            showTimeInput
                                        />
                                    </div>
                                </SelectGroup>
                                <div
                                    className={'text-center d-flex align-items-center justify-content-center'}
                                    style={{ height: '10px' }}
                                >
                                    {` ~ `}
                                </div>
                                <SelectGroup>
                                    <div className={'datePicker-container'}>
                                        <DatePicker
                                            value={endDate}
                                            handleChange={selected => setEndDate(selected)}
                                            valueType={'ms'}
                                            minDate={startDate}
                                            maxDate={new Date()}
                                            showTimeInput
                                        />
                                    </div>
                                </SelectGroup>
                            </FilterList>
                        </div>
                        <div className="search-box flx-row flx-top gap-1">
                            <SearchWrap>
                                <SelectGroup>
                                    <ReactSelect
                                        onChange={handleEventTypeChange}
                                        options={evenTypeOptions}
                                        placeholder={t('Event type')}
                                        value={eventType}
                                        name={'eventType'}
                                        customControlStyles={{
                                            width: '100%',
                                        }}
                                        customMenuStyles={{ width: '100%' }}
                                        customOptionStyles={{ width: '100%' }}
                                    />
                                </SelectGroup>
                                <InputWrap>
                                    <InputGroup>
                                        <SearchAutocomplete
                                            emptyInput={emptyInput}
                                            handleSelectedKeyword={handleSelectedChange}
                                        />
                                    </InputGroup>
                                    <Button
                                        className={'btn-brand btn-icon'}
                                        iconClassName={'icon-search'}
                                        onClick={handleClick}
                                    >
                                        {t('Search', 'Search')}
                                    </Button>
                                </InputWrap>
                            </SearchWrap>
                        </div>
                    </Search>
                }
            >
                <Category />
                <Floor />
            </Filter>
            <Card
                key={1}
                header={{
                    title: t('History List'),
                }}
            >
                <div style={{ height: '32.8rem' }}>
                    <Table
                        key={listUpdateKey}
                        data={alertInfo}
                        columns={alertHistoryColumns}
                        onPageChange={pageIndex => {
                            if (filterParam.categoryCodes) {
                                const requestParam = {
                                    pageSize: 20,
                                    page: pageIndex,
                                    startDate: startDate ? moment(startDate).unix() : null,
                                    endDate: endDate ? moment(endDate).unix() : null,
                                    opt: 'targetId',
                                    keyword: searchKeyword,
                                    interfaceCommandTypes: selectedEventType,
                                    ...filterParam,
                                };
                                getAlertInfo(requestParam);
                            }
                        }}
                    />
                </div>
            </Card>
        </Page>
    );
};

export default AlertHistory;
