import React from 'react';
import useTranslation from '../../../util/hooks/useTranslation';
import WidgetSettingModal from '../Components/WidgetSettingModal';

const RegisteredAssetSetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('RegisteredAsset');
    return (
        <WidgetSettingModal
            headerTitle={t('Registered asset widget setting')}
            widgetInfo={widgetInfo}
            hiddenHeader
            {...restProps}
        />
    );
};

export default RegisteredAssetSetting;
