import React, { useState } from 'react';
import ResponsiveBarChart from '../../Common/Charts/BarChart';
import ReactResizeDetector from 'react-resize-detector';
import { useSettings } from '../util/useSettings';
import { fetchTriggeredAlertStatus } from '../../../api/statistics';
import useMonitor from '../util/useMonitor';
import { Col, Row, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import GridLayoutCard from '../Components/GridLayoutCard';
import PerfectScrollbar from 'react-perfect-scrollbar';

const TriggeredAlertStatus = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const [tableHeight, setTableHeight] = useState();
    const { t } = useTranslation();

    const defaultData = { rows: [{ key: 'PNT', name: 'PNT', count: 0 }] };
    const chartMeta = {
        dataSet: [{ key: 'count', name: t('TriggeredAlertStatus;Count'), color: '#1f77b4' }],
    };

    const { data } = useMonitor({
        config,
        defaultData,
        fetchData: fetchTriggeredAlertStatus,
        makeData: ({ newData }) => {
            const triggeredAlertList = newData.rows || [];
            let chartData = [
                {
                    key: 'SOS',
                    name: 'SOS',
                    count: 0,
                },
                {
                    key: 'Restricted',
                    name: t('TriggeredAlertStatus;Restricted'),
                    count: 0,
                },
                {
                    key: 'Long Stay',
                    name: 'Long Stay',
                    count: 0,
                },
                {
                    key: 'Out Of Range',
                    name: t('TriggeredAlertStatus;Out Of Range'),
                    count: 0,
                },
                {
                    key: 'Lost Signal',
                    name: t('TriggeredAlertStatus;Lost Signal'),
                    count: 0,
                },
                {
                    key: 'Low Battery',
                    name: 'Low Battery',
                    count: 0,
                },
            ];

            if (triggeredAlertList) {
                for (let i = 0; i < triggeredAlertList.length; i++) {
                    switch (triggeredAlertList[i].eventType) {
                        case 'SN':
                        case 'SF':
                            chartData[0] = {
                                ...chartData[0],
                                count: chartData[0].count + triggeredAlertList[i].eventCnt,
                            };
                            break;
                        case 'RI':
                        case 'RS':
                        case 'RO':
                            chartData[1] = {
                                ...chartData[1],
                                count: chartData[1].count + triggeredAlertList[i].eventCnt,
                            };
                            break;
                        case 'LN':
                        case 'LF':
                            chartData[2] = {
                                ...chartData[2],
                                count: chartData[2].count + triggeredAlertList[i].eventCnt,
                            };
                            break;
                        case 'SO':
                            chartData[3] = {
                                ...chartData[3],
                                count: chartData[3].count + triggeredAlertList[i].eventCnt,
                            };
                            break;
                        case 'LS':
                            chartData[4] = {
                                ...chartData[4],
                                count: chartData[4].count + triggeredAlertList[i].eventCnt,
                            };
                            break;
                        case 'LB':
                            chartData[5] = {
                                ...chartData[5],
                                count: chartData[5].count + triggeredAlertList[i].eventCnt,
                            };
                            break;
                    }
                }
            }
            return chartData;
        },
    });

    return (
        <GridLayoutCard widgetInfo={widgetInfo} {...restProps}>
            <div className={'scrollbar-container'}>
                <PerfectScrollbar>
                    <Row style={{ height: '15rem' }}>
                        <ResponsiveBarChart meta={chartMeta} data={data} />
                    </Row>
                    <Row>
                        <ReactResizeDetector
                            handleHeight
                            onResize={(width, height) => {
                                setTableHeight(height);
                            }}
                        >
                            <Col>
                                {t('TriggeredAlertStatus;Today / Count')}
                                <Table className={'mb-0'}>
                                    <thead>
                                        <tr>
                                            <th className={'text-center'}>SOS</th>
                                            <th className={'text-center'}>{t('TriggeredAlertStatus;Restricted')}</th>
                                            <th className={'text-center'}>Long Stay</th>
                                            <th className={'text-center'}>{t('TriggeredAlertStatus;Out Of Range')}</th>
                                            <th className={'text-center'}>{t('TriggeredAlertStatus;Lost Signal')}</th>
                                            <th className={'text-center'}>Low Battery</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {data.map((v, i) => (
                                                <td key={v.key} className={'text-center'}>
                                                    {v.count || 0}
                                                </td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </ReactResizeDetector>
                    </Row>
                </PerfectScrollbar>
            </div>
            {children}
        </GridLayoutCard>
    );
};

export default TriggeredAlertStatus;
