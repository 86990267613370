import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { fetchFloorInfo } from '../../../api/common';
import useAsync from '../../../util/hooks/useAsync';
import FilterOptionBox from '../../Filter/FilterOption/FilterOptionBox';
import useTranslation from '../../../util/hooks/useTranslation';
import { getLeafFloors } from '../../../util/common/common';

const Floor = ({ selected, handleChange }) => {
    const t = useTranslation('Floor');
    const { filterInfo } = useSelector(state => state.Filter);
    const [originFloorList, setOriginFloorList] = useState([]);
    const [options, setOptions] = useState([]);

    const { promise: getFloorInfo } = useAsync({
        promise: fetchFloorInfo,
        resolve: res => {
            let tempFloorList = getLeafFloors(res.rows);
            tempFloorList = tempFloorList.map(floor => ({
                ...floor,
                bounds: [
                    [floor.swLat, floor.swLng],
                    [floor.neLat, floor.neLng],
                ],
            }));
            setOriginFloorList(tempFloorList || []);
        },
        reject: error => {
            console.log(error);
            setOptions([]);
        },
    });

    useEffect(() => {
        getFloorInfo({ isAll: 'Y' });
    }, []);

    useEffect(() => {
        if (filterInfo.floorIds.length) {
            setOptions(originFloorList.filter(floor => filterInfo.floorIds.includes(floor.floorId)));
        } else {
            setOptions(originFloorList);
        }
    }, [originFloorList, filterInfo]);

    return (
        <FilterOptionBox className={'flex2 mr-0'}>
            <Select
                isSearchable={false}
                value={options.filter(v => v.floorId === selected)}
                onChange={handleChange}
                options={options}
                placeholder={t('Select a floor')}
                getOptionValue={option => option.floorId}
                getOptionLabel={option => option.floorName}
                className="react-select z-index-1"
                classNamePrefix="react-select"
            />
        </FilterOptionBox>
    );
};

export default Floor;
