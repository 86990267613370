import React, { useMemo, useState } from 'react';
import WidgetSettingModal from '../Components/WidgetSettingModal';
import { useDispatch } from 'react-redux';
import { editWidgetSettings } from '../../../reducers/Dashboards/DashboardEdit';
import useTranslation from '../../../util/hooks/useTranslation';
import { useSettings } from '../util/useSettings';
import SingleSelect from '../../Common/Select/SingleSelect';
import Label from '../../Common/Label';

const IotItemStatusSetting = ({ widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const { settings: customSettings } = config;
    const dispatch = useDispatch();
    const settings = useSettings(config);

    const t = useTranslation('IotItemStatus');

    const categoryOptions = useMemo(() => {
        return [
            { value: 'ASSET', label: t('asset') },
            { value: 'PEOPLE', label: t('people') },
        ];
    }, [t]);

    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});

    const handleSubmitClick = data => {
        if (customSettings) {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...customSettings, ...data, ...updatedWidgetInfo },
                }),
            );
        } else {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...data, ...updatedWidgetInfo },
                }),
            );
        }
    };

    const handleCategoryChange = selectedCategory => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, categoryCode: selectedCategory };
        });
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    return (
        <WidgetSettingModal
            headerTitle={t('Iot Item Status Setting')}
            widgetInfo={widgetInfo}
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <Label
                name={t('Type of Category', 'CategoryChart')}
                value={
                    <SingleSelect
                        className={'w-100'}
                        options={categoryOptions}
                        value={updatedWidgetInfo.categoryCode ? updatedWidgetInfo.categoryCode : settings.categoryCode}
                        onChange={handleCategoryChange}
                    />
                }
            />
        </WidgetSettingModal>
    );
};

export default IotItemStatusSetting;
