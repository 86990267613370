import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from '../../../reducers/Filter';
import { fetchFloorInfo } from '../../../api/common';
import useAsync from '../../../util/hooks/useAsync';
import FilterOptionBox from './FilterOptionBox';
import { setFloorList } from '../../../reducers/CommonInfo';
import useTranslation from '../../../util/hooks/useTranslation';
import { getLeafFloors } from '../../../util/common/common';
import TreeSelect from '../../Common/Select/TreeSelect';

const Floor = ({}) => {
    const dispatch = useDispatch();
    const t = useTranslation('Filter');
    const { floorList } = useSelector(state => state.CommonInfo);
    const { floorIds } = useSelector(state => state.Filter.filterInfo);
    const { promise: getFloorInfo } = useAsync({
        promise: fetchFloorInfo,
        resolve: res => {
            const tempFloorList = getLeafFloors(res.rows);
            dispatch(setFloorList({ ...res, rows: tempFloorList }));
        },
        reject: error => {
            console.log(error);
            dispatch(setFloorList({ rows: [] }));
        },
    });

    const handleChange = selectedOption => {
        dispatch(
            setFilter({
                floorIds: selectedOption,
            }),
        );
    };

    useEffect(() => {
        getFloorInfo({ isAll: 'Y' });
    }, []);

    return (
        <FilterOptionBox>
            <TreeSelect
                data={floorList}
                selected={floorIds}
                title={t('Floor')}
                labelKey={'floorName'}
                valueKey={'floorId'}
                parentKey={'upperFloorId'}
                onChange={handleChange}
            />
        </FilterOptionBox>
    );
};

export default Floor;
