import { reqGet, reqPost, reqDelete } from './index';

export const fetchCategoryInfo = param => reqGet('/v3/api/common/target/category/info', { ...param });
export const fetchCategoryConfigInfo = param =>
    reqGet('/v3/api/common/target/category/config', { ...param, isAll: 'Y' });
export const fetchGeofenceInfo = param => reqGet('/v3/api/common/geofence', param);
export const fetchFloorInfo = param => reqGet('/v3/api/common/floor', { ...param, isAll: 'Y' });
export const fetchSelectedFloorInfo = param => reqGet(`/v3/api/common/floor/${param.floorId}`, param);

export const createBookmark = param => reqPost('/v3/api/common/target/bookmark', param);
export const deleteBookmark = param => reqDelete(`/v3/api/common/target/bookmark/${param.targetNum}`);
export const fetchBookmark = param => reqGet('/v3/api/common/target/bookmark/info', param);
