import React, { useEffect, useMemo, useState } from 'react';
import WidgetCard from '../Components/WidgetCard';
import SimpleCount from '../../MainPages/Components/SimpleCount';
import useTranslation from '../../../util/hooks/useTranslation';
import useMonitor from '../util/useMonitor';
import { fetchPlacementState } from '../../../api/widget';
import { useSelector } from 'react-redux';
import Card from '../../Common/Card';
import Table from '../../Common/Table';
import useColumns from '../../../util/hooks/useColumns';
import * as column from '../../MainPages/util/grid/column';
import { fetchCountByCategory } from '../../../api/category';
import { fetchAssetList } from '../../../api/asset';
import useAsync from '../../../util/hooks/useAsync';
import Button from '../../Common/Button';
import AssetTable from '../../MainPages/Components/AssetTable';

const checkFalsy = value => {
    return value ? value : 0;
};

const returnCategoryCodeStr = arr => {
    let tempArr = [];
    arr.map(category => tempArr.push(category.categoryCode));
    return tempArr.toString();
};
const category = '20202020202020202020,ASSET01,ASSET02,ASSET_03,ca,inj,sad,sse_test';
const defaultData = [];

const AssetTrackingByCategory = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('AssetTrackingByCategoryWidget');
    const { config } = widgetInfo;
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const [checkAll, setCheckAll] = useState(false);
    const [categoryCountInfo, setCategoryCountInfo] = useState([]);
    const [categoryCodes, setCategoryCodes] = useState('');
    const [page, setPage] = useState(1);
    const [targetNum, setTargetNum] = useState(null);

    const toggleCheck = ({ currentTarget: { id, checked } }) => {
        const rows = categoryCountInfo;

        const updatedRows = rows.map(info => {
            if (info.categoryCode === id) {
                return { ...info, checked };
            }
            return info;
        });

        const checkedArr = updatedRows.filter(v => v.checked);
        const categoryCodesStr = returnCategoryCodeStr(checkedArr);
        setCategoryCodes(categoryCodesStr);

        if (!categoryCodesStr) {
            setPage(1);
        }

        if (rows.length === checkedArr.length) {
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        setCategoryCountInfo(updatedRows);
    };

    const toggleCheckAll = e => {
        const checked = e.currentTarget.checked;
        setCheckAll(checked);
        setCategoryCodes(checked ? returnCategoryCodeStr(categoryCountInfo) : '');
        setCategoryCountInfo(categoryCountInfo.map(info => ({ ...info, checked: checked })));
    };

    const handlePageChange = pageNum => {
        setPage(pageNum);
        // getAssetList({ categoryCodes: categoryCodes, page: pageNum });
    };

    const columns = useColumns(
        [
            {
                Header: (
                    <input
                        type={'checkbox'}
                        name={'categoryCodeCheckAll'}
                        onChange={toggleCheckAll}
                        checked={checkAll}
                    />
                ),
                accessor: 'checked',
                Cell: ({ value, row: { original } }) => {
                    return (
                        <div className={'d-flex align-items-center'}>
                            <input
                                id={original.categoryCode}
                                type="checkbox"
                                name={'categoryCode'}
                                value={value}
                                checked={value}
                                onChange={toggleCheck}
                            />
                        </div>
                    );
                },
                width: 30,
                className: 'text-center',
                headerClassName: 'text-center',
            },
            column.categoryName(),
            column.categoryCount(),
        ],
        t,
        [categoryCountInfo],
    );

    const assetListColumns = useColumns([
        column.categoryName(),
        column.targetName(),
        column.floorName(),
        column.lastDate(),
    ]);

    const filterParam = useMemo(() => {
        let tempParam = {
            categoryCodes: category,
        };
        return tempParam;
    }, []);

    const listFilterParam = useMemo(() => {
        let tempParam = {
            categoryCodes: categoryCodes ? categoryCodes : category,
            page: page,
            pageSize: 20,
        };

        return tempParam;
    }, [categoryCodes, page]);

    const { data } = useMonitor({
        config,
        defaultData,
        dynamicParam: filterParam,
        fetchData: fetchPlacementState,
    });

    const { data: assetInfo } = useMonitor({
        config,
        defaultData,
        dynamicParam: listFilterParam,
        fetchData: fetchAssetList,
    });

    const { promise: getCountByCategory } = useAsync({
        promise: fetchCountByCategory,
        resolve: res => {
            if (res.rows) {
                setCategoryCountInfo(res.rows.map(category => ({ ...category, checked: checkAll })));
                if (categoryCodes) {
                    const tempArr = categoryCodes.split(',');
                    setCategoryCountInfo(
                        res.rows.map(category => ({ ...category, checked: tempArr.includes(category.categoryCode) })),
                    );
                } else {
                    setCategoryCountInfo(res.rows.map(category => ({ ...category, checked: checkAll })));
                }
            }
        },
        reject: error => {
            console.log(error);
        },
    });

    useEffect(() => {
        let tempArr = [];
        categoryList
            .filter(category => category.parentCode === 'ASSET')
            .map(category => tempArr.push(category.categoryCode));

        getCountByCategory({ categoryCodes: tempArr.toString() });
    }, [data]);

    useEffect(() => {
        let tempArr = [];
        categoryList
            .filter(category => category.parentCode === 'ASSET')
            .map(category => tempArr.push(category.categoryCode));
        if (categoryCodes) {
            setPage(1);
        }
    }, [categoryCodes]);

    return (
        <WidgetCard widgetInfo={widgetInfo} {...restProps}>
            <div className={'d-grid h-100'} style={{ gridTemplateColumns: '1fr 2fr', columnGap: '10px' }}>
                <div className={'d-grid'} style={{ gridTemplateRows: '200px auto' }}>
                    <SimpleCount
                        key={'1'}
                        widgetInfo={{
                            title: t('Count By Placement State'),
                            mainCnt: checkFalsy(data.detectedTargetCnt),
                            mainAllCnt: checkFalsy(data.total),
                            mainStatus: t('Mapped'),
                            colorClass: 'color-secondary',
                            detailCnt: [
                                {
                                    colorClass: 'color-success',
                                    label: t('In'),
                                    value: checkFalsy(data.locatedInTargetCnt),
                                },
                                {
                                    colorClass: 'color-danger',
                                    label: t('Out'),
                                    value: checkFalsy(data.locatedOutTargetCnt),
                                },
                                {
                                    colorClass: 'color-gray',
                                    label: t('Unknown'),
                                    value: checkFalsy(data.neverDetectedTargetCnt),
                                },
                            ],
                        }}
                    />
                    <Card header={{ title: t('Count by category') }}>
                        <Table paging={false} data={{ rows: categoryCountInfo }} columns={columns} />
                    </Card>
                </div>
                <Card
                    header={{
                        title: <>{targetNum ? t('Selected Asset', 'Menu') : t('Asset List', 'Menu')}</>,
                        action: targetNum && (
                            <Button className={'btn-brand'} onClick={() => setTargetNum(null)}>
                                {t('Back')}
                            </Button>
                        ),
                    }}
                >
                    <AssetTable
                        targetNum={targetNum}
                        data={assetInfo.rows && assetInfo.rows.length > 0 ? assetInfo : { rows: [] }}
                        columns={assetListColumns}
                        onPageChange={handlePageChange}
                        onTrClick={({ targetNum }) => {
                            setTargetNum(targetNum);
                        }}
                    />
                </Card>
            </div>

            {children}
        </WidgetCard>
    );
};

export default AssetTrackingByCategory;
