import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { HourlyCongestionStatusContext, HourlyCongestionStatusDispatchContext } from '../index';
import { setEndDate, setSelectedCategory, setSelectedFloor, setStartDate } from '../hourlyCongestionStatusReducer';
import FilterSearchGroup from '../../../Common/FilterSearchGroup';
import DatePicker from '../../../Common/DatePicker';
import Button from '../../../Common/Button';
import SingleSelect from '../../../Common/Select/SingleSelect';
import FloorTreeSelect from '../../Components/FloorTreeSelect';
import moment from 'moment';
import SearchGroup from '../../../Common/FilterSearchGroup/Components/SearchGroup';
import { FilterList, SelectGroup } from '../../../Common/FilterSearchGroup/Components/Part';
import SearchButtonArea from '../../../Common/FilterSearchGroup/Components/SearchButtonArea';

const HourlyCongestionStatusFilterGroup = ({ handleSearchBtnClick }) => {
    const { t } = useTranslation();
    const [categoryListWithAllOption, setCategoryListWithAllOption] = useState([]);
    const { floorList } = useSelector(state => state.FloorInfo);
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const { selectedCategory, selectedFloor, startDate, endDate } = useContext(HourlyCongestionStatusContext);
    const dispatch = useContext(HourlyCongestionStatusDispatchContext);

    const handleSelectCategoryChange = e => {
        dispatch(setSelectedCategory(e));
    };

    const handleYesterdayBtnClick = () => {
        dispatch(setStartDate(moment().subtract(1, 'days').startOf('day').valueOf()));
        dispatch(setEndDate(moment().subtract(1, 'days').endOf('day').valueOf()));
    };

    const handleTodayBtnClick = () => {
        dispatch(setStartDate(moment().startOf('day').valueOf()));
        dispatch(setEndDate(moment().endOf('day').valueOf()));
    };

    const handleFloorChange = selects => {
        if (selects[0]) {
            dispatch(setSelectedFloor(selects[0].floorId));
        }
    };

    useEffect(() => {
        setCategoryListWithAllOption([{ categoryCode: '', categoryName: 'All' }, ...categoryList]);
    }, [categoryList]);

    return (
        <>
            <FilterSearchGroup className={'card absolute-filter'}>
                <SearchGroup label={t('CongestionStatus;Filter')}>
                    <SingleSelect
                        placeholder={'All'}
                        value={categoryListWithAllOption.length > 0 ? selectedCategory : ''}
                        onChange={handleSelectCategoryChange}
                        options={categoryListWithAllOption}
                        valueKey={'categoryCode'}
                        labelKey={'categoryName'}
                        listStyle={{ height: '200px', overflow: 'auto' }}
                    />
                    <FloorTreeSelect
                        key={selectedFloor}
                        floorList={floorList}
                        selectedFloorStr={selectedFloor}
                        mode={'radioSelect'}
                        handleChange={handleFloorChange}
                    />
                </SearchGroup>
                <SearchGroup className={'bg-brand'} label={t('CongestionStatus;Search')}>
                    <FilterList>
                        <SelectGroup>
                            <div className={'datePicker-container mr-2'}>
                                <DatePicker
                                    value={startDate}
                                    handleChange={selected => dispatch(setStartDate(selected))}
                                    valueType={'ms'}
                                    maxDate={endDate || new Date()}
                                    showTimeInput
                                />
                            </div>
                        </SelectGroup>
                        <div> ~ </div>
                        <SelectGroup>
                            <div className={'datePicker-container ml-2'}>
                                <DatePicker
                                    value={endDate}
                                    handleChange={selected => dispatch(setEndDate(selected))}
                                    valueType={'ms'}
                                    minDate={startDate}
                                    maxDate={new Date()}
                                    showTimeInput
                                />
                            </div>
                        </SelectGroup>
                    </FilterList>
                    <SearchButtonArea>
                        <Button className={'btn-secondary btn-yesterday'} onClick={handleYesterdayBtnClick}>
                            {t('Button;Yesterday')}
                        </Button>
                        <Button className={'btn-secondary btn-yesterday'} onClick={handleTodayBtnClick}>
                            {t('Button;Today')}
                        </Button>
                        <Button className="pnt-btn btn-brand" onClick={handleSearchBtnClick}>
                            {t('CongestionStatus;Search')}
                        </Button>
                    </SearchButtonArea>
                </SearchGroup>
            </FilterSearchGroup>
        </>
    );
};

export default HourlyCongestionStatusFilterGroup;
