import React, { useState, useEffect } from 'react';
import Category from '../../../Filter/FilterOption/Category';
import Filter from '../../../Filter';
import Table from '../../../Common/Table';
import * as col from '../../util/grid/column';
import Card from '../../../Common/Card';
import Floor from '../../../Filter/FilterOption/Floor';
import useAsync from '../../../../util/hooks/useAsync';
import { fetchGeofenceLog } from '../../../../api/log';
import { useFilterParam } from '../../../../util/hooks/useFilterParam';
import Search from '../../../Search';
import useTranslation from '../../../../util/hooks/useTranslation';
import ReactSelect from '../../../Common/Select';
import TextInput from '../../../Common/Input/TextInput';
import Page from '../../../Common/Page';
import { SearchWrap, SelectGroup, FilterList } from '../../../Common/FilterSearchGroup/Components/Part';
import InputWrap from '../../../Common/FilterSearchGroup/Components/InputWrap';
import InputGroup from '../../../Common/FilterSearchGroup/Components/InputGroup';
import Button from '../../../Common/Button';
import DatePicker from '../../../Common/DatePicker';
import moment from 'moment';

const Entrance = () => {
    const t = useTranslation('Entrance');
    const [listUpdateKey, setListUpdateKey] = useState(Date.now());
    const [entranceLogInfo, setEntranceLogInfo] = useState({ rows: [], page: 1 });
    const [inputOption, setInputOption] = useState('');
    const [keyword, setKeyword] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [resetDate, setResetDate] = useState(false);
    const [changedFilterParam, setChangedFilterParam] = useState(false);

    const searchOptions = [
        { value: 'targetName', label: t('Name', 'AssetList') },
        { value: 'targetId', label: t('ID', 'AssetList') },
    ];

    const { promise: getGeofenceLog } = useAsync({
        promise: fetchGeofenceLog,
        resolve: res => {
            setEntranceLogInfo(res || { rows: [], page: 1 });
        },
        reject: error => {
            console.log(error);
        },
    });
    const filterParam = useFilterParam();
    const geofenceLogColumns = [
        col.categoryNameAT(),
        col.targetNameAT(),
        col.targetIdAT(),
        col.entranceEventTypeAT(),
        col.positionAT(),
        col.entryDateAT(),
        col.exitDateAT(),
    ];

    useEffect(() => {
        if (!changedFilterParam && filterParam.categoryCodes) {
            getGeofenceLog({ ...filterParam, pageSize: 20 });
            setChangedFilterParam(true);
        }
    }, [filterParam]);

    const handleOptionChange = e => {
        setInputOption(e);
    };

    const handleValueChange = e => {
        setKeyword(e.target.value);
    };

    const refreshSearchSection = () => {
        setInputOption('');
        setKeyword('');
        setResetDate(!resetDate);
        setStartDate(null);
        setEndDate(null);
        setListUpdateKey(Date.now());
    };

    const handleClick = () => {
        setListUpdateKey(Date.now());
        getGeofenceLog({
            pageSize: 20,
            ...filterParam,
            startDate: startDate ? moment(startDate).unix() : null,
            endDate: endDate ? moment(endDate).unix() : null,
            opt: inputOption.value,
            keyword: keyword,
        });
    };

    return (
        <Page className="h-100">
            <Filter
                searchRefresh={refreshSearchSection}
                Search={
                    <>
                        <Search>
                            <div className="search-box flx-row flx-top">
                                <FilterList>
                                    <SelectGroup>
                                        <div className={'datePicker-container'}>
                                            <DatePicker
                                                value={startDate}
                                                handleChange={selected => setStartDate(selected)}
                                                valueType={'ms'}
                                                maxDate={endDate || new Date()}
                                                showTimeInput
                                            />
                                        </div>
                                    </SelectGroup>
                                    <div
                                        className={'text-center d-flex align-items-center justify-content-center'}
                                        style={{ height: '10px' }}
                                    >
                                        {` ~ `}
                                    </div>
                                    <SelectGroup>
                                        <div className={'datePicker-container'}>
                                            <DatePicker
                                                value={endDate}
                                                handleChange={selected => setEndDate(selected)}
                                                valueType={'ms'}
                                                minDate={startDate}
                                                maxDate={new Date()}
                                                showTimeInput
                                            />
                                        </div>
                                    </SelectGroup>
                                </FilterList>
                            </div>
                            <div className="search-box flx-row flx-top gap-1">
                                <SearchWrap>
                                    <SelectGroup>
                                        <ReactSelect
                                            name={'selectInput'}
                                            onChange={handleOptionChange}
                                            options={searchOptions}
                                            value={inputOption}
                                            placeholder={t('Search option')}
                                            customControlStyles={{
                                                width: '100%',
                                            }}
                                            customMenuStyles={{ width: '100%' }}
                                            customOptionStyles={{ width: '100%' }}
                                        />
                                    </SelectGroup>
                                    <InputWrap>
                                        <InputGroup>
                                            {inputOption ? (
                                                <TextInput
                                                    handleChange={handleValueChange}
                                                    value={keyword}
                                                    placeholder={t('Please enter a search keyword')}
                                                    name={'textInput'}
                                                />
                                            ) : (
                                                <TextInput
                                                    name={'optionNone'}
                                                    value={''}
                                                    disabled
                                                    placeholder={t('Please select option')}
                                                />
                                            )}
                                        </InputGroup>
                                        <Button
                                            className={'btn-brand btn-icon'}
                                            iconClassName={'icon-search'}
                                            onClick={handleClick}
                                        >
                                            {t('Search', 'Search')}
                                        </Button>
                                    </InputWrap>
                                </SearchWrap>
                            </div>
                        </Search>
                    </>
                }
            >
                <Category />
                <Floor />
            </Filter>
            <Card key={1} header={{ title: t('List') }}>
                <div style={{ height: '32.8rem' }}>
                    <Table
                        key={listUpdateKey}
                        data={entranceLogInfo}
                        columns={geofenceLogColumns}
                        onPageChange={pageIndex => {
                            if (filterParam.categoryCodes) {
                                const requestParam = {
                                    pageSize: 20,
                                    page: pageIndex,
                                    startDate: startDate ? moment(startDate).unix() : null,
                                    endDate: endDate ? moment(endDate).unix() : null,
                                    opt: inputOption.value,
                                    keyword: keyword,
                                    ...filterParam,
                                };
                                getGeofenceLog(requestParam);
                            }
                        }}
                    />
                </div>
            </Card>
        </Page>
    );
};

export default Entrance;
