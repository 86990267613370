import React, { useContext, useState } from 'react';
import Button from '../../Button';
import useTranslation from '../../../../util/hooks/useTranslation';
import { setFilterOpen } from '../filterSearchGroupReducer';
import { FilterSearchGroupDispatchContext, FilterSearchGroupStateContext } from '../index';

const FilterButtonArea = ({ children, widgetSearch = false }) => {
    const { filterOpen } = useContext(FilterSearchGroupStateContext);
    const dispatch = useContext(FilterSearchGroupDispatchContext);
    const handleSearchBoxToggle = () => dispatch(setFilterOpen(!filterOpen));
    const t = useTranslation('Search');
    return (
        <div className="filter-function">
            <Button
                className={`btn-gray btn-icon filter-open`}
                iconClassName={'icon-down'}
                onClick={handleSearchBoxToggle}
            >
                {t('More')}
            </Button>
            {children}
        </div>
    );
};

export default FilterButtonArea;
