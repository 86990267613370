import React from 'react';
import FilterOptionBox from './FilterOptionBox';
import SearchableSelect from '../../Common/Select/SearchableSelect';
import { setFilter } from '../../../reducers/Filter';
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from '../../../util/hooks/useTranslation';

const Authorization = () => {
    const dispatch = useDispatch();
    const { isRestrictionStatus } = useSelector(state => state.Filter.filterInfo);
    const t = useTranslation('Filter');
    const authorizationOptions = [
        { value: 'N', label: t('Authorized') },
        { value: 'Y', label: t('Restricted') },
    ];
    const handleChange = selectedOption => {
        if (selectedOption && selectedOption.length === 1) {
            if (Array.isArray(selectedOption)) {
                dispatch(setFilter({ isRestrictionStatus: selectedOption.map(v => v.value) }));
            } else {
                dispatch(setFilter({ isRestrictionStatus: selectedOption.value }));
            }
        } else {
            dispatch(setFilter({ isRestrictionStatus: null }));
        }
    };
    return (
        <FilterOptionBox>
            <SearchableSelect
                data={authorizationOptions}
                selected={isRestrictionStatus}
                title={t('Placement')}
                onChange={handleChange}
            />
        </FilterOptionBox>
    );
};

export default Authorization;
