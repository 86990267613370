import React from 'react';
import { Route } from 'react-router-dom';
import Entrance from './Entrance';
import AnalysisByLocation from './AnalysisByLocation';
import AnalysisByAsset from './AnalysisByAsset';
import { ErrorHandleSwitch } from '../Components/Router';

const PositionHistory = ({ match }) => {
    return (
        <ErrorHandleSwitch>
            <Route exact path={`${match.path}/entrance`} component={Entrance} />
            <Route exact path={`${match.path}/analysisByLocation`} component={AnalysisByLocation} />
            <Route exact path={`${match.path}/analysisByAsset`} component={AnalysisByAsset} />
        </ErrorHandleSwitch>
    );
};

export default PositionHistory;
