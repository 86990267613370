import React, { useState, useReducer, createContext, useEffect, useCallback } from 'react';
import Category from '../../../Filter/FilterOption/Category';
import Filter from '../../../Filter';
import Search from '../../../Search';
import Table from '../../../Common/Table';
import Card from '../../../Common/Card';
import useAsync from '../../../../util/hooks/useAsync';
import { fetchTargetLocationLog } from '../../../../api/log';
import Map from '../../../Common/Map/Components/Map';
import { LayersControl, Marker } from 'react-leaflet';
import L from 'leaflet';
import Floor from '../../../Filter/FilterOption/Floor';
import PlayController from '../Components/PlayController';
import { useFilterParam } from '../../../../util/hooks/useFilterParam';
import * as col from '../../util/grid/column';
import moment from 'moment';
import ConfirmModal from '../../../Common/ConfirmModal';
import { initialState, playControllerReducer, setInitState } from '../Components/PlayController/playControllerReducer';
import RotatedImageOverlay from '../../../Common/Map/Components/RotatedImageOverlay';
import notFoundImg from '../../../../assets/images/image-not-found.jpg';
import SearchAutocomplete from '../../../Search/SearchOption/SearchAutocomplete';
import useTranslation from '../../../../util/hooks/useTranslation';
import { fetchFloorInfo, fetchGeofenceInfo } from '../../../../api/common';
import GeofenceLayer from '../../../Common/Map/Layers/GeofenceLayer';
import { getLeafFloors } from '../../../../util/common/common';
import HeatmapLayer from 'react-leaflet-heatmap-layer';
import Control from 'react-leaflet-control';
import MapOptionSelect from '../Components/MapOptionSelect';
import { Polyline } from 'react-leaflet/es';
import { useSelector } from 'react-redux';
import Page from '../../../Common/Page';
import {
    FilterList,
    SelectGroup,
    SearchWrap,
    InputWrap,
    InputGroup,
} from '../../../Common/FilterSearchGroup/Components/Part';
import DatePicker from '../../../Common/DatePicker';
import Button from '../../../Common/Button';

const now = moment().unix();
const defaultEndTime = now + 1;

export const PlayControllerStateContext = createContext();
export const PlayControllerDispatchContext = createContext();

const AnalysisByAsset = () => {
    const t = useTranslation('AnalysisByAsset');
    const { lang } = useSelector(state => state.UserInfo);
    const [state, dispatch] = useReducer(playControllerReducer, initialState);
    const [beforePlayTime, setBeforePlayTime] = useState();
    const filterParam = useFilterParam();
    const [selectAssetModal, setSelectAssetModal] = useState(false);
    const [selectDateRangeModal, setSelectDateRangeModal] = useState(false);
    const [searchParam, setSearchParam] = useState({});
    const [emptyInput, setEmptyInput] = useState(false);
    const [refreshDatepicker, setRefreshDatepicker] = useState(false);
    const [geofenceInfo, setGeofenceInfo] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [error, setError] = useState(false);
    const [floorInfo, setFloorInfo] = useState([]);
    const [currentFloor, setCurrentFloor] = useState({});
    const [markerLogListInfo, setMarkerLogListInfo] = useState({});
    const [heatmapLogInfo, setHeatmapLogInfo] = useState([]);
    const [withinOneMinuteHeatmapLogInfo, setWithinOneMinuteHeatmapLogInfo] = useState([]);
    const [spaghettiBeforePlaytimeLogInfo, setSpaghettiBeforePlaytimeLogInfo] = useState([]);
    const [selectedMapOption, setSelectedMapOption] = useState([{ value: '', label: t('None') }]);
    const [locationLogListInfo, setLocationLogListInfo] = useState({
        rows: [],
        totalCount: 0,
        totalPage: 0,
        pageSize: 0,
    });
    const assetColumn = [col.floorAT({ className: 'd-flex justify-content-center' }), col.regDateAT()];
    const gradient = {
        0.2: '#5a81e6',
        0.4: '#dbe371',
        0.6: '#d49657',
        0.8: '#d45e57',
        0.9: '#d45e57',
    };

    const { promise: getGeofenceInfo } = useAsync({
        promise: fetchGeofenceInfo,
        resolve: res => {
            if (res.rows) {
                setGeofenceInfo(
                    res.rows.map(geofence => {
                        return { ...geofence, bounds: geofence.latLngList.map(latLng => [latLng.lat, latLng.lng]) };
                    }),
                );
            } else {
                setGeofenceInfo([]);
            }
        },
        reject: error => {
            console.log(error);
            setGeofenceInfo([]);
        },
    });

    const { promise: getFloorInfo } = useAsync({
        promise: fetchFloorInfo,
        resolve: res => {
            const tempFloorList = getLeafFloors(res.rows);
            setFloorInfo({ ...res, rows: tempFloorList } || {});
        },
        reject: error => {
            console.log(error);
            setFloorInfo({ rows: [] });
        },
    });

    const { promise: getTargetLocationLog } = useAsync({
        promise: fetchTargetLocationLog,
        resolve: res => {
            const tempArrForTable = [];
            const tempArr = res.rows;
            const indexArr = [];
            let tempLog;
            let newFloorIndex;
            if (res.rows.length > 0) {
                tempLog = [];
                // regDate 기준으로 오름차순
                tempArr.sort(function (a, b) {
                    return a.regDate - b.regDate;
                });

                for (let i = 0; i < tempArr.length; i++) {
                    tempLog.unshift([tempArr[i].lat, tempArr[i].lng, 1, tempArr[i].regDate]);
                }

                newFloorIndex = 0;
                indexArr.push(newFloorIndex);

                const foundedFloor = floorInfo.rows.find(floor => floor.floorId === tempArr[0].floorId);
                setCurrentFloor(foundedFloor);
                // tempArr를 돌면서 만약 이전과 다른 floorId를 가지고 있다면 그 index를 저장 후 array 에 저장
                for (let i = 1; i < tempArr.length; i++) {
                    if (tempArr[newFloorIndex].floorId !== tempArr[i].floorId) {
                        newFloorIndex = i;
                        indexArr.push(newFloorIndex);
                    }
                }

                // 저장된 index들을 통해 각 층의 시작시간과 종료시간을 구하고 locationLogListInfo에 저장
                for (let i = 0; i < indexArr.length; i++) {
                    let tempStartDate = moment(tempArr[indexArr[i]].regDate * 1000).format('MM-DD HH:mm:ss');
                    let tempEndDate;
                    if (i + 1 === indexArr.length) {
                        tempEndDate = moment(tempArr[tempArr.length - 1].regDate * 1000).format('MM-DD HH:mm:ss');
                    } else {
                        tempEndDate = moment(tempArr[indexArr[i + 1] - 1].regDate * 1000).format('MM-DD HH:mm:ss');
                    }

                    tempArrForTable.push({
                        floorName: tempArr[indexArr[i]].floorId,
                        regDate: `${tempStartDate} - ${tempEndDate}`,
                    });
                }
                setHeatmapLogInfo(tempLog);
                setLocationLogListInfo({ ...locationLogListInfo, rows: tempArrForTable });
            }

            const markerLog = {};
            let tempStartTime = now;
            let tempEndTime = defaultEndTime;

            if (res.rows) {
                tempStartTime = null;
                tempEndTime = null;
                const rows = res.rows;
                for (let i = 0, len = rows.length; i < len; i++) {
                    const log = rows[i];

                    if (markerLog[log.regDate]) {
                        markerLog[log.regDate].push(log);
                    } else {
                        markerLog[log.regDate] = [log];
                    }

                    if (!tempStartTime || tempStartTime > log.regDate) {
                        tempStartTime = log.regDate;
                    }
                    if (!tempEndTime || tempEndTime < log.regDate) {
                        tempEndTime = log.regDate;
                    }
                }
            }

            if (tempStartTime && tempEndTime) {
                dispatch(setInitState({ startTime: tempStartTime, endTime: tempEndTime }));
            }
            setMarkerLogListInfo(markerLog);
        },
        reject: err => {
            console.log(err);
        },
    });

    const handleSearch = () => {
        if (!(startDate && endDate)) {
            setSelectDateRangeModal(true);
            return false;
        }
        if (!searchParam.targetId) {
            setSelectAssetModal(true);
            return false;
        }
        if (searchParam.targetId && startDate && endDate) {
            setCurrentFloor({});
            getTargetLocationLog({
                ...filterParam,
                opt: 'targetId',
                targetId: searchParam.targetId,
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
                isAll: 'Y',
            });
        }
    };

    useEffect(() => {
        getGeofenceInfo({ isAll: 'Y' });
        getFloorInfo({ isAll: 'Y' });
    }, []);

    useEffect(() => {
        const tempSpaghettiLog = [];
        const thisLog = markerLogListInfo[state.playTime];
        const tempLog = heatmapLogInfo.filter(log => log[3] <= state.playTime);
        setWithinOneMinuteHeatmapLogInfo(tempLog);

        const logInOneMinute = tempLog.filter(log => log[3] >= state.playTime - 60);
        logInOneMinute.map(log => tempSpaghettiLog.unshift([log[0], log[1]]));

        setSpaghettiBeforePlaytimeLogInfo(tempSpaghettiLog);
        setWithinOneMinuteHeatmapLogInfo(tempLog);

        if (thisLog && thisLog.length > 0) {
            if (thisLog[0].floorId !== currentFloor.floorId) {
                const foundedFloor = floorInfo.rows.find(floor => floor.floorId === thisLog[0].floorId);
                setCurrentFloor(foundedFloor);
            }
        }
        return () => {
            if (markerLogListInfo[state.playTime]) {
                setBeforePlayTime(state.playTime);
            }
        };
    }, [state.playTime]);

    const handleSearchRefresh = () => {
        setEmptyInput(!emptyInput);
        setRefreshDatepicker(!refreshDatepicker);
        setStartDate(null);
        setEndDate(null);
    };

    const handleTargetId = selected => {
        if (selected.length > 0) {
            setSearchParam({ targetId: selected[0].value });
        }
    };

    const handleSelectedMapOption = useCallback(selected => {
        setSelectedMapOption(selected);
    }, []);

    return (
        <Page className={'h-100'}>
            <Filter
                searchRefresh={handleSearchRefresh}
                Search={
                    <Search>
                        <div className="search-box flx-row flx-top">
                            <FilterList>
                                <SelectGroup>
                                    <div className={'datePicker-container'}>
                                        <DatePicker
                                            value={startDate}
                                            handleChange={selected => setStartDate(selected)}
                                            valueType={'ms'}
                                            maxDate={endDate || new Date()}
                                            showTimeInput
                                        />
                                    </div>
                                </SelectGroup>
                                <div
                                    className={'text-center d-flex align-items-center justify-content-center'}
                                    style={{ height: '10px' }}
                                >
                                    {` ~ `}
                                </div>
                                <SelectGroup>
                                    <div className={'datePicker-container'}>
                                        <DatePicker
                                            value={endDate}
                                            handleChange={selected => setEndDate(selected)}
                                            valueType={'ms'}
                                            minDate={startDate}
                                            maxDate={new Date()}
                                            showTimeInput
                                        />
                                    </div>
                                </SelectGroup>
                            </FilterList>
                        </div>
                        <div className="search-box flx-row flx-top gap-1">
                            <SearchWrap>
                                <InputWrap>
                                    <InputGroup>
                                        <SearchAutocomplete
                                            emptyInput={emptyInput}
                                            handleSelectedKeyword={handleTargetId}
                                        />
                                    </InputGroup>
                                    <Button
                                        className={'btn-brand btn-icon'}
                                        iconClassName={'icon-search'}
                                        onClick={handleSearch}
                                    >
                                        {t('Search', 'Search')}
                                    </Button>
                                </InputWrap>
                            </SearchWrap>
                        </div>
                    </Search>
                }
            >
                <Category />
                <Floor />
            </Filter>
            <PlayControllerDispatchContext.Provider value={dispatch}>
                <PlayControllerStateContext.Provider value={state}>
                    <div className="analysis-layout">
                        <Card
                            key={'1'}
                            header={{
                                title: t('Position List'),
                            }}
                            className={'analysis-list-height'}
                        >
                            <Table data={locationLogListInfo} columns={assetColumn} paging={false} />
                        </Card>
                        <Card
                            key={'2'}
                            header={{
                                title: t('Position History Viewer', 'AnalysisByLocation'),
                            }}
                            className={'analysis-viewer-height'}
                        >
                            <div style={{ height: '109px', width: '100%' }}>
                                <PlayController
                                    dispatch={dispatch}
                                    state={state}
                                    on={!!locationLogListInfo.rows.length}
                                />
                            </div>
                            <div style={{ height: 'calc(100% - 125px)' }}>
                                <Map tile={true} className="z-index-0">
                                    <Control
                                        position={'topleft'}
                                        key={`control-${t(selectedMapOption[0].label)}-${lang}`}
                                    >
                                        <MapOptionSelect
                                            handleChange={handleSelectedMapOption}
                                            value={selectedMapOption.map(v => ({ ...v, label: v.label }))}
                                        />
                                    </Control>
                                    {currentFloor && currentFloor.neLat && (
                                        <RotatedImageOverlay
                                            key={currentFloor.floorId}
                                            url={currentFloor.imgURL}
                                            deg={currentFloor.deg}
                                            bounds={[
                                                [currentFloor.neLat, currentFloor.neLng],
                                                [currentFloor.swLat, currentFloor.swLng],
                                            ]}
                                            onLoad={e => {
                                                setError(false);
                                            }}
                                            onError={e => {
                                                setError(true);
                                            }}
                                        />
                                    )}
                                    {currentFloor && currentFloor.neLat && error && (
                                        <RotatedImageOverlay
                                            key={currentFloor.imgURL}
                                            url={notFoundImg}
                                            bounds={[
                                                [currentFloor.neLat, currentFloor.neLng],
                                                [currentFloor.swLat, currentFloor.swLng],
                                            ]}
                                        />
                                    )}
                                    {markerLogListInfo[state.playTime]
                                        ? markerLogListInfo[state.playTime].map(v => (
                                              <Marker
                                                  key={v.targetId}
                                                  position={[v.lat, v.lng]}
                                                  icon={L.divIcon({
                                                      className: 'simple-marker',
                                                      html: `<div></div>`,
                                                  })}
                                              />
                                          ))
                                        : markerLogListInfo[beforePlayTime] &&
                                          markerLogListInfo[beforePlayTime].map(v => (
                                              <Marker
                                                  key={v.targetId}
                                                  position={[v.lat, v.lng]}
                                                  icon={L.divIcon({
                                                      className: 'simple-marker',
                                                      html: `<div></div>`,
                                                  })}
                                              />
                                          ))}
                                    {selectedMapOption.length > 0 && selectedMapOption[0].value === 'heatmap' && (
                                        <HeatmapLayer
                                            fitBoundsOnLoad={false}
                                            fitBoundsOnUpdate={false}
                                            points={withinOneMinuteHeatmapLogInfo}
                                            gradient={gradient}
                                            longitudeExtractor={m => m[1]}
                                            latitudeExtractor={m => m[0]}
                                            intensityExtractor={m => parseFloat(m[2])}
                                        />
                                    )}
                                    {selectedMapOption.length > 0 &&
                                        selectedMapOption[0].value === 'spaghettiDiagram' && (
                                            <Polyline
                                                pathOptions={{ color: 'red' }}
                                                positions={spaghettiBeforePlaytimeLogInfo}
                                            />
                                        )}
                                    <LayersControl position="topright">
                                        <LayersControl.Overlay
                                            name={t('Zone')}
                                            checked={true}
                                            key={`layer-${t('Zone')}-${lang}`}
                                        >
                                            {geofenceInfo && (
                                                <GeofenceLayer
                                                    geofenceList={geofenceInfo.filter(
                                                        geofence => geofence.floor === currentFloor.floorId,
                                                    )}
                                                />
                                            )}
                                        </LayersControl.Overlay>
                                    </LayersControl>
                                </Map>
                            </div>
                        </Card>
                    </div>
                </PlayControllerStateContext.Provider>
            </PlayControllerDispatchContext.Provider>
            <ConfirmModal
                removeCancel
                confirmText={t('Please select the date first', 'AnalysisByLocation')}
                initModal={selectDateRangeModal}
                toggleModal={() => setSelectDateRangeModal(!selectDateRangeModal)}
            />
            <ConfirmModal
                removeCancel
                confirmText={t('Select a asset')}
                initModal={selectAssetModal}
                toggleModal={() => setSelectAssetModal(!selectAssetModal)}
            />
        </Page>
    );
};

export default AnalysisByAsset;
