import { reqGet, reqPost } from './index';

// 위치로그 목록조회
export const fetchLocationLogList = param => reqGet('/v3/api/common/log/presence', param);

// 위치로그 Excel 파일 내보내기
export const fetchExcelLogExport = ({ columnMetas, zipFileName, fileName, ...restParam }) =>
    reqPost(
        '/v3/api/common/log/presence/export',
        {
            columnMetas,
            zipFileName,
            fileName,
        },
        restParam,
    );
// 스캐너 상태 로그 조회
export const fetchScannerStateLogList = param => reqGet('/v3/api/common/log/scanner/status', param);
export const fetchScannerListApi = param => reqGet(`/v3/api/common/scanner`, { ...param, isAll: 'Y' });
// 스캐너 상태 로그 Excel 파일 내보내기
export const fetchScannerStateExcelLogExport = ({ columnMetas, zipFileName, fileName, isDateFormat, ...restParam }) =>
    reqPost(
        '/v3/api/common/log/scanner/status/export',
        {
            columnMetas,
            zipFileName,
            fileName,
        },
        restParam,
    );

//센서 로그
export const fetchSensorLogList = param => reqGet('/v3/api/common/log/sensor', param);
//센서 로그 목록 정보 조회
export const fetchSensorList = param =>
    reqGet('/v3/api/common/sensor', {
        ...param,
        isAll: 'Y',
    });

// 센서 로그 Excel 파일 내보내기
export const fetchExcelSensorLogExport = ({ columnMetas, zipFileName, fileName, ...restParam }) =>
    reqPost(
        '/v3/api/common/log/sensor/export',
        {
            columnMetas,
            zipFileName,
            fileName,
        },
        restParam,
    );

// 지오펜스 목록 정보
export const fetchGeofenceInfo = param => reqGet('/v3/api/common/geofence', param);
// 지오펜스 로그 목록조회
export const fetchGeofenceLogList = param => reqGet('/v3/api/common/log/presence/geofence', param);
// 지오펜스 Excel 파일 내보내기
export const fetchGeofenceExcelLogExport = ({ columnMetas, zipFileName, fileName, startDate, endDate, ...restParam }) =>
    reqPost(
        '/v3/api/common/log/presence/geofence/export',
        {
            startDate,
            endDate,
            columnMetas,
            zipFileName,
            fileName,
        },
        restParam,
    );
// 엑세 파일 저장내역 조회

export const fetchExcelLogList = param => reqGet(`/v3/api/common/excel`, param);
export const fetchExcelLogApi = param => reqGet(`/v3/api/common/excel/${param.reqNum}`, param);
//// Iot 아이템 정보 로그
// Iot 아이템 정보 로그 목록조회
export const getIotItemInfoLogListApi = param => reqGet('/v3/api/common/log/target/info', param);
// Iot 아이템 정보 로그 엑셀 다운로드 요청
export const createIotItemExcelApi = ({ columnMetas, zipFileName, fileName, ...restParam }) =>
    reqPost(
        '/v3/api/common/log/target/info/export',
        {
            columnMetas,
            zipFileName,
            fileName,
        },
        restParam,
    );

//// Iot 아이템 매핑 로그
// Iot 아이템 매핑 정보 로그 목록 조회
export const getIotItemMappingLogApi = param => reqGet('/v3/api/common/log/target/device/mapping', param);
// Iot 아이템 정보 로그 엑셀 다운로드 요청
export const createIotItemMappingExcelApi = ({ columnMetas, zipFileName, fileName, ...restParam }) =>
    reqPost(
        '/v3/api/common/log/target/device/mapping/export',
        {
            columnMetas,
            zipFileName,
            fileName,
        },
        restParam,
    );

//// 로그인 이력 로그
// 로그인 이력 로그 목록 조회
export const getLoginLogListApi = param => reqGet('/v3/api/common/log/login', param);

//// 로그 정보
// 대상 지오펜스 입&출입 로그
export const fetchGeofenceLog = param => reqGet('/v3/api/common/target/geofence/log', param);
// 대상 위치 로그 정보 목록 조회
export const fetchTargetLocationLog = param => reqGet('/v3/api/common/target/location/log', param);
//매핑된 센서 월별 리포트 조회 API
export const getMappedSensorMonthlyReportApi = param =>
    reqPost(`/v3/api/common/statistics/sensor/monthly/report`, { ...param });

export const fetchHeatMapLocationLogList = param =>
    reqGet('/v3/api/common/log/presence', { ...param, includeDetectedTarget: 'Y', isAll: 'Y' });

//// 센서 현황
// 센서 월별 리포트 데이터 엑셀 다운로드 API
export const getSensorMonthlyReportApi = param => reqPost('/v3/api/common/log/sensor/report/export', param);
