import React from 'react';
import { useHistory } from 'react-router-dom';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GoQuickSearch = () => {
    const history = useHistory();
    const handleClick = () => {
        history.push('/asset/quickSearch');
    };

    return (
        <div className="icon-wrapper icon-wrapper-alt rounded-circle search-icon-div">
            <button className="pnt-btn" onClick={handleClick}>
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
            </button>
        </div>
    );
};

export default GoQuickSearch;
