import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import Map from '../../../Common/Map/Components/Map';
import Control from 'react-leaflet-control';
import Button from '../../../Common/Button';
import DetectedTargetPopup from './DetectedTargetPopup';
import { useTranslation } from 'react-i18next';
import { HeatMapDispatchContext, HeatMapStateContext } from '../index';
import RotatedImageOverlay from '../../../Common/Map/Components/RotatedImageOverlay';
import HeatmapLayer from './HeatmapLayer';
import { setHeatmapPointsLocUpdate } from '../heatMapReducer';

const HeatMap = (props, ref) => {
    const { t } = useTranslation();
    const state = useContext(HeatMapStateContext);
    const dispatch = useContext(HeatMapDispatchContext);
    const { selectedFloor, play, playTime, resultHeatmapPointsLoc } = state;

    useEffect(() => {
        if (play) {
            dispatch(setHeatmapPointsLocUpdate(playTime));
        }
    }, [playTime, play]);

    const mapRef = useRef();

    const [heatMapConfig, setHeatMapConfig] = useState({
        radius: 34,
        blur: 40,
        gradient: {
            0.4: 'blue',
            0.6: 'cyan',
            0.7: 'lime',
            0.8: 'yellow',
            1.0: 'red',
        },
    });

    const [togglePopup, setTogglePopup] = useState(false);
    const handleModalToggle = () => {
        setTogglePopup(!togglePopup);
    };

    return (
        <Map tile={false} ref={mapRef} zoom={13}>
            <Control position="topleft" className={'control-container'}>
                <Button iconClassName={'icon-edit'} onClick={handleModalToggle} className={'btn-icon btn-lightgray'}>
                    {t('RealTimeLocationStatus;Detail')}
                </Button>
            </Control>
            {!!selectedFloor ? (
                selectedFloor.imgURL && (
                    <RotatedImageOverlay
                        key={selectedFloor.floorId}
                        url={`http://${selectedFloor.imgURL}`}
                        deg={selectedFloor.deg}
                        bounds={selectedFloor.bounds}
                        onLoad={e => {
                            if (mapRef.current) {
                                const map = mapRef.current.leafletElement;
                                if (map) {
                                    // map.setView(latLng);
                                }
                            }
                        }}
                    />
                )
            ) : (
                <div
                    style={{
                        color: 'black',
                        fontSize: '1.5rem',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    {t('No Floor')}
                </div>
            )}
            <HeatmapLayer
                blur={heatMapConfig.blur}
                radius={heatMapConfig.radius}
                minOpacity={0.1}
                gradient={heatMapConfig.gradient}
                points={resultHeatmapPointsLoc[0]}
                longitudeExtractor={m => m[1]}
                latitudeExtractor={m => m[0]}
                intensityExtractor={m => parseFloat(m[2])}
            />
            <DetectedTargetPopup modal={togglePopup} toggleModal={handleModalToggle} widgetRef={ref} mapRef={mapRef} />
        </Map>
    );
};

export default forwardRef(HeatMap);
