export const getLeafFloors = floorList => {
    let upperNodeIds = floorList.reduce((acc, curr) => {
        if (curr.upperNodeId !== null) {
            acc.push(curr.upperNodeId);
        }
        return acc;
    }, []);
    let lastFloorList = floorList.filter(v => !upperNodeIds.includes(v.floorId));

    return lastFloorList;
};

export const forObj = (obj, callback = function () {}) => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const prop = obj[key];
            callback(prop);
        }
    }
    return obj;
};
