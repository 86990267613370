import React, { useState, useEffect } from 'react';
import useTranslation from '../../../../util/hooks/useTranslation';
import TreeSelectWithOption from '../Components/SelectWithOption/TreeSelectWithOption';
import { getOwnershipListApi } from '../../../../api/asset';
import useAsync from '../../../../util/hooks/useAsync';

const OwnershipSelect = ({ selected, isEditable, handleChange, disableOption }) => {
    const t = useTranslation('Filter');
    const [selectedOption, setSelectedOption] = useState(selected);
    const [treeData, setTreeData] = useState([]);

    useAsync({
        promise: getOwnershipListApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        resolve: res => {
            let responseData = [];
            if (res && res.rows) {
                responseData = res.rows.map(v => {
                    return { ...v, own_dep_id: v.groupNum, groupNum: v.groupNum.toString() };
                });
            }
            setTreeData(responseData);
        },
    });

    const handleSelectedOptionChange = (selected, isEditable) => {
        // const groupNums = treeData.reduce((acc, curr) => {
        //     if ((selected || []).includes(curr.groupId)) {
        //         acc.push(curr.groupNum);
        //     }
        //     return acc;
        // }, []);
        // console.log('ownership onchange', selected);
        handleChange(selected, isEditable);
        setSelectedOption(selected);
    };

    useEffect(() => {
        if (treeData.length && selected && selected.length) {
            setSelectedOption(
                selected,
                // treeData.reduce((acc, curr) => {
                //     if (selected.includes(curr.groupNum)) {
                //         acc.push(curr.groupId);
                //     }
                //     return acc;
                // }, []),
            );
        } else {
            setSelectedOption([]);
        }
    }, [selected, treeData]);

    return (
        <TreeSelectWithOption
            data={treeData}
            title={t('Ownership Organization')}
            valueKey={'groupNum'}
            treeKey={'groupId'}
            parentKey={'parentGroupId'}
            labelKey={'groupName'}
            selected={selectedOption}
            onChange={handleSelectedOptionChange}
            isEditable={isEditable}
            disableOption={disableOption}
        />
    );
};

export default OwnershipSelect;
