import React from 'react';
import useAsync from '../../../../util/hooks/useAsync';
import { getDashboardListApi } from '../../../../api/dashboard';
import { fetchCompany, fetchUserInfo, getUserGroupInfoApi } from '../../../../api/login';
import { setBookmarkMenu } from '../../../../reducers/CustomMenu';
import { setAdditionalInfo } from '../../../../reducers/UserInfo';
import { useDispatch, useSelector } from 'react-redux';

// 유저가 만들어둔 대쉬보드, 유저의 이름, 유저가 속해있는 회사정보 불러오기
const useMoreUserInfo = () => {
    const dispatch = useDispatch();
    const { lang } = useSelector(state => state.UserInfo);

    const { promise: getMoreUserInfo } = useAsync({
        promise: [getDashboardListApi, fetchUserInfo, fetchCompany, getUserGroupInfoApi],
        param: [{ isIndividual: 'Y', show: 'Y', isAll: 'Y' }, { lookupItems: 'userName' }, null, null],
        resolve: ([{ data: menuInfo }, { data: userInfo }, { data: companyInfo }, { rows: groupInfo }]) => {
            const additionalInfo = {
                userNum: userInfo.userNum,
                userName: userInfo.userName,
                roleNum: userInfo.roleNum,
                groupId: null,
                groupName: '',
                groupNum: '',
                groupNums: [],
                companyInfo,
                lang,
            };

            if (groupInfo && groupInfo[0]) {
                // 자산 대여를 위한 그룹 정보 저장. 추후 수정 필요
                additionalInfo.groupId = groupInfo[0].groupId;
                additionalInfo.groupName = groupInfo[0].groupName;
                additionalInfo.groupNum = groupInfo[0].groupNum;

                additionalInfo.groupNums = groupInfo.map(v => v.groupNum);
            }

            //4-1. DashBoard와 Home 설정해둔 DashBoard가 존재할 시 뷸러오기
            if (menuInfo.rows[0] && menuInfo.rows.find(v => v.home === 'Y')) {
                additionalInfo.home = menuInfo.rows.find(v => v.home === 'Y').menuNum;
                dispatch(setBookmarkMenu(menuInfo.rows));
            } else {
                dispatch(setBookmarkMenu([]));
            }

            dispatch(setAdditionalInfo(additionalInfo));
        },
    });

    return getMoreUserInfo;
};

export default useMoreUserInfo;
