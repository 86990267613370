import React, { useState, useEffect } from 'react';
import useAsync from '../../../../util/hooks/useAsync';
import SearchableSelectWithOption from '../Components/SelectWithOption/SearchableSelectWithOption';
import { getAssetStatusListApi } from '../../../../api/asset';
import useTranslation from '../../../../util/hooks/useTranslation';

const AssetStatusSelect = ({ selected, isEditable, handleChange, disableOption, className }) => {
    const t = useTranslation('Filter');
    const [assetStatusList, setAssetStatusList] = useState([]);
    const [selectedOption, setSelectedOption] = useState(selected);

    useAsync({
        promise: getAssetStatusListApi,
        immediate: true,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            setAssetStatusList(
                res.rows.map(v => {
                    return { ...v, statusCategoryNums: v.statusCategoryNum.toString() };
                }),
            );
        },
    });

    const handleSelectedOptionChange = (selected, isEditable) => {
        handleChange(selected, isEditable);
        setSelectedOption(selected);
    };

    useEffect(() => {
        if (assetStatusList.length && selected && selected.length) {
            setSelectedOption(selected);
        } else {
            setSelectedOption([]);
        }
    }, [selected, assetStatusList]);

    return (
        <SearchableSelectWithOption
            data={assetStatusList}
            onChange={handleSelectedOptionChange}
            title={t('Asset Status')}
            selected={selectedOption}
            labelKey={'statusCategoryName'}
            valueKey={'statusCategoryNums'}
            className={className}
            isEditable={isEditable}
            disableOption={disableOption}
        />
    );
};

export default AssetStatusSelect;
