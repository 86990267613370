import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    scannerList: [],
    startDate: null,
    endDate: null,
};

const { actions, reducer } = createSlice({
    name: 'scannerLog',
    initialState,
    reducers: {
        setScannerList: (state, action) => {
            state.scannerList = action.payload.rows.map(item => {
                const { scannerName, scannerNum } = item;
                return { label: scannerName, value: scannerNum };
            });
        },
        setStartDate: (state, action) => {
            state.startDate = action.payload;
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload;
        },
    },
});

export const { setScannerList, setStartDate, setEndDate } = actions;
export default reducer;
