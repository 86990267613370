import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faHome, faShareAlt, faStar } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { selectDashboard, setDashboardList } from '../../../../../reducers/Dashboards/Dashboards';
import { faClone, faFileAlt, faTrashAlt, faFileWord } from '@fortawesome/free-regular-svg-icons';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { deleteDashboardApi, getDashboardListApi, updateDashboardApi } from '../../../../../api/dashboard';
import ConfirmModal from '../../../../Common/ConfirmModal';
import useAsync from '../../../../../util/hooks/useAsync';
import { useTranslation } from 'react-i18next';
import { widgetInfoExport } from '../../util';

const BookmarkIcon = ({ value, row: { original } }) => {
    const dispatch = useDispatch();
    const { promise: getDashboardList } = useAsync({
        promise: getDashboardListApi,
        resolve: response => {
            dispatch(setDashboardList(response));
        },
    });

    const { promise: updateDashboardBookmark } = useAsync({
        promise: updateDashboardApi,
        resolve: response => {
            getDashboardList({ isIndividual: 'Y' });
        },
    });

    return (
        <>
            {original.show === 'Y' &&
                (original.home === 'Y' ? (
                    <FontAwesomeIcon icon={faHome} style={{ color: 'var(--org)' }} />
                ) : (
                    <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: '#ffd400', cursor: 'pointer' }}
                        onClick={() => updateDashboardBookmark({ menuNum: original.menuNum, show: 'N' })}
                    />
                ))}
            {original.show === 'N' && (
                <FontAwesomeIcon
                    icon={faStar}
                    style={{ cursor: 'pointer' }}
                    onClick={() => updateDashboardBookmark({ menuNum: original.menuNum, show: 'Y' })}
                />
            )}
        </>
    );
};
const ShareIcon = ({ value, row: { original } }) => {
    return original.share === 'Y' && <FontAwesomeIcon icon={faShareAlt} />;
};
const DashboardSubMenu = ({ value, row: { original } }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [deleteModal, setDeleteModal] = useState(false);
    const { dashboardListInfo, selectedDashboard } = useSelector(state => state.Dashboards);
    const { promise: getDashboardList } = useAsync({
        promise: getDashboardListApi,
        resolve: response => {
            dispatch(setDashboardList(response));
        },
    });
    const { promise: deleteDashBoard } = useAsync({
        promise: deleteDashboardApi,
        resolve: response => {
            getDashboardList({ pageSize: dashboardListInfo.pageSize, page: dashboardListInfo.page, isIndividual: 'Y' });
        },
    });
    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };
    const handleDelete = dashboardInfo => {
        deleteDashBoard(dashboardInfo);
    };

    return (
        <>
            <UncontrolledDropdown>
                <DropdownToggle tag={'div'} className={'flex-center'}>
                    <FontAwesomeIcon icon={faBars} style={{ width: '100%', fontSize: '1.2rem', cursor: 'pointer' }} />
                </DropdownToggle>
                <DropdownMenu className={'grid-sub-menu'}>
                    <DropdownItem
                        onClick={e => {
                            dispatch(selectDashboard(original));
                        }}
                    >
                        <FontAwesomeIcon className={'mr-2 fa-w-16'} icon={faFileAlt} />
                        {t('Dashboard;Dashboard detail')}
                    </DropdownItem>
                    {!isMobile && (
                        <Link to={`/dashboards/edit/${original.menuNum}`}>
                            <DropdownItem>
                                <FontAwesomeIcon className={'mr-2 fa-w-16'} icon={faClone} />
                                {t('Dashboard;Edit the dashboard')}
                            </DropdownItem>
                        </Link>
                    )}
                    <DropdownItem
                        onClick={() => {
                            // dispatch(deleteDashboard(original.menuNum));
                            toggleDeleteModal();
                        }}
                    >
                        <FontAwesomeIcon className={'mr-2 fa-w-16'} icon={faTrashAlt} />
                        {t('Dashboard;Delete the dashboard')}
                    </DropdownItem>
                    {/*
                    대시보드 목록 버튼에 내보내기 버튼 추가
                    widgetInfo 데이터가 없을 경우 hidden
                       */}
                    {original.widgetInfo && (
                        <DropdownItem
                            onClick={() =>
                                widgetInfoExport({
                                    filename: `${original.menuName}.json`,
                                    exportWidgetInfo: JSON.parse(original.widgetInfo),
                                })
                            }
                        >
                            <FontAwesomeIcon className={'mr-2 fa-w-16'} icon={faFileWord} />
                            {t('Dashboard;Export')}
                        </DropdownItem>
                    )}
                </DropdownMenu>
            </UncontrolledDropdown>
            <ConfirmModal
                initModal={deleteModal}
                toggleModal={toggleDeleteModal}
                header={{ title: t('ConfirmModal;Confirm') }}
                confirmText={t('Dashboard;Do you want to delete the dashboard?')}
                okCallback={handleDelete}
                callbackParam={original}
            />
        </>
    );
};

export { BookmarkIcon, ShareIcon, DashboardSubMenu };
