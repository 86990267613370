import { atom, selector } from 'recoil';
import { rmsKey } from './keys/rms';

export const filterList = atom({
    key: rmsKey.filter.list,
    default: [],
});

export const filterMenuList = selector({
    key: rmsKey.filter.menuList,
    get: ({ get }) => {
        const allFilterList = get(filterList);
        return allFilterList.filter(v => !!v);
    },
});
