import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Hamburger from 'react-hamburgers';

import AppMobileMenu from '../AppMobileMenu';

import { setEnableClosedSidebar } from '../../../reducers/ThemeOptions';

const HeaderLogo = props => {
    const dispatch = useDispatch();
    const { enableClosedSidebar } = useSelector(state => state.ThemeOptions);
    const [active, setActive] = useState(false);

    const toggleActive = () => {
        setActive(!active);
    };

    const toggleEnableClosedSidebar = () => {
        dispatch(setEnableClosedSidebar(!enableClosedSidebar));
    };

    return (
        <>
            <div className="app-header__logo">
                <div className="logo-src">Asset Tracking</div>
                <div className="header__pane ml-auto">
                    <div onClick={toggleEnableClosedSidebar}>
                        <Hamburger active={enableClosedSidebar} type="elastic" onClick={toggleActive} />
                    </div>
                </div>
            </div>
            <AppMobileMenu />
        </>
    );
};

export default HeaderLogo;
