import React from 'react';
import styled from 'styled-components';

const FilterOptionBox = styled.div`
    display: inline-block;
    margin-right: 0.5rem;
    //&:not(:last-child) {
    //    margin-right: 0.5rem;
    //}
`;

export default FilterOptionBox;
