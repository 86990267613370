import React, { useState } from 'react';
import Button from '../../../../Common/Button';
import useTranslation from '../../../../../util/hooks/useTranslation';
import DatePicker from '../../../../Common/DatePicker';
import moment from 'moment';
import FilterSearchGroup from '../../../../Common/FilterSearchGroup';
import { SelectGroup, FilterList } from '../../../../Common/FilterSearchGroup/Components/Part';
import FilterGroup from '../../../../Common/FilterSearchGroup/Components/FilterGroup';
import FilterButtonArea from '../../../../Common/FilterSearchGroup/Components/FilterButtonArea';

const Search = ({ getExcelLogList }) => {
    const t = useTranslation('Search');

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleSearchClick = () => {
        getExcelLogList({
            startDate: startDate ? moment(startDate).unix() : null,
            endDate: endDate ? moment(endDate).unix() : null,
        });
    };

    const handleRefreshClick = e => {
        setStartDate(null);
        setEndDate(null);
        getExcelLogList();
    };

    const handleYesterdayButtonClick = () => {
        const start = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
        const end = new Date();

        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);

        setStartDate(start.valueOf());
        setEndDate(end.valueOf() - 1);
    };

    return (
        <FilterSearchGroup>
            <FilterGroup className={'bg-brand'} label={t('Search', 'Search')}>
                <FilterList>
                    <SelectGroup>
                        <div className={'datePicker-container'}>
                            <DatePicker
                                value={startDate}
                                handleChange={selected => setStartDate(selected)}
                                valueType={'ms'}
                                maxDate={endDate || new Date()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <div className={'text-center'}> ~ </div>
                    <SelectGroup>
                        <div className={'datePicker-container'}>
                            <DatePicker
                                value={endDate}
                                handleChange={selected => setEndDate(selected)}
                                valueType={'ms'}
                                minDate={startDate}
                                maxDate={new Date()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                </FilterList>
                <FilterButtonArea>
                    <Button className={'btn-secondary btn-yesterday'} onClick={handleYesterdayButtonClick}>
                        {t('Yesterday', 'Button')}
                    </Button>
                    <Button className={'btn-brand btn-icon'} iconClassName={'icon-search'} onClick={handleSearchClick}>
                        {t('Search', 'Search')}
                    </Button>
                    <Button
                        className="btn-danger btn-icon-only"
                        iconClassName={'icon-replay'}
                        onClick={handleRefreshClick}
                    />
                </FilterButtonArea>
            </FilterGroup>
        </FilterSearchGroup>
    );
};

export default Search;
