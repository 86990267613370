import React, { useState, useEffect, useReducer, createContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useAsync from '../../../util/hooks/useAsync';
import { setOauthInfo, setLanguage } from '../../../reducers/UserInfo';
import { fetchOAuthToken } from '../../../api/login';
import Button from '../../Common/Button';
import Select, { components } from 'react-select';
import language from '../../../util/staticData/language.json';
import { reducer, initialState, setShowErrorMsg } from './reducer';
import cx from 'classnames';

// For Further CSS
import { decodeInfo } from '../../../util/common/util';
import useTranslation from '../../../util/hooks/useTranslation';
import LoginForm from './Components/LoginForm';
import FindForm from './Components/FindForm';
import ChangeForm from './Components/ChangeForm';
import CompleteForm from './Components/CompleteForm';
import useMoreUserInfo from './hooks/useMoreUserInfo';

const Control = ({ children, ...rest }) => (
    <components.Control {...rest}>
        <span
            className="icon-globe"
            style={{ marginLeft: '.5rem', filter: 'invert(1)', transform: "scale('0.8rem')" }}
        />
        {children}
    </components.Control>
);

export const LoginStateContext = createContext();
export const LoginDispatchContext = createContext();

const FormByStep = ({ step }) => {
    switch (step) {
        case 'LOGIN':
            return <LoginForm />;
        case 'FIND':
            return <FindForm />;
        case 'CHANGE':
            return <ChangeForm />;
        case 'COMPLETE':
            return <CompleteForm />;
        default:
            return <LoginForm />;
    }
};

const Login = props => {
    const storeDispatch = useDispatch();
    const t = useTranslation('Login');
    const [state, dispatch] = useReducer(reducer, initialState);
    const { step } = state;
    const [showMobileLogin, setShowMobileLogin] = useState(false);

    const history = useHistory();
    const { oAuthInfo: ssoOAuthInfo } = useParams();

    const { userInfo, lang } = useSelector(state => state.UserInfo);

    const { promise: initialLogin } = useAsync({
        promise: fetchOAuthToken,
        resolve: res => {
            // 2. 로그인 인포가 보내지고 access token이 받아져왔을때
            if (res.scope) {
                storeDispatch(setOauthInfo(res));
                getMoreUserInfo();
            }
        },
        reject: err => {
            dispatch(setShowErrorMsg(true));
        },
    });

    const getMoreUserInfo = useMoreUserInfo();

    // 5. 유저 이름, 유저가 속한 회사 정보가 받아와진 경우 실행
    useEffect(() => {
        if (userInfo && userInfo.companyInfo && userInfo.companyInfo.timeZone) {
            // 대쉬보드의 홈설정이 되어있는경우
            if (userInfo.home) {
                history.replace(`/dashboards/${userInfo.home}`);
            }
            // 대쉬보드의 홈설정이 되어있지 않은경우
            else {
                history.replace('/');
            }
        } else {
            history.replace('/login');
        }
    }, [userInfo]);

    useEffect(() => {
        if (ssoOAuthInfo) {
            try {
                const decodedInfo = decodeInfo(atob(ssoOAuthInfo));
                initialLogin({
                    grant_type: 'refresh_token',
                    refresh_token: decodedInfo.refresh_token,
                });
            } catch (e) {
                console.log(e);
            }
        }
    }, [ssoOAuthInfo]);

    // 모바일 창에서 로그인 버튼 클릭시 아이디 패스워드를 입력할 수 있는 창으로 변경
    const handleMobileLoginClick = e => {
        e.preventDefault();
        setShowMobileLogin(!showMobileLogin);
    };

    // 언어 변환
    const handleLangChange = selectedOption => {
        storeDispatch(setLanguage(selectedOption.value));
    };

    return (
        <LoginDispatchContext.Provider value={dispatch}>
            <LoginStateContext.Provider value={state}>
                <div className="page-landering">
                    <div className={`page-landering__cont-wrap ${showMobileLogin && 'login_clicked'}`}>
                        {/* 모바일용 헤더 */}
                        <div className={`lander-mobile-header`}>
                            <div className="mobile-header__wrap">
                                <div className="logo-box">
                                    <div className="pnt-logo logo-o-w" />
                                </div>
                                <div className="language-box">
                                    <Select
                                        isSearchable={false}
                                        value={language.find(v => v.value === lang)}
                                        onChange={handleLangChange}
                                        options={language}
                                        components={{ Control }}
                                        styles={{
                                            container: (provided, state) => ({
                                                ...provided,
                                                minWidth: '8rem',
                                                maxWidth: '12rem',
                                            }),
                                            control: (provided, state) => ({
                                                ...provided,
                                                cursor: 'pointer',
                                                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                                border: '1px solid #e6eaee',
                                            }),
                                            singleValue: (provided, state) => ({
                                                ...provided,
                                                lineHeight: 2,
                                                color: 'white',
                                            }),
                                            option: (provided, state) => {
                                                return {
                                                    ...provided,
                                                    color: 'white !important',
                                                    backgroundColor: state.isSelected
                                                        ? '#3E66FB'
                                                        : state.isFocused && 'rgba(255,255,255,0.5)',
                                                    cursor: state.isFocused && 'pointer',
                                                };
                                            },
                                            menu: (provided, state) => {
                                                return {
                                                    ...provided,
                                                    border: '1px solid #e6eaee',
                                                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                                };
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* 데스크용 */}
                        <div className={`lander-info`}>
                            <div className="lander-info__wrap">
                                <div className="info--logo">
                                    <div className="pnt-logo logo-b-b" />
                                </div>
                                <div className="info--txt">
                                    <h2 className="txt__main">Asset Tracking</h2>
                                    <b className="txt__sub">Efficiency starts with knowing where things are.</b>
                                    <p className="txt__desc">
                                        IndoorPlus+ provides you a location-based IoT asset solution and you will not
                                        miss out on anything you value.
                                        <br />
                                        Looking for something? Let IndoorPlus+ assist you.
                                    </p>
                                </div>
                                <div className="info--language">
                                    <Select
                                        isSearchable={false}
                                        value={language.find(v => v.value === lang)}
                                        onChange={handleLangChange}
                                        options={language}
                                        components={{ Control }}
                                        styles={{
                                            container: (provided, state) => ({
                                                ...provided,
                                                minWidth: '8rem',
                                                maxWidth: '12rem',
                                                fontSize: '.8rem',
                                            }),
                                            control: (provided, state) => ({
                                                ...provided,
                                                cursor: 'pointer',
                                                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                                border: '1px solid #e6eaee',
                                            }),
                                            singleValue: (provided, state) => ({
                                                ...provided,
                                                lineHeight: 2,
                                                color: 'white',
                                            }),
                                            option: (provided, state) => {
                                                return {
                                                    ...provided,
                                                    color: 'white !important',
                                                    backgroundColor: state.isSelected
                                                        ? '#3E66FB'
                                                        : state.isFocused && 'rgba(255,255,255,0.5)',
                                                    cursor: state.isFocused && 'pointer',
                                                };
                                            },
                                            menu: (provided, state) => {
                                                return {
                                                    ...provided,
                                                    border: '1px solid #e6eaee',
                                                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                                };
                                            },
                                        }}
                                    />
                                </div>
                                <div className="info--login-btn">
                                    <Button
                                        className={'btn-brand btn-animation-move'}
                                        iconClassName={'icon-next'}
                                        onClick={handleMobileLoginClick}
                                    >
                                        {t('Login')}
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className={`lander-login`}>
                            <div
                                className={cx(
                                    'lander-login__wrap flx-col',
                                    step === 'CHANGE' && 'flx-full pnt-label-6 label-col-mobile',
                                )}
                            >
                                <FormByStep step={step} />
                            </div>
                        </div>
                    </div>
                    <div className="page-landering__bg" />
                </div>
            </LoginStateContext.Provider>
        </LoginDispatchContext.Provider>
    );
};

export default Login;
